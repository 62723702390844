/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./summary-dialog.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./summary-dialog.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_SummaryDialogComponent = [i0.styles];
var RenderType_SummaryDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SummaryDialogComponent, data: {} });
export { RenderType_SummaryDialogComponent as RenderType_SummaryDialogComponent };
function View_SummaryDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["id", "icon-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.iconClass + " severity-color"); _ck(_v, 1, 0, currVal_0); }); }
function View_SummaryDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cancelText; _ck(_v, 1, 0, currVal_0); }); }
export function View_SummaryDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["id", "dialog-containter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "primary": 0, "warn": 1, "danger": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummaryDialogComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["id", "header-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["id", "msg-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["id", "footer-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SummaryDialogComponent_2)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.severity <= 1), (_co.severity == 2), (_co.severity == 3)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.iconClass; _ck(_v, 5, 0, currVal_1); var currVal_5 = _co.showCancel; _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.message; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.confirmText; _ck(_v, 14, 0, currVal_4); }); }
export function View_SummaryDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-summary-dialog", [], null, null, null, View_SummaryDialogComponent_0, RenderType_SummaryDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.SummaryDialogComponent, [i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SummaryDialogComponentNgFactory = i1.ɵccf("app-summary-dialog", i3.SummaryDialogComponent, View_SummaryDialogComponent_Host_0, {}, {}, []);
export { SummaryDialogComponentNgFactory as SummaryDialogComponentNgFactory };
