import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import * as moment from 'moment';
var DateTimeService = /** @class */ (function () {
    function DateTimeService(_appLocalizationService) {
        this._appLocalizationService = _appLocalizationService;
    }
    DateTimeService.prototype.createDateRangePickerOptions = function () {
        var options = {
            locale: {
                format: 'L',
                applyLabel: this._appLocalizationService.l('Apply'),
                cancelLabel: this._appLocalizationService.l('Cancel'),
                customRangeLabel: this._appLocalizationService.l('CustomRange')
            },
            min: moment('2015-05-01'),
            minDate: moment('2015-05-01'),
            max: moment(),
            maxDate: moment(),
            opens: 'left',
            ranges: {}
        };
        return options;
    };
    return DateTimeService;
}());
export { DateTimeService };
