import {
    AppMarkets,
    FixedPriceType,
    OrderDeliveryMethod,
    PaygateMode,
    PaymentTransactionStatus,
    PaymentTypes,
    ServiceApplicationType,
    ServiceCriteria,
    ServicePropertyMappingType
} from './AppEnums';
import {PriceListUploadType} from "@app/admin/price-list/price-list-selection/price-list-selection.component";

export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';
    static readonly defaultDateFormat = 'DD-MM-YYYY';
    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNotifyDayCount: number;
    static helpDeskEmail: string;
    static helpDeskEmailAuthSupport: string;
    static supportEmailAddress: string;
    static partnerNetworkUrl: string;
    static customDomain: string;
    static customTitle: string;
    static customFaviconUrl: string;
    static googleAnalyticsTrackingId:string;
    static enableTracking:Boolean;
    static idleTimeMinutes: number = 60;
    static selecteMarket: number = AppMarkets.GeneralMarket; //Default to marketplace on landing page
    static whatWeDoLink: string = 'https://info.axiz.com/what-we-do';
    
    //#region Payment Gateway Config 
    static paygateIntegrationMode: PaygateMode = PaygateMode.Production; 
    static defaultTransactionStatus: PaymentTransactionStatus = 2;
    static BlockCDCCloseCardForTesting: boolean = false;
    static bypassPaymentGatewayMessage: string = 'The system is currently configured to bypass the payment gateway.';
    static runPaygateLocalMessage: string = 'The system is currently configured to run paygate locally. Make sure you have updated the hosts file with the localPaygateTestUrl value.';
    static localPaygateTestUrl: string;
    //#endregion

    static title = 'AxizDigital'
    static resellerKey = 'reseller';
    static customerKey = 'customer';

    /// <summary>
    /// The new relationship association.
    /// </summary>
    static newRelationshipAssociation = 'NewAssociation';

    static styleVersionNumber = 'v1';

    static vatCost = 'VAT Cost';
    static inclusive = 'inclusive';
    static SellPrice = 'Sell Price';
    static Discount = 'Discount';
    static Quantity = 'Quantity';
    static VATMultipler: number = 0.15;
    static addVAT = ((vatMultiplier: number, priceExc: number) => {
        return (priceExc * (vatMultiplier + 1));
    });  
    static removeVAT = ((vatMultiplier: number, priceInc: number) => {
        return (priceInc / (vatMultiplier + 1));
    });

    static Suspended = 'suspended';
    static OnlineServicesNCE = 'OnlineServicesNCE';
    static NCESubsciptionAutoRenewal: 'NCESubsciptionAutoRenewal';
    static NCECancelSubscription: 'NCECancelSubscription';
    static NCETrial = 'NCETrial';

     /// <summary>
    /// TaggingBackToStoreArrow.
    /// </summary>
    static TaggingBackToStore: 'TaggingBackToStore';

    /// <summary>
    /// The existing relationship association
    /// </summary>
    static existingRelationshipAssociation = 'ExistingAssociation';

    static readonly domainState = {
        cloudAgreement: 'cloudAgreement',
        domainVerification: 'domainVerification',
    };

    static activeState = 'active';

    static SingleMapping = 'Single';
    static MultipleMapping = 'Multiple';

    static DeliverOrder = 'Delivery';
    static CollectOrder = 'Collect';

    static manageTenantUrl = 'manage-tenants';

    static customHeaders = {
        viewingTenantID: 'viewing-tenant-id',
    };

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Digital',
    };
    
    static readonly subscriptionStatus = [
        { value: 'active', label: 'Active' },
        { value: 'pending', label: 'Pending' },
        { value: 'suspended', label: 'Suspended' },
        { value: 'deleted', label: 'Deleted' },
        { value: 'expired', label: 'Expired' },
        { value: 'disabled', label: 'Disabled' },
    ];

    static readonly billingCycleTypes = [
        { value: 'unknown', label: 'Unknown' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'annual', label: 'Annual' },
        { value: 'none', label: 'None' },
        { value: 'oneTime', label: 'OneTime' },
    ];

    static readonly ServiceCriteriaTypeList = [
        { value: ServiceCriteria.StartsWith, label: 'Starts with' },
        { value: ServiceCriteria.Contains, label: 'Contains' },
        { value: ServiceCriteria.EndWith, label: 'Ends with' },
        { value: ServiceCriteria.ExactMatch, label: 'Exact Match' },
    ];

    static readonly ServicePropertyMappingTypeList = [
        { value: ServicePropertyMappingType.SingleMapping, label: 'Single', isActive: true },
        { value: ServicePropertyMappingType.MultipleMapping, label: 'Multiple', isActive: false },
    ];

    static readonly OrderDeliveryMethodList = [
        { value: OrderDeliveryMethod.DeliverOrder, label: 'Delivery', isActive: true, feature: 'App.CartFeature.CartDeliveryEnabled', enabled: false },
        { value: OrderDeliveryMethod.CollectOrder, label: 'Collect', isActive: false, feature: 'App.CartFeature.CollectionEnabled', enabled: false },
    ];

    static readonly ServiceApplicationTypeList = [
        { value: ServiceApplicationType.AdHoc, label: 'Ad hoc', description: 'The service will not automatically be included.' },
        {
            value: ServiceApplicationType.Custom,
            label: 'Price Rule',
            description:
                'Specific rules for when the service should be added can be setup once pricing method is selected. The service will also be added by default.',
        },
        {
            value: ServiceApplicationType.Default,
            label: 'Default',
            description:
                'The service will automatically be added to all estimates that are created. The service will also not be linked to a specific product.',
        },
    ];

    static readonly FixedPriceTypeList = [
        { value: FixedPriceType.Margin, label: FixedPriceType.Margin, isActive: true },
        { value: FixedPriceType.IPA, label: FixedPriceType.IPA, isActive: false },
    ];

    static readonly ServiceStatus = [
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' },
    ];

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
    };
    
    static readonly sessionNames = {
        tenantId: 'Abp.TenantId',
    }

    static readonly grid = {
        defaultPageSize: 10,
    };

    static readonly momentThreshold = {
        maxShowDaysOnly: 45,
    };

    static readonly azureOfferId = 'MS-AZR-0145P';

    static readonly AzurePlanId = "DZH318Z0BPS6"

    static readonly AzurePlan = "Azure plan"

    static readonly customPrice = 'Custom Price';

    static readonly firstMonthExpiryWarning = 3;
    static readonly secondMonthExpiryWarning = 2;
    static readonly thirdMonthExpiryWarning = 1;
    static readonly daysTillSubscriptionCancelExpires = 7;
    static readonly daysTillAdobeSubscriptionCancelExpires = 14;
    static readonly timeoutRemainingSeconds = 120;

    static readonly priceRuleCategories = [
        {
            id: 1,
            heading: 'Governance',
            isPartnerSpecific: false,
            isProductSpecific: false,
            currentUserHasPermission: false
        },
        {
            id: 2,
            heading: 'Product',
            isPartnerSpecific: false,
            isProductSpecific: true,
            currentUserHasPermission: false
        },
        {
            id: 3,
            heading: 'Partner',
            isPartnerSpecific: true,
            isProductSpecific: true,
            currentUserHasPermission: false
        },
        {
            id: 4,
            heading: 'Special',
            isPartnerSpecific: true,
            isProductSpecific: true,
            currentUserHasPermission: false
        },
    ];

    static readonly pricingRuleStatus = {
        All: {
            id: 0,
        },
        Active: {
            id: 1,
        },
        Disabled: {
            id: 2,
        },
        Expired: {
            id: 3,
        },
    };

    static readonly priceRuleStatuses = [
        {
            id: 0,
            name: 'All',
        },
        {
            id: 1,
            name: 'Active',
        },
        {
            id: 2,
            name: 'Inactive',
        },
        {
            id: 3,
            name: 'Expired',
        },
    ];

    static readonly PaymentMethods = [
        {
            id: PaymentTypes.AxizCredit,
            name: 'Axiz Credit',
            value: PaymentTypes[PaymentTypes.AxizCredit],
        },
        {
            id: PaymentTypes.CapitecCredit,
            name: 'Capitec Credit',
            value: PaymentTypes[PaymentTypes.CapitecCredit],
        },
        {
            id: PaymentTypes.AxizCDC,
            name: 'Paygate',
            value: PaymentTypes[PaymentTypes.AxizCDC],
        }
        ,
        {
            id: PaymentTypes.OrderRequest,
            name: 'Order Request',
            value: PaymentTypes[PaymentTypes.OrderRequest],
        },
        {
            id: PaymentTypes.AxizCloudCredit,
            name: 'Axiz Cloud Credit',
            value: PaymentTypes[PaymentTypes.AxizCloudCredit],
        },
        {
            id: PaymentTypes.ProofOfPayment,
            name: 'Proof Of Payment Request',
            value: PaymentTypes[PaymentTypes.ProofOfPayment],
        },
    ];

    static readonly PaymentTerms = [
        {
            value: 24,
            name: '24 Months',
            factor: 0.05050
        },
        {
            value: 36,
            name: '36 Months',
            factor: 0.03562
        }
    ];

    static readonly GoogleAnalyticsSource = `https://www.googletagmanager.com/gtag/js?id=`

    static readonly PayGateProcessURL = 'https://secure.paygate.co.za/payweb3/process.trans';
    
    //GM Additional Info
    static readonly AppliedView = 'AppliedView';
    static readonly ProductDescription = 'ProductDescription';
    static readonly ReservationQuantity = 'ReservationQuantity';
    static readonly DefaultImageUrl = 'DefaultImageUrl';
    static readonly PurchaseOptions = 'PurchaseOptions';
    static readonly SellPriceCurrencyCode = 'SellPriceCurrencyCode';
    static readonly IncSellPrice = 'IncSellPrice';
    static readonly UpliftType = 'UpliftType';
    static readonly CapitecCredit = 'CapitecCredit'
    static readonly LongDescription = 'LongDescription'; 
    
    static readonly SupportUploadFileTypes: string[] = ["xls", "xlsx", "doc", "docx", "jpg", "jpeg", "png", "pdf"];
    static readonly SupportEmailAddress: string = "digitalsupport@axiz.com"
    static readonly TermsAndConditionsLink = "https://axizdocsblobstorage.blob.core.windows.net/axizdocs/Terms and Conditions/Axiz Customer Terms and Conditions - NO TERMS_v5.0 (Final) (002).pdf"
    static readonly DigitalCommercePath: string = '/app/main/landing-page';
    static readonly EnterpriseCommercePath: string = '/app/main/landing-page';
    static readonly RegisterDocumentStorageKeyPrefix: string = 'RegisterBusinessDocument-';
    
    // START Landing Page
    static readonly LandingPageConfigCleanupStorageKey: string = 'LandingPageConfigs';
    static readonly BannersImageStorageKey: string = 'BannerImage-';
    static readonly LandingPageGeneralDefaultConfig = 'General';
    static readonly LandingPageCloudDefaultConfig = 'Cloud';
    static readonly LandingPageEnterpriseDefaultConfig = 'Enterprise';
    static readonly LandingPagePath: string = '/app/main/landing-page';
    // END Landing Page

    static readonly FlexOffer = 'Flex Offer';
    static readonly MicrosoftOnboardRequestKey = "OnboardMicrsoftRequest"
    static readonly ProofOfPayment = 'ProofOfPayment'
    
    //START Bulk Import
    static readonly BulkImportSupportUploadFileTypes: string[] = ["xls", "xlsx"];
    //END Bulk Import

    //START Volume pricing
    static readonly VolumeDefaultMinValue: number = 1;
    //END Volume pricing

    static readonly PublishImage = 'assets/common/images/Publish.svg';
    static readonly AutoRenewalImage = 'assets/common/images/Manage_auto_renew_not_available.svg';
    static readonly DeactivateImage = 'assets/common/images/Deactivate.svg';
    static readonly ConfirmWarning = 'assets/common/images/ConfirmWarning.png';

    static readonly primaryButton='btn-primary';
    static readonly primaryButtonOutlined='m-btn--primary--inverted';
    static readonly dangerButton='m-btn--danger';
    static readonly dangerButtonOutlined='m-btn--danger--inverted';
    static readonly redColor='red';

    static readonly PriceMaintenanceFilterKey = 'searchTerm';

    static readonly MIME_TYPES = {
        EXCEL: [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel', 
        ],
        PDF: [
            'application/pdf',
        ],
        IMAGES: [
            'image/jpeg',
            'image/png',
            'image/gif',
        ],
        CSV: [
            'text/csv',
        ]
    };
    
    static readonly PriceListImporters = {
        AdobePriceListImporter: 'Adobe Price List Importer',
    }

    static readonly DefaultCurrencyCode = 'ZAR';

    static readonly MicrosoftPriceListUploadTypes: PriceListUploadType[]= [
        {
            name: "Online Services (CSP)",
            mask: ""
        },
        {
            name: "Online Services New Commerce Experience (NCE)",
            mask: ""
        },
        {
            name: "Software (Subscriptions and Perpetual)",
            mask: ""
        },
    ]

    static readonly AdobePriceListUploadTypes: PriceListUploadType[]= [
        {
            name: "Commercial (eg. ...vipmpc...xlsx)",
            mask: "vipmpc"
        },
        {
            name: "Education (eg. ...vipmpe...xlsx)",
            mask: "vipmpe"
        },
        {
            name: "Government (eg. ...vipmpg...xlsx)",
            mask: "vipmpg"
        },
    ]

    static readonly AdobePriceSupportUploadFileTypes: string[] = ["xls", "xlsx"];

    static readonly ciscoTokenResetSessionName = 'ciscoMasterTokenReset'

    static readonly GenericFeed: string = "GenericFeed";
    static readonly OwnFeed: string = "OwnFeed";
    static readonly OwnFeedCustomLocation: string = "OwnFeedCustomLocation";
}
