import { AppConsts } from '@shared/AppConsts';
import { FormattedStringValueExtracter } from '@shared/helpers/FormattedStringValueExtracter';
var SubdomainTenancyNameFinder = /** @class */ (function () {
    function SubdomainTenancyNameFinder() {
    }
    SubdomainTenancyNameFinder.prototype.getCurrentTenancyNameOrNull = function (rootAddress) {
        if (rootAddress.indexOf(AppConsts.tenancyNamePlaceHolderInUrl) < 0) {
            // Web site does not support subdomain tenant name
            return null;
        }
        var currentRootAddress = document.location.href;
        var formattedStringValueExtracter = new FormattedStringValueExtracter();
        var values = formattedStringValueExtracter.IsMatch(currentRootAddress, rootAddress);
        if (!values.length) {
            return null;
        }
        return values[0];
    };
    return SubdomainTenancyNameFinder;
}());
export { SubdomainTenancyNameFinder };
