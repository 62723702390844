import { ChangeDetectorRef } from '@angular/core';
import { timer, from } from 'rxjs';
import { IdleTimeoutService } from './idle-timeout.service';
import { DialogService } from './session-dialog.service';
import { TokenManagementService } from './token-management.service';
import * as i0 from "@angular/core";
import * as i1 from "./idle-timeout.service";
import * as i2 from "./session-dialog.service";
import * as i3 from "./token-management.service";
var SessionManagementService = /** @class */ (function () {
    function SessionManagementService(idleTimeoutSvc, dialogSvc, tokenSvc) {
        this.idleTimeoutSvc = idleTimeoutSvc;
        this.dialogSvc = dialogSvc;
        this.tokenSvc = tokenSvc;
        this._counter = 0;
    }
    SessionManagementService.prototype.startCounter = function () {
        var _this = this;
        if (this.tokenSvc.isCurrentTokenValid() == false)
            return;
        if (this._timerSubscription) {
            this._timerSubscription.unsubscribe();
        }
        this._counter = 0;
        this._timer = timer(1000, 1000);
        this._timerSubscription = this._timer.subscribe(function (n) {
            _this._counter++;
            _this.changeRef.markForCheck();
        });
    };
    SessionManagementService.prototype.reset = function () {
        this.startCounter();
        this.idleTimeoutSvc.resetTimer();
    };
    SessionManagementService.prototype.initialize = function (changeRef) {
        var _this = this;
        if (this.tokenSvc.isCurrentTokenValid() == false)
            return;
        this.changeRef = changeRef;
        this.startCounter();
        this._notify_idleTimerSubscription = this.idleTimeoutSvc.notify_timeoutExpired.subscribe(function (res) {
            var modalPromise = _this.dialogSvc.open("Session Expiring!", "Your session will expire in " + _this.idleTimeoutSvc.minutesToNotifyFrom + " minute(s). Do you need more time?", true, "Yes", "No");
            var newObservable = from(modalPromise);
            newObservable.subscribe(function (res) {
                if (res === true) {
                    console.log("Extending session...");
                    _this.tokenSvc.refreshToken().then(function (res) {
                        _this.idleTimeoutSvc.resetTimer();
                        _this.startCounter();
                        _this.changeRef.markForCheck();
                    });
                }
                else {
                    console.log("Not extending session...");
                }
            }, function (reason) {
                console.log("Dismissed " + reason);
            });
        });
        this._idleTimerSubscription = this.idleTimeoutSvc.timeoutExpired.subscribe(function (s) { return location.reload(); });
    };
    SessionManagementService.prototype.stop = function () {
        this._idleTimerSubscription.unsubscribe();
        this._notify_idleTimerSubscription.unsubscribe();
    };
    SessionManagementService.prototype.onTokenExpired = function () {
        if (this.tokenSvc.isCurrentTokenValid() == false) {
            location.reload();
        }
    };
    SessionManagementService.ngInjectableDef = i0.defineInjectable({ factory: function SessionManagementService_Factory() { return new SessionManagementService(i0.inject(i1.IdleTimeoutService), i0.inject(i2.DialogService), i0.inject(i3.TokenManagementService)); }, token: SessionManagementService, providedIn: "root" });
    return SessionManagementService;
}());
export { SessionManagementService };
