import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from "@app/shared/layout/footer.component";
import {HeaderComponent} from "@app/shared/layout/header.component";
import {AppCommonModule} from "@app/shared/common/app-common.module";
import {LoginAttemptsModalComponent} from "@app/shared/layout/login-attempts-modal.component";
import {LinkedAccountsModalComponent} from "@app/shared/layout/linked-accounts-modal.component";
import {ChangePasswordModalComponent} from "@app/shared/layout/profile/change-password-modal.component";
import {ChangeProfilePictureModalComponent} from "@app/shared/layout/profile/change-profile-picture-modal.component";
import {MySettingsModalComponent} from "@app/shared/layout/profile/my-settings-modal.component";
import {
    NotificationSettingsModalComponent
} from "@app/shared/layout/notifications/notification-settings-modal.component";
import {MegaMenuComponent} from "@app/shared/layout/nav/top-nav-bar/mega-menu/mega-menu.component";
import {TopNavBarComponent} from "@app/shared/layout/nav/top-nav-bar/top-nav-bar.component";
import {RouterModule} from "@angular/router";
import {UtilsModule} from "@shared/utils/utils.module";
import {
    PaymentMethodsBannerComponent
} from "@app/shared/layout/payment-methods-banner/payment-methods-banner.component";
import {DropDownMenuComponent} from "@app/shared/layout/nav/top-nav-bar/drop-down-menu/drop-down-menu.component";
import {ModalModule, TabsModule, TooltipModule} from "@node_modules/ngx-bootstrap";
import {CustomFormsModule} from "@node_modules/ng2-validation";
import {SmsVerificationModalComponent} from "@app/shared/layout/profile/sms-verification-modal.component";
import {FormsModule} from "@angular/forms";
import {NgbModule} from "@node_modules/@ng-bootstrap/ng-bootstrap";
import {FileUploadModule} from "@node_modules/ng2-file-upload";
import {LinkAccountModalComponent} from "@app/shared/layout/link-account-modal.component";
import {TableModule} from "@node_modules/primeng/table";
import {PaginatorModule} from "@node_modules/primeng/primeng";
import {HeaderNotificationsComponent} from "@app/shared/layout/notifications/header-notifications.component";

@NgModule({
    imports: [
        CommonModule,
        AppCommonModule,
        RouterModule,
        UtilsModule,
        TabsModule,
        CustomFormsModule,
        FormsModule,
        NgbModule,
        ModalModule,
        FileUploadModule,
        TableModule,
        PaginatorModule,
        TooltipModule
    ],
    declarations: [
        FooterComponent,
        HeaderComponent,
        LoginAttemptsModalComponent,
        LinkedAccountsModalComponent,
        ChangePasswordModalComponent,
        ChangeProfilePictureModalComponent,
        MySettingsModalComponent,
        NotificationSettingsModalComponent,
        MegaMenuComponent,
        TopNavBarComponent,
        PaymentMethodsBannerComponent,
        DropDownMenuComponent,
        SmsVerificationModalComponent,
        LinkAccountModalComponent,
        HeaderNotificationsComponent
    ],
    exports: [
        FooterComponent,
        HeaderComponent
    ]
})
export class AppLayoutModule {
}
