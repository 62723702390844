import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { EventEmitter, Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { ChatFriendDto } from './ChatFriendDto';
var ChatFriendListItemComponent = /** @class */ (function () {
    function ChatFriendListItemComponent(injector) {
        this.remoteServiceUrl = AppConsts.remoteServiceBaseUrl;
        this.appPath = AppConsts.appBaseUrl;
        this.selectChatFriend = new EventEmitter();
        this.multiTenancy = injector.get(AbpMultiTenancyService);
    }
    ChatFriendListItemComponent.prototype.getShownUserName = function (tenanycName, userName) {
        if (!this.multiTenancy.isEnabled) {
            return userName;
        }
        return (tenanycName ? tenanycName : '.') + '\\' + userName;
    };
    ChatFriendListItemComponent.prototype.getRemoteImageUrl = function (profilePictureId, userId, tenantId) {
        return this.remoteServiceUrl + '/Profile/GetFriendProfilePictureById?id=' + profilePictureId + '&userId=' + userId + '&tenantId=' + tenantId;
    };
    return ChatFriendListItemComponent;
}());
export { ChatFriendListItemComponent };
