import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FeatureCheckerService } from 'abp-ng2-module/dist/src/features/feature-checker.service';
import { finalize } from 'rxjs/operators';

export class ContactForm {
    name: string;
    email: string;
    subject: string;
    message: string;
}

@Component({
    templateUrl: './footer.component.html',
    selector: 'footer-bar',
    styleUrls: ['./footer.component.less']
})
export class FooterComponent extends AppComponentBase implements OnInit {
    private basePath = "./assets/address/";
    private address = `${this.basePath}address.json`;
    addressData: any;

    assetsPaymentFolder = './assets/payment';
    assetsDocuments = './assets/common/documents';
    links1 = [ 
        { name: this.l("AboutAxiz"), url: "https://info.axiz.com/about/"},
        { name: this.l("CompanyBBBEECertificate"), url: "https://info.axiz.com/wp-content/uploads/2022/09/ELC11400_DCT-Holdings_BEE-Certificate_Final.pdf"},
    ];

    links2 = [
        { name: this.l("CompanyDocuments"), url: `https://info.axiz.com/about/#Documents` },
        { name: this.l("WebsiteTermsOfUse"), url: `https://info.axiz.com/wp-content/uploads/2023/07/Axiz-Website-Terms-of-Use_2023.001.pdf` },
        { name: this.l("AlvivaCodeOfConduct"), url: `https://info.axiz.com/wp-content/uploads/2022/04/4-alviva-holdings-ltd-code-of-conduct.pdf` },
        { name: this.l("Complaints"), url: `https://info.axiz.com/complaints` },
    ]

    links3 = [ 
        { name: this.l("PaymentGatewayTermsAndConditions"), url: `${this.assetsPaymentFolder}/PayGate Terms and Conditions.pdf`},
        { name: this.l("YourAccount"), url: "https://www.axiz.com/account/login"},
        { name: this.l("YourOrders"), url: "https://www.axiz.com/account/login"},
        { name: this.l("GetInTouchWithAxiz"), url: "https://info.axiz.com/contact/"},
    ];

    hasAxizContentFeature = this.feature.isEnabled("App.WhiteLabeling.AxizContent");
    showFooter = this.feature.isEnabled("App.WhiteLabeling.ShowAxizFooter");

    constructor(
        injector: Injector,
        feature: FeatureCheckerService,
        private httpClient: HttpClient,
    ) {
        super(injector);
        
    }

    ngOnInit(): void {
        this.httpClient.get(this.address)
         .pipe(finalize(() => {
            
         }))
         .subscribe((x: any) => {
            this.addressData = x;
         });
    }


}
