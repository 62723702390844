var AppUiCustomizationService = /** @class */ (function () {
    function AppUiCustomizationService() {
    }
    AppUiCustomizationService.prototype.getTheme = function () {
        return this.getSetting("App.UiManagement.Theme");
    };
    AppUiCustomizationService.prototype.getContainerClass = function () {
        return this.getSetting('App.UiManagement.LayoutType') === 'boxed'
            ? 'm-container--responsive'
            : 'm-container--fluid';
    };
    AppUiCustomizationService.prototype.getAsideSkin = function () {
        if (this.topMenuUsed()) {
            return this.getSetting('App.UiManagement.Header.Skin');
        }
        return this.getSetting('App.UiManagement.Left.AsideSkin');
    };
    AppUiCustomizationService.prototype.allowAsideHiding = function () {
        return this.getSetting('App.UiManagement.Left.AllowAsideHiding') === 'true';
    };
    AppUiCustomizationService.prototype.allowAsideMinimizing = function () {
        return this.getSetting('App.UiManagement.Left.AllowAsideMinimizing') === 'true';
    };
    AppUiCustomizationService.prototype.leftMenuUsed = function () {
        return this.getSetting('App.UiManagement.Left.Position') === 'left';
    };
    AppUiCustomizationService.prototype.topMenuUsed = function () {
        return this.getSetting('App.UiManagement.Left.Position') === 'top';
    };
    AppUiCustomizationService.prototype.getAppModuleBodyClass = function () {
        return 'm-page--' + this.getSetting('App.UiManagement.LayoutType') + ' m--skin-' + this.getSetting('App.UiManagement.ContentSkin') + ' ' +
            (this.getSetting('App.UiManagement.ContentSkin') !== '' ? ('m-content--skin-' + this.getSetting('App.UiManagement.ContentSkin')) : '') + ' ' +
            'm-header--' + (this.getSetting('App.UiManagement.Header.DesktopFixedHeader') === 'true' ? 'fixed' : 'static') + ' ' +
            (this.getSetting('App.UiManagement.Header.MobileFixedHeader') === 'true' ? 'm-header--fixed-mobile' : '') + ' ' +
            ((this.getSetting('App.UiManagement.Left.FixedAside') === 'true' && !this.topMenuUsed() ? 'm-aside-left--fixed' : '')) + ' ' +
            (this.getSetting('App.UiManagement.Left.DefaultMinimizedAside') === 'true' ? 'm-aside-left--minimize m-brand--minimize' : '') + ' ' +
            (this.getSetting('App.UiManagement.Left.DefaultHiddenAside') === 'true' || this.getSetting('App.UiManagement.Left.Position') === 'top' ? 'm-aside-left--hide' : '') + ' ' +
            'm-aside-left--enabled' + ' ' +
            'm-aside-left--skin-' + this.getSetting('App.UiManagement.Left.AsideSkin') + ' ' +
            'm-aside-left--offcanvas' + ' ' +
            (this.getSetting('App.UiManagement.Footer.FixedFooter') === 'true' && this.getSetting('App.UiManagement.LayoutType') !== 'boxed' ? 'm-footer--fixed' : '');
    };
    AppUiCustomizationService.prototype.getAccountModuleBodyClass = function () {
        return 'm--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';
    };
    AppUiCustomizationService.prototype.getSelectEditionBodyClass = function () {
        return 'm--skin-';
    };
    AppUiCustomizationService.prototype.getHeaderSkin = function () {
        return this.getSetting('App.UiManagement.Header.Skin');
    };
    //User navigation menu
    AppUiCustomizationService.prototype.getSideBarMenuClass = function () {
        var menuCssClass = 'm-aside-menu m-aside-menu--skin-' + this.getSetting('App.UiManagement.Left.AsideSkin');
        menuCssClass += ' m-aside-menu--submenu-skin-';
        if (this.getSetting('App.UiManagement.Left.DropdownSubmenuSkin') === 'inherit') {
            menuCssClass += this.getSetting('App.UiManagement.Left.AsideSkin');
        }
        else {
            menuCssClass += this.getSetting('App.UiManagement.Left.DropdownSubmenuSkin');
        }
        if (this.getSetting('App.UiManagement.Left.SubmenuToggle') === 'dropdown') {
            menuCssClass += ' m-aside-menu--dropdown';
        }
        return menuCssClass;
    };
    AppUiCustomizationService.prototype.getMenuListClass = function () {
        if (this.getSetting('App.UiManagement.Left.DropdownSubmenuArrow')) {
            return 'm-menu__nav--dropdown-submenu-arrow';
        }
        return '';
    };
    AppUiCustomizationService.prototype.getTopBarMenuClass = function () {
        var menuCssClass = 'm-container m-container--responsive m-container--full-height m-page__container';
        if (this.getSetting('App.UiManagement.LayoutType') === 'boxed') {
            return menuCssClass + ' m-container--xxl';
        }
        return menuCssClass;
    };
    AppUiCustomizationService.prototype.getIsMenuDropdown = function () {
        return this.getSetting('App.UiManagement.Left.SubmenuToggle') === 'dropdown';
    };
    AppUiCustomizationService.prototype.getIsMenuScrollable = function () {
        return this.getSetting('App.UiManagement.Left.FixedAside') === 'true';
    };
    AppUiCustomizationService.prototype.getIsMenuMinimizable = function () {
        return this.getSetting('App.UiManagement.Header.DesktopMinimizeMode') !== '';
    };
    AppUiCustomizationService.prototype.getIsMenuMinimizableMobile = function () {
        return this.getSetting('App.UiManagement.Header.MobileFixedHeader') === 'true';
    };
    AppUiCustomizationService.prototype.getSetting = function (key) {
        var setting = abp.setting.get(key);
        if (!setting) {
            return null;
        }
        return abp.setting.get(key).toLocaleLowerCase();
    };
    return AppUiCustomizationService;
}());
export { AppUiCustomizationService };
