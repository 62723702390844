import { Component, ViewChild, Injector, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
import { CustomersServiceProxy, NameValueDto, PagedAndFilteredInputDto, TenantServiceProxy, UserServiceProxy, FindUsersInput,  } from '@shared/service-proxies/service-proxies';
import { AppLookupComponentBase } from './base/app-lookup-component-base';

@Component({
    selector: 'app-user-lookup',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './base/app-lookup-component-base.html'
})
export class UserLookupComponent extends AppLookupComponentBase{

    tenantId: number;
    customerId: number;

    constructor(
        injector: Injector,
        private _userService: UserServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {

        this.configure({
            title: this.l('SelectAUser'),
            dataSource: (skipCount: number, maxResultCount: number, filter: string, tenantId?: number) => {

                return this._userService.findUsersLookup(new FindUsersInput({
                    customerId: this.customerId,
                    tenantId: this.tenantId,
                    filter: filter,
                    maxResultCount: maxResultCount,
                    skipCount: skipCount
                }));
            },
        });
    }

}
