<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" (onShown)="onShown()"
     aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #editForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{l("NotificationSettings")}}</span>
                    </h5>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" *ngIf="settings">
                    <h4>{{l("ReceiveNotifications")}}</h4>
                    <div>
                        <input type="checkbox"
                               id="ReceiveNotifications"
                               jq-plugin="bootstrapSwitch"
                               name="ReceiveNotifications"
                               attr.switch-size="small"
                               [attr.switch-on-text]="l('On')"
                               [attr.switch-off-text]="l('Off')"/>
                        <div class="help-block">{{l("ReceiveNotifications_Definition")}}</div>
                    </div>
                    <h4 *ngIf="settings.notifications.length" class="m--margin-top-20">{{l("NotificationTypes")}}</h4>
                    <p *ngIf="settings.notifications.length && !settings.receiveNotifications" class="text-danger"><small>{{l("ReceiveNotifications_DisableInfo")}}</small>
                    </p>
                    <div class="m-checkbox-list">
                        <div *ngFor="let notification of settings.notifications">
                            <label attr.for="Notification_{{notification.name}}" class="m-checkbox">
                                <input id="Notification_{{notification.name}}" type="checkbox" name="Notification_{{notification.name}}" [(ngModel)]="notification.isSubscribed" [disabled]="!settings.receiveNotifications">
                                {{notification.displayName}}
                                <span></span>
                            </label>
                            <span *ngIf="notification.description" class="help-block">{{notification.description}}</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{l("Save")}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
