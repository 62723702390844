import { ShoppingCartFormDataDto, CheckoutDataDto } from '@shared/service-proxies/service-proxies';
var InlineDtoEdit = /** @class */ (function () {
    function InlineDtoEdit() {
    }
    return InlineDtoEdit;
}());
export { InlineDtoEdit };
var InlineCreateOrEditDto = /** @class */ (function () {
    function InlineCreateOrEditDto(ctor) {
        this.physicalCopy = new ctor();
        this.editCopy = new ctor();
    }
    return InlineCreateOrEditDto;
}());
export { InlineCreateOrEditDto };
export function cloneShoppingCartFormDataDto(src) {
    var result = new ShoppingCartFormDataDto();
    result.checkoutData = new CheckoutDataDto();
    result.checkoutData.selectedCustomerDisplayName = src.checkoutData.selectedCustomerDisplayName;
    result.checkoutData.selectedCustomerId = src.checkoutData.selectedCustomerId;
    return result;
}
var DynamicMarketInput = /** @class */ (function () {
    function DynamicMarketInput(data) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property)) {
                    this[property] = data[property];
                }
            }
        }
    }
    DynamicMarketInput.fromJS = function (data) {
        data = typeof data === 'object' ? data : {};
        var result = new DynamicMarketInput();
        result.init(data);
        return result;
    };
    DynamicMarketInput.prototype.init = function (data) {
        if (data) {
            this.market = data['market'];
            if (data['input']) {
                this.input = {};
                for (var key in data['input']) {
                    if (data['input'].hasOwnProperty(key)) {
                        this.input[key] = data['input'][key];
                    }
                }
            }
        }
    };
    DynamicMarketInput.prototype.toJSON = function (data) {
        data = typeof data === 'object' ? data : {};
        data['market'] = this.market;
        if (this.input) {
            data['input'] = {};
            for (var key in this.input) {
                if (this.input.hasOwnProperty(key)) {
                    data['input'][key] = this.input[key];
                }
            }
        }
        return data;
    };
    return DynamicMarketInput;
}());
export { DynamicMarketInput };
