import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AccountServiceProxy, ImpersonateInput } from '@shared/service-proxies/service-proxies';
var ImpersonationService = /** @class */ (function () {
    function ImpersonationService(_accountService, _appUrlService, _authService) {
        this._accountService = _accountService;
        this._appUrlService = _appUrlService;
        this._authService = _authService;
    }
    ImpersonationService.prototype.impersonate = function (userId, tenantId) {
        var _this = this;
        var input = new ImpersonateInput();
        input.userId = userId;
        input.tenantId = tenantId;
        this._accountService.impersonate(input)
            .subscribe(function (result) {
            _this._authService.logout(false);
            var targetUrl = _this._appUrlService.getAppRootUrlOfTenant(result.tenancyName) + '?impersonationToken=' + result.impersonationToken;
            if (input.tenantId) {
                targetUrl = targetUrl + '&tenantId=' + input.tenantId;
            }
            location.href = targetUrl;
        });
    };
    ImpersonationService.prototype.backToImpersonator = function () {
        var _this = this;
        this._accountService.backToImpersonator()
            .subscribe(function (result) {
            _this._authService.logout(false);
            var targetUrl = _this._appUrlService.getAppRootUrlOfTenant(result.tenancyName) + '?impersonationToken=' + result.impersonationToken;
            if (abp.session.impersonatorTenantId) {
                targetUrl = targetUrl + '&tenantId=' + abp.session.impersonatorTenantId;
            }
            location.href = targetUrl;
        });
    };
    return ImpersonationService;
}());
export { ImpersonationService };
