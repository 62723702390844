export var DialogType;
(function (DialogType) {
    DialogType[DialogType["Detail"] = 1] = "Detail";
    DialogType[DialogType["Summary"] = 2] = "Summary";
})(DialogType || (DialogType = {}));
export var DialogSeverity;
(function (DialogSeverity) {
    DialogSeverity[DialogSeverity["Info"] = 0] = "Info";
    DialogSeverity[DialogSeverity["Question"] = 1] = "Question";
    DialogSeverity[DialogSeverity["Warn"] = 2] = "Warn";
    DialogSeverity[DialogSeverity["Danger"] = 3] = "Danger";
})(DialogSeverity || (DialogSeverity = {}));
var DialogOptions = /** @class */ (function () {
    function DialogOptions(title, message, showCancel, confirmText, cancelText, dialogType, iconClass, severity) {
        if (showCancel === void 0) { showCancel = true; }
        if (confirmText === void 0) { confirmText = "Ok"; }
        if (cancelText === void 0) { cancelText = "Cancel"; }
        if (dialogType === void 0) { dialogType = DialogType.Summary; }
        if (iconClass === void 0) { iconClass = ''; }
        if (severity === void 0) { severity = DialogSeverity.Info; }
        this.title = title;
        this.message = message;
        this.showCancel = showCancel;
        this.confirmText = confirmText;
        this.cancelText = cancelText;
        this.dialogType = dialogType;
        this.iconClass = iconClass;
        this.dialogSeverity = severity;
    }
    return DialogOptions;
}());
export { DialogOptions };
