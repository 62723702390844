import {Component, Injector, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {AppComponentBase} from '@shared/common/app-component-base';
import {NotificationServiceProxy, UserNotification} from '@shared/service-proxies/service-proxies';
import {IFormattedUserNotification, UserNotificationHelper} from './UserNotificationHelper';

@Component({
    templateUrl: './header-notifications.component.html',
    styleUrls: ['./notifications.component.less'],
    selector: '[headerNotifications]',
    encapsulation: ViewEncapsulation.None
})
export class HeaderNotificationsComponent extends AppComponentBase implements OnInit, OnDestroy {

    notifications: IFormattedUserNotification[] = [];
    unreadNotificationCount = 0;
    subscriptions = [];

    constructor(
        injector: Injector,
        private _notificationService: NotificationServiceProxy,
        private _userNotificationHelper: UserNotificationHelper
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.loadNotifications();
        this.registerToEvents();
    }

    ngOnDestroy(): void {
        this.unregisterEvents();
    }

    loadNotifications(): void {
        this._notificationService.getUserNotifications(undefined, 3, 0).subscribe(result => {
            this.unreadNotificationCount = result.unreadCount;
            this.notifications = [];
            $.each(result.items, (index, item: UserNotification) => {
                this.notifications.push(this._userNotificationHelper.format(<any>item));
            });
        });
    }

    registerToEvents() {
        let self = this;

        let cbReceived = (userNotification) => {
            self._userNotificationHelper.show(userNotification);
            self.loadNotifications();
        }
        this.registerEvent('abp.notifications.received', cbReceived);

        let cbRefresh = () => {
            self.loadNotifications();
        }
        this.registerEvent('app.notifications.refresh', cbRefresh);

        let cbRead = (userNotificationId) => {
            for (let i = 0; i < this.notifications.length; i++) {
                if (this.notifications[i].userNotificationId === userNotificationId) {
                    this.notifications[i].state = 'READ';
                }
            }

            this.unreadNotificationCount -= 1;
        }
        this.registerEvent('app.notifications.read', cbRead);
    }

    registerEvent(name, cb) {
        this.subscriptions.push({
            name: name,
            cb: cb
        });
        abp.event.on(name, cb);
    }

    unregisterEvents() {
        for (let subscription of this.subscriptions) {
            abp.event.off(subscription.name, subscription.cb);
        }
        this.subscriptions = [];
    }

    setAllNotificationsAsRead(): void {
        this._userNotificationHelper.setAllAsRead();
    }

    openNotificationSettingsModal(): void {
        this._userNotificationHelper.openSettingsModal();
    }

    setNotificationAsRead(userNotification: IFormattedUserNotification): void {
        this._userNotificationHelper.setAsRead(userNotification.userNotificationId);
    }

    gotoUrl(url): void {
        if (url) {
            location.href = url;
        }
    }
}
