/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./session-expiry-dialog.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./session-expiry-dialog.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_SessionExpiryDialogComponent = [i0.styles];
var RenderType_SessionExpiryDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SessionExpiryDialogComponent, data: {} });
export { RenderType_SessionExpiryDialogComponent as RenderType_SessionExpiryDialogComponent };
function View_SessionExpiryDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["style", "font-size: small;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["NOTE : "])), (_l()(), i1.ɵted(-1, null, [" Page will refresh "]))], null, null); }
function View_SessionExpiryDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cancelText; _ck(_v, 1, 0, currVal_0); }); }
export function View_SessionExpiryDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SessionExpiryDialogComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SessionExpiryDialogComponent_2)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showRefreshHint; _ck(_v, 9, 0, currVal_2); var currVal_4 = _co.showCancel; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 7, 0, currVal_1); var currVal_3 = _co.confirmText; _ck(_v, 12, 0, currVal_3); }); }
export function View_SessionExpiryDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-session-expiry-dialog", [], null, null, null, View_SessionExpiryDialogComponent_0, RenderType_SessionExpiryDialogComponent)), i1.ɵdid(1, 114688, null, 0, i3.SessionExpiryDialogComponent, [i4.NgbActiveModal, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionExpiryDialogComponentNgFactory = i1.ɵccf("app-session-expiry-dialog", i3.SessionExpiryDialogComponent, View_SessionExpiryDialogComponent_Host_0, { title: "title", message: "message", showCancel: "showCancel", confirmText: "confirmText", cancelText: "cancelText", showRefreshHint: "showRefreshHint" }, {}, []);
export { SessionExpiryDialogComponentNgFactory as SessionExpiryDialogComponentNgFactory };
