import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-session-expiry-dialog',
  templateUrl: './session-expiry-dialog.component.html',
  styleUrls: ['./session-expiry-dialog.component.less']
})
export class SessionExpiryDialogComponent implements OnInit {

  @Input() title;
  @Input() message;
  @Input() showCancel = false;
  @Input() confirmText: string = "Ok";
  @Input() cancelText: string = "Cancel";
  @Input() showRefreshHint: boolean = true;
  
  constructor(public activeModal: NgbActiveModal, public changeRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

}
