/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./validation-messages.component";
import * as i3 from "../../app/shared/common/localization/app-localization.service";
var styles_ValidationMessagesComponent = [];
var RenderType_ValidationMessagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ValidationMessagesComponent, data: {} });
export { RenderType_ValidationMessagesComponent as RenderType_ValidationMessagesComponent };
function View_ValidationMessagesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "form-control-feedback"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getErrorDefinitionMessage(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_ValidationMessagesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValidationMessagesComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getErrorDefinitionIsInValid(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ValidationMessagesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "has-danger"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValidationMessagesComponent_2)), i0.ɵdid(2, 802816, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorDefsInternal; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ValidationMessagesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValidationMessagesComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.formCtrl && _co.formCtrl.invalid) && (_co.formCtrl.dirty || _co.formCtrl.touched)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ValidationMessagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "validation-messages", [], null, null, null, View_ValidationMessagesComponent_0, RenderType_ValidationMessagesComponent)), i0.ɵdid(1, 49152, null, 0, i2.ValidationMessagesComponent, [i3.AppLocalizationService], null, null)], null, null); }
var ValidationMessagesComponentNgFactory = i0.ɵccf("<validation-messages>", i2.ValidationMessagesComponent, View_ValidationMessagesComponent_Host_0, { formCtrl: "formCtrl", errorDefs: "errorDefs" }, {}, []);
export { ValidationMessagesComponentNgFactory as ValidationMessagesComponentNgFactory };
