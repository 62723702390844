import { NavigationEnd, Router, Routes } from '@angular/router';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { AppConsts } from "@shared/AppConsts";
var ɵ0 = { preload: true };
var routes = [
    { path: '', redirectTo: AppConsts.DigitalCommercePath + '?page=' + AppConsts.LandingPageGeneralDefaultConfig, pathMatch: 'full' },
    {
        path: 'account',
        loadChildren: 'account/account.module#AccountModule',
        data: ɵ0
    }
];
var RootRoutingModule = /** @class */ (function () {
    function RootRoutingModule(router, _uiCustomizationService) {
        var _this = this;
        this.router = router;
        this._uiCustomizationService = _uiCustomizationService;
        router.events.subscribe(function (event) {
            setTimeout(function () {
                _this.toggleBodyCssClass(event.url);
            }, 0);
        });
    }
    RootRoutingModule.prototype.toggleBodyCssClass = function (url) {
        if (url) {
            if (url === '/') {
                if (abp.session.userId > 0) {
                    this.setAppModuleBodyClassInternal();
                }
                else {
                    $('body').attr('class', this._uiCustomizationService.getAccountModuleBodyClass());
                }
            }
            if (url.indexOf('/account/') >= 0) {
                $('body').attr('class', this._uiCustomizationService.getAccountModuleBodyClass());
            }
            else {
                this.setAppModuleBodyClassInternal();
            }
        }
    };
    RootRoutingModule.prototype.setAppModuleBodyClassInternal = function () {
        var $currentBodyClass = $('body').attr('class');
        var classesToRemember = '';
        if ($currentBodyClass.indexOf('m-brand--minimize') >= 0) {
            classesToRemember += 'm-brand--minimize ';
        }
        if ($currentBodyClass.indexOf('m-aside-left--minimize') >= 0) {
            classesToRemember += 'm-aside-left--minimize';
        }
        if ($currentBodyClass.indexOf('m-brand--hide') >= 0) {
            classesToRemember += 'm-brand--hide';
        }
        if ($currentBodyClass.indexOf('m-aside-left--hide') >= 0) {
            classesToRemember += 'm-aside-left--hide';
        }
        $('body').attr('class', this._uiCustomizationService.getAppModuleBodyClass() + ' ' + classesToRemember);
    };
    RootRoutingModule.prototype.getSetting = function (key) {
        return abp.setting.get(key);
    };
    return RootRoutingModule;
}());
export { RootRoutingModule };
export { ɵ0 };
