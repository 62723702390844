/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-nav-bar.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown";
import * as i5 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown-config";
import * as i6 from "./drop-down-menu/drop-down-menu.component.ngfactory";
import * as i7 from "./drop-down-menu/drop-down-menu.component";
import * as i8 from "../app-navigation.service";
import * as i9 from "../../../../../shared/common/session/app-session.service";
import * as i10 from "@angular/common/http";
import * as i11 from "./top-nav-bar.component";
var styles_TopNavBarComponent = [i0.styles];
var RenderType_TopNavBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopNavBarComponent, data: {} });
export { RenderType_TopNavBarComponent as RenderType_TopNavBarComponent };
function View_TopNavBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[8, "id", 0], [8, "className", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.resetActivePath() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_4 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.route, ""); _ck(_v, 1, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "link_", _v.parent.context.index, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "nav-item nav-link ", _co.getMenuItemStateClass(_v.parent.context.$implicit), ""); var currVal_2 = i1.ɵnov(_v, 1).target; var currVal_3 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = _co.l(_v.parent.context.$implicit.name); _ck(_v, 2, 0, currVal_5); }); }
function View_TopNavBarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], [[8, "id", 0], [8, "target", 0], [8, "href", 4], [8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetActivePath() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "link_", _v.parent.context.index, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.getActionLinkTarget(_v.parent.context.$implicit), ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.getFullActionLink(_v.parent.context.$implicit.actionLink), ""); var currVal_3 = i1.ɵinlineInterpolate(1, "nav-item nav-link ", _co.getMenuItemStateClass(_v.parent.context.$implicit), ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.l(_v.parent.context.$implicit.name); _ck(_v, 1, 0, currVal_4); }); }
function View_TopNavBarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["dd", 1]], null, 13, "div", [["class", "nav-item dropdown"], ["ngbDropdown", ""]], [[8, "id", 0], [2, "show", null]], [[null, "openChange"], [null, "keyup.esc"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup.esc" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).closeFromOutsideEsc() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).closeFromClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("openChange" === en)) {
        var pd_2 = (_co.toggled($event, i1.ɵnov(_v, 4).id) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 2, i4.NgbDropdown, [i5.NgbDropdownConfig, i1.NgZone], null, { openChange: "openChange" }), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵeld(4, 0, [["link", 1]], null, 6, "a", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "dropdown-toggle"], ["data-toggle", "dropdown"], ["href", "#"], ["ngbDropdownToggle", ""], ["role", "menuitem"]], [[8, "className", 0], [8, "id", 0], [1, "tabindex", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i4.NgbDropdownToggle, [i4.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i4.NgbDropdownAnchor, null, [i4.NgbDropdownToggle]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "m-menu__link-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "m-menu__hor-arrow la la-angle-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, [["dropDownMenu", 1]], null, 2, "div", [["class", "dropdown-menu"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-drop-down-menu", [], null, null, null, i6.View_DropDownMenuComponent_0, i6.RenderType_DropDownMenuComponent)), i1.ɵdid(13, 114688, null, 0, i7.DropDownMenuComponent, [i2.Router, i8.AppNavigationService, i9.AppSessionService, i1.Injector, i10.HttpClient], { MenuItems: [0, "MenuItems"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_7 = _co.getDisplayableChildren(_v.parent.context.$implicit); _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "dropdown_", _v.parent.context.index, ""); var currVal_1 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "nav-link dropdown-toggle ", _co.getParentActiveState(_v.parent.context.$implicit), ""); var currVal_3 = i1.ɵinlineInterpolate(1, "link_", _v.parent.context.index, ""); var currVal_4 = ((_v.parent.context.index == 0) ? 0 : (0 - 1)); var currVal_5 = i1.ɵnov(_v, 5).dropdown.isOpen(); _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.l(_v.parent.context.$implicit.name); _ck(_v, 9, 0, currVal_6); }); }
function View_TopNavBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopNavBarComponent_3)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopNavBarComponent_4)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopNavBarComponent_5)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isRouterLinkItem(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isActionLinkItem(_v.context.$implicit); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.isDropDownMenu(_v.context.$implicit); _ck(_v, 5, 0, currVal_2); }, null); }
function View_TopNavBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "collapse navbar-collapse justify-content-between"], ["id", "navbarCollapse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopNavBarComponent_2)), i1.ɵdid(3, 802816, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu.items; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TopNavBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "nav-bar slider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container"], ["id", "nav-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "nav", [["class", "navbar navbar-expand-md bg-dark navbar-dark"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopNavBarComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_TopNavBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-nav-bar", [], null, null, null, View_TopNavBarComponent_0, RenderType_TopNavBarComponent)), i1.ɵdid(1, 114688, null, 0, i11.TopNavBarComponent, [i8.AppNavigationService, i9.AppSessionService, i1.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopNavBarComponentNgFactory = i1.ɵccf("app-top-nav-bar", i11.TopNavBarComponent, View_TopNavBarComponent_Host_0, {}, {}, []);
export { TopNavBarComponentNgFactory as TopNavBarComponentNgFactory };
