<div bsModal #twoFaAlertModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="twoFaAlertModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <span>{{l("Two Factor Authentication")}}</span>
                </h5>
            </div>
            <div class="modal-body">
                <div class="fa-modal-content">
                    <img src="assets/common/images/2fa-image.png" alt="2fa Image">

                    <span>Two factor authentication has been enabled on your <br>
                    account by your administrator. Please navigate to your <br>
                    profile in order to configure it.</span>

                </div>
            </div>
            <div class="modal-footer">
                <div class="fa-modal-action-row">
                    <button type="button" class="btn btn-primary" (click)="onConfigureClickEvent()">Configure</button>
                </div>
            </div>
        </div>
    </div>
</div>
