import {
    CheckoutDataDto,
    IAvaProductAddOnDto,
    PaymentReferenceDto,
    PaymentsServiceProxy,
    TenantAccountDto,
    TenantServiceProxy,
} from './../../../../shared/service-proxies/service-proxies';
import { Component, OnInit, Input, Injector, Output, EventEmitter } from '@angular/core';
import { CartServiceProxy, AvaCartDto, AvaCartItemDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import {AppMarkets, CartCheckoutDisable, PaygateMode, PaymentTypes, ProductItemType} from '@shared/AppEnums';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { CartDataService } from '@app/shared/common/services/cart.data.service';
import { ShoppingCartFormService } from '@app/cart/services/shopping-cart-form.service';
import { AppConsts } from '@shared/AppConsts';
import { result } from 'lodash';
import { finalize } from 'rxjs/operators';

export interface ICart {
    rootCart: AvaCartDto;
    cartItems: ICartItems[];
}

export interface ICartItems {
    marketProvider: number;
    items: AvaCartItemDto[];
}

export interface ICartItemAddon {
    productAddon: IAvaProductAddOnDto;
    cartItemId: number;
}

@Component({
    selector: 'app-shopping-cart-base',
    templateUrl: './shopping-cart-base.component.html',
    styleUrls: ['./shopping-cart-base.component.css'],
})
export class ShoppingCartBaseComponentComponent extends AppComponentBase implements OnInit {
    @Output() cartLoaded = new EventEmitter<ICart>();
    @Output() onCartItemsUpdatedEvent: EventEmitter<AvaCartItemDto> = new EventEmitter<AvaCartItemDto>();
    @Output() avaReferenceGenerated = new EventEmitter<string>();

    @Input() cart: AvaCartDto;
    @Input() checkoutData: CheckoutDataDto;

    processing = true;
    loading = false;
    groupedCartItems: ICartItems[] = [];
    paymentReference: PaymentReferenceDto;
    tenantAccount: TenantAccountDto = new TenantAccountDto();
    static initiated = false;

    // The scope of binding expressions in a components template is the components class instance.
    // You can't refer to globals or statics directly.
    //As a workaround you can add a getter to your components class
    get IBM() {
        return AppMarkets.IBM;
    }

    get Microsoft() {
        return AppMarkets.Microsoft;
    }

    get Cisco() {
        return AppMarkets.Cisco;
    }

    get Redhat() {
        return AppMarkets.Redhat;
    }

    get GeneralMarket() {
        return AppMarkets.GeneralMarket;
    }

    constructor(
        injector: Injector,
        protected cartService: CartServiceProxy,
        protected router: Router,
        protected cartDataService: CartDataService,
        protected shoppingCartFormService: ShoppingCartFormService,
        protected tenantsAppService: TenantServiceProxy,
        protected paymentsService: PaymentsServiceProxy
    ) {
        super(injector);
    }

    private getCartApplicableMarkets(): number[] {
        let marketProviderIds = [];

        if (this.cart && this.cart.items) {
            this.cart.items
                .filter((i) => i != null)
                .forEach((item) => {
                    marketProviderIds.push(item.purchaseDecision.product.market);
                });
        }

        return marketProviderIds;
    }

    ngOnInit() {
        this.groupCartByProduct();
    }

    getItems(): AvaCartItemDto[] {
        if (this.cart && this.cart.items) return this.cart.items;

        return [] as AvaCartItemDto[];
    }

    cartHasIbmProducts(): boolean {
        return this.getCartApplicableMarkets().filter((market) => market === AppMarkets.IBM).length > 0;
    }

    cartHasIbmMetaData(): boolean {
        return (
            _(this.checkoutData.marketCheckoutData)
                .map((m) => m.market)
                .indexOf(AppMarkets.IBM) !== -1
        );
    }


    cartHasMicrosoftItems(cartItems?: AvaCartItemDto[]): boolean {
        return this.cartHasMarketItems(AppMarkets.Microsoft, cartItems);
    }

    cartHasAdobeItems(cartItems?: AvaCartItemDto[]): boolean {
        return this.cartHasMarketItems(AppMarkets.Adobe, cartItems);
    }
    
    cartHasCiscoItems(cartItems?: AvaCartItemDto[]): boolean {
        return this.cartHasMarketItems(AppMarkets.Cisco, cartItems);
    }

    cartHasMarketItems(market: AppMarkets, cartItems?: AvaCartItemDto[]): boolean {
        if (!cartItems) {
            cartItems = this.cart.items;
        }
        return this.cart
            ? _(cartItems)
            .map((m) => this.getCartItemMarket(m))
            .indexOf(market) !== -1
            : false;
    }
    
    cartHasGeneralMarketItems(cartItems?: AvaCartItemDto[]): boolean {
        return this.cartHasMarketItems(AppMarkets.GeneralMarket, cartItems);
    }

    getPaymentMethod() {
        if (this.checkoutData) {
            if (this.checkoutData.paymentMethod == PaymentTypes[PaymentTypes.AxizCredit]) {
                return PaymentTypes.AxizCredit;
            } else if (this.checkoutData.paymentMethod == PaymentTypes[PaymentTypes.AxizCDC]) {
                return PaymentTypes.AxizCDC;
            } else if (this.checkoutData.paymentMethod == PaymentTypes[PaymentTypes.CapitecCredit]) {
                return PaymentTypes.CapitecCredit;
            }
        }

        return PaymentTypes.NoneSelected;
    }

    isAxizCDC() {
        return this.getPaymentMethod() === PaymentTypes.AxizCDC;
    }

    notifyBypassPaymentGateway() {
        if (AppConsts.paygateIntegrationMode === PaygateMode.BypassPayGate) {
            this.notify.warn(AppConsts.bypassPaymentGatewayMessage);
        }
        if(AppConsts.BlockCDCCloseCardForTesting == true) {
            this.notify.warn("Paygate in debug mode, order will not be placed");
        }
    }

    notifyRunPaygateLocal() {
        if (AppConsts.paygateIntegrationMode === PaygateMode.Local) {
            this.notify.warn(AppConsts.runPaygateLocalMessage);
        }
    }

    hasAnyVendorSpecificRequirements(): boolean {
        return this.cartHasIbmProducts() && this.cartHasIbmMetaData();
    }

    private getCartItemMarket(cartItem: AvaCartItemDto) {
        if (!cartItem) return AppMarkets.Default;

        return cartItem.purchaseDecision.product.market;
    }

    groupCartByProduct(): void {
        if (!this.cart) {
            this.cart = new AvaCartDto();
            this.cart.init({
                items: [],
            });
        } else {
            this.groupedCartItems = _(this.cart.items)
                .groupBy((g) => this.getCartItemMarket(g))
                .map((group) => {
                    return <ICartItems>{
                        marketProvider: this.getCartItemMarket(group[0]),
                        items: group,
                    };
                })
                .value();
        }
        this.cartLoaded.emit({
            rootCart: this.cart,
            cartItems: this.groupedCartItems,
        });
    }

    public isProcessing(): boolean {
        return this.processing;
    }

    clearProcessing(): void {
        this.processing = false;
        this.loading = false;
        abp.ui.clearBusy();
    }

    setLoading(element?: any): void {
        abp.ui.setBusy(element);
        this.loading = true;
    }

    setProcessing(): void {
        this.processing = true;
    }

    // Gets markets based on cart items
    public getCartMarkets(): any[] {
        let markets = [];
        if(this.cart.items && this.cart.items.length > 0) {
            this.cart.items.forEach((item) => {
                if (markets.indexOf(item.purchaseDecision.product.market) == -1) {
                    markets.push(item.purchaseDecision.product.market);
                }
            });
        }
        return markets;
    }
    
    public tenantAcountLoaded(tenantAccount: TenantAccountDto) {
        this.tenantAccount = tenantAccount;
        
        if (result && tenantAccount.accountNumber && tenantAccount.accountNumber.length > 0) {
            let msg = `${this.l('SelectedTenantAccountMsg')} ${tenantAccount.accountNumber}`;
            this.notify.info(msg);

            // Gets and sets the PaymentReference, the account is used as a prefix
            this.paymentsService
                .generatePaymentReference(tenantAccount.accountNumber, "", "", false, true)
                .pipe(
                    finalize(() => {
                        this.shoppingCartFormService.setPaymentReference(this.paymentReference);
                        this.shoppingCartFormService.setResellerAccountNumber(tenantAccount.accountNumber);
                        this.clearProcessing();

                        this.avaReferenceGenerated.emit(this.paymentReference.avaReferenceNumber);
                    })
                )
                .subscribe((paymentReference) => {
                    this.paymentReference = paymentReference;
                });
        } else {
            this.notify.error(this.l('CannotFindTenantAccountMsg'));
            this.shoppingCartFormService.UpdateDisableCheckout(true, CartCheckoutDisable.TENANT_NOT_FOUND )
        }
    }

    // Gets the correct account based on the items in the cart
    public getAccountForCheckout() {

        if (this.shoppingCartFormService.getFormData().checkoutData.paymentMethod == PaymentTypes[PaymentTypes.AxizCDC]){
            this.tenantsAppService.fetchTenantAccountByType(this.abpSession.tenantId, 1, this.cart.items)
                .subscribe(tenantAccount=> { this.tenantAcountLoaded(tenantAccount)});
            
        } else {
            this.tenantsAppService.getTenantAccountForCheckout(this.abpSession.tenantId, this.getCartMarkets())
                .subscribe((tenantAccount) => { this.tenantAcountLoaded(tenantAccount)});
        }
    }

    public getDealTotal(): number {
        let items = _.filter(this.cart.items, { 
            purchaseDecision: { 
                product: {
                    itemType: ProductItemType.Deal.valueOf(),
                } 
            }
        });
        return _.sumBy(items, x =>
            x.pricingSummary.sellPriceInc
        );
    }

    public getTotal(): number {
        return this.cart.totalInc;
    }

    public haveDealInBasket(): boolean {
        let item = this.getItems().find(x=>x.purchaseDecision.product.itemType == ProductItemType.Deal.valueOf());
        return item != null && item != undefined; 
    } 

    public onCartItemsUpdated(cartItem: AvaCartItemDto) {
        this.onCartItemsUpdatedEvent.emit(cartItem);
    }
    
    public skipDelivery(){
        return this.cartHasMicrosoftItems() || this.cartHasAdobeItems();
    }
}