import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-methods-banner',
  templateUrl: './payment-methods-banner.component.html',
  styleUrls: ['./payment-methods-banner.component.less']
})
export class PaymentMethodsBannerComponent implements OnInit {
  assetsImagesFolder = './assets/payment/methods';

  americanExpress = `${this.assetsImagesFolder}/american-express-blue.png`;
  dinersClub = `${this.assetsImagesFolder}/diners-club-blue.png`;
  visa = `${this.assetsImagesFolder}/visa-blue.png`;
  verifiedByVisa = `${this.assetsImagesFolder}/visa-verified-blue.png`;
  masterpass = `${this.assetsImagesFolder}/masterpass.png`;
  paygateDpoCompany = `${this.assetsImagesFolder}/paygate-dpo-company.png`;

  paymentMethods = [{
    name: 'americanExpress',
    link: `${this.americanExpress}`,
    order: 3
  },
  {
    name: 'paygateDpoCompany',
    link: `${this.paygateDpoCompany}`,
    order: -1
  },
  {
    name: 'dinersClub',
    link: `${this.dinersClub}`,
    order: 4
  },
  {
    name: 'visa',
    link: `${this.visa}`,
    order: 1
  },
  {
    name: 'verifiedByVisa',
    link: `${this.verifiedByVisa}`,
    order: 2
  },
  {
    name: 'masterpass',
    link: `${this.masterpass}`,
    order: 5
  }];

  constructor() {
    this.paymentMethods = this.paymentMethods.sort((a, b) => (a.order < b.order ? -1 : 1));
   }

  ngOnInit() {
  }

}
