import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './auto-focus.directive';
import { BusyIfDirective } from './busy-if.directive';
import { ButtonBusyDirective } from './button-busy.directive';
import { CurrencyInputDirective } from './currency-input.directive';
import { FileDownloadService } from './file-download.service';
import { FriendProfilePictureComponent } from './friend-profile-picture.component';
import { LocalStorageService } from './local-storage.service';
import { MomentFormatPipe } from './moment-format.pipe';
import { PriceFormatterPipe } from "@shared/utils/price-format.pipe";
import { NormalizeDropdownPositionDirective } from './normalize-dropdown-position.directive';
import { ValidationMessagesComponent } from './validation-messages.component';
import { EqualValidator } from './validation/equal-validator.directive';
import { MinValueValidator } from './validation/min-value-validator.directive';
import { PasswordComplexityValidator } from './validation/password-complexity-validator.directive';
import { TruncatePipe } from './string-truncate.pipe';
import { KeyValuePipe } from "@shared/utils/key-value.pipe";
import { PhoneNumberValidatorDirective } from './validation/phone-number-validator.directive';
import { HoverClassDirective } from './hover-class.directive';
import { RegistrationNumberValidatorDirective } from './validation/registration-number-validator.directive';
import {UploadUtilsService} from "@shared/utils/upload-utils.service";
import { SafeHtmlPipeDirective } from './safe-html-pipe.directive';
import {IdNumberValidatorDirective} from "@shared/utils/validation/id-number-validator.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        FileDownloadService,
        LocalStorageService,
        UploadUtilsService
    ],
    declarations: [
        EqualValidator,
        PasswordComplexityValidator,
        MinValueValidator,
        ButtonBusyDirective,
        AutoFocusDirective,
        BusyIfDirective,
        FriendProfilePictureComponent,
        MomentFormatPipe,
        PriceFormatterPipe,
        CurrencyInputDirective,
        NormalizeDropdownPositionDirective,
        ValidationMessagesComponent,
        TruncatePipe,
        KeyValuePipe,
        PhoneNumberValidatorDirective,
        HoverClassDirective,
        RegistrationNumberValidatorDirective,
        SafeHtmlPipeDirective,
        IdNumberValidatorDirective,
    ],
    exports: [
        EqualValidator,
        PasswordComplexityValidator,
        MinValueValidator,
        ButtonBusyDirective,
        AutoFocusDirective,
        BusyIfDirective,
        FriendProfilePictureComponent,
        MomentFormatPipe,
        PriceFormatterPipe,
        CurrencyInputDirective,
        NormalizeDropdownPositionDirective,
        ValidationMessagesComponent,
        KeyValuePipe,
        TruncatePipe,
        PhoneNumberValidatorDirective,
        HoverClassDirective,
        RegistrationNumberValidatorDirective,
        SafeHtmlPipeDirective,
        IdNumberValidatorDirective,
    ]
})
export class UtilsModule { }
