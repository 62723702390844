import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core'; 
import { Router } from '@angular/router';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { PaymentsServiceProxy,  CartServiceProxy, TenantServiceProxy  } from '@shared/service-proxies/service-proxies';
import { CartDataService } from '../../../shared/common/services/cart.data.service';
import { ShoppingCartBaseComponentComponent } from '../../base/shopping-cart-base-component/shopping-cart-base.component';
import { ShoppingCartFormService } from '../../services/shopping-cart-form.service'; 

@Component({
  selector: 'payment-complete',
    templateUrl: './payment-complete.component.html',
    styleUrls: ['./payment-complete.component.less']
})
export class PaymentCompletedComponent extends ShoppingCartBaseComponentComponent implements OnInit { 

    constructor(
        injector: Injector,
        cartService: CartServiceProxy,
        router: Router,
        protected shoppingCartFormService: ShoppingCartFormService,
        protected cartDataService: CartDataService,
        protected paymentsService: PaymentsServiceProxy,
        protected tenantsAppService: TenantServiceProxy,
    ) {
        super(
            injector, 
            cartService, 
            router, 
            cartDataService, 
            shoppingCartFormService,
            tenantsAppService,
            paymentsService);
            
    }
}
