var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponentBase } from '../generic-dialog-component-base';
var SummaryDialogComponent = /** @class */ (function (_super) {
    __extends(SummaryDialogComponent, _super);
    function SummaryDialogComponent(activeModal) {
        var _this = _super.call(this, activeModal) || this;
        _this.activeModal = activeModal;
        return _this;
    }
    SummaryDialogComponent.prototype.ngOnInit = function () {
        this.init();
    };
    return SummaryDialogComponent;
}(DialogComponentBase));
export { SummaryDialogComponent };
