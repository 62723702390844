import { OnInit } from '@angular/core';
import { PermissionServiceProxy } from '../../../../shared/service-proxies/service-proxies';
import { ReplaySubject } from "@node_modules/rxjs";
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/service-proxies/service-proxies";
var ImpersonatorPermissionsService = /** @class */ (function () {
    function ImpersonatorPermissionsService(permissionsService) {
        this.permissionsService = permissionsService;
        this.loadingPermissions = false;
        this.permissionsLoaded = new ReplaySubject(1);
    }
    ImpersonatorPermissionsService.prototype.ngOnInit = function () {
        this.loadPermissions();
    };
    //Load and store impersonator permissions for checking
    ImpersonatorPermissionsService.prototype.loadPermissions = function () {
        var _this = this;
        this.loadingPermissions = true;
        this.permissionsService.getAllImpersonatorPermissions().subscribe(function (results) {
            _this.permissions = results.items;
            _this.loadingPermissions = false;
            _this.permissionsLoaded.next(true);
        });
    };
    //is permission granted
    ImpersonatorPermissionsService.prototype.isGranted = function (permissionName) {
        if (!this.permissions) {
            if (!this.loadingPermissions) {
                this.loadPermissions();
            }
            return false;
        }
        return this.permissions.some(function (item) { return item.name == permissionName; });
    };
    ImpersonatorPermissionsService.ngInjectableDef = i0.defineInjectable({ factory: function ImpersonatorPermissionsService_Factory() { return new ImpersonatorPermissionsService(i0.inject(i1.PermissionServiceProxy)); }, token: ImpersonatorPermissionsService, providedIn: "root" });
    return ImpersonatorPermissionsService;
}());
export { ImpersonatorPermissionsService };
