import { Subject } from "@node_modules/rxjs";
import { TokenAuthServiceProxy, UserTwoFactorAuthDetailsModel } from "@shared/service-proxies/service-proxies";
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/service-proxies/service-proxies";
var UserAccountService = /** @class */ (function () {
    function UserAccountService(_tokenAuthService) {
        this._tokenAuthService = _tokenAuthService;
    }
    UserAccountService.prototype.getTwoFactorStatus = function () {
        var userTwoFactorAuthDetailsSubject = new Subject();
        if (!abp.session.userId || abp.session.impersonatorUserId) {
            userTwoFactorAuthDetailsSubject.next(new UserTwoFactorAuthDetailsModel());
        }
        else {
            this._tokenAuthService.getUserTwoFactorAuthStatus(abp.session.tenantId != null ? abp.session.tenantId : undefined, abp.session.userId)
                .subscribe(function (result) {
                userTwoFactorAuthDetailsSubject.next(result);
            });
        }
        return userTwoFactorAuthDetailsSubject.asObservable();
    };
    UserAccountService.ngInjectableDef = i0.defineInjectable({ factory: function UserAccountService_Factory() { return new UserAccountService(i0.inject(i1.TokenAuthServiceProxy)); }, token: UserAccountService, providedIn: "root" });
    return UserAccountService;
}());
export { UserAccountService };
