/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./chat-message.component";
import * as i3 from "../../../../shared/service-proxies/service-proxies";
var styles_ChatMessageComponent = [];
var RenderType_ChatMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChatMessageComponent, data: {} });
export { RenderType_ChatMessageComponent as RenderType_ChatMessageComponent };
function View_ChatMessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "img", [["class", "chat-image-preview"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chatMessage; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.chatMessage; _ck(_v, 1, 0, currVal_1); }); }
function View_ChatMessageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "chat-file-preview"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-file"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-download pull-right"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chatMessage; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.fileName; _ck(_v, 2, 0, currVal_1); }); }
function View_ChatMessageComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "chat-link-message"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-link"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chatMessage; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.chatMessage; _ck(_v, 2, 0, currVal_1); }); }
function View_ChatMessageComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chatMessage; _ck(_v, 0, 0, currVal_0); }); }
export function View_ChatMessageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChatMessageComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChatMessageComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChatMessageComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChatMessageComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.chatMessageType === "image"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.chatMessageType === "file"); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.chatMessageType === "link"); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.chatMessageType === "text"); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_ChatMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "chat-message", [], null, null, null, View_ChatMessageComponent_0, RenderType_ChatMessageComponent)), i0.ɵdid(1, 114688, null, 0, i2.ChatMessageComponent, [i3.ChatServiceProxy], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatMessageComponentNgFactory = i0.ɵccf("chat-message", i2.ChatMessageComponent, View_ChatMessageComponent_Host_0, { message: "message" }, {}, []);
export { ChatMessageComponentNgFactory as ChatMessageComponentNgFactory };
