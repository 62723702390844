var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { AppNavigationService } from '../app-navigation.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { TopMenuBase } from './top-menu-base';
var TopNavBarComponent = /** @class */ (function (_super) {
    __extends(TopNavBarComponent, _super);
    function TopNavBarComponent(navigationSvc, appSessionService, injector) {
        var _this = _super.call(this, appSessionService, navigationSvc, injector) || this;
        _this.navigationSvc = navigationSvc;
        _this.userId = abp.session.userId;
        _this.tenantId = abp.session.tenantId;
        _this.isLoggedIn = _this.userId != null && _this.userId > 0;
        _this.isPartner = _this.isLoggedIn && _this.tenantId > 0;
        _this.isHost = _this.isLoggedIn && !_this.isPartner;
        _this.assetsImagesFolder = './assets/common/images';
        _this.activeArea = '';
        return _this;
    }
    TopNavBarComponent.prototype.ngOnInit = function () {
        this.menu = this.navigationSvc.getTopMenu();
    };
    TopNavBarComponent.prototype.getMenuItemStateClass = function (menuItem) {
        var location = this.activeArea ? this.activeArea : window.location.pathname;
        return (location.toLowerCase() == menuItem.route.toLowerCase()) ? 'active' : '';
    };
    TopNavBarComponent.prototype.getParentActiveState = function (menuItem) {
        var currentLocation = window.location.pathname;
        if (this.hasChildren(menuItem) &&
            this.getDisplayableChildren(menuItem).filter(function (c) { return c.route == currentLocation; }).length > 0) {
            return 'active';
        }
        else {
            return '';
        }
    };
    TopNavBarComponent.prototype.getActionLinkTarget = function (menuItem) {
        return menuItem.actionLink.indexOf('#') > -1 ? '' : '_blank';
    };
    TopNavBarComponent.prototype.isLastMenuItem = function (menuItem) {
        var _this = this;
        var displayedList = this.menu.items.filter(function (m) { return _this.isDisplayed(m); });
        return displayedList[displayedList.length - 1].name === menuItem.name;
    };
    TopNavBarComponent.prototype.resetActivePath = function () {
        this.activeArea = '';
    };
    TopNavBarComponent.prototype.toggled = function (isOpen, id) {
        if (isOpen) {
            this.activeArea = id;
        }
        else {
            if (this.activeArea == id)
                this.activeArea = window.location.pathname;
        }
    };
    TopNavBarComponent.prototype.isDropDownMenu = function (menuItem) {
        return this.hasChildren(menuItem) && this.showMenuItem(menuItem);
    };
    TopNavBarComponent.prototype.isActionLinkItem = function (menuItem) {
        return !this.hasChildren(menuItem) && !menuItem.route && menuItem.actionLink && this.showMenuItem(menuItem);
    };
    TopNavBarComponent.prototype.isRouterLinkItem = function (menuItem) {
        return !this.hasChildren(menuItem) && menuItem.route && this.showMenuItem(menuItem);
    };
    TopNavBarComponent.prototype.isDisplayed = function (menuItem) {
        return this.isActionLinkItem(menuItem) || this.isRouterLinkItem(menuItem) || this.isDropDownMenu(menuItem);
    };
    return TopNavBarComponent;
}(TopMenuBase));
export { TopNavBarComponent };
