var FormattedStringValueExtracter = /** @class */ (function () {
    function FormattedStringValueExtracter() {
    }
    FormattedStringValueExtracter.prototype.Extract = function (str, format) {
        if (str === format) {
            return new ExtractionResult(true);
        }
        var formatTokens = new FormatStringTokenizer().Tokenize(format);
        if (!formatTokens) {
            return new ExtractionResult(str === '');
        }
        var result = new ExtractionResult(true);
        for (var i = 0; i < formatTokens.length; i++) {
            var currentToken = formatTokens[i];
            var previousToken = i > 0 ? formatTokens[i - 1] : null;
            if (currentToken.Type === FormatStringTokenType.ConstantText) {
                if (i === 0) {
                    if (str.indexOf(currentToken.Text) !== 0) {
                        result.IsMatch = false;
                        return result;
                    }
                    str = str.substr(currentToken.Text.length, str.length - currentToken.Text.length);
                }
                else {
                    var matchIndex = str.indexOf(currentToken.Text);
                    if (matchIndex < 0) {
                        result.IsMatch = false;
                        return result;
                    }
                    result.Matches.push({ name: previousToken.Text, value: str.substr(0, matchIndex) });
                    str = str.substring(0, matchIndex + currentToken.Text.length);
                }
            }
        }
        var lastToken = formatTokens[formatTokens.length - 1];
        if (lastToken.Type === FormatStringTokenType.DynamicValue) {
            result.Matches.push({ name: lastToken.Text, value: str });
        }
        return result;
    };
    FormattedStringValueExtracter.prototype.IsMatch = function (str, format) {
        var result = new FormattedStringValueExtracter().Extract(str, format);
        if (!result.IsMatch) {
            return [];
        }
        var values = [];
        for (var i = 0; i < result.Matches.length; i++) {
            values.push(result.Matches[i].value);
        }
        return values;
    };
    return FormattedStringValueExtracter;
}());
export { FormattedStringValueExtracter };
var ExtractionResult = /** @class */ (function () {
    function ExtractionResult(isMatch) {
        this.IsMatch = isMatch;
        this.Matches = [];
    }
    return ExtractionResult;
}());
var FormatStringTokenType;
(function (FormatStringTokenType) {
    FormatStringTokenType[FormatStringTokenType["ConstantText"] = 0] = "ConstantText";
    FormatStringTokenType[FormatStringTokenType["DynamicValue"] = 1] = "DynamicValue";
})(FormatStringTokenType || (FormatStringTokenType = {}));
var FormatStringToken = /** @class */ (function () {
    function FormatStringToken(text, type) {
        this.Text = text;
        this.Type = type;
    }
    return FormatStringToken;
}());
var FormatStringTokenizer = /** @class */ (function () {
    function FormatStringTokenizer() {
    }
    FormatStringTokenizer.prototype.Tokenize = function (format, includeBracketsForDynamicValues) {
        if (includeBracketsForDynamicValues === void 0) { includeBracketsForDynamicValues = false; }
        var tokens = [];
        var currentText = '';
        var inDynamicValue = false;
        for (var i = 0; i < format.length; i++) {
            var c = format[i];
            switch (c) {
                case '{':
                    if (inDynamicValue) {
                        throw new Error('Incorrect syntax at char ' + i + '! format string can not contain nested dynamic value expression!');
                    }
                    inDynamicValue = true;
                    if (currentText.length > 0) {
                        tokens.push(new FormatStringToken(currentText, FormatStringTokenType.ConstantText));
                        currentText = '';
                    }
                    break;
                case '}':
                    if (!inDynamicValue) {
                        throw new Error(('Incorrect syntax at char ' + i + '! These is no opening brackets for the closing bracket }.'));
                    }
                    inDynamicValue = false;
                    if (currentText.length <= 0) {
                        throw new Error(('Incorrect syntax at char ' + i + '! Brackets does not containt any chars.'));
                    }
                    var dynamicValue = currentText;
                    if (includeBracketsForDynamicValues) {
                        dynamicValue = '{' + dynamicValue + '}';
                    }
                    tokens.push(new FormatStringToken(dynamicValue, FormatStringTokenType.DynamicValue));
                    currentText = '';
                    break;
                default:
                    currentText += c;
                    break;
            }
        }
        if (inDynamicValue) {
            throw new Error(('There is no closing } char for an opened { char.'));
        }
        if (currentText.length > 0) {
            tokens.push(new FormatStringToken(currentText, FormatStringTokenType.ConstantText));
        }
        return tokens;
    };
    return FormatStringTokenizer;
}());
