import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
var ɵ0 = { preload: true }, ɵ1 = { preload: true }, ɵ2 = { preload: true }, ɵ3 = { preload: true }, ɵ4 = { preload: true }, ɵ5 = { preload: true }, ɵ6 = { preload: true };
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule(router) {
        this.router = router;
        router.events.subscribe(function (event) {
            if (event instanceof RouteConfigLoadStart) {
                abp.ui.setBusy();
            }
            if (event instanceof RouteConfigLoadEnd) {
                abp.ui.clearBusy();
            }
            if (event instanceof NavigationEnd) {
                $('meta[property=og\\:url]').attr('content', window.location.href);
                $('#m_aside_left').mOffcanvas().hide();
            }
        });
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
