var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, EventEmitter, Injector, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppChatMessageReadState, AppChatSide, AppFriendshipState } from '@shared/AppEnums';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { DomHelper } from '@shared/helpers/DomHelper';
import { BlockUserInput, ChatMessageDtoSide, ChatServiceProxy, CreateFriendshipRequestByUserNameInput, CreateFriendshipRequestInput, FriendDto, FriendDtoState, FriendshipServiceProxy, MarkAllUnreadMessagesOfUserAsReadInput, NameValueDto, ProfileServiceProxy, UnblockUserInput, UserLoginInfoDto } from '@shared/service-proxies/service-proxies';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import { QuickSideBarChat } from './QuickSideBarChat';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ChatFriendDto } from './ChatFriendDto';
import { ChatSignalrService } from './chat-signalr.service';
import { UserLookupComponent } from '../../common/lookup/user-lookup.component';
var ChatBarComponent = /** @class */ (function (_super) {
    __extends(ChatBarComponent, _super);
    function ChatBarComponent(injector, _appSessionService, _friendshipService, _chatService, _localStorageService, _chatSignalrService, _profileService, _quickSideBarChat) {
        var _this = _super.call(this, injector) || this;
        _this._appSessionService = _appSessionService;
        _this._friendshipService = _friendshipService;
        _this._chatService = _chatService;
        _this._localStorageService = _localStorageService;
        _this._chatSignalrService = _chatSignalrService;
        _this._profileService = _profileService;
        _this._quickSideBarChat = _quickSideBarChat;
        _this.onProgress = new EventEmitter();
        _this.progress = 0;
        _this.isFileSelected = false;
        _this.friendDtoState = AppFriendshipState;
        _this.currentUser = _this._appSessionService.user;
        _this.profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        _this.chatMessage = '';
        _this.tenantToTenantChatAllowed = false;
        _this.tenantToHostChatAllowed = false;
        _this.interTenantChatAllowed = false;
        _this.sendingMessage = false;
        _this.loadingPreviousUserMessages = false;
        _this.userNameFilter = '';
        _this.serverClientTimeDifference = 0;
        _this.isMultiTenancyEnabled = _this.multiTenancy.isEnabled;
        _this.appChatSide = AppChatSide;
        _this.appChatMessageReadState = AppChatMessageReadState;
        _this._pinned = false;
        _this._selectedUser = new ChatFriendDto();
        _this.uploadUrl = AppConsts.remoteServiceBaseUrl + '/Chat/UploadFile';
        return _this;
    }
    Object.defineProperty(ChatBarComponent.prototype, "isOpen", {
        get: function () {
            return this._isOpen;
        },
        set: function (newValue) {
            if (newValue === this._isOpen) {
                return;
            }
            this._localStorageService.setItem('app.chat.isOpen', newValue);
            this._isOpen = newValue;
            if (newValue) {
                this.markAllUnreadMessagesOfUserAsRead(this.selectedUser);
            }
            this.adjustNotifyPosition();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatBarComponent.prototype, "pinned", {
        get: function () {
            return this._pinned;
        },
        set: function (newValue) {
            if (newValue === this._pinned) {
                return;
            }
            this._pinned = newValue;
            this._localStorageService.setItem('app.chat.pinned', newValue);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChatBarComponent.prototype, "selectedUser", {
        get: function () {
            return this._selectedUser;
        },
        set: function (newValue) {
            if (newValue === this._selectedUser) {
                return;
            }
            this._selectedUser = newValue;
            //NOTE: this is a fix for localForage is not able to store user with messages array filled
            if (newValue.messages) {
                newValue.messages = [];
                newValue.messagesLoaded = false;
            }
            this._localStorageService.setItem('app.chat.selectedUser', newValue);
        },
        enumerable: true,
        configurable: true
    });
    ChatBarComponent.prototype.shareCurrentLink = function () {
        this.chatMessage = '[link]{"message":"' + window.location.href + '"}';
        this.sendMessage();
        $('#chatDropdownToggle').dropdown('toggle');
    };
    ChatBarComponent.prototype.onFileSelect = function () {
        $('#chatDropdownToggle').dropdown('toggle');
    };
    ChatBarComponent.prototype.onUploadImage = function (event) {
        var jsonResult = JSON.parse(event.xhr.response);
        this.chatMessage = '[image]{"id":"' + jsonResult.result.id + '", "name":"' + jsonResult.result.name + '", "contentType":"' + jsonResult.result.contentType + '"}';
        this.sendMessage();
        this.isFileSelected = false;
        this.progress = 0;
    };
    ChatBarComponent.prototype.onUploadFile = function (event) {
        var jsonResult = JSON.parse(event.xhr.response);
        this.chatMessage = '[file]{"id":"' + jsonResult.result.id + '", "name":"' + jsonResult.result.name + '", "contentType":"' + jsonResult.result.contentType + '"}';
        this.sendMessage();
        this.isFileSelected = false;
        this.progress = 0;
    };
    ChatBarComponent.prototype.onBeforeSend = function (event) {
        var _this = this;
        this.isFileSelected = true;
        event.xhr.setRequestHeader('Authorization', 'Bearer ' + abp.auth.getToken());
        event.xhr.upload.addEventListener('progress', function (e) {
            if (e.lengthComputable) {
                _this.progress = Math.round((e.loaded * 100) / e.total);
            }
            _this.onProgress.emit({ originalEvent: e, progress: _this.progress });
        }, false);
    };
    ChatBarComponent.prototype.ngOnInit = function () {
        this.init();
    };
    ChatBarComponent.prototype.getShownUserName = function (tenanycName, userName) {
        if (!this.isMultiTenancyEnabled) {
            return userName;
        }
        return (tenanycName ? tenanycName : '.') + '\\' + userName;
    };
    ChatBarComponent.prototype.getProfilePicture = function () {
        var _this = this;
        this._profileService.getProfilePicture().subscribe(function (result) {
            if (result && result.profilePicture) {
                _this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    };
    ChatBarComponent.prototype.block = function (user) {
        var _this = this;
        var blockUserInput = new BlockUserInput();
        blockUserInput.tenantId = user.friendTenantId;
        blockUserInput.userId = user.friendUserId;
        this._friendshipService.blockUser(blockUserInput).subscribe(function () {
            _this.notify.info(_this.l('UserBlocked'));
        });
    };
    ChatBarComponent.prototype.unblock = function (user) {
        var _this = this;
        var unblockUserInput = new UnblockUserInput();
        unblockUserInput.tenantId = user.friendTenantId;
        unblockUserInput.userId = user.friendUserId;
        this._friendshipService.unblockUser(unblockUserInput).subscribe(function () {
            _this.notify.info(_this.l('UserUnblocked'));
        });
    };
    ChatBarComponent.prototype.markAllUnreadMessagesOfUserAsRead = function (user) {
        if (!user || !this.isOpen) {
            return;
        }
        var unreadMessages = _.filter(user.messages, function (m) { return m.readState === AppChatMessageReadState.Unread; });
        var unreadMessageIds = _.map(unreadMessages, 'id');
        if (!unreadMessageIds.length) {
            return;
        }
        var input = new MarkAllUnreadMessagesOfUserAsReadInput();
        input.tenantId = user.friendTenantId;
        input.userId = user.friendUserId;
        this._chatService.markAllUnreadMessagesOfUserAsRead(input).subscribe(function () {
            _.forEach(user.messages, function (message) {
                if (unreadMessageIds.indexOf(message.id) >= 0) {
                    message.readState = AppChatMessageReadState.Read;
                }
            });
        });
    };
    ChatBarComponent.prototype.loadMessages = function (user, callback) {
        var _this = this;
        this.loadingPreviousUserMessages = true;
        var minMessageId;
        if (user.messages && user.messages.length) {
            minMessageId = _.min(_.map(user.messages, function (m) { return m.id; }));
        }
        this._chatService.getUserChatMessages(user.friendTenantId ? user.friendTenantId : undefined, user.friendUserId, minMessageId)
            .subscribe(function (result) {
            if (!user.messages) {
                user.messages = [];
            }
            user.messages = result.items.concat(user.messages);
            _this.markAllUnreadMessagesOfUserAsRead(user);
            if (!result.items.length) {
                user.allPreviousMessagesLoaded = true;
            }
            _this.loadingPreviousUserMessages = false;
            if (callback) {
                callback();
            }
        });
    };
    ChatBarComponent.prototype.openSearchModal = function (userName, tenantId) {
        this.userLookupModal.filterText = userName;
        this.userLookupModal.show();
    };
    ChatBarComponent.prototype.addFriendSelected = function (item) {
        var _this = this;
        var userId = item.value;
        var input = new CreateFriendshipRequestInput();
        input.userId = parseInt(userId);
        input.tenantId = this._appSessionService.tenant ? this._appSessionService.tenant.id : null;
        this._friendshipService.createFriendshipRequest(input).subscribe(function () {
            _this.userNameFilter = '';
        });
    };
    ChatBarComponent.prototype.search = function () {
        var _this = this;
        var input = new CreateFriendshipRequestByUserNameInput();
        if (this.userNameFilter.indexOf('\\') === -1) {
            input.userName = this.userNameFilter;
        }
        else {
            var tenancyAndUserNames = this.userNameFilter.split('\\');
            input.tenancyName = tenancyAndUserNames[0];
            input.userName = tenancyAndUserNames[1];
        }
        if (!input.tenancyName || !this.interTenantChatAllowed) {
            var tenantId = this._appSessionService.tenant ? this._appSessionService.tenant.id : null;
            this.openSearchModal(input.userName, tenantId);
        }
        else {
            this._friendshipService.createFriendshipRequestByUserName(input).subscribe(function () {
                _this.userNameFilter = '';
            });
        }
    };
    ChatBarComponent.prototype.getFriendOrNull = function (userId, tenantId) {
        var friends = _.filter(this.friends, function (friend) { return friend.friendUserId === userId && friend.friendTenantId === tenantId; });
        if (friends.length) {
            return friends[0];
        }
        return null;
    };
    ChatBarComponent.prototype.getFilteredFriends = function (state, userNameFilter) {
        var _this = this;
        var foundFriends = _.filter(this.friends, function (friend) { return friend.state === state &&
            _this.getShownUserName(friend.friendTenancyName, friend.friendUserName)
                .toLocaleLowerCase()
                .indexOf(userNameFilter.toLocaleLowerCase()) >= 0; });
        return foundFriends;
    };
    ChatBarComponent.prototype.getFilteredFriendsCount = function (state) {
        return _.filter(this.friends, function (friend) { return friend.state === state; }).length;
    };
    ChatBarComponent.prototype.getUserNameByChatSide = function (chatSide) {
        return chatSide === AppChatSide.Sender ?
            this.currentUser.userName :
            this.selectedUser.friendUserName;
    };
    ChatBarComponent.prototype.getFixedMessageTime = function (messageTime) {
        return moment(messageTime).add(-1 * this.serverClientTimeDifference, 'seconds').format('YYYY-MM-DDTHH:mm:ssZ');
    };
    ChatBarComponent.prototype.changeNotifyPosition = function (positionClass) {
        if (!toastr) {
            return;
        }
        toastr.clear();
        toastr.options.positionClass = positionClass;
    };
    ChatBarComponent.prototype.getFriendsAndSettings = function (callback) {
        var _this = this;
        this._chatService.getUserChatFriendsWithSettings().subscribe(function (result) {
            _this.friends = result.friends;
            _this.serverClientTimeDifference = moment(abp.clock.now()).diff(result.serverTime, 'seconds');
            _this.triggerUnreadMessageCountChangeEvent();
            callback();
        });
    };
    ChatBarComponent.prototype.scrollToBottom = function () {
        var _this = this;
        setTimeout(function () {
            _this.scrollToBottomInternal();
        }, 100);
    };
    ChatBarComponent.prototype.scrollToBottomInternal = function () {
        DomHelper.waitUntilElementIsVisible('.m-messenger-conversation .m-messenger__messages', function () {
            setTimeout(function () {
                var $scrollArea = $('.m-messenger-conversation .m-messenger__messages');
                var scrollToVal = $scrollArea.prop('scrollHeight') + 'px';
                $scrollArea.slimScroll({ scrollTo: scrollToVal });
            });
        });
    };
    ChatBarComponent.prototype.loadLastState = function () {
        var self = this;
        self._localStorageService.getItem('app.chat.isOpen', function (err, isOpen) {
            self.isOpen = isOpen;
            self._localStorageService.getItem('app.chat.pinned', function (err, pinned) {
                self.pinned = pinned;
            });
            if (isOpen) {
                self._quickSideBarChat.show();
                self._localStorageService.getItem('app.chat.selectedUser', function (err, selectedUser) {
                    if (selectedUser && selectedUser.friendUserId) {
                        self.showMessagesPanel();
                        self.selectFriend(selectedUser);
                    }
                    else {
                        self.showFriendsPanel();
                    }
                });
            }
        });
    };
    ChatBarComponent.prototype.selectFriend = function (friend) {
        var _this = this;
        var chatUser = this.getFriendOrNull(friend.friendUserId, friend.friendTenantId);
        this.selectedUser = chatUser;
        if (!chatUser) {
            return;
        }
        this.chatMessage = '';
        this.showMessagesPanel();
        if (!chatUser.messagesLoaded) {
            this.loadMessages(chatUser, function () {
                chatUser.messagesLoaded = true;
                _this.scrollToBottom();
                _this.$_chatMessageInput.focus();
            });
        }
        else {
            this.markAllUnreadMessagesOfUserAsRead(this.selectedUser);
            this.scrollToBottom();
            this.$_chatMessageInput.focus();
        }
    };
    ChatBarComponent.prototype.showMessagesPanel = function () {
        var _this = this;
        $('.m-messenger-friends').hide();
        $('.m-messenger-conversation').show(function () {
            _this.initConversationScrollbar();
        });
        $('#m_quick_sidebar_back').removeClass('d-none');
    };
    ChatBarComponent.prototype.showFriendsPanel = function () {
        $('.m-messenger-friends').show();
        $('.m-messenger-conversation').hide();
        $('#m_quick_sidebar_back').addClass('d-none');
    };
    ChatBarComponent.prototype.initConversationScrollbar = function () {
        var $messengerMessages = $('.m-messenger-conversation .m-messenger__messages');
        var height = $('#m_quick_sidebar').outerHeight(true) - $('.selected-chat-user').outerHeight(true) - $('#ChatMessage').height() - 150;
        $messengerMessages.slimScroll({ destroy: true });
        $messengerMessages.slimScroll({
            height: height
        });
    };
    ChatBarComponent.prototype.sendMessage = function () {
        var _this = this;
        if (!this.chatMessage) {
            return;
        }
        this.sendingMessage = true;
        var tenancyName = this._appSessionService.tenant ? this._appSessionService.tenant.tenancyName : null;
        this._chatSignalrService.sendMessage({
            tenantId: this.selectedUser.friendTenantId,
            userId: this.selectedUser.friendUserId,
            message: this.chatMessage,
            tenancyName: tenancyName,
            userName: this._appSessionService.user.userName,
            profilePictureId: this._appSessionService.user.profilePictureId
        }, function () {
            _this.chatMessage = '';
            _this.sendingMessage = false;
        });
    };
    ChatBarComponent.prototype.reversePinned = function () {
        this.pinned = !this.pinned;
    };
    ChatBarComponent.prototype.bindUiEvents = function () {
        var _this = this;
        var self = this;
        self._quickSideBarChat.init(function (e, pos) {
            if (pos === 0 && !_this.selectedUser.allPreviousMessagesLoaded && !_this.loadingPreviousUserMessages) {
                self.loadMessages(self.selectedUser, null);
            }
        });
        var $backToList = $('#m_quick_sidebar_back');
        $backToList.on('click', function () {
            self.selectedUser = new ChatFriendDto();
            _this.showFriendsPanel();
        });
        var $sidebarTogglers = $('#m_quick_sidebar_toggle');
        $sidebarTogglers.on('click', function () {
            _this.isOpen = $('body').hasClass('m-quick-sidebar--on');
        });
        $('div.m-quick-sidebar').on('mouseleave', function () {
            if (_this.pinned) {
                return;
            }
            self._quickSideBarChat.hide();
            _this.isOpen = false;
            _this.adjustNotifyPosition();
        });
        $(window).on('resize', function () {
            _this.initConversationScrollbar();
        });
    };
    ChatBarComponent.prototype.ngAfterViewInit = function () {
        this.$_chatMessageInput = $('#ChatMessage');
    };
    ChatBarComponent.prototype.adjustNotifyPosition = function () {
        if (this.isOpen) {
            this.changeNotifyPosition('toast-chat-open');
        }
        else {
            this.changeNotifyPosition('toast-bottom-right');
        }
    };
    ChatBarComponent.prototype.triggerUnreadMessageCountChangeEvent = function () {
        var totalUnreadMessageCount = 0;
        if (this.friends) {
            totalUnreadMessageCount = _.reduce(this.friends, function (memo, friend) { return memo + friend.unreadMessageCount; }, 0);
        }
        abp.event.trigger('app.chat.unreadMessageCountChanged', totalUnreadMessageCount);
    };
    ChatBarComponent.prototype.registerEvents = function () {
        var _this = this;
        var self = this;
        abp.event.on('app.chat.messageReceived', function (message) {
            var user = _this.getFriendOrNull(message.targetUserId, message.targetTenantId);
            if (!user) {
                return;
            }
            user.messages = user.messages || [];
            user.messages.push(message);
            if (message.side === AppChatSide.Receiver) {
                user.unreadMessageCount += 1;
                message.readState = AppChatMessageReadState.Unread;
                _this.triggerUnreadMessageCountChangeEvent();
                if (_this.isOpen && _this.selectedUser !== null && user.friendTenantId === _this.selectedUser.friendTenantId && user.friendUserId === _this.selectedUser.friendUserId) {
                    _this.markAllUnreadMessagesOfUserAsRead(user);
                }
                else {
                    _this.notify.info(abp.utils.formatString('{0}: {1}', user.friendUserName, abp.utils.truncateString(message.message, 100)), null, {
                        onclick: function () {
                            if (!$('body').hasClass('m-quick-sidebar--on')) {
                                self._quickSideBarChat.show();
                                self.isOpen = true;
                            }
                            self.showMessagesPanel();
                            self.selectFriend(user);
                            self.pinned = true;
                        }
                    });
                }
            }
            self.scrollToBottom();
        });
        abp.event.on('app.chat.friendshipRequestReceived', function (data, isOwnRequest) {
            if (!isOwnRequest) {
                abp.notify.info(_this.l('UserSendYouAFriendshipRequest', data.friendUserName));
            }
            if (!_.filter(_this.friends, function (f) { return f.friendUserId === data.friendUserId && f.friendTenantId === data.friendTenantId; }).length) {
                _this.friends.push(data);
            }
        });
        abp.event.on('app.chat.userConnectionStateChanged', function (data) {
            var user = _this.getFriendOrNull(data.friend.userId, data.friend.tenantId);
            if (!user) {
                return;
            }
            user.isOnline = data.isConnected;
        });
        abp.event.on('app.chat.userStateChanged', function (data) {
            var user = _this.getFriendOrNull(data.friend.userId, data.friend.tenantId);
            if (!user) {
                return;
            }
            user.state = data.state;
        });
        abp.event.on('app.chat.allUnreadMessagesOfUserRead', function (data) {
            var user = _this.getFriendOrNull(data.friend.userId, data.friend.tenantId);
            if (!user) {
                return;
            }
            user.unreadMessageCount = 0;
            _this.triggerUnreadMessageCountChangeEvent();
        });
        abp.event.on('app.chat.readStateChange', function (data) {
            var user = _this.getFriendOrNull(data.friend.userId, data.friend.tenantId);
            if (!user) {
                return;
            }
            $.each(user.messages, function (index, message) {
                message.receiverReadState = AppChatMessageReadState.Read;
            });
        });
        abp.event.on('app.chat.connected', function () {
            var self = _this;
            self.getFriendsAndSettings(function () {
                DomHelper.waitUntilElementIsReady('#m_quick_sidebar, #m_quick_sidebar_toggle', function () {
                    self.bindUiEvents();
                    DomHelper.waitUntilElementIsReady('.m-quick-sidebar', function () {
                        self.loadLastState();
                    });
                });
            });
        });
    };
    ChatBarComponent.prototype.init = function () {
        if (this._appSessionService.userId && this._appSessionService.userId > 0) {
            this.getProfilePicture();
        }
        this.registerEvents();
        this.tenantToTenantChatAllowed = this.feature.isEnabled('App.ChatFeature.TenantToTenant');
        this.tenantToHostChatAllowed = this.feature.isEnabled('App.ChatFeature.TenantToHost');
        this.interTenantChatAllowed = this.feature.isEnabled('App.ChatFeature.TenantToTenant') || this.feature.isEnabled('App.ChatFeature.TenantToHost') || !this._appSessionService.tenant;
    };
    return ChatBarComponent;
}(AppComponentBase));
export { ChatBarComponent };
