var AppUiHelper = /** @class */ (function () {
    function AppUiHelper() {
    }
    AppUiHelper.hideSideBar = function () {
        $('body, #m-aside-left').removeClass('m-aside-left');
        $('body, #m-aside-left').addClass('m-aside-left--hide');
    };
    return AppUiHelper;
}());
export { AppUiHelper };
