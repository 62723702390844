import { ChatMessageDtoReadState, ChatMessageDtoSide, CreatePaymentDtoEditionPaymentType, CreatePaymentDtoPaymentPeriodType, CreatePaymentDtoSubscriptionPaymentGatewayType, DefaultTimezoneScope, FriendDtoState, IncomeStatisticsDateInterval, IsTenantAvailableOutputState, RegisterTenantInputSubscriptionStartType, SalesSummaryDatePeriod, ServiceDtoApplicationType, ServiceEntityType, UserNotificationState, } from '@shared/service-proxies/service-proxies';
var AppChatMessageReadState = /** @class */ (function () {
    function AppChatMessageReadState() {
    }
    AppChatMessageReadState.Unread = ChatMessageDtoReadState._1;
    AppChatMessageReadState.Read = ChatMessageDtoReadState._2;
    return AppChatMessageReadState;
}());
export { AppChatMessageReadState };
var AppChatSide = /** @class */ (function () {
    function AppChatSide() {
    }
    AppChatSide.Sender = ChatMessageDtoSide._1;
    AppChatSide.Receiver = ChatMessageDtoSide._2;
    return AppChatSide;
}());
export { AppChatSide };
var AppFriendshipState = /** @class */ (function () {
    function AppFriendshipState() {
    }
    AppFriendshipState.Accepted = FriendDtoState._1;
    AppFriendshipState.Blocked = FriendDtoState._2;
    return AppFriendshipState;
}());
export { AppFriendshipState };
var AppTimezoneScope = /** @class */ (function () {
    function AppTimezoneScope() {
    }
    AppTimezoneScope.Application = DefaultTimezoneScope._1;
    AppTimezoneScope.Tenant = DefaultTimezoneScope._2;
    AppTimezoneScope.User = DefaultTimezoneScope._4;
    return AppTimezoneScope;
}());
export { AppTimezoneScope };
var AppUserNotificationState = /** @class */ (function () {
    function AppUserNotificationState() {
    }
    AppUserNotificationState.Unread = UserNotificationState._0;
    AppUserNotificationState.Read = UserNotificationState._1;
    return AppUserNotificationState;
}());
export { AppUserNotificationState };
var AppTenantAvailabilityState = /** @class */ (function () {
    function AppTenantAvailabilityState() {
    }
    AppTenantAvailabilityState.Available = IsTenantAvailableOutputState._1;
    AppTenantAvailabilityState.InActive = IsTenantAvailableOutputState._2;
    AppTenantAvailabilityState.NotFound = IsTenantAvailableOutputState._3;
    return AppTenantAvailabilityState;
}());
export { AppTenantAvailabilityState };
var AppIncomeStatisticsDateInterval = /** @class */ (function () {
    function AppIncomeStatisticsDateInterval() {
    }
    AppIncomeStatisticsDateInterval.Daily = IncomeStatisticsDateInterval._1;
    AppIncomeStatisticsDateInterval.Weekly = IncomeStatisticsDateInterval._2;
    AppIncomeStatisticsDateInterval.Monthly = IncomeStatisticsDateInterval._3;
    return AppIncomeStatisticsDateInterval;
}());
export { AppIncomeStatisticsDateInterval };
var SubscriptionStartType = /** @class */ (function () {
    function SubscriptionStartType() {
    }
    SubscriptionStartType.Free = RegisterTenantInputSubscriptionStartType._1;
    SubscriptionStartType.Trial = RegisterTenantInputSubscriptionStartType._2;
    SubscriptionStartType.Paid = RegisterTenantInputSubscriptionStartType._3;
    return SubscriptionStartType;
}());
export { SubscriptionStartType };
var EditionPaymentType = /** @class */ (function () {
    function EditionPaymentType() {
    }
    EditionPaymentType.NewRegistration = CreatePaymentDtoEditionPaymentType._0;
    EditionPaymentType.BuyNow = CreatePaymentDtoEditionPaymentType._1;
    EditionPaymentType.Upgrade = CreatePaymentDtoEditionPaymentType._2;
    EditionPaymentType.Extend = CreatePaymentDtoEditionPaymentType._3;
    return EditionPaymentType;
}());
export { EditionPaymentType };
var ServiceApplicationType = /** @class */ (function () {
    function ServiceApplicationType() {
    }
    ServiceApplicationType.AdHoc = ServiceDtoApplicationType._1;
    ServiceApplicationType.Custom = ServiceDtoApplicationType._2;
    ServiceApplicationType.Default = ServiceDtoApplicationType._3;
    ServiceApplicationType.All = ServiceDtoApplicationType._100;
    return ServiceApplicationType;
}());
export { ServiceApplicationType };
var ServiceCriteria = /** @class */ (function () {
    function ServiceCriteria() {
    }
    ServiceCriteria.StartsWith = 2;
    ServiceCriteria.Contains = 4;
    ServiceCriteria.EndWith = 3;
    ServiceCriteria.ExactMatch = 1;
    return ServiceCriteria;
}());
export { ServiceCriteria };
var EntityType = /** @class */ (function () {
    function EntityType() {
    }
    EntityType.Cisco_Estimate = ServiceEntityType._1;
    EntityType.Cisco_Product = ServiceEntityType._2;
    return EntityType;
}());
export { EntityType };
var AppEditionExpireAction = /** @class */ (function () {
    function AppEditionExpireAction() {
    }
    AppEditionExpireAction.DeactiveTenant = 'DeactiveTenant';
    AppEditionExpireAction.AssignToAnotherEdition = 'AssignToAnotherEdition';
    return AppEditionExpireAction;
}());
export { AppEditionExpireAction };
var PaymentPeriodType = /** @class */ (function () {
    function PaymentPeriodType() {
    }
    PaymentPeriodType.Monthly = CreatePaymentDtoPaymentPeriodType._30;
    PaymentPeriodType.Annual = CreatePaymentDtoPaymentPeriodType._365;
    return PaymentPeriodType;
}());
export { PaymentPeriodType };
var SubscriptionPaymentGatewayType = /** @class */ (function () {
    function SubscriptionPaymentGatewayType() {
    }
    SubscriptionPaymentGatewayType.Paypal = CreatePaymentDtoSubscriptionPaymentGatewayType._1;
    return SubscriptionPaymentGatewayType;
}());
export { SubscriptionPaymentGatewayType };
var AppSalesSummaryDatePeriod = /** @class */ (function () {
    function AppSalesSummaryDatePeriod() {
    }
    AppSalesSummaryDatePeriod.Daily = SalesSummaryDatePeriod._1;
    AppSalesSummaryDatePeriod.Weekly = SalesSummaryDatePeriod._2;
    AppSalesSummaryDatePeriod.Monthly = SalesSummaryDatePeriod._3;
    return AppSalesSummaryDatePeriod;
}());
export { AppSalesSummaryDatePeriod };
var AppMarkets = /** @class */ (function () {
    function AppMarkets() {
    }
    AppMarkets.Default = 0;
    AppMarkets.IBM = 1;
    AppMarkets.Microsoft = 2;
    AppMarkets.IBM_MOCK = 3;
    AppMarkets.Cisco = 4;
    AppMarkets.Redhat = 5;
    AppMarkets.CiscoMock = 6;
    AppMarkets.MicrosoftMock = 7;
    AppMarkets.Huawei = 13;
    AppMarkets.GeneralMarket = 14;
    AppMarkets.HPE = 15;
    AppMarkets.Adobe = 16;
    AppMarkets.MicrosoftDesc = 'Microsoft';
    AppMarkets.IBMDesc = 'IBM';
    AppMarkets.CiscoDesc = 'Cisco';
    AppMarkets.RedhatDesc = 'Redhat';
    AppMarkets.CiscoMockDesc = 'CiscoMock';
    AppMarkets.MicrosoftMockDesc = 'MicrosoftMock';
    AppMarkets.DellDesc = 'Dell';
    AppMarkets.GeneralMarketDesc = 'Marketplace';
    AppMarkets.AdobeMarketDesc = 'Adobe';
    return AppMarkets;
}());
export { AppMarkets };
export var PaymentTypes;
(function (PaymentTypes) {
    PaymentTypes[PaymentTypes["NoneSelected"] = 0] = "NoneSelected";
    PaymentTypes[PaymentTypes["AxizCredit"] = 1] = "AxizCredit";
    PaymentTypes[PaymentTypes["CapitecCredit"] = 2] = "CapitecCredit";
    PaymentTypes[PaymentTypes["AxizCDC"] = 3] = "AxizCDC";
    PaymentTypes[PaymentTypes["OrderRequest"] = 4] = "OrderRequest";
    PaymentTypes[PaymentTypes["AxizCloudCredit"] = 5] = "AxizCloudCredit";
    PaymentTypes[PaymentTypes["ProofOfPayment"] = 6] = "ProofOfPayment";
})(PaymentTypes || (PaymentTypes = {}));
var ConfigurableBrands = /** @class */ (function () {
    function ConfigurableBrands() {
    }
    ConfigurableBrands.HPE = 'HPE';
    return ConfigurableBrands;
}());
export { ConfigurableBrands };
export var AppMarketsDesc;
(function (AppMarketsDesc) {
    AppMarketsDesc[AppMarketsDesc["IBM"] = 1] = "IBM";
    AppMarketsDesc[AppMarketsDesc["Microsoft"] = 2] = "Microsoft";
    AppMarketsDesc[AppMarketsDesc["Cisco"] = 4] = "Cisco";
    AppMarketsDesc[AppMarketsDesc["Redhat"] = 5] = "Redhat";
    AppMarketsDesc[AppMarketsDesc["CiscoMock"] = 6] = "CiscoMock";
    AppMarketsDesc[AppMarketsDesc["MicrosoftMock"] = 7] = "MicrosoftMock";
    AppMarketsDesc[AppMarketsDesc["Marketplace"] = 14] = "Marketplace";
    AppMarketsDesc[AppMarketsDesc["Adobe"] = 16] = "Adobe";
})(AppMarketsDesc || (AppMarketsDesc = {}));
export var AuthorizationType;
(function (AuthorizationType) {
    AuthorizationType[AuthorizationType["Basic"] = 0] = "Basic";
    AuthorizationType[AuthorizationType["OAuth2"] = 1] = "OAuth2";
    AuthorizationType[AuthorizationType["Custom"] = 2] = "Custom";
    AuthorizationType[AuthorizationType["None"] = 3] = "None";
    AuthorizationType[AuthorizationType["HpeValidation"] = 4] = "HpeValidation";
})(AuthorizationType || (AuthorizationType = {}));
export var PriceRuleGrouping;
(function (PriceRuleGrouping) {
    PriceRuleGrouping[PriceRuleGrouping["Governance"] = 1] = "Governance";
    PriceRuleGrouping[PriceRuleGrouping["Product"] = 2] = "Product";
    PriceRuleGrouping[PriceRuleGrouping["Partner"] = 3] = "Partner";
    PriceRuleGrouping[PriceRuleGrouping["Special"] = 4] = "Special";
})(PriceRuleGrouping || (PriceRuleGrouping = {}));
export var PricingBreakdownKey;
(function (PricingBreakdownKey) {
    PricingBreakdownKey[PricingBreakdownKey["ROE"] = 1] = "ROE";
    PricingBreakdownKey[PricingBreakdownKey["Monthly"] = 2] = "Monthly";
    PricingBreakdownKey[PricingBreakdownKey["MonthlyInclusive"] = 3] = "MonthlyInclusive";
    PricingBreakdownKey[PricingBreakdownKey["Annual"] = 4] = "Annual";
    PricingBreakdownKey[PricingBreakdownKey["AnnualInclusive"] = 5] = "AnnualInclusive";
    PricingBreakdownKey[PricingBreakdownKey["VAT"] = 6] = "VAT";
    PricingBreakdownKey[PricingBreakdownKey["VATCost"] = 7] = "VATCost";
    PricingBreakdownKey[PricingBreakdownKey["SellPriceInclusive"] = 8] = "SellPriceInclusive";
    PricingBreakdownKey[PricingBreakdownKey["UnitListPrice"] = 9] = "UnitListPrice";
    PricingBreakdownKey[PricingBreakdownKey["Discount"] = 10] = "Discount";
    PricingBreakdownKey[PricingBreakdownKey["Qty"] = 11] = "Qty";
    PricingBreakdownKey[PricingBreakdownKey["ExtendedNetPrice"] = 12] = "ExtendedNetPrice";
    PricingBreakdownKey[PricingBreakdownKey["LogisticsCost"] = 13] = "LogisticsCost";
    PricingBreakdownKey[PricingBreakdownKey["Margin"] = 14] = "Margin";
    PricingBreakdownKey[PricingBreakdownKey["BuyPrice"] = 15] = "BuyPrice";
    PricingBreakdownKey[PricingBreakdownKey["Uplift"] = 16] = "Uplift";
    PricingBreakdownKey[PricingBreakdownKey["SellPrice"] = 17] = "SellPrice";
    PricingBreakdownKey[PricingBreakdownKey["DealQty"] = 18] = "DealQty";
})(PricingBreakdownKey || (PricingBreakdownKey = {}));
var OAuth2Providers = /** @class */ (function () {
    function OAuth2Providers() {
    }
    OAuth2Providers.CCW = 1;
    OAuth2Providers.CCWDesc = 'Cisco Commerce Workspace';
    return OAuth2Providers;
}());
export { OAuth2Providers };
var PricingCalculationType = /** @class */ (function () {
    function PricingCalculationType() {
    }
    PricingCalculationType.AddOnPricing = 'AddOnPricing';
    PricingCalculationType.AzureUsageDto = 'AzureUsageDto';
    PricingCalculationType.CiscoQuoteLineDto = 'CiscoQuoteLineDto';
    return PricingCalculationType;
}());
export { PricingCalculationType };
var TenantStatuses = /** @class */ (function () {
    function TenantStatuses() {
    }
    TenantStatuses.Active = 1;
    TenantStatuses.InActive = 2;
    TenantStatuses.Suspended = 3;
    TenantStatuses.RegistrationInProgress = 4;
    return TenantStatuses;
}());
export { TenantStatuses };
var AddressFormMode = /** @class */ (function () {
    function AddressFormMode() {
    }
    AddressFormMode.View = 'view';
    AddressFormMode.CreateOrEdit = 'create-or-edit';
    return AddressFormMode;
}());
export { AddressFormMode };
var AddressFormLayout = /** @class */ (function () {
    function AddressFormLayout() {
    }
    AddressFormLayout.Edit = 'edit';
    AddressFormLayout.Create = 'create';
    return AddressFormLayout;
}());
export { AddressFormLayout };
var CustomerFormLayout = /** @class */ (function () {
    function CustomerFormLayout() {
    }
    CustomerFormLayout.Edit = 'edit';
    CustomerFormLayout.Create = 'create';
    return CustomerFormLayout;
}());
export { CustomerFormLayout };
var MicrosoftSubscriptionUpdateType = /** @class */ (function () {
    function MicrosoftSubscriptionUpdateType() {
    }
    MicrosoftSubscriptionUpdateType.SeatCount = 'seatCount';
    MicrosoftSubscriptionUpdateType.SubStatus = 'subStatus';
    MicrosoftSubscriptionUpdateType.BillingFreq = 'billingFrequency';
    return MicrosoftSubscriptionUpdateType;
}());
export { MicrosoftSubscriptionUpdateType };
var TagActions = /** @class */ (function () {
    function TagActions() {
    }
    TagActions.None = 1;
    TagActions.Add = 2;
    TagActions.Remove = 3;
    return TagActions;
}());
export { TagActions };
var ServiceActions = /** @class */ (function () {
    function ServiceActions() {
    }
    ServiceActions.None = 1;
    ServiceActions.Add = 2;
    ServiceActions.Remove = 3;
    return ServiceActions;
}());
export { ServiceActions };
var MicrosoftPartnerRelationship = /** @class */ (function () {
    function MicrosoftPartnerRelationship() {
    }
    MicrosoftPartnerRelationship.None = 1;
    MicrosoftPartnerRelationship.Reseller = 2;
    MicrosoftPartnerRelationship.Customer = 3;
    return MicrosoftPartnerRelationship;
}());
export { MicrosoftPartnerRelationship };
var ServiceStatusEnum = /** @class */ (function () {
    function ServiceStatusEnum() {
    }
    ServiceStatusEnum.Active = true;
    ServiceStatusEnum.Inactive = false;
    return ServiceStatusEnum;
}());
export { ServiceStatusEnum };
export var DocumentType;
(function (DocumentType) {
    DocumentType[DocumentType["Notification"] = 1] = "Notification";
    DocumentType[DocumentType["Warning"] = 2] = "Warning";
})(DocumentType || (DocumentType = {}));
var ServicesPricingMethodType = /** @class */ (function () {
    function ServicesPricingMethodType() {
    }
    ServicesPricingMethodType.CustomPrice = 1;
    ServicesPricingMethodType.FixedPrice = 2;
    ServicesPricingMethodType.Margin = 3;
    ServicesPricingMethodType.Markup = 4;
    ServicesPricingMethodType.Quantity = 5;
    return ServicesPricingMethodType;
}());
export { ServicesPricingMethodType };
var ServicePropertyMappingType = /** @class */ (function () {
    function ServicePropertyMappingType() {
    }
    ServicePropertyMappingType.SingleMapping = 'Single';
    ServicePropertyMappingType.MultipleMapping = 'Multiple';
    return ServicePropertyMappingType;
}());
export { ServicePropertyMappingType };
var OrderDeliveryMethod = /** @class */ (function () {
    function OrderDeliveryMethod() {
    }
    OrderDeliveryMethod.DeliverOrder = 'Delivery';
    OrderDeliveryMethod.CollectOrder = 'Collect';
    return OrderDeliveryMethod;
}());
export { OrderDeliveryMethod };
var OrderStatuses = /** @class */ (function () {
    function OrderStatuses() {
    }
    OrderStatuses.Requested = 1;
    OrderStatuses.Ordered = 2;
    OrderStatuses.BackOrder = 3;
    OrderStatuses.InTransit = 4;
    OrderStatuses.Delivered = 5;
    OrderStatuses['Partially Delivered'] = 6;
    OrderStatuses.Cancelled = 7;
    OrderStatuses['Request Error'] = 8;
    return OrderStatuses;
}());
export { OrderStatuses };
export var SelectableVariableType;
(function (SelectableVariableType) {
    //Add Enums Here
    SelectableVariableType[SelectableVariableType["%"] = 1] = "%";
    SelectableVariableType[SelectableVariableType["ZAR"] = 2] = "ZAR";
    SelectableVariableType[SelectableVariableType["USD"] = 3] = "USD";
    SelectableVariableType[SelectableVariableType["EUR"] = 4] = "EUR";
})(SelectableVariableType || (SelectableVariableType = {}));
var FixedPriceType = /** @class */ (function () {
    function FixedPriceType() {
    }
    FixedPriceType.Margin = 'Margin';
    FixedPriceType.IPA = 'IPA';
    FixedPriceType.QuantityReference = 'Quantity Reservation';
    FixedPriceType.Volume = 'Volume';
    return FixedPriceType;
}());
export { FixedPriceType };
export var PaymentTransactionStatus;
(function (PaymentTransactionStatus) {
    PaymentTransactionStatus[PaymentTransactionStatus["NotDone"] = 0] = "NotDone";
    PaymentTransactionStatus[PaymentTransactionStatus["Approved"] = 1] = "Approved";
    PaymentTransactionStatus[PaymentTransactionStatus["Declined"] = 2] = "Declined";
    PaymentTransactionStatus[PaymentTransactionStatus["Cancelled"] = 3] = "Cancelled";
    PaymentTransactionStatus[PaymentTransactionStatus["UserCancelled"] = 4] = "UserCancelled";
    PaymentTransactionStatus[PaymentTransactionStatus["Received"] = 5] = "Received";
    PaymentTransactionStatus[PaymentTransactionStatus["Voided"] = 7] = "Voided";
    PaymentTransactionStatus[PaymentTransactionStatus["ChecksumUnverified"] = 1001] = "ChecksumUnverified";
    PaymentTransactionStatus[PaymentTransactionStatus["TransactionUnverified"] = 1002] = "TransactionUnverified";
})(PaymentTransactionStatus || (PaymentTransactionStatus = {}));
export var PaygateMode;
(function (PaygateMode) {
    PaygateMode[PaygateMode["Production"] = 1] = "Production";
    PaygateMode[PaygateMode["Local"] = 2] = "Local";
    PaygateMode[PaygateMode["BypassPayGate"] = 3] = "BypassPayGate";
})(PaygateMode || (PaygateMode = {}));
export var MicrosoftProductType;
(function (MicrosoftProductType) {
    MicrosoftProductType["OnlineServicesNCE"] = "OnlineServicesNCE";
})(MicrosoftProductType || (MicrosoftProductType = {}));
export var DealTypes;
(function (DealTypes) {
    /// <summary>
    /// The ava deal. Created by a salesperson within the AVA platform
    /// </summary>
    DealTypes[DealTypes["MarketplaceQuote"] = 1] = "MarketplaceQuote";
    /// <summary>
    /// We get this from Cisco API from CCW Quote endpoint
    /// </summary>
    DealTypes[DealTypes["CCWQuote"] = 2] = "CCWQuote";
    /// <summary>
    /// We get this from Cisco API from CCW Estimate endpoint
    /// A deal.
    /// </summary>
    DealTypes[DealTypes["CCWEstimate"] = 3] = "CCWEstimate";
    /// <summary>
    /// HPE Iquote external cart
    /// The subscription. Typically Microsoft
    /// </summary>
    DealTypes[DealTypes["HPEIQouteBasket"] = 4] = "HPEIQouteBasket";
    /// <summary>
    /// We get this from Cisco API from CCW Renewal endpoint
    /// A deal.
    /// </summary>
    DealTypes[DealTypes["CCWRenewal"] = 5] = "CCWRenewal";
})(DealTypes || (DealTypes = {}));
export var BrandEnum;
(function (BrandEnum) {
    // Make sure to update ERP brands in Micro-services when this file is updated. The two files should match
    BrandEnum[BrandEnum["Lenovo"] = 1] = "Lenovo";
    BrandEnum[BrandEnum["Logitech"] = 2] = "Logitech";
    BrandEnum[BrandEnum["HPE"] = 3] = "HPE";
    BrandEnum[BrandEnum["Dell"] = 4] = "Dell";
    BrandEnum[BrandEnum["DellE"] = 5] = "DellE";
    BrandEnum[BrandEnum["HPIP"] = 6] = "HPIP";
    BrandEnum[BrandEnum["HPIC"] = 7] = "HPIC";
    BrandEnum[BrandEnum["Audiocodes"] = 8] = "Audiocodes";
    BrandEnum[BrandEnum["Adobe"] = 9] = "Adobe";
    BrandEnum[BrandEnum["AOC"] = 10] = "AOC";
    BrandEnum[BrandEnum["Axiz"] = 11] = "Axiz";
    BrandEnum[BrandEnum["Cisco"] = 12] = "Cisco";
    BrandEnum[BrandEnum["Citrix"] = 13] = "Citrix";
    BrandEnum[BrandEnum["Connex"] = 14] = "Connex";
    BrandEnum[BrandEnum["Corel"] = 15] = "Corel";
    BrandEnum[BrandEnum["IBM"] = 16] = "IBM";
    BrandEnum[BrandEnum["ICCN"] = 17] = "ICCN";
    BrandEnum[BrandEnum["Innova"] = 18] = "Innova";
    BrandEnum[BrandEnum["Ixia"] = 19] = "Ixia";
    BrandEnum[BrandEnum["Kingston"] = 20] = "Kingston";
    BrandEnum[BrandEnum["Legion"] = 21] = "Legion";
    BrandEnum[BrandEnum["Magix"] = 22] = "Magix";
    BrandEnum[BrandEnum["Mcafee"] = 23] = "Mcafee";
    BrandEnum[BrandEnum["MicroFocus"] = 24] = "MicroFocus";
    BrandEnum[BrandEnum["Micropoint"] = 25] = "Micropoint";
    BrandEnum[BrandEnum["Microsoft"] = 26] = "Microsoft";
    BrandEnum[BrandEnum["Mindjet"] = 27] = "Mindjet";
    BrandEnum[BrandEnum["NComputing"] = 28] = "NComputing";
    BrandEnum[BrandEnum["Nettrace"] = 29] = "Nettrace";
    BrandEnum[BrandEnum["Norton"] = 30] = "Norton";
    BrandEnum[BrandEnum["NozomiNetworks"] = 31] = "NozomiNetworks";
    BrandEnum[BrandEnum["Nutanix"] = 32] = "Nutanix";
    BrandEnum[BrandEnum["Nuance"] = 33] = "Nuance";
    BrandEnum[BrandEnum["RedHat"] = 34] = "RedHat";
    BrandEnum[BrandEnum["Ring"] = 35] = "Ring";
    BrandEnum[BrandEnum["Solar"] = 37] = "Solar";
    BrandEnum[BrandEnum["Supermicro"] = 38] = "Supermicro";
    BrandEnum[BrandEnum["Suse"] = 39] = "Suse";
    BrandEnum[BrandEnum["Targus"] = 40] = "Targus";
    BrandEnum[BrandEnum["Veeam"] = 41] = "Veeam";
    BrandEnum[BrandEnum["Veritas"] = 42] = "Veritas";
    BrandEnum[BrandEnum["VMWare"] = 43] = "VMWare";
    BrandEnum[BrandEnum["Volkano"] = 44] = "Volkano";
    BrandEnum[BrandEnum["WesternDigital"] = 45] = "WesternDigital";
    BrandEnum[BrandEnum["Zimbra"] = 46] = "Zimbra";
    BrandEnum[BrandEnum["Rocket"] = 47] = "Rocket";
    BrandEnum[BrandEnum["TechnologyServices"] = 48] = "TechnologyServices";
    BrandEnum[BrandEnum["CloudTechnologies"] = 49] = "CloudTechnologies";
    BrandEnum[BrandEnum["DataCentrix"] = 50] = "DataCentrix";
    BrandEnum[BrandEnum["Forcepoint"] = 51] = "Forcepoint";
    BrandEnum[BrandEnum["GeneralMarket"] = 52] = "GeneralMarket";
    BrandEnum[BrandEnum["Services"] = 53] = "Services";
    BrandEnum[BrandEnum["Unbranded"] = 300] = "Unbranded";
    BrandEnum[BrandEnum["Capitec"] = 54] = "Capitec";
    BrandEnum[BrandEnum["Cradlepoint"] = 55] = "Cradlepoint";
    BrandEnum[BrandEnum["Imeon"] = 56] = "Imeon";
    BrandEnum[BrandEnum["Trina"] = 57] = "Trina";
    BrandEnum[BrandEnum["SolarEdge"] = 58] = "SolarEdge";
    BrandEnum[BrandEnum["SchneiderElectric"] = 59] = "SchneiderElectric";
})(BrandEnum || (BrandEnum = {}));
export var RegisterBusinessDocumentType;
(function (RegisterBusinessDocumentType) {
    RegisterBusinessDocumentType[RegisterBusinessDocumentType["EntityRegistration"] = 0] = "EntityRegistration";
    RegisterBusinessDocumentType[RegisterBusinessDocumentType["ProofOfVatRegistration"] = 1] = "ProofOfVatRegistration";
})(RegisterBusinessDocumentType || (RegisterBusinessDocumentType = {}));
export var ProductItemType;
(function (ProductItemType) {
    ProductItemType[ProductItemType["Product"] = 1] = "Product";
    ProductItemType[ProductItemType["Deal"] = 2] = "Deal";
    ProductItemType[ProductItemType["Subscription"] = 3] = "Subscription";
})(ProductItemType || (ProductItemType = {}));
export var DealAdditionalInfo;
(function (DealAdditionalInfo) {
    DealAdditionalInfo[DealAdditionalInfo["QuoteId"] = 1] = "QuoteId";
    DealAdditionalInfo[DealAdditionalInfo["DealId"] = 2] = "DealId";
    DealAdditionalInfo[DealAdditionalInfo["EndUser"] = 3] = "EndUser";
    DealAdditionalInfo[DealAdditionalInfo["ParentId"] = 4] = "ParentId";
    DealAdditionalInfo[DealAdditionalInfo["ParentProductId"] = 5] = "ParentProductId";
    DealAdditionalInfo[DealAdditionalInfo["Sku"] = 6] = "Sku";
    DealAdditionalInfo[DealAdditionalInfo["PartNumber"] = 7] = "PartNumber";
    DealAdditionalInfo[DealAdditionalInfo["DealReference"] = 8] = "DealReference";
    DealAdditionalInfo[DealAdditionalInfo["BasketId"] = 9] = "BasketId";
    DealAdditionalInfo[DealAdditionalInfo["OrderName"] = 10] = "OrderName";
    DealAdditionalInfo[DealAdditionalInfo["SmartAccountDomain"] = 11] = "SmartAccountDomain";
    DealAdditionalInfo[DealAdditionalInfo["DealEta"] = 12] = "DealEta";
    DealAdditionalInfo[DealAdditionalInfo["Index"] = 13] = "Index";
    DealAdditionalInfo[DealAdditionalInfo["DealName"] = 14] = "DealName";
    DealAdditionalInfo[DealAdditionalInfo["DocumentDate"] = 15] = "DocumentDate";
    DealAdditionalInfo[DealAdditionalInfo["ExpiryDate"] = 16] = "ExpiryDate";
    DealAdditionalInfo[DealAdditionalInfo["IsAutoQuote"] = 17] = "IsAutoQuote";
    DealAdditionalInfo[DealAdditionalInfo["IsActive"] = 18] = "IsActive";
    DealAdditionalInfo[DealAdditionalInfo["ExportMessage"] = 19] = "ExportMessage";
    DealAdditionalInfo[DealAdditionalInfo["QuoteName"] = 20] = "QuoteName";
    DealAdditionalInfo[DealAdditionalInfo["CreatedBy"] = 21] = "CreatedBy";
    DealAdditionalInfo[DealAdditionalInfo["CreatedDate"] = 22] = "CreatedDate";
    DealAdditionalInfo[DealAdditionalInfo["InstanceId"] = 23] = "InstanceId";
    DealAdditionalInfo[DealAdditionalInfo["ServiceLevel"] = 24] = "ServiceLevel";
    DealAdditionalInfo[DealAdditionalInfo["ServiceLevelDescription"] = 25] = "ServiceLevelDescription";
    DealAdditionalInfo[DealAdditionalInfo["TargetContractNumber"] = 26] = "TargetContractNumber";
    DealAdditionalInfo[DealAdditionalInfo["PAKSerialNumber"] = 27] = "PAKSerialNumber";
    DealAdditionalInfo[DealAdditionalInfo["ReferenceSerial"] = 28] = "ReferenceSerial";
    DealAdditionalInfo[DealAdditionalInfo["EndDate"] = 29] = "EndDate";
    DealAdditionalInfo[DealAdditionalInfo["StartDate"] = 30] = "StartDate";
    DealAdditionalInfo[DealAdditionalInfo["InstanceSerial"] = 31] = "InstanceSerial";
    DealAdditionalInfo[DealAdditionalInfo["CreatorEmail"] = 32] = "CreatorEmail";
    DealAdditionalInfo[DealAdditionalInfo["Supplier"] = 33] = "Supplier";
    DealAdditionalInfo[DealAdditionalInfo["OpgRef"] = 34] = "OpgRef";
    DealAdditionalInfo[DealAdditionalInfo["OpgQuantity"] = 35] = "OpgQuantity";
    DealAdditionalInfo[DealAdditionalInfo["OpgEndDate"] = 36] = "OpgEndDate";
    DealAdditionalInfo[DealAdditionalInfo["URLProductImage"] = 37] = "URLProductImage";
    DealAdditionalInfo[DealAdditionalInfo["URLProductSpecs"] = 38] = "URLProductSpecs";
    DealAdditionalInfo[DealAdditionalInfo["SystemId"] = 39] = "SystemId";
    DealAdditionalInfo[DealAdditionalInfo["Class"] = 40] = "Class";
    DealAdditionalInfo[DealAdditionalInfo["VendorListPrice"] = 41] = "VendorListPrice";
    DealAdditionalInfo[DealAdditionalInfo["VendorListPriceCurrencyCode"] = 42] = "VendorListPriceCurrencyCode";
    DealAdditionalInfo[DealAdditionalInfo["Discount"] = 43] = "Discount";
    DealAdditionalInfo[DealAdditionalInfo["PriceProtectionDate"] = 44] = "PriceProtectionDate";
})(DealAdditionalInfo || (DealAdditionalInfo = {}));
export var LandingWidgetType;
(function (LandingWidgetType) {
    LandingWidgetType[LandingWidgetType["SINGLE"] = 0] = "SINGLE";
    LandingWidgetType[LandingWidgetType["FULL"] = 1] = "FULL";
    LandingWidgetType[LandingWidgetType["FOUR"] = 2] = "FOUR";
})(LandingWidgetType || (LandingWidgetType = {}));
export var BannerEditAction;
(function (BannerEditAction) {
    BannerEditAction[BannerEditAction["UNCHANGED"] = 0] = "UNCHANGED";
    BannerEditAction[BannerEditAction["ADD"] = 1] = "ADD";
    BannerEditAction[BannerEditAction["REMOVE"] = 2] = "REMOVE";
    BannerEditAction[BannerEditAction["UPDATE"] = 3] = "UPDATE";
})(BannerEditAction || (BannerEditAction = {}));
export var TrackingClickAction;
(function (TrackingClickAction) {
    TrackingClickAction[TrackingClickAction["VIEW"] = 0] = "VIEW";
    TrackingClickAction[TrackingClickAction["ADD_TO_CART"] = 1] = "ADD_TO_CART";
    TrackingClickAction[TrackingClickAction["REGISTER"] = 2] = "REGISTER";
})(TrackingClickAction || (TrackingClickAction = {}));
export var TrackingClickPage;
(function (TrackingClickPage) {
    TrackingClickPage[TrackingClickPage["LANDING_PAGE"] = 0] = "LANDING_PAGE";
    TrackingClickPage[TrackingClickPage["CART"] = 1] = "CART";
})(TrackingClickPage || (TrackingClickPage = {}));
export var CountryCodes;
(function (CountryCodes) {
    CountryCodes[CountryCodes["SOUTH_AFRICA"] = 207] = "SOUTH_AFRICA";
})(CountryCodes || (CountryCodes = {}));
export var UserViewRule;
(function (UserViewRule) {
    UserViewRule[UserViewRule["ALL"] = 0] = "ALL";
    UserViewRule[UserViewRule["SIGNED_IN"] = 1] = "SIGNED_IN";
    UserViewRule[UserViewRule["SIGNED_OUT"] = 2] = "SIGNED_OUT";
})(UserViewRule || (UserViewRule = {}));
export var AcceptanceTerms;
(function (AcceptanceTerms) {
    AcceptanceTerms[AcceptanceTerms["SELF_REGISTRATION"] = 0] = "SELF_REGISTRATION";
    AcceptanceTerms[AcceptanceTerms["ADOBE_NEW_RESELLER"] = 1] = "ADOBE_NEW_RESELLER";
})(AcceptanceTerms || (AcceptanceTerms = {}));
export var OnboardingRequestType;
(function (OnboardingRequestType) {
    OnboardingRequestType[OnboardingRequestType["TOC"] = 0] = "TOC";
    OnboardingRequestType[OnboardingRequestType["MODAL"] = 1] = "MODAL";
})(OnboardingRequestType || (OnboardingRequestType = {}));
export var CartCheckoutDisable;
(function (CartCheckoutDisable) {
    CartCheckoutDisable[CartCheckoutDisable["ADOBE_CUSTOMER_NOT_VERIFIED"] = 0] = "ADOBE_CUSTOMER_NOT_VERIFIED";
    CartCheckoutDisable[CartCheckoutDisable["MICROSOFT_CUSTOMER_NOT_VERIFIED"] = 1] = "MICROSOFT_CUSTOMER_NOT_VERIFIED";
    CartCheckoutDisable[CartCheckoutDisable["PAYMENT_METHODS_NOT_VALID"] = 2] = "PAYMENT_METHODS_NOT_VALID";
    CartCheckoutDisable[CartCheckoutDisable["PROOF_OF_PAYMENT"] = 3] = "PROOF_OF_PAYMENT";
    CartCheckoutDisable[CartCheckoutDisable["MAX_PAYMENT_EXCEEDED"] = 4] = "MAX_PAYMENT_EXCEEDED";
    CartCheckoutDisable[CartCheckoutDisable["TENANT_NOT_FOUND"] = 5] = "TENANT_NOT_FOUND";
    CartCheckoutDisable[CartCheckoutDisable["DOC_UPLOAD_REQUIRED"] = 6] = "DOC_UPLOAD_REQUIRED";
    CartCheckoutDisable[CartCheckoutDisable["PENDING_PAYMENT"] = 7] = "PENDING_PAYMENT";
    CartCheckoutDisable[CartCheckoutDisable["AVA_REFERENCE_REQUIRED"] = 8] = "AVA_REFERENCE_REQUIRED";
    CartCheckoutDisable[CartCheckoutDisable["MISSING_PERMISSION"] = 9] = "MISSING_PERMISSION";
})(CartCheckoutDisable || (CartCheckoutDisable = {}));
export var CustomerMenuBarAction;
(function (CustomerMenuBarAction) {
    CustomerMenuBarAction[CustomerMenuBarAction["NONE"] = 0] = "NONE";
    CustomerMenuBarAction[CustomerMenuBarAction["EXPORT_MICROSOFT"] = 1] = "EXPORT_MICROSOFT";
    CustomerMenuBarAction[CustomerMenuBarAction["EXPORT_ADOBE"] = 2] = "EXPORT_ADOBE";
    CustomerMenuBarAction[CustomerMenuBarAction["SAVE"] = 3] = "SAVE";
})(CustomerMenuBarAction || (CustomerMenuBarAction = {}));
export var SubscriptionBillingPeriods;
(function (SubscriptionBillingPeriods) {
    SubscriptionBillingPeriods[SubscriptionBillingPeriods["unknown"] = 0] = "unknown";
    SubscriptionBillingPeriods[SubscriptionBillingPeriods["yearly"] = 1] = "yearly";
    SubscriptionBillingPeriods[SubscriptionBillingPeriods["monthly"] = 2] = "monthly";
})(SubscriptionBillingPeriods || (SubscriptionBillingPeriods = {}));
export var SubscriptionCommitmentPeriods;
(function (SubscriptionCommitmentPeriods) {
    SubscriptionCommitmentPeriods[SubscriptionCommitmentPeriods["unknown"] = 0] = "unknown";
    SubscriptionCommitmentPeriods[SubscriptionCommitmentPeriods["annual"] = 1] = "annual";
    SubscriptionCommitmentPeriods[SubscriptionCommitmentPeriods["monthToMonth"] = 2] = "monthToMonth";
    SubscriptionCommitmentPeriods[SubscriptionCommitmentPeriods["_3Years"] = 3] = "_3Years";
    SubscriptionCommitmentPeriods[SubscriptionCommitmentPeriods["onceOff"] = 4] = "onceOff";
})(SubscriptionCommitmentPeriods || (SubscriptionCommitmentPeriods = {}));
export var ConfirmationModalTypes;
(function (ConfirmationModalTypes) {
    ConfirmationModalTypes[ConfirmationModalTypes["Activate"] = 1] = "Activate";
    ConfirmationModalTypes[ConfirmationModalTypes["Deactivate"] = 2] = "Deactivate";
    ConfirmationModalTypes[ConfirmationModalTypes["LegacyAutoRenewal"] = 3] = "LegacyAutoRenewal";
    ConfirmationModalTypes[ConfirmationModalTypes["CancelSubscription"] = 4] = "CancelSubscription";
})(ConfirmationModalTypes || (ConfirmationModalTypes = {}));
export var PricingFileStatus;
(function (PricingFileStatus) {
    PricingFileStatus[PricingFileStatus["Created"] = 1] = "Created";
    PricingFileStatus[PricingFileStatus["ProcessingConfiguration"] = 2] = "ProcessingConfiguration";
    PricingFileStatus[PricingFileStatus["ReadyForConfiguration"] = 3] = "ReadyForConfiguration";
    PricingFileStatus[PricingFileStatus["ProcessingImportRules"] = 4] = "ProcessingImportRules";
    PricingFileStatus[PricingFileStatus["Complete"] = 5] = "Complete";
    PricingFileStatus[PricingFileStatus["Aborted"] = 6] = "Aborted";
    PricingFileStatus[PricingFileStatus["Error"] = 7] = "Error";
})(PricingFileStatus || (PricingFileStatus = {}));
export var PricingVariableEnum;
(function (PricingVariableEnum) {
    PricingVariableEnum[PricingVariableEnum["BRAND"] = 27] = "BRAND";
    PricingVariableEnum[PricingVariableEnum["SKU"] = 26] = "SKU";
    PricingVariableEnum[PricingVariableEnum["FIXED_PRICE"] = 22] = "FIXED_PRICE";
    PricingVariableEnum[PricingVariableEnum["IPA"] = 20] = "IPA";
    PricingVariableEnum[PricingVariableEnum["QUANTITY"] = 19] = "QUANTITY";
    PricingVariableEnum[PricingVariableEnum["REBATE"] = 4] = "REBATE";
    PricingVariableEnum[PricingVariableEnum["MARGIN"] = 13] = "MARGIN";
    PricingVariableEnum[PricingVariableEnum["VOLUME"] = 28] = "VOLUME";
})(PricingVariableEnum || (PricingVariableEnum = {}));
export var PricingAuditEnum;
(function (PricingAuditEnum) {
    PricingAuditEnum[PricingAuditEnum["ListPrice"] = 1] = "ListPrice";
    PricingAuditEnum[PricingAuditEnum["VendorDiscount"] = 2] = "VendorDiscount";
    PricingAuditEnum[PricingAuditEnum["RoE"] = 3] = "RoE";
    PricingAuditEnum[PricingAuditEnum["EstimatedFreight"] = 4] = "EstimatedFreight";
    PricingAuditEnum[PricingAuditEnum["FreightMechanism"] = 5] = "FreightMechanism";
    PricingAuditEnum[PricingAuditEnum["OverrideFreight"] = 6] = "OverrideFreight";
    PricingAuditEnum[PricingAuditEnum["Duties"] = 7] = "Duties";
    PricingAuditEnum[PricingAuditEnum["SABS"] = 8] = "SABS";
    PricingAuditEnum[PricingAuditEnum["LandingPrice"] = 9] = "LandingPrice";
    PricingAuditEnum[PricingAuditEnum["VendorMarketingUplift"] = 10] = "VendorMarketingUplift";
    PricingAuditEnum[PricingAuditEnum["ResellerUplift"] = 11] = "ResellerUplift";
    PricingAuditEnum[PricingAuditEnum["AxizUplift"] = 12] = "AxizUplift";
    PricingAuditEnum[PricingAuditEnum["CorporateUplift"] = 13] = "CorporateUplift";
    PricingAuditEnum[PricingAuditEnum["InsuranceUplift"] = 14] = "InsuranceUplift";
    PricingAuditEnum[PricingAuditEnum["FinanceUplift"] = 15] = "FinanceUplift";
    PricingAuditEnum[PricingAuditEnum["AverageCost"] = 16] = "AverageCost";
    PricingAuditEnum[PricingAuditEnum["ReplacementCost"] = 17] = "ReplacementCost";
    PricingAuditEnum[PricingAuditEnum["Margin"] = 18] = "Margin";
    PricingAuditEnum[PricingAuditEnum["AxizSellPrice"] = 19] = "AxizSellPrice";
    PricingAuditEnum[PricingAuditEnum["ResellerMargin"] = 20] = "ResellerMargin";
    PricingAuditEnum[PricingAuditEnum["ResellerSellPrice"] = 21] = "ResellerSellPrice";
    PricingAuditEnum[PricingAuditEnum["VAT"] = 22] = "VAT";
    PricingAuditEnum[PricingAuditEnum["VATInclusiveSellPrice"] = 23] = "VATInclusiveSellPrice";
    PricingAuditEnum[PricingAuditEnum["VendorDiscountedSellPrice"] = 24] = "VendorDiscountedSellPrice";
    PricingAuditEnum[PricingAuditEnum["DeferredRebatePrice"] = 25] = "DeferredRebatePrice";
    PricingAuditEnum[PricingAuditEnum["RebateDiscount"] = 26] = "RebateDiscount";
    PricingAuditEnum[PricingAuditEnum["RebateClaim"] = 27] = "RebateClaim";
    PricingAuditEnum[PricingAuditEnum["BuyPrice"] = 28] = "BuyPrice";
    PricingAuditEnum[PricingAuditEnum["RebateAppliedListPrice"] = 29] = "RebateAppliedListPrice";
    PricingAuditEnum[PricingAuditEnum["RebateAppliedLandingPrice"] = 30] = "RebateAppliedLandingPrice";
    PricingAuditEnum[PricingAuditEnum["RebateAppliedAverageCostPrice"] = 31] = "RebateAppliedAverageCostPrice";
    PricingAuditEnum[PricingAuditEnum["RebateAppliedEstimatePrice"] = 32] = "RebateAppliedEstimatePrice";
    PricingAuditEnum[PricingAuditEnum["RebateAppliedSellPrice"] = 33] = "RebateAppliedSellPrice";
    PricingAuditEnum[PricingAuditEnum["VendorDiscountPercentage"] = 34] = "VendorDiscountPercentage";
    PricingAuditEnum[PricingAuditEnum["MarginPercentage"] = 35] = "MarginPercentage";
    PricingAuditEnum[PricingAuditEnum["EstimatedFreightPercentage"] = 36] = "EstimatedFreightPercentage";
    PricingAuditEnum[PricingAuditEnum["VendorMarketingUpliftPercentage"] = 37] = "VendorMarketingUpliftPercentage";
    PricingAuditEnum[PricingAuditEnum["ResellerUpliftPercentage"] = 38] = "ResellerUpliftPercentage";
    PricingAuditEnum[PricingAuditEnum["AxizUpliftPercentage"] = 39] = "AxizUpliftPercentage";
    PricingAuditEnum[PricingAuditEnum["CorporateUpliftPercentage"] = 40] = "CorporateUpliftPercentage";
    PricingAuditEnum[PricingAuditEnum["InsuranceUpliftPercentage"] = 41] = "InsuranceUpliftPercentage";
    PricingAuditEnum[PricingAuditEnum["FinanceUpliftPercentage"] = 42] = "FinanceUpliftPercentage";
    PricingAuditEnum[PricingAuditEnum["AvailableToSell"] = 43] = "AvailableToSell";
    PricingAuditEnum[PricingAuditEnum["CostPriceCurrency"] = 44] = "CostPriceCurrency";
    PricingAuditEnum[PricingAuditEnum["ProductDiscount"] = 45] = "ProductDiscount";
    PricingAuditEnum[PricingAuditEnum["ProductDiscountPercentage"] = 46] = "ProductDiscountPercentage";
    PricingAuditEnum[PricingAuditEnum["TacticalUplift"] = 47] = "TacticalUplift";
    PricingAuditEnum[PricingAuditEnum["ManualVendorMarketingUplift"] = 48] = "ManualVendorMarketingUplift";
    PricingAuditEnum[PricingAuditEnum["TacticalUpliftPercentage"] = 49] = "TacticalUpliftPercentage";
    PricingAuditEnum[PricingAuditEnum["ManualVendorMarketingUpliftPercentage"] = 50] = "ManualVendorMarketingUpliftPercentage";
    PricingAuditEnum[PricingAuditEnum["Freight"] = 51] = "Freight";
    PricingAuditEnum[PricingAuditEnum["VendorMarketingUpliftMechanism"] = 52] = "VendorMarketingUpliftMechanism";
    PricingAuditEnum[PricingAuditEnum["ResellerUpliftMechanism"] = 53] = "ResellerUpliftMechanism";
    PricingAuditEnum[PricingAuditEnum["AxizUpliftMechanism"] = 54] = "AxizUpliftMechanism";
    PricingAuditEnum[PricingAuditEnum["CorporateUpliftMechanism"] = 55] = "CorporateUpliftMechanism";
    PricingAuditEnum[PricingAuditEnum["InsuranceUpliftMechanism"] = 56] = "InsuranceUpliftMechanism";
    PricingAuditEnum[PricingAuditEnum["FinanceUpliftMechanism"] = 57] = "FinanceUpliftMechanism";
    PricingAuditEnum[PricingAuditEnum["TacticalUpliftMechanism"] = 58] = "TacticalUpliftMechanism";
    PricingAuditEnum[PricingAuditEnum["ManualVendorMarketingUpliftMechanism"] = 59] = "ManualVendorMarketingUpliftMechanism";
    PricingAuditEnum[PricingAuditEnum["DutiesMechanism"] = 60] = "DutiesMechanism";
    PricingAuditEnum[PricingAuditEnum["SABSMechanism"] = 61] = "SABSMechanism";
    PricingAuditEnum[PricingAuditEnum["MarginMechanism"] = 62] = "MarginMechanism";
    PricingAuditEnum[PricingAuditEnum["ResellerMarginMechanism"] = 63] = "ResellerMarginMechanism";
    PricingAuditEnum[PricingAuditEnum["UsedLot"] = 64] = "UsedLot";
    PricingAuditEnum[PricingAuditEnum["SupplierId"] = 65] = "SupplierId";
    PricingAuditEnum[PricingAuditEnum["RebateReference"] = 66] = "RebateReference";
    PricingAuditEnum[PricingAuditEnum["RebateType"] = 67] = "RebateType";
    PricingAuditEnum[PricingAuditEnum["VendorRebateClaim_DiscountAmount"] = 68] = "VendorRebateClaim_DiscountAmount";
    PricingAuditEnum[PricingAuditEnum["VendorRebateClaim_DiscountCurrency"] = 69] = "VendorRebateClaim_DiscountCurrency";
    PricingAuditEnum[PricingAuditEnum["FromLot"] = 70] = "FromLot";
    PricingAuditEnum[PricingAuditEnum["IPAAmount"] = 71] = "IPAAmount";
    PricingAuditEnum[PricingAuditEnum["IPAPosting"] = 72] = "IPAPosting";
    PricingAuditEnum[PricingAuditEnum["RoEConvertedListPrice"] = 73] = "RoEConvertedListPrice";
    PricingAuditEnum[PricingAuditEnum["RoEConvertedBuyPrice"] = 74] = "RoEConvertedBuyPrice";
    PricingAuditEnum[PricingAuditEnum["LastPurchasePrice"] = 75] = "LastPurchasePrice";
    PricingAuditEnum[PricingAuditEnum["TrueCostPrice"] = 76] = "TrueCostPrice";
    PricingAuditEnum[PricingAuditEnum["OriginalListPrice"] = 77] = "OriginalListPrice";
    PricingAuditEnum[PricingAuditEnum["OriginalLandingPrice"] = 78] = "OriginalLandingPrice";
    PricingAuditEnum[PricingAuditEnum["OriginalEstimateCostPrice"] = 79] = "OriginalEstimateCostPrice";
    PricingAuditEnum[PricingAuditEnum["OriginalSellPrice"] = 80] = "OriginalSellPrice";
    PricingAuditEnum[PricingAuditEnum["OriginalAverageCost"] = 81] = "OriginalAverageCost";
    PricingAuditEnum[PricingAuditEnum["ResellerOriginalSellPrice"] = 82] = "ResellerOriginalSellPrice";
    PricingAuditEnum[PricingAuditEnum["FreightFinanceMarginUplift"] = 83] = "FreightFinanceMarginUplift";
    PricingAuditEnum[PricingAuditEnum["FreightUpliftValue"] = 84] = "FreightUpliftValue";
    PricingAuditEnum[PricingAuditEnum["DutiesMechanismValue"] = 85] = "DutiesMechanismValue";
    PricingAuditEnum[PricingAuditEnum["IPAMechanism"] = 86] = "IPAMechanism";
    PricingAuditEnum[PricingAuditEnum["FixedSellPriceMin"] = 87] = "FixedSellPriceMin";
    PricingAuditEnum[PricingAuditEnum["FixedSellPriceMax"] = 88] = "FixedSellPriceMax";
    PricingAuditEnum[PricingAuditEnum["FixedPrice"] = 89] = "FixedPrice";
    PricingAuditEnum[PricingAuditEnum["FixedPriceMargin"] = 90] = "FixedPriceMargin";
    PricingAuditEnum[PricingAuditEnum["FixedSellPriceFluctuatingVariable"] = 91] = "FixedSellPriceFluctuatingVariable";
    PricingAuditEnum[PricingAuditEnum["FromLotQuantity"] = 92] = "FromLotQuantity";
    PricingAuditEnum[PricingAuditEnum["AdditionalFee"] = 93] = "AdditionalFee";
    PricingAuditEnum[PricingAuditEnum["AdditionalFeeMechanism"] = 94] = "AdditionalFeeMechanism";
    PricingAuditEnum[PricingAuditEnum["ProductDiscountMechanism"] = 95] = "ProductDiscountMechanism";
    PricingAuditEnum[PricingAuditEnum["QuantityFulfillable"] = 96] = "QuantityFulfillable";
    PricingAuditEnum[PricingAuditEnum["ReservationReference"] = 97] = "ReservationReference";
    PricingAuditEnum[PricingAuditEnum["ReservationQuantity"] = 98] = "ReservationQuantity";
    PricingAuditEnum[PricingAuditEnum["RebatePriceRuleId"] = 99] = "RebatePriceRuleId";
    PricingAuditEnum[PricingAuditEnum["RebatePriceRuleName"] = 100] = "RebatePriceRuleName";
    PricingAuditEnum[PricingAuditEnum["DealBasketId"] = 101] = "DealBasketId";
    PricingAuditEnum[PricingAuditEnum["LastPurchasePriceSource"] = 102] = "LastPurchasePriceSource";
    PricingAuditEnum[PricingAuditEnum["CostPriceSource"] = 103] = "CostPriceSource";
    PricingAuditEnum[PricingAuditEnum["PriceListSource"] = 104] = "PriceListSource";
    PricingAuditEnum[PricingAuditEnum["PriceListStartValidityDate"] = 105] = "PriceListStartValidityDate";
    PricingAuditEnum[PricingAuditEnum["PriceListEndValidityDate"] = 106] = "PriceListEndValidityDate";
    PricingAuditEnum[PricingAuditEnum["PriceListName"] = 107] = "PriceListName";
    PricingAuditEnum[PricingAuditEnum["PromotionDiscount"] = 108] = "PromotionDiscount";
    PricingAuditEnum[PricingAuditEnum["PromotionDiscountPercentage"] = 109] = "PromotionDiscountPercentage";
    PricingAuditEnum[PricingAuditEnum["PromotionDiscountMechanism"] = 110] = "PromotionDiscountMechanism";
    PricingAuditEnum[PricingAuditEnum["AllUpliftSum"] = 111] = "AllUpliftSum";
})(PricingAuditEnum || (PricingAuditEnum = {}));
export var RebateMappingDecision;
(function (RebateMappingDecision) {
    RebateMappingDecision[RebateMappingDecision["FileRebateReference"] = 1] = "FileRebateReference";
    RebateMappingDecision[RebateMappingDecision["GlobalRebateReference"] = 2] = "GlobalRebateReference";
})(RebateMappingDecision || (RebateMappingDecision = {}));
export var BillingCycle;
(function (BillingCycle) {
    BillingCycle[BillingCycle["Unknown"] = 0] = "Unknown";
    BillingCycle[BillingCycle["Monthly"] = 1] = "Monthly";
    BillingCycle[BillingCycle["Annual"] = 2] = "Annual";
    BillingCycle[BillingCycle["None"] = 3] = "None";
    BillingCycle[BillingCycle["OneTime"] = 4] = "OneTime";
})(BillingCycle || (BillingCycle = {}));
export var LandingWidgetSection;
(function (LandingWidgetSection) {
    LandingWidgetSection[LandingWidgetSection["EDIT"] = 0] = "EDIT";
    LandingWidgetSection[LandingWidgetSection["SEARCH_BAR"] = 1] = "SEARCH_BAR";
    LandingWidgetSection[LandingWidgetSection["TOP_BANNER"] = 2] = "TOP_BANNER";
    LandingWidgetSection[LandingWidgetSection["MAIL_LIST"] = 3] = "MAIL_LIST";
    LandingWidgetSection[LandingWidgetSection["RECOMMENDED_PRODUCTS"] = 4] = "RECOMMENDED_PRODUCTS";
    LandingWidgetSection[LandingWidgetSection["WIDGETS"] = 5] = "WIDGETS";
    LandingWidgetSection[LandingWidgetSection["TOP_PRODUCTS"] = 6] = "TOP_PRODUCTS";
    LandingWidgetSection[LandingWidgetSection["ONBOARDING"] = 7] = "ONBOARDING";
})(LandingWidgetSection || (LandingWidgetSection = {}));
