var DomHelper = /** @class */ (function () {
    function DomHelper() {
    }
    DomHelper.waitUntilElementIsReady = function (selector, callback, checkPeriod) {
        if (!$) {
            return;
        }
        var elementCount = selector.split(',').length;
        if (!checkPeriod) {
            checkPeriod = 100;
        }
        var checkExist = setInterval(function () {
            if ($(selector).length >= elementCount) {
                clearInterval(checkExist);
                callback();
            }
        }, checkPeriod);
    };
    DomHelper.waitUntilElementIsVisible = function (selector, callback, checkPeriod) {
        if (!$) {
            return;
        }
        var elementCount = selector.split(',').length;
        if (!checkPeriod) {
            checkPeriod = 100;
        }
        var checkExist = setInterval(function () {
            if ($(selector.replace('/,/g', ':visible,' + ':visible')).length >= elementCount) {
                clearInterval(checkExist);
                callback();
            }
        }, checkPeriod);
    };
    return DomHelper;
}());
export { DomHelper };
