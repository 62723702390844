import { ShoppingCartFormDataDto, CheckoutDataDto } from '@shared/service-proxies/service-proxies';
import { cloneShoppingCartFormDataDto } from '@shared/service-proxies/service-proxies-extension';
import * as i0 from "@angular/core";
var ShoppingCartFormLocalStorageService = /** @class */ (function () {
    function ShoppingCartFormLocalStorageService() {
        this.stateKey = 'CartForm_V3';
    }
    ShoppingCartFormLocalStorageService.prototype.getFormData = function () {
        var storedFormData = localStorage.getItem(this.stateKey);
        if (storedFormData === null) {
            // This use to be handled in the constructor, but since we moved to the black magic that is ASPNetZero we will have to do things differently
            var newFormData = new ShoppingCartFormDataDto();
            newFormData.checkoutData = new CheckoutDataDto();
            //newFormData.checkoutData.ibmCheckoutData = new IbmCheckoutData();
            return newFormData;
        }
        var formData = JSON.parse(storedFormData);
        // Handle complex objects
        if (formData &&
            formData.checkoutData) {
            //formData.checkoutData.ibmCheckoutData.licencing.captured_dateTime = moment(formData.checkoutData.ibmCheckoutData.licencing.captured_dateTime);
        }
        return cloneShoppingCartFormDataDto(formData); // We clone the object, but this is here for fixing the typings actually
    };
    ShoppingCartFormLocalStorageService.prototype.saveFormData = function (formData) {
        localStorage.setItem(this.stateKey, JSON.stringify(formData));
    };
    ShoppingCartFormLocalStorageService.prototype.resetFormData = function () {
        localStorage.removeItem(this.stateKey);
    };
    ShoppingCartFormLocalStorageService.ngInjectableDef = i0.defineInjectable({ factory: function ShoppingCartFormLocalStorageService_Factory() { return new ShoppingCartFormLocalStorageService(); }, token: ShoppingCartFormLocalStorageService, providedIn: "root" });
    return ShoppingCartFormLocalStorageService;
}());
export { ShoppingCartFormLocalStorageService };
