import { ModuleWithProviders } from '@angular/core';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true,
};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
var AppCommonModule = /** @class */ (function () {
    function AppCommonModule() {
    }
    AppCommonModule.forRoot = function () {
        return {
            ngModule: AppCommonModule,
            providers: [AppAuthService, AppRouteGuard],
        };
    };
    return AppCommonModule;
}());
export { AppCommonModule };
export { ɵ0 };
