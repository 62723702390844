import {Injectable} from '@angular/core';
import {AppConsts} from '@shared/AppConsts';
import {AppUiHelper} from '@shared/common/ui/ui-controller-helper';
import {TokenAuthServiceProxy, UserTwoFactorAuthDetailsModel} from "@shared/service-proxies/service-proxies";
import {Observable, Subject} from "rxjs";
import {UtilsService} from "@node_modules/abp-ng2-module/src/utils/utils.service";

@Injectable()
export class AppAuthService {
    private _utilsService: UtilsService = new UtilsService();
    constructor() {
    }

    logout(reload?: boolean, returnUrl?: string): void {
        abp.auth.clearToken();
        // Remove the tenant id when logging out
        this._utilsService.deleteCookie(AppConsts.sessionNames.tenantId, abp.appPath);
        AppUiHelper.hideSideBar();
        if (reload !== false) {
            if (returnUrl) {
                location.href = returnUrl;
            } else {
                location.href = AppConsts.appBaseUrl + '/account/login';
            }
        }
    }
}
