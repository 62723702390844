﻿import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CreateOrEditServiceComponent } from '@app/shared/common/digital-services/create-edit-services/create-edit-services.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'digital-services',
                children: [ 
                    { path: 'create-edit-services', component: CreateOrEditServiceComponent, data: { permission: 'Pages.Administration' } },
                ],
            },
        ]),
    ],
    exports: [RouterModule],
})
export class CommonRoutingModule { }