var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TopMenuBase } from '../top-menu-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppNavigationService } from '../../app-navigation.service';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
var DropDownMenuComponent = /** @class */ (function (_super) {
    __extends(DropDownMenuComponent, _super);
    function DropDownMenuComponent(router, navigationSvc, appSessionService, injector, httpClient) {
        var _this = _super.call(this, appSessionService, navigationSvc, injector) || this;
        _this.router = router;
        _this.httpClient = httpClient;
        _this.onCloseClick = new EventEmitter();
        _this.basePath = "../../assets/common/images/dropdown-menu-banners/";
        _this.imageLayouts = _this.basePath + "layout.json";
        _this.marketingTag = 'Just What Spring Ordered'; //this is a temporary marketing tag that will be removed
        _this.menuSubSections = ['Categories', 'Tools', 'User Management', 'Profile', 'Brands', 'Stores', 'Pricelists', 'Vendors', 'Pricing System'];
        return _this;
    }
    DropDownMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        var links = this.imageLinks().length + this.actionLinks().length;
        this.linksExist = links > 0;
        this.linkWidth = 100 / (links + 1);
        this.httpClient.get(this.imageLayouts)
            .pipe(finalize(function () {
            _this.imageLayoutData.sort(function (a, b) {
                return a.order - b.order;
            });
        }))
            .subscribe(function (x) {
            _this.imageLayoutData = x;
        });
    };
    DropDownMenuComponent.prototype.hover = function (ev, navItem) {
        if (navItem.imageLink && navItem.imageLink.alternativeImageExist() && navItem.imageLink.alternateImages)
            $(ev.currentTarget).attr('src', navItem.imageLink.alternateImageSrc);
    };
    DropDownMenuComponent.prototype.unhover = function (ev, navItem) {
        if (navItem.imageLink && navItem.imageLink.alternateImages)
            $(ev.currentTarget).attr('src', navItem.imageLink.defaultImageSrc);
    };
    DropDownMenuComponent.prototype.navigateWithParams = function (path, tag) {
        this.router.navigate([path], { queryParams: { tag: tag } }).then(function () {
            window.location.reload();
        });
    };
    DropDownMenuComponent.prototype.navigate = function (path) {
        this.router.navigate([path]);
    };
    DropDownMenuComponent.prototype.imageLinks = function () {
        var _this = this;
        var any = this.MenuItems && this.MenuItems.length > 0;
        if (any) {
            return this.MenuItems.filter(function (i) { return _this.isImageLink(i); });
        }
        return [];
    };
    DropDownMenuComponent.prototype.actionLinks = function () {
        var _this = this;
        var any = this.MenuItems && this.MenuItems.length > 0;
        if (any) {
            return this.MenuItems.filter(function (i) { return _this.isActionLink(i); });
        }
        return [];
    };
    DropDownMenuComponent.prototype.showMenuSection = function (sectionName) {
        var found = this.MenuItems.find(function (d) { return d.subSection == sectionName; });
        if (found) {
            return true;
        }
        return false;
    };
    DropDownMenuComponent.prototype.navigateTo = function (menuItem) {
        var name = menuItem.name;
        var paramName = menuItem.paramName;
        var paramValue = menuItem.paramValue;
        switch (paramName) {
            case 'Brand': {
                this.router.navigate([menuItem.route], { queryParams: { Brand: name, BrandId: paramValue } });
                break;
            }
            case 'Category': {
                this.router.navigate([menuItem.route], { queryParams: { Category: name } });
                break;
            }
            case 'View': {
                this.router.navigate([menuItem.route], { queryParams: { viewId: paramValue } });
                break;
            }
            default: {
                this.router.navigate([menuItem.route]);
                break;
            }
        }
    };
    DropDownMenuComponent.prototype.getRouterParams = function (routerLink) {
        if (routerLink.paramName && routerLink.paramValue) {
            return _a = {}, _a[routerLink.paramName] = routerLink.paramValue, _a;
        }
        return null;
        var _a;
    };
    DropDownMenuComponent.prototype.onClosedDropDownClick = function () {
        this.onCloseClick.emit(true);
    };
    DropDownMenuComponent.prototype.getUrl = function (url, isRel) {
        if (isRel) {
            return "" + this.basePath + url;
        }
        return url;
    };
    return DropDownMenuComponent;
}(TopMenuBase));
export { DropDownMenuComponent };
