var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FeatureCheckerService } from 'abp-ng2-module/dist/src/features/feature-checker.service';
import { finalize } from 'rxjs/operators';
var ContactForm = /** @class */ (function () {
    function ContactForm() {
    }
    return ContactForm;
}());
export { ContactForm };
var FooterComponent = /** @class */ (function (_super) {
    __extends(FooterComponent, _super);
    function FooterComponent(injector, feature, httpClient) {
        var _this = _super.call(this, injector) || this;
        _this.httpClient = httpClient;
        _this.basePath = "./assets/address/";
        _this.address = _this.basePath + "address.json";
        _this.assetsPaymentFolder = './assets/payment';
        _this.assetsDocuments = './assets/common/documents';
        _this.links1 = [
            { name: _this.l("AboutAxiz"), url: "https://info.axiz.com/about/" },
            { name: _this.l("CompanyBBBEECertificate"), url: "https://info.axiz.com/wp-content/uploads/2022/09/ELC11400_DCT-Holdings_BEE-Certificate_Final.pdf" },
        ];
        _this.links2 = [
            { name: _this.l("CompanyDocuments"), url: "https://info.axiz.com/about/#Documents" },
            { name: _this.l("WebsiteTermsOfUse"), url: "https://info.axiz.com/wp-content/uploads/2023/07/Axiz-Website-Terms-of-Use_2023.001.pdf" },
            { name: _this.l("AlvivaCodeOfConduct"), url: "https://info.axiz.com/wp-content/uploads/2022/04/4-alviva-holdings-ltd-code-of-conduct.pdf" },
            { name: _this.l("Complaints"), url: "https://info.axiz.com/complaints" },
        ];
        _this.links3 = [
            { name: _this.l("PaymentGatewayTermsAndConditions"), url: _this.assetsPaymentFolder + "/PayGate Terms and Conditions.pdf" },
            { name: _this.l("YourAccount"), url: "https://www.axiz.com/account/login" },
            { name: _this.l("YourOrders"), url: "https://www.axiz.com/account/login" },
            { name: _this.l("GetInTouchWithAxiz"), url: "https://info.axiz.com/contact/" },
        ];
        _this.hasAxizContentFeature = _this.feature.isEnabled("App.WhiteLabeling.AxizContent");
        _this.showFooter = _this.feature.isEnabled("App.WhiteLabeling.ShowAxizFooter");
        return _this;
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.httpClient.get(this.address)
            .pipe(finalize(function () {
        }))
            .subscribe(function (x) {
            _this.addressData = x;
        });
    };
    return FooterComponent;
}(AppComponentBase));
export { FooterComponent };
