import { NgModule } from '@angular/core';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterModule } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { AppRouteGuard } from '@app/shared/common/auth/auth-route-guard';
import { NotificationsComponent } from '@app/shared/layout/notifications/notifications.component'; 
import { PaymentCompletedComponent } from './cart/payment-methods/PaymentCompleted/Payment-complete.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: '',
                        children: [
                            { path: 'notifications', component: NotificationsComponent },
                            { path: 'payment', component: PaymentCompletedComponent },
                            { path: '', redirectTo: '/main/store', pathMatch: 'full' }
                        ]
                    },
                    {
                        path: 'main',
                        loadChildren: 'app/main/main.module#MainModule', //Lazy load main module
                        data: { preload: true }
                    },
                    {
                        path: 'cart',
                        loadChildren: 'app/cart/cart.module#CartModule', //Lazy load cart module
                        data: { preload: true }
                    }, 
                    {
                        path: 'order',
                        loadChildren: 'app/order/order.module#OrderModule', //Lazy load order module
                        data: { preload: true }
                    },
                    {
                        path: 'journeys',
                        loadChildren: 'app/journeys/journey.module#JourneyModule', //Lazy load journey module
                    },
                    {
                        path: 'markets/redhat/reporting/ccsp-report',
                        loadChildren: 'app/reporting/reporting.module#ReportingModule', //Lazy load reporting module
                    },
                    {
                        path: 'admin',
                        loadChildren: 'app/admin/admin.module#AdminModule', //Lazy load admin module
                        data: { preload: true }
                    }, 
                    {
                        path: 'oauth2',
                        loadChildren: 'app/oauth2/oauth2.module#OAuth2Module', //Lazy load oauth2 module
                        data: { preload: true }
                    },
                    {
                        path: 'deals',
                        loadChildren: 'app/deals/deal.module#DealModule', //Lazy load deal module
                        data: { preload: true }
                    },
                    {
                        path: 'common',
                        loadChildren: 'app/shared/common/app-common.module#AppCommonModule', //Lazy load common module
                        data: { preload: true }
                    },
                    {
                        path: '**', redirectTo: 'notifications'
                    }
                ]
            },
            {
                path: 'payment',
                component: PaymentCompletedComponent
            }
        ])
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
    constructor(
        private router: Router
    ) {
        router.events.subscribe((event) => {

            if (event instanceof RouteConfigLoadStart) {
                abp.ui.setBusy();
            }

            if (event instanceof RouteConfigLoadEnd) {
                abp.ui.clearBusy();
            }

            if (event instanceof NavigationEnd) {
                $('meta[property=og\\:url]').attr('content', window.location.href);
                $('#m_aside_left').mOffcanvas().hide();
            }
        });
    }
}
