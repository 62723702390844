<form #createOrEditForm="ngForm">
  <div class="m-subheader ">
    <div class="d-flex align-items-center">
      <div class="mr-auto col-xs-6">
        <h3 class="m-subheader__title m-subheader__title--separator">
          <span>{{l("MarketConfiguration")}}</span>
        </h3>
        <span class="m-section__sub">
          {{l("ConfigureDigitalServices")}}
        </span>
      </div>
      <div class="col-xs-6 text-right"></div>
    </div>
  </div>    
  <div *ngIf="active" class="m-portlet m-portlet--mobile">
     <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
          <div class="m-portlet__head-title">
            <h3 class="m-portlet__head-text">
              {{l("AddService")}}
                <small>{{l("AddNewService")}}</small>
            </h3>
          </div>
        </div>
        <div class="m-portlet__head-tools">
        </div>
     </div>
     <div class="m-portlet__body">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12 m--padding-0">
                <h5>{{l('ServiceApplicationMethod')}}</h5> 
              <small *ngIf="hasServicesLinked"> ** If this service was used on a market you will not be able to change some values.</small>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 sercivecol" *ngFor="let appMethod of appMethodList" (click)="changeApplicationMethod(appMethod)"
              [ngClass]="{ 'service-disabled' : hasServicesLinked || (selectedPricingMethod && selectedPricingMethod.id == servicesPricingMethodTypes.CustomPrice), 'selected': appMethod == applicationMethod }">
              <h5>{{appMethod?.label}}</h5><small>{{appMethod?.description}}</small>
            </div>
          </div>

          <div class="m-content" *ngIf="applicationMethod">
            <div class="row">
              <div class="form-group m-form__group">
                <label> {{ l('ServiceEntityType') }} </label>
                <div class="m-checkbox-list">
                  <label class="m-checkbox" *ngFor="let serviceEntityType of availableEntityTypes"  >
                    <input type="checkbox" [checked]="isChecked(serviceEntityType)" [value]="serviceEntityType.id" 
                    (change)='updateSelection($event, serviceEntityType)' [disabled]="serviceEntityType.isLinkedToEntity">
                    {{serviceEntityType?.name}} 
                    <span></span>
                </label>
                </div>
            </div>
            </div>
            <div class="row"> 
              <div class="form-group m--width-50-per">
                <label for="pricingMethod">{{ l('ServicePricingMethod') }} </label>
                <select id="pricingMethod" class="form-control" [(ngModel)]="selectedPricingMethod" [disabled]="hasServicesLinked"
                  (ngModelChange)="pricingMethodChanged($event)" name="pricingMethod" required>
                  <option *ngFor="let priceMethod of filteredPriceMethods" [ngValue]="priceMethod">{{priceMethod?.name}}
                  </option>
                </select>
                <validation-messages [formCtrl]="pricingMethod"> </validation-messages>
              </div>
              <div class="m--width-50-per" *ngIf="warningMessage">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                  <div class="m-alert m-alert--icon m-alert--icon-solid m-alert--outline alert alert-warning"
                    role="alert">
                    <div class="m-alert__icon">
                      <i class="flaticon-information"></i>
                      <span></span>
                    </div>
                    <div class="m-alert__text">
                      {{warningMessage}}
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            <div class="row"
                 *ngIf="selectedPricingMethod && selectedPricingMethod.id !== servicesPricingMethodTypes.CustomPrice
                 && applicationMethod && applicationMethod.value !== serviceApplicationType.Custom">
                <div class="form-group m-form__group">
                    <label class="form-group m--width-100-per"> {{ l('ServicePropertyMappingType') }} </label>

                    <div *ngIf="allowMultiMapping" class="btn-group btn-group-devided" data-toggle="buttons">
                        <label class="btn m-btn--pill btn-secondary" (click)="propertyMappingChange($event,type.value)" [ngClass]="{'active':type.isActive }"
                               *ngFor="let type of servicePropertyMappingList">
                            <fieldset [disabled]="true">
                                <input type="radio" name="PropertyMappingType" class="toggle" [value]="type.value" />
                            </fieldset>
                            {{type?.label}}
                        </label>
                    </div>
                    <div *ngIf="!allowMultiMapping" class="btn-group btn-group-devided">
                        <label class="btn m-btn--pill btn-secondary disabled noPointer" [ngClass]="{'active':type.isActive}"
                               *ngFor="let type of servicePropertyMappingList">
                            <fieldset [disabled]="true">
                                <input type="radio" name="PropertyMappingType" class="toggle" [value]="type.value" />
                            </fieldset>
                            {{type?.label}}
                        </label>
                    </div>
                </div>
            </div>
            <!-- custom properties -->
            <div *ngIf="selectedPricingMethod && applicationMethod && selectedEntityTypes && selectedEntityTypes.length > 0">
                <app-add-custom-properties [serviceMapping]="service.serviceMappings" (onMappingRemoved)="updateServiceMappings($event)"
                                            [selectedPricingMethod]="selectedPricingMethod" [serviceApplicationType]="serviceApplicationType" [entityTypes]="selectedEntityTypes"
                                            [service]="service"
                                            *ngIf="applicationMethod.value === serviceApplicationType.Custom || multipleMappingSelected">
                </app-add-custom-properties>
            </div>
            <!-- custom pricing value -->
            <div *ngIf="applicationMethod.value !== serviceApplicationType.Custom
                && selectedPricingMethod && (!multipleMappingSelected || !allowMultiMapping) ">
                <div *ngIf="service">
                    <div class="row">
                        <div class="form-group m--width-50-per"
                                *ngFor="let priceValue of selectedPricingMethod.servicePricingMethodVariableTypeLinks; let ind = index">
                            <div [ngStyle]="{ 'padding-left' : ([ind]==0)? '0px' : '10px' }">
                                <label *ngIf="service.applicationType !== serviceApplicationType.Custom"
                                        for="serviceValue{{ind}}">{{priceValue?.servicePriceVariableType?.name}}</label>
                                <input id="serviceValue{{ind}}" class="form-control m-input" type="number" [disabled]="hasServicesLinked" min="0"
                                        name="serviceValue{{ind}}" minlength="1" maxlength="150" required
                                        [(ngModel)]="service.serviceMappings[0]?.servicePricingVariable[ind].value" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group m--width-50-per">
                    <label for="description">{{ l('ServiceDescription') }} </label>
                    <input id="description" [(ngModel)]="service.description" class="form-control m-input" type="text"
                            name="description" minlength="1" maxlength="150" required />
                    <validation-messages [formCtrl]="description"> </validation-messages>
                </div>
            </div>
            <div class="row">
                <div class="form-group m--width-50-per">
                    <label for="serviceCode">{{ l('ServiceUniqueCode') }} </label>
                    <input id="serviceCode" [(ngModel)]="service.code" class="form-control m-input" type="text" [disabled]="hasServicesLinked"
                            name="serviceCode" minlength="1" maxlength="150" required />
                    <validation-messages [formCtrl]="serviceCode"> </validation-messages>
                </div>
            </div>
            <div class="row">
                <div class="form-group m--width-50-per">
                    <label for="serviceStatus"> {{ l('ServiceStatus') }}</label>
                    <select id="serviceStatus" [compareWith]="compareFn" class="form-control" name="serviceStatus"
                            [(ngModel)]="service.active" required>
                        <option [ngValue]="true"> Active</option>
                        <option [ngValue]="false"> Inactive</option>
                    </select>
                    <validation-messages [formCtrl]="status"> </validation-messages>
                </div>
            </div>
            <div class="row" *ngIf="sessionTenant">
                <div class="form-group m--width-50-per">
                    <label for="serviceCode">{{ l('ServiceCustomerName') }} </label>
                    <div class="ui-fluid">
                        <p-autoComplete [(ngModel)]="customerDetail" [suggestions]="filteredCustomers"
                                        (completeMethod)="filterCustomerSingle($event)" field="name" [size]="10"
                                        placeholder="Search customer by name" [ngModelOptions]="{standalone: true}" [disabled]="hasServicesLinked">
                        </p-autoComplete>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="m-portlet__foot m-portlet__foot--fit" *ngIf="applicationMethod">
        <div class="m-form__actions m--padding-20">
          <div class="col-xl-12 text-right">
            <button class="btn btn-metal" type="button" (click)="cancel()">{{l("Cancel")}}</button>
            <button class="btn btn-primary" (click)="save()" type="submit" [disabled]="createOrEditForm.invalid"><i
                class="fa fa-save"></i>{{l("Save")}}</button>
          </div>
        </div>
     </div>
   </div>
  </div>
</form>