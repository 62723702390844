var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnInit } from '@angular/core';
import { AppUserNotificationState } from '@shared/AppEnums';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NotificationServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { Paginator } from 'primeng/components/paginator/paginator';
import { Table } from 'primeng/components/table/table';
import { UserNotificationHelper } from './UserNotificationHelper';
import { ActivatedRoute } from '@angular/router';
var NotificationsComponent = /** @class */ (function (_super) {
    __extends(NotificationsComponent, _super);
    function NotificationsComponent(injector, _notificationService, _userNotificationHelper, changeDetectorRef, _activatedRoute) {
        var _this = _super.call(this, injector) || this;
        _this._notificationService = _notificationService;
        _this._userNotificationHelper = _userNotificationHelper;
        _this.changeDetectorRef = changeDetectorRef;
        _this._activatedRoute = _activatedRoute;
        _this.readStateFilter = 'ALL';
        _this.loading = false;
        _this.selectedId = undefined;
        return _this;
    }
    NotificationsComponent.prototype.ngOnInit = function () {
        this.selectedId = undefined;
        if (this._activatedRoute.snapshot.queryParams['id'] != null) {
            this.selectedId = this._activatedRoute.snapshot.queryParams['id'];
        }
    };
    NotificationsComponent.prototype.reloadPage = function () {
        this.paginator.changePage(this.paginator.getPage());
    };
    NotificationsComponent.prototype.setAsRead = function (record) {
        var _this = this;
        this.setNotificationAsRead(record, function () {
            _this.reloadPage();
        });
    };
    NotificationsComponent.prototype.isRead = function (record) {
        return record.formattedNotification.state === 'READ';
    };
    NotificationsComponent.prototype.fromNow = function (date) {
        return moment(date).fromNow();
    };
    NotificationsComponent.prototype.formatRecord = function (record) {
        return this._userNotificationHelper.format(record, false);
    };
    NotificationsComponent.prototype.formatNotification = function (record) {
        var formattedRecord = this.formatRecord(record);
        return abp.utils.truncateStringWithPostfix(formattedRecord.text, 120);
    };
    NotificationsComponent.prototype.formatNotifications = function (records) {
        var formattedRecords = [];
        for (var _i = 0, records_1 = records; _i < records_1.length; _i++) {
            var record = records_1[_i];
            record.formattedNotification = this.formatRecord(record);
            formattedRecords.push(record);
        }
        return formattedRecords;
    };
    NotificationsComponent.prototype.truncateString = function (text, length) {
        return abp.utils.truncateStringWithPostfix(text, length);
    };
    NotificationsComponent.prototype.getNotifications = function (event) {
        var _this = this;
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }
        this.primengTableHelper.showLoadingIndicator();
        this._notificationService.getUserNotifications(this.readStateFilter === 'ALL' ? undefined : AppUserNotificationState.Unread, this.primengTableHelper.getMaxResultCount(this.paginator, event), this.primengTableHelper.getSkipCount(this.paginator, event)).subscribe(function (result) {
            _this.primengTableHelper.totalRecordsCount = result.totalCount;
            _this.primengTableHelper.records = _this.formatNotifications(result.items);
            _this.primengTableHelper.hideLoadingIndicator();
        });
    };
    NotificationsComponent.prototype.setAllNotificationsAsRead = function () {
        var _this = this;
        this._userNotificationHelper.setAllAsRead(function () {
            _this.getNotifications();
        });
    };
    NotificationsComponent.prototype.openNotificationSettingsModal = function () {
        this._userNotificationHelper.openSettingsModal();
    };
    NotificationsComponent.prototype.setNotificationAsRead = function (userNotification, callback) {
        this._userNotificationHelper
            .setAsRead(userNotification.id, function () {
            if (callback) {
                callback();
            }
        });
    };
    NotificationsComponent.prototype.getRowClass = function (formattedRecord) {
        return formattedRecord.state === 'READ' ? 'notification-read' : '';
    };
    NotificationsComponent.prototype.setSelectedItem = function (id) {
        this.selectedId = id.toLowerCase();
    };
    return NotificationsComponent;
}(AppComponentBase));
export { NotificationsComponent };
