import { AppConsts } from '@shared/AppConsts';
var FileDownloadService = /** @class */ (function () {
    function FileDownloadService() {
    }
    FileDownloadService.prototype.downloadTempFile = function (file) {
        var url = AppConsts.remoteServiceBaseUrl + '/api/services/app/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        location.href = url; //TODO: This causes reloading of same page in Firefox
    };
    return FileDownloadService;
}());
export { FileDownloadService };
