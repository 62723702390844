import { AppConsts } from '@shared/AppConsts';
import { AppUiHelper } from '@shared/common/ui/ui-controller-helper';
import { UtilsService } from "@node_modules/abp-ng2-module/src/utils/utils.service";
var AppAuthService = /** @class */ (function () {
    function AppAuthService() {
        this._utilsService = new UtilsService();
    }
    AppAuthService.prototype.logout = function (reload, returnUrl) {
        abp.auth.clearToken();
        // Remove the tenant id when logging out
        this._utilsService.deleteCookie(AppConsts.sessionNames.tenantId, abp.appPath);
        AppUiHelper.hideSideBar();
        if (reload !== false) {
            if (returnUrl) {
                location.href = returnUrl;
            }
            else {
                location.href = AppConsts.appBaseUrl + '/account/login';
            }
        }
    };
    return AppAuthService;
}());
export { AppAuthService };
