import { Component, OnInit, Injector } from '@angular/core';
import { AppMenu } from '../app-menu';
import { AppNavigationService } from '../app-navigation.service';
import { AppMenuItem } from '../app-menu-item';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { TopMenuBase } from './top-menu-base';
import { MenuItem } from 'primeng/primeng';

@Component({
    selector: 'app-top-nav-bar',
    templateUrl: './top-nav-bar.component.html',
    styleUrls: ['./top-nav-bar.component.less']
})
export class TopNavBarComponent extends TopMenuBase implements OnInit {
    userId = abp.session.userId;
    tenantId = abp.session.tenantId;
    menu: AppMenu;

    isLoggedIn = this.userId != null && this.userId > 0;
    isPartner = this.isLoggedIn && this.tenantId > 0;
    isHost = this.isLoggedIn && !this.isPartner;

    assetsImagesFolder = './assets/common/images';

    activeArea = '';
    constructor(
        private navigationSvc: AppNavigationService,
        appSessionService: AppSessionService,
        injector: Injector) {
        super(appSessionService, navigationSvc, injector)
    }

    ngOnInit() {
        this.menu = this.navigationSvc.getTopMenu();
    }

    getMenuItemStateClass(menuItem: AppMenuItem) {
        var location = this.activeArea ? this.activeArea : window.location.pathname;

        return (location.toLowerCase() == menuItem.route.toLowerCase()) ? 'active' : '';
    }

    getParentActiveState(menuItem: AppMenuItem) {
        var currentLocation = window.location.pathname;

        if (this.hasChildren(menuItem) &&
            this.getDisplayableChildren(menuItem).filter(c => c.route == currentLocation).length > 0) {
            return 'active';
        } else {
            return '';
        }
    }

    getActionLinkTarget(menuItem: AppMenuItem) {
        return menuItem.actionLink.indexOf('#') > -1 ? '' : '_blank'
    }

    isLastMenuItem(menuItem: AppMenuItem) {
        var displayedList = this.menu.items.filter(m => this.isDisplayed(m));
        return displayedList[displayedList.length - 1].name === menuItem.name;
    }

    resetActivePath() {
        this.activeArea = '';
    }

    toggled(isOpen: boolean, id: string) {
        if (isOpen) {
            this.activeArea = id;
        } else {
            if (this.activeArea == id)
                this.activeArea = window.location.pathname;
        }
    }

    isDropDownMenu(menuItem) {
        return this.hasChildren(menuItem) && this.showMenuItem(menuItem)
    }

    isActionLinkItem(menuItem) {
        return !this.hasChildren(menuItem) && !menuItem.route && menuItem.actionLink && this.showMenuItem(menuItem)
    }

    isRouterLinkItem(menuItem) {
        return !this.hasChildren(menuItem) && menuItem.route && this.showMenuItem(menuItem)
    }

    isDisplayed(menuItem) {
        return this.isActionLinkItem(menuItem) || this.isRouterLinkItem(menuItem) || this.isDropDownMenu(menuItem);
    }
}
