import { AfterViewInit, ElementRef } from '@angular/core';
var AutoFocusDirective = /** @class */ (function () {
    function AutoFocusDirective(_element) {
        this._element = _element;
    }
    AutoFocusDirective.prototype.ngAfterViewInit = function () {
        $(this._element.nativeElement).focus();
    };
    return AutoFocusDirective;
}());
export { AutoFocusDirective };
