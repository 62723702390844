<footer *ngIf="showFooter" id="footer">
    <div>
        <div class="width-percent-100">
            <app-payment-methods-banner></app-payment-methods-banner>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-xs-12">
                <ul class="one">
                    <span>{{ l("GetToKnowUs") }}</span>
                    <li *ngFor="let link of links1">
                        <a [href]="link.url" target="_blank">{{ link.name }}</a>
                    </li>
                </ul>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <ul class="two">
                    <span>{{ l("CorporateGovernance") }}</span>
                    <li *ngFor="let link of links2">
                        <a [href]="link.url" target="_blank">{{ link.name }}</a>
                    </li>
                </ul>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <ul class="three">
                    <span>{{ l("LetUsHelpYou") }}</span>
                    <li *ngFor="let link of links3">
                        <a [href]="link.url" target="_blank">{{ link.name }}</a>
                    </li>
                    <div class="row">
                        <div class="col-1">
                            <a href="https://www.facebook.com/Axizsa/" target="_blank">
                                <i class="fa fa-facebook-official" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class="col-1">
                            <a href="https://www.linkedin.com/company/axiz" target="_blank">
                                <i class="fa fa-linkedin-square" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </ul>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <ul *ngIf="addressData" class="four">
                    <span>{{ l("AxizHeadOffice") }}</span>
                    <li>
                        <div class="d-flex justify-content-start">
                            <div class="mr-2 contact">
                                <i class="fas fa-building contact"></i>
                            </div>
                            <div>
                                <div>{{ addressData.Line1 }}</div>
                                <div>{{ addressData.Line2 }}</div>
                                <div>{{ addressData.Line3 }}</div>
                                <div>{{ addressData.Line4 }}</div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="d-flex justify-content-start">
                            <div class="m-0 p-0 mr-2 contact">
                                <i class="fas fa-phone contact"></i>
                            </div>
                            <div>
                                {{ addressData.Tel }}
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="d-flex justify-content-start">
                            <div class="mr-2 contact">
                                <i class="fas fa-truck contact"></i>
                            </div>
                            <div>
                                {{ addressData.POBox }}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>