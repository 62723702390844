import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var CartDataService = /** @class */ (function () {
    function CartDataService() {
        this.cartSource = new BehaviorSubject(null);
        this.currentCart = this.cartSource.asObservable();
        this.cartRefresh = new BehaviorSubject(null);
        this.currentCartRefresh = this.cartRefresh.asObservable();
    }
    CartDataService.prototype.updateCurrentCart = function (cart) {
        this.cartSource.next(cart);
    };
    CartDataService.prototype.refreshCurrentCart = function (cart) {
        this.cartRefresh.next(cart);
    };
    CartDataService.ngInjectableDef = i0.defineInjectable({ factory: function CartDataService_Factory() { return new CartDataService(); }, token: CartDataService, providedIn: "root" });
    return CartDataService;
}());
export { CartDataService };
