﻿import { AbpHttpInterceptor, AbpHttpConfiguration } from '@abp/abpHttpInterceptor';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpResponse, HttpEventType } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, last, map, tap } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AxizHttpInterceptor extends AbpHttpInterceptor  implements HttpInterceptor {

    constructor(configuration: AbpHttpConfiguration,
        private readonly route: Router) {
        super(configuration);
    }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

  
        //Check if a tenant is being managed , add the viewing tenant id to the header
        if (this.route.url.indexOf(AppConsts.manageTenantUrl) > -1) {

            //get id of tenant from url
            let index = this.route.url.lastIndexOf("/");
            let tenantId = this.route.url.substr(index + 1);

            //Add the viewing tenant id to the header
            let httpRequest =  request.clone({
                headers: request.headers.set(AppConsts.customHeaders.viewingTenantID, tenantId)
            });

            return super.intercept(httpRequest, next);

        }
        else {
            return super.intercept(request, next);
        }
    }

    protected handleSuccessResponse(event: HttpEvent<any>, interceptObservable: Subject<HttpEvent<any>>) {

        if (!event || !event.type) {            
            super.handleSuccessResponse(event, interceptObservable);
            return;
        }

        // When upload progress is relayed. Follow default angular behaviour
        if (event.type === HttpEventType.UploadProgress) {
            interceptObservable.next(event);
            return;
        }
        
        super.handleSuccessResponse(event, interceptObservable);
    }
}