import { DialogSeverity } from "./generic-dialog-models";
var DialogComponentBase = /** @class */ (function () {
    //modalClass = '';
    function DialogComponentBase(activeModal) {
        this.activeModal = activeModal;
        this.iconClass = '';
        this.title = '';
        this.message = '';
        this.showCancel = false;
        this.confirmText = "Ok";
        this.cancelText = "Cancel";
        this.severity = DialogSeverity.Info;
    }
    DialogComponentBase.prototype.init = function () {
        if (!this.iconClass)
            this.getDefaultSeverityIcon();
    };
    DialogComponentBase.prototype.getDefaultSeverityIcon = function () {
        var s = this.severity;
        if (s == DialogSeverity.Info)
            this.iconClass = 'fa fa-info-circle';
        else if (s == DialogSeverity.Question)
            this.iconClass = 'fa fa-question-circle';
        else if (s == DialogSeverity.Warn)
            this.iconClass = 'fa fa-exclamation-circle';
        else if (s == DialogSeverity.Danger)
            this.iconClass = 'fa fa-exclamation-triangle';
    };
    return DialogComponentBase;
}());
export { DialogComponentBase };
