import { AppMarkets, FixedPriceType, OrderDeliveryMethod, PaygateMode, PaymentTypes, ServiceApplicationType, ServiceCriteria, ServicePropertyMappingType } from './AppEnums';
var AppConsts = /** @class */ (function () {
    function AppConsts() {
    }
    AppConsts.tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';
    AppConsts.defaultDateFormat = 'DD-MM-YYYY';
    AppConsts.idleTimeMinutes = 60;
    AppConsts.selecteMarket = AppMarkets.GeneralMarket; //Default to marketplace on landing page
    AppConsts.whatWeDoLink = 'https://info.axiz.com/what-we-do';
    //#region Payment Gateway Config 
    AppConsts.paygateIntegrationMode = PaygateMode.Production;
    AppConsts.defaultTransactionStatus = 2;
    AppConsts.BlockCDCCloseCardForTesting = false;
    AppConsts.bypassPaymentGatewayMessage = 'The system is currently configured to bypass the payment gateway.';
    AppConsts.runPaygateLocalMessage = 'The system is currently configured to run paygate locally. Make sure you have updated the hosts file with the localPaygateTestUrl value.';
    //#endregion
    AppConsts.title = 'AxizDigital';
    AppConsts.resellerKey = 'reseller';
    AppConsts.customerKey = 'customer';
    /// <summary>
    /// The new relationship association.
    /// </summary>
    AppConsts.newRelationshipAssociation = 'NewAssociation';
    AppConsts.styleVersionNumber = 'v1';
    AppConsts.vatCost = 'VAT Cost';
    AppConsts.inclusive = 'inclusive';
    AppConsts.SellPrice = 'Sell Price';
    AppConsts.Discount = 'Discount';
    AppConsts.Quantity = 'Quantity';
    AppConsts.VATMultipler = 0.15;
    AppConsts.addVAT = (function (vatMultiplier, priceExc) {
        return (priceExc * (vatMultiplier + 1));
    });
    AppConsts.removeVAT = (function (vatMultiplier, priceInc) {
        return (priceInc / (vatMultiplier + 1));
    });
    AppConsts.Suspended = 'suspended';
    AppConsts.OnlineServicesNCE = 'OnlineServicesNCE';
    AppConsts.NCETrial = 'NCETrial';
    /// <summary>
    /// The existing relationship association
    /// </summary>
    AppConsts.existingRelationshipAssociation = 'ExistingAssociation';
    AppConsts.domainState = {
        cloudAgreement: 'cloudAgreement',
        domainVerification: 'domainVerification',
    };
    AppConsts.activeState = 'active';
    AppConsts.SingleMapping = 'Single';
    AppConsts.MultipleMapping = 'Multiple';
    AppConsts.DeliverOrder = 'Delivery';
    AppConsts.CollectOrder = 'Collect';
    AppConsts.manageTenantUrl = 'manage-tenants';
    AppConsts.customHeaders = {
        viewingTenantID: 'viewing-tenant-id',
    };
    AppConsts.localeMappings = [];
    AppConsts.userManagement = {
        defaultAdminUserName: 'admin',
    };
    AppConsts.localization = {
        defaultLocalizationSourceName: 'Digital',
    };
    AppConsts.subscriptionStatus = [
        { value: 'active', label: 'Active' },
        { value: 'pending', label: 'Pending' },
        { value: 'suspended', label: 'Suspended' },
        { value: 'deleted', label: 'Deleted' },
        { value: 'expired', label: 'Expired' },
        { value: 'disabled', label: 'Disabled' },
    ];
    AppConsts.billingCycleTypes = [
        { value: 'unknown', label: 'Unknown' },
        { value: 'monthly', label: 'Monthly' },
        { value: 'annual', label: 'Annual' },
        { value: 'none', label: 'None' },
        { value: 'oneTime', label: 'OneTime' },
    ];
    AppConsts.ServiceCriteriaTypeList = [
        { value: ServiceCriteria.StartsWith, label: 'Starts with' },
        { value: ServiceCriteria.Contains, label: 'Contains' },
        { value: ServiceCriteria.EndWith, label: 'Ends with' },
        { value: ServiceCriteria.ExactMatch, label: 'Exact Match' },
    ];
    AppConsts.ServicePropertyMappingTypeList = [
        { value: ServicePropertyMappingType.SingleMapping, label: 'Single', isActive: true },
        { value: ServicePropertyMappingType.MultipleMapping, label: 'Multiple', isActive: false },
    ];
    AppConsts.OrderDeliveryMethodList = [
        { value: OrderDeliveryMethod.DeliverOrder, label: 'Delivery', isActive: true, feature: 'App.CartFeature.CartDeliveryEnabled', enabled: false },
        { value: OrderDeliveryMethod.CollectOrder, label: 'Collect', isActive: false, feature: 'App.CartFeature.CollectionEnabled', enabled: false },
    ];
    AppConsts.ServiceApplicationTypeList = [
        { value: ServiceApplicationType.AdHoc, label: 'Ad hoc', description: 'The service will not automatically be included.' },
        {
            value: ServiceApplicationType.Custom,
            label: 'Price Rule',
            description: 'Specific rules for when the service should be added can be setup once pricing method is selected. The service will also be added by default.',
        },
        {
            value: ServiceApplicationType.Default,
            label: 'Default',
            description: 'The service will automatically be added to all estimates that are created. The service will also not be linked to a specific product.',
        },
    ];
    AppConsts.FixedPriceTypeList = [
        { value: FixedPriceType.Margin, label: FixedPriceType.Margin, isActive: true },
        { value: FixedPriceType.IPA, label: FixedPriceType.IPA, isActive: false },
    ];
    AppConsts.ServiceStatus = [
        { value: true, label: 'Active' },
        { value: false, label: 'Inactive' },
    ];
    AppConsts.authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
    };
    AppConsts.sessionNames = {
        tenantId: 'Abp.TenantId',
    };
    AppConsts.grid = {
        defaultPageSize: 10,
    };
    AppConsts.momentThreshold = {
        maxShowDaysOnly: 45,
    };
    AppConsts.azureOfferId = 'MS-AZR-0145P';
    AppConsts.AzurePlanId = "DZH318Z0BPS6";
    AppConsts.AzurePlan = "Azure plan";
    AppConsts.customPrice = 'Custom Price';
    AppConsts.firstMonthExpiryWarning = 3;
    AppConsts.secondMonthExpiryWarning = 2;
    AppConsts.thirdMonthExpiryWarning = 1;
    AppConsts.daysTillSubscriptionCancelExpires = 7;
    AppConsts.daysTillAdobeSubscriptionCancelExpires = 14;
    AppConsts.timeoutRemainingSeconds = 120;
    AppConsts.priceRuleCategories = [
        {
            id: 1,
            heading: 'Governance',
            isPartnerSpecific: false,
            isProductSpecific: false,
            currentUserHasPermission: false
        },
        {
            id: 2,
            heading: 'Product',
            isPartnerSpecific: false,
            isProductSpecific: true,
            currentUserHasPermission: false
        },
        {
            id: 3,
            heading: 'Partner',
            isPartnerSpecific: true,
            isProductSpecific: true,
            currentUserHasPermission: false
        },
        {
            id: 4,
            heading: 'Special',
            isPartnerSpecific: true,
            isProductSpecific: true,
            currentUserHasPermission: false
        },
    ];
    AppConsts.pricingRuleStatus = {
        All: {
            id: 0,
        },
        Active: {
            id: 1,
        },
        Disabled: {
            id: 2,
        },
        Expired: {
            id: 3,
        },
    };
    AppConsts.priceRuleStatuses = [
        {
            id: 0,
            name: 'All',
        },
        {
            id: 1,
            name: 'Active',
        },
        {
            id: 2,
            name: 'Inactive',
        },
        {
            id: 3,
            name: 'Expired',
        },
    ];
    AppConsts.PaymentMethods = [
        {
            id: PaymentTypes.AxizCredit,
            name: 'Axiz Credit',
            value: PaymentTypes[PaymentTypes.AxizCredit],
        },
        {
            id: PaymentTypes.CapitecCredit,
            name: 'Capitec Credit',
            value: PaymentTypes[PaymentTypes.CapitecCredit],
        },
        {
            id: PaymentTypes.AxizCDC,
            name: 'Paygate',
            value: PaymentTypes[PaymentTypes.AxizCDC],
        },
        {
            id: PaymentTypes.OrderRequest,
            name: 'Order Request',
            value: PaymentTypes[PaymentTypes.OrderRequest],
        },
        {
            id: PaymentTypes.AxizCloudCredit,
            name: 'Axiz Cloud Credit',
            value: PaymentTypes[PaymentTypes.AxizCloudCredit],
        },
        {
            id: PaymentTypes.ProofOfPayment,
            name: 'Proof Of Payment Request',
            value: PaymentTypes[PaymentTypes.ProofOfPayment],
        },
    ];
    AppConsts.PaymentTerms = [
        {
            value: 24,
            name: '24 Months',
            factor: 0.05050
        },
        {
            value: 36,
            name: '36 Months',
            factor: 0.03562
        }
    ];
    AppConsts.GoogleAnalyticsSource = "https://www.googletagmanager.com/gtag/js?id=";
    AppConsts.PayGateProcessURL = 'https://secure.paygate.co.za/payweb3/process.trans';
    //GM Additional Info
    AppConsts.AppliedView = 'AppliedView';
    AppConsts.ProductDescription = 'ProductDescription';
    AppConsts.ReservationQuantity = 'ReservationQuantity';
    AppConsts.DefaultImageUrl = 'DefaultImageUrl';
    AppConsts.PurchaseOptions = 'PurchaseOptions';
    AppConsts.SellPriceCurrencyCode = 'SellPriceCurrencyCode';
    AppConsts.IncSellPrice = 'IncSellPrice';
    AppConsts.UpliftType = 'UpliftType';
    AppConsts.CapitecCredit = 'CapitecCredit';
    AppConsts.LongDescription = 'LongDescription';
    AppConsts.SupportUploadFileTypes = ["xls", "xlsx", "doc", "docx", "jpg", "jpeg", "png", "pdf"];
    AppConsts.SupportEmailAddress = "digitalsupport@axiz.com";
    AppConsts.TermsAndConditionsLink = "https://axizdocsblobstorage.blob.core.windows.net/axizdocs/Terms and Conditions/Axiz Customer Terms and Conditions - NO TERMS_v5.0 (Final) (002).pdf";
    AppConsts.DigitalCommercePath = '/app/main/landing-page';
    AppConsts.EnterpriseCommercePath = '/app/main/landing-page';
    AppConsts.RegisterDocumentStorageKeyPrefix = 'RegisterBusinessDocument-';
    // START Landing Page
    AppConsts.LandingPageConfigCleanupStorageKey = 'LandingPageConfigs';
    AppConsts.BannersImageStorageKey = 'BannerImage-';
    AppConsts.LandingPageGeneralDefaultConfig = 'General';
    AppConsts.LandingPageCloudDefaultConfig = 'Cloud';
    AppConsts.LandingPageEnterpriseDefaultConfig = 'Enterprise';
    AppConsts.LandingPagePath = '/app/main/landing-page';
    // END Landing Page
    AppConsts.FlexOffer = 'Flex Offer';
    AppConsts.MicrosoftOnboardRequestKey = "OnboardMicrsoftRequest";
    AppConsts.ProofOfPayment = 'ProofOfPayment';
    //START Bulk Import
    AppConsts.BulkImportSupportUploadFileTypes = ["xls", "xlsx"];
    //END Bulk Import
    //START Volume pricing
    AppConsts.VolumeDefaultMinValue = 1;
    //END Volume pricing
    AppConsts.PublishImage = 'assets/common/images/Publish.svg';
    AppConsts.AutoRenewalImage = 'assets/common/images/Manage_auto_renew_not_available.svg';
    AppConsts.DeactivateImage = 'assets/common/images/Deactivate.svg';
    AppConsts.ConfirmWarning = 'assets/common/images/ConfirmWarning.png';
    AppConsts.primaryButton = 'btn-primary';
    AppConsts.primaryButtonOutlined = 'm-btn--primary--inverted';
    AppConsts.dangerButton = 'm-btn--danger';
    AppConsts.dangerButtonOutlined = 'm-btn--danger--inverted';
    AppConsts.redColor = 'red';
    AppConsts.PriceMaintenanceFilterKey = 'searchTerm';
    AppConsts.MIME_TYPES = {
        EXCEL: [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
        ],
        PDF: [
            'application/pdf',
        ],
        IMAGES: [
            'image/jpeg',
            'image/png',
            'image/gif',
        ],
        CSV: [
            'text/csv',
        ]
    };
    AppConsts.PriceListImporters = {
        AdobePriceListImporter: 'Adobe Price List Importer',
    };
    AppConsts.DefaultCurrencyCode = 'ZAR';
    AppConsts.MicrosoftPriceListUploadTypes = [
        {
            name: "Online Services (CSP)",
            mask: ""
        },
        {
            name: "Online Services New Commerce Experience (NCE)",
            mask: ""
        },
        {
            name: "Software (Subscriptions and Perpetual)",
            mask: ""
        },
    ];
    AppConsts.AdobePriceListUploadTypes = [
        {
            name: "Commercial (eg. ...vipmpc...xlsx)",
            mask: "vipmpc"
        },
        {
            name: "Education (eg. ...vipmpe...xlsx)",
            mask: "vipmpe"
        },
        {
            name: "Government (eg. ...vipmpg...xlsx)",
            mask: "vipmpg"
        },
    ];
    AppConsts.AdobePriceSupportUploadFileTypes = ["xls", "xlsx"];
    AppConsts.ciscoTokenResetSessionName = 'ciscoMasterTokenReset';
    AppConsts.GenericFeed = "GenericFeed";
    AppConsts.OwnFeed = "OwnFeed";
    AppConsts.OwnFeedCustomLocation = "OwnFeedCustomLocation";
    return AppConsts;
}());
export { AppConsts };
