import {Injectable} from '@angular/core';
import {SignalRHelper} from "./SignalRHelper";
import {ChatSignalrService} from "../../app/shared/layout/chat/chat-signalr.service";

@Injectable({
    providedIn: 'root'
})
export class SignalRConnectionService {

    constructor(
        private _chatSignalrService: ChatSignalrService,
    ) {
    }

    connected: boolean;

    connect() {
        if (this.connected) {
            return;
        }
        SignalRHelper.initSignalR(() => {
            this._chatSignalrService.init();
        });
        this.connected = true;
    }

}
