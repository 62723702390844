import { ModuleWithProviders } from '@angular/core';
import { AppUrlService } from './nav/app-url.service';
import { AppSessionService } from './session/app-session.service';
import { AppUiCustomizationService } from './ui/app-ui-customization.service';
var CommonModule = /** @class */ (function () {
    function CommonModule() {
    }
    CommonModule.forRoot = function () {
        return {
            ngModule: CommonModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppUiCustomizationService
            ]
        };
    };
    return CommonModule;
}());
export { CommonModule };
