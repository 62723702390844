var QuickSideBarChat = /** @class */ (function () {
    function QuickSideBarChat() {
    }
    QuickSideBarChat.prototype.initOffcanvas = function () {
        var $offCanvas = $('#m_quick_sidebar').mOffcanvas({
            class: 'm-quick-sidebar',
            //overlay: false,
            close: $('#m_quick_sidebar_close'),
            toggle: $('#m_quick_sidebar_toggle')
        });
        // run once on first time dropdown shown
        $('#m_quick_sidebar').mOffcanvas().one('afterShow', function () {
            mApp.block($('#m_quick_sidebar'));
            setTimeout(function () {
                mApp.unblock($('#m_quick_sidebar'));
                $('#m_quick_sidebar').find('.m-quick-sidebar__content').removeClass('m--hide');
            }, 1000);
        });
    };
    QuickSideBarChat.prototype.hide = function () {
        $('body, #m_quick_sidebar').removeClass('m-quick-sidebar--on');
        $('#m_quick_sidebar').mOffcanvas().hide();
    };
    QuickSideBarChat.prototype.show = function () {
        $('body, #m_quick_sidebar').addClass('m-quick-sidebar--on');
        $('#m_quick_sidebar').mOffcanvas().show();
    };
    QuickSideBarChat.prototype.init = function (scrollEvent) {
        this.initOffcanvas();
        if (scrollEvent) {
            scrollEvent();
        }
    };
    return QuickSideBarChat;
}());
export { QuickSideBarChat };
