import { OnInit } from '@angular/core';
import { ChatMessageDto, ChatServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppConsts } from 'shared/AppConsts';
var ChatMessageComponent = /** @class */ (function () {
    function ChatMessageComponent(_chatService) {
        this._chatService = _chatService;
    }
    ChatMessageComponent.prototype.ngOnInit = function () {
        this.setChatMessageType();
        $('#chatImageUpload').find('.fa-plus').addClass('fa-camera').removeClass('fa-plus');
        $('#chatFileUpload').find('.fa-plus').addClass('fa-file').removeClass('fa-plus');
    };
    ChatMessageComponent.prototype.setChatMessageType = function () {
        if (this.message.message.startsWith('[image]')) {
            this.chatMessageType = 'image';
            var image = JSON.parse(this.message.message.substring('[image]'.length));
            this.chatMessage = AppConsts.remoteServiceBaseUrl +
                '/Chat/GetUploadedObject?fileId=' +
                image.id +
                '&contentType=' +
                image.contentType;
        }
        else if (this.message.message.startsWith('[file]')) {
            this.chatMessageType = 'file';
            var file = JSON.parse(this.message.message.substring('[file]'.length));
            this.chatMessage = AppConsts.remoteServiceBaseUrl +
                '/Chat/GetUploadedObject?fileId=' +
                file.id +
                '&contentType=' +
                file.contentType;
            this.fileName = file.name;
        }
        else if (this.message.message.startsWith('[link]')) {
            this.chatMessageType = 'link';
            var linkMessage = JSON.parse(this.message.message.substring('[link]'.length));
            this.chatMessage = linkMessage.message == null ? '' : linkMessage.message;
        }
        else {
            this.chatMessageType = 'text';
            this.chatMessage = this.message.message;
        }
    };
    return ChatMessageComponent;
}());
export { ChatMessageComponent };
