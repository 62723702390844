/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "ng2-file-upload/file-upload/file-select.directive";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "@angular/common";
import * as i6 from "./change-profile-picture-modal.component";
import * as i7 from "../../../../shared/service-proxies/service-proxies";
import * as i8 from "abp-ng2-module/dist/src/auth/token.service";
var styles_ChangeProfilePictureModalComponent = [];
var RenderType_ChangeProfilePictureModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ChangeProfilePictureModalComponent, data: {} });
export { RenderType_ChangeProfilePictureModalComponent as RenderType_ChangeProfilePictureModalComponent };
function View_ChangeProfilePictureModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 25, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.save() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ɵangular_packages_forms_forms_bg, [], null, null), i0.ɵdid(2, 4210688, [["changeProfilePictureModalForm", 4]], 0, i1.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(4, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["class", "close"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵeld(12, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "input", [["ng2FileSelect", ""], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).onChange() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 16384, null, 0, i2.FileSelectDirective, [i0.ElementRef], { uploader: [0, "uploader"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 1, "span", [["class", "help-block m-b-none"]], null, null, null, null, null)), (_l()(), i0.ɵted(17, null, ["", ""])), (_l()(), i0.ɵeld(18, 0, null, null, 0, "img", [["id", "ProfilePictureResize"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 1, "button", [["class", "btn btn-secondary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(21, null, ["", ""])), (_l()(), i0.ɵeld(22, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 0, "i", [["class", "fa fa-save"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(25, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.uploader; _ck(_v, 15, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.l("ChangeProfilePicture"); _ck(_v, 8, 0, currVal_7); var currVal_8 = _co.l("Close"); _ck(_v, 9, 0, currVal_8); var currVal_10 = _co.l("ProfilePicture_Change_Info", _co.maxProfilPictureBytesUserFriendlyValue); _ck(_v, 17, 0, currVal_10); var currVal_11 = !_co.temporaryPictureUrl; _ck(_v, 18, 0, currVal_11); var currVal_12 = _co.saving; _ck(_v, 20, 0, currVal_12); var currVal_13 = _co.l("Cancel"); _ck(_v, 21, 0, currVal_13); var currVal_14 = (!i0.ɵnov(_v, 2).form.valid || _co.saving); _ck(_v, 22, 0, currVal_14); var currVal_15 = _co.l("Save"); _ck(_v, 25, 0, currVal_15); }); }
export function View_ChangeProfilePictureModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { modal: 0 }), (_l()(), i0.ɵeld(1, 16777216, null, null, 6, "div", [["aria-hidden", "true"], ["aria-labelledby", "myLargeModalLabel"], ["bsModal", ""], ["class", "modal fade"], ["role", "dialog"], ["tabindex", "-1"]], null, [[null, "onShown"], [null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.esc" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onEsc($event) !== false);
        ad = (pd_1 && ad);
    } if (("onShown" === en)) {
        var pd_2 = (_co.onModalShown() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 212992, [[1, 4], ["changeProfilePictureModal", 4]], 0, i3.ModalDirective, [i0.ElementRef, i0.ViewContainerRef, i0.Renderer2, i4.ComponentLoaderFactory], { config: [0, "config"] }, { onShown: "onShown" }), i0.ɵpod(3, { backdrop: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "modal-dialog modal-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChangeProfilePictureModalComponent_1)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, "static"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.active; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_ChangeProfilePictureModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "changeProfilePictureModal", [], null, null, null, View_ChangeProfilePictureModalComponent_0, RenderType_ChangeProfilePictureModalComponent)), i0.ɵdid(1, 49152, null, 0, i6.ChangeProfilePictureModalComponent, [i0.Injector, i7.ProfileServiceProxy, i8.TokenService], null, null)], null, null); }
var ChangeProfilePictureModalComponentNgFactory = i0.ɵccf("changeProfilePictureModal", i6.ChangeProfilePictureModalComponent, View_ChangeProfilePictureModalComponent_Host_0, {}, {}, []);
export { ChangeProfilePictureModalComponentNgFactory as ChangeProfilePictureModalComponentNgFactory };
