import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AppPreBootstrap } from "AppPreBootstrap";

@Injectable({
    providedIn: 'root'
  })
  export class TokenManagementService {

    public getTokenExpiryDateTime() {
        var myRawToken = abp.auth.getToken();
        var jwtHelper = new JwtHelperService();
        return jwtHelper.getTokenExpirationDate(myRawToken)
      }

      public isCurrentTokenValid() : boolean {
          return this.getTokenExpiryDateTime() > new Date();
      }

      public refreshToken(callbackFunction = null){
          return AppPreBootstrap.RefreshToken(abp.auth.getToken(), callbackFunction);
      }
  }