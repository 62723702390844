import { AfterViewInit, ElementRef } from '@angular/core';
var JqPluginDirective = /** @class */ (function () {
    function JqPluginDirective(_element) {
        this._element = _element;
    }
    JqPluginDirective.prototype.ngAfterViewInit = function () {
        var $element = $(this._element.nativeElement);
        var pluginName = $element.attr('jq-plugin');
        var options = $element.attr('jq-options');
        if (!options) {
            $element[pluginName]();
        }
        else {
            $element[pluginName](eval('(' + options + ')'));
        }
    };
    return JqPluginDirective;
}());
export { JqPluginDirective };
