<div class="row" *ngIf="service">
  <div class="form-group m--width-100-per">
    <table class="table table-striped" *ngIf="service.serviceMappings.length > 0">
      <tr>
        <th *ngIf="entityTypes && entityTypes.length > 1">Service Entity Type</th>
        <th>Property</th>
        <th>Criteria</th>
        <th>Match Value</th>
        <th *ngFor="let priceValue of selectedPricingMethod.servicePricingMethodVariableTypeLinks;" style="width: 200px;">
          {{priceValue.servicePriceVariableType.name}}</th>
        <th></th>
      </tr>
      <tbody>
        <tr *ngFor="let mapping of getMappings(); let mapIndex = index">
          <td *ngIf="entityTypes && entityTypes.length > 1">
            <select id="entityType{{mapIndex}}" [compareWith]="compareFn" class="form-control" name="entityType{{mapIndex}}" *ngIf="mapping" 
                [(ngModel)]="mapping.serviceEntityTypeId" required>
                <option *ngFor="let entityType of entityTypes" [ngValue]="entityType.id">
                {{entityType.name}}</option>
            </select>
          </td>
          <td>
            <select id="propertyMapping{{mapIndex}}" [compareWith]="compareFn" class="form-control" name="propertyMapping{{mapIndex}}" *ngIf="mapping" 
              [(ngModel)]="mapping.propertyId" required>
              <option *ngFor="let property of getProperties(mapping)" [ngValue]="property.id">
                {{property.displayName}}</option>
            </select>
          </td>
          <td>
            <select id="serviceCriteria{{mapIndex}}" [compareWith]="compareFn" class="form-control" name="serviceCriteria{{mapIndex}}" *ngIf="mapping"
              [(ngModel)]="mapping.criteria" required>
              <option *ngFor="let criteria of serviceCriteriaTypes" [ngValue]="criteria.value">{{criteria.label}}
              </option>
            </select>
          </td>
          <td> 
              <input id="matchValue{{mapIndex}}" class="form-control m-input" type="text" name="matchValue{{mapIndex}}"
              [(ngModel)]="mapping.matchValue" minlength="1" maxlength="150" required autocomplete="off" />
          </td>
          <td *ngFor="let priceValue of selectedPricingMethod.servicePricingMethodVariableTypeLinks; let ind = index">
            <div [ngStyle]="{ 'padding-left' : ([ind]==0)? '0px' : '10px' }">
              <input id="serviceValue{{mapIndex}}{{ind}}" class="form-control m-input" type="number" min="0" 
                name="serviceValue{{mapIndex}}{{ind}}" minlength="1" maxlength="150" required autocomplete="off"
                [(ngModel)]="mapping.servicePricingVariable[ind].value" />
            </div>
          </td>
          <td class="row custom-service-row">
            <div class="m-alert__icon m--padding-left-10"><button class="btn btn-primary"
                (click)="copyPropertyMapping(mapping)"><i class="fa fa-copy custom-service"></i></button></div>
            <div class="m-alert__icon  m--padding-left-5"><button class="btn btn-danger" *ngIf="canDelete()"
                (click)="removeMapping(mapping)"><i class="fa fa-times custom-service"></i></button></div>
          </td>
        </tr>        
      </tbody>
    </table>
    <div class="m-alert__icon m--padding-15"><button class="btn btn-primary"
      (click)=pushMapping(serviceMapping)><i class="fa fa-plus-square"></i>Add additional property</button>
    </div>
  </div>
</div>