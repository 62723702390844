<div class="m-grid m-grid--hor m-grid--root m-page" [ngClass]="{'subscription-bar-visible': subscriptionStatusBarVisible()}">
    <!-- BEGIN: Header -->
    <header-bar *ngIf="!installationMode"></header-bar>
    <!-- END: Header -->
    
    <div class="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
        <div class="m-grid__item m-grid__item--fluid m-wrapper">
            <!--<app-top-nav-bar style="width: 100 !important;"></app-top-nav-bar>-->
            <!-- begin::Body -->
            <div class="my-router-outlet">
                <router-outlet></router-outlet>
            </div>
            <!-- end:: Body -->
            <!-- begin::Footer -->
            <div id="div-footer">
                <footer-bar style="bottom: 0;"></footer-bar>
            </div>
            <!-- end::Footer -->
        </div>
        
    </div>  
</div>
<chat-bar *ngIf="!installationMode"></chat-bar>
