<div [@routerTransition]>
    <div class="m-subheader ">
        <div class="row align-items-center">
            <div class="mr-auto col-sm-12 col-md-6">
                <h3 class="m-subheader__title">
                    <span>{{l("Notifications")}}</span>
                </h3>
            </div>
            <div class="col-sm-12 col-md-6 text-right">
                <button class="btn btn-success" (click)="openNotificationSettingsModal()"><i class="fa fa-cog"></i> {{l("NotificationSettings")}}</button>
                <button class="btn btn-primary" (click)="setAllNotificationsAsRead()"><i class="fa fa-check"></i> {{l("SetAllAsRead")}}</button>
            </div>
        </div>
    </div>
    <div id="ContentArea" class="m-content">
        <div class="m-portlet m-portlet--mobile">
            <div class="m-portlet__body">
                <div class="m-form m-form--label-align-right">
                    <div class="row m--margin-bottom-10">
                        <div class="col-xl-6">
                            <div class="form-group">
                                <select #TargetValueFilterSelectionCombobox
                                        class="form-control"
                                        [(ngModel)]="readStateFilter"
                                        name="readStateFilter"
                                        jq-plugin="selectpicker">
                                    <option value="ALL">{{l("All")}}</option>
                                    <option value="UNREAD">{{l("Unread")}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-xl-6 text-right">
                            <div class="form-group">
                                <button class="btn btn-metal" (click)="getNotifications()"><i class="fa fa-refresh"></i> {{l("Refresh")}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                 (onLazyLoad)="getNotifications($event)"
                                 [value]="primengTableHelper.records"
                                 rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                 [paginator]="false"
                                 [lazy]="true"
                                 [autoLayout]="true"
                                 [responsive]="primengTableHelper.isResponsive">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:130px">
                                        {{l('Actions')}}    
                                    </th>
                                    <th>
                                        {{l('Notification')}}
                                    </th>
                                    <th class="date-column">
                                        {{l('CreationTime')}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr [ngClass]="{'selected-notification': record.id === selectedId}"  (click)="setSelectedItem(record.id)">
                                    <td style="width:130px">
                                        <div class="btn-group dropdown">
                                            <button class="btn btn-sm btn-primary" [attr.title]="!isRead(record) ? l('SetAsRead') : ''" (click)="setAsRead(record)" [disabled]="isRead(record)">
                                                <i class="fa fa-check" *ngIf="isRead(record)" [attr.aria-label]="l('Read')"></i>
                                                <i class="fa fa-circle-o" *ngIf="!isRead(record)" [attr.aria-label]="l('Unread')"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td class="notification-column">
                                        <span class="ui-column-title">{{l('Notification')}}</span>
                                        <a *ngIf="record.formattedNotification.url" href="{{record.formattedNotification.url}}" class="{{getRowClass(record)}}">{{record.formattedNotification.text}}</a>
                                        <span *ngIf="!record.formattedNotification.url" title="{{record.formattedNotification.text}}" class="{{getRowClass(record)}}">{{record.formattedNotification.text}}</span>
                                    </td>
                                    <td >
                                        <span title="{{record.notification.creationTime | momentFormat:'llll'}}" class="{{getRowClass(record)}}">
                                            {{fromNow(record.notification.creationTime)}}
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                         #paginator
                                         (onPageChange)="getNotifications($event)"
                                         [totalRecords]="primengTableHelper.totalRecordsCount"
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>
</div>
