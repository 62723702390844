import { Injectable } from '@angular/core';
import { AppCartSteps } from '@app/cart/models/shopping-cart-steps';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartWorkflowService {
  constructor() {}

  private workflow = [
    { step: AppCartSteps.items, valid: false },
    { step: AppCartSteps.checkout, valid: false },
    { step: AppCartSteps.payment, valid: false },
    { step: AppCartSteps.finish, valid: false }
  ];

  /**
   * Run the the steps and get the first item that matches the past step
   * @param step
   */
  private getWorkflowItemByStep(step: string): any {
    for (let i = 0; i < this.workflow.length; i++) {
      if (this.workflow[i].step === step) {
        return this.workflow[i];
      }
    }
  }

  /**
   * If the state is found, set the valid field to true.
   * @param step target Step
   */
  validateStep(step: string) {
    let workflow = this.getWorkflowItemByStep(step);
    if (workflow) {
      workflow.valid = true;
    }
  }

  /**
   * If the state is found, set the valid field to false.
   * @param step target Step
   */
  invalidateStep(step: string) {
    let workflow = this.getWorkflowItemByStep(step);
    if (workflow) {
      workflow.valid = false;
    }
  }

  /**
   * If the state is found, set the valid field to true.
   * @param step target Step
   */
  isStepValid(step: string) {
    let workflow = this.getWorkflowItemByStep(step);
    return (workflow && workflow.valid) || false;
  }

  /**
   *  Reset all the steps in the Workflow to be invalid
   */
  resetSteps() {
    this.workflow.forEach(element => {
      element.valid = false;
    });
  }

  /**
   * If all the previous steps are validated, return blank
   * Otherwise, return the first invalid step
   * @param step target Step
   */
  getFirstInvalidStep(step: string): string {
    let found = false;
    let valid = true;
    let redirectToStep = '';
    for (let i = 0; i < this.workflow.length && !found && valid; i++) {
      let item = this.workflow[i];
      if (item.step === step) {
        found = true;
        redirectToStep = '';
      } else {
        valid = item.valid;
        redirectToStep = item.step;
      }
    }
    return redirectToStep;
  }
}
