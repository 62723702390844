/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mega-menu.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown";
import * as i5 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown-config";
import * as i6 from "../drop-down-menu/drop-down-menu.component.ngfactory";
import * as i7 from "../drop-down-menu/drop-down-menu.component";
import * as i8 from "../../app-navigation.service";
import * as i9 from "../../../../../../shared/common/session/app-session.service";
import * as i10 from "@angular/common/http";
import * as i11 from "./mega-menu.component";
import * as i12 from "../../../../../../shared/service-proxies/service-proxies";
var styles_MegaMenuComponent = [i0.styles];
var RenderType_MegaMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MegaMenuComponent, data: {} });
export { RenderType_MegaMenuComponent as RenderType_MegaMenuComponent };
function View_MegaMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [], [[8, "id", 0], [8, "className", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.resetActivePath() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "m--margin-top-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.getRouterParams(_v.parent.parent.context.$implicit); var currVal_5 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.route, ""); _ck(_v, 1, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "link_", _v.parent.parent.context.index, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "top-nav nav-item nav-link ", _co.getMenuItemStateClass(_v.parent.parent.context.$implicit), ""); var currVal_2 = i1.ɵnov(_v, 1).target; var currVal_3 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_6 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.icon, ""); _ck(_v, 2, 0, currVal_6); var currVal_7 = _co.l(_v.parent.parent.context.$implicit.name); _ck(_v, 4, 0, currVal_7); }); }
function View_MegaMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], [[8, "id", 0], [8, "target", 0], [8, "href", 4], [8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetActivePath() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "m--margin-top-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "link_", _v.parent.parent.context.index, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.getActionLinkTarget(_v.parent.parent.context.$implicit), ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.getFullActionLink(_v.parent.parent.context.$implicit.actionLink), ""); var currVal_3 = i1.ɵinlineInterpolate(1, "nav-item nav-link ", _co.getMenuItemStateClass(_v.parent.parent.context.$implicit), ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.l(_v.parent.parent.context.$implicit.name); _ck(_v, 2, 0, currVal_4); }); }
function View_MegaMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "m--margin-top-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.l(_v.parent.parent.parent.context.$implicit.name); _ck(_v, 1, 0, currVal_0); }); }
function View_MegaMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "m--margin-top-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.defaultHome; _ck(_v, 1, 0, currVal_0); }); }
function View_MegaMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["dd", 1]], null, 14, "div", [["class", "nav-item dropdown"], ["ngbDropdown", ""]], [[8, "id", 0], [2, "show", null]], [[null, "openChange"], [null, "keyup.esc"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup.esc" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).closeFromOutsideEsc() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).closeFromClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("openChange" === en)) {
        var pd_2 = (_co.toggled($event, i1.ɵnov(_v, 4).id) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 2, i4.NgbDropdown, [i5.NgbDropdownConfig, i1.NgZone], null, { openChange: "openChange" }), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵeld(4, 0, [["link", 1]], null, 7, "a", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "dropdown-toggle"], ["data-toggle", "dropdown"], ["href", "#"], ["ngbDropdownToggle", ""], ["role", "menuitem"]], [[8, "className", 0], [8, "id", 0], [1, "tabindex", 0], [1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeMegaDropDown() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i4.NgbDropdownToggle, [i4.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i4.NgbDropdownAnchor, null, [i4.NgbDropdownToggle]), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_7)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_8)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, [["dropDownMenu", 1]], null, 2, "div", [["appendTo", "body"], ["class", "dropdown-menu"], ["role", "menu"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-drop-down-menu", [], null, [[null, "onCloseClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCloseClick" === en)) {
        var pd_0 = (_co.closeMegaDropDown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DropDownMenuComponent_0, i6.RenderType_DropDownMenuComponent)), i1.ɵdid(14, 114688, null, 0, i7.DropDownMenuComponent, [i2.Router, i8.AppNavigationService, i9.AppSessionService, i1.Injector, i10.HttpClient], { MenuItems: [0, "MenuItems"], menuItemName: [1, "menuItemName"], marketingImage: [2, "marketingImage"] }, { onCloseClick: "onCloseClick" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_7 = (_v.parent.parent.context.$implicit.name !== "Digital Commerce"); _ck(_v, 9, 0, currVal_7); var currVal_8 = (_v.parent.parent.context.$implicit.name === "Digital Commerce"); _ck(_v, 11, 0, currVal_8); var currVal_10 = _co.getDisplayableChildren(_v.parent.parent.context.$implicit); var currVal_11 = _v.parent.parent.context.$implicit.name; var currVal_12 = _v.parent.parent.context.$implicit.marketingImage; _ck(_v, 14, 0, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "dropdown_", _v.parent.parent.context.index, ""); var currVal_1 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "top-nav nav-link dropdown-toggle ", _co.getParentActiveState(_v.parent.parent.context.$implicit), ""); var currVal_3 = i1.ɵinlineInterpolate(1, "link_", _v.parent.parent.context.index, ""); var currVal_4 = ((_v.parent.parent.context.index == 0) ? 0 : (0 - 1)); var currVal_5 = i1.ɵnov(_v, 5).dropdown.isOpen(); _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.icon, ""); _ck(_v, 7, 0, currVal_6); var currVal_9 = i1.ɵinlineInterpolate(1, "dropDownMenu_", _v.parent.parent.context.index, ""); _ck(_v, 12, 0, currVal_9); }); }
function View_MegaMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["style", "width: 100px!important"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_4)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_5)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_6)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isRouterLinkItem(_v.parent.context.$implicit); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.isActionLinkItem(_v.parent.context.$implicit); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.isDropDownMenu(_v.parent.context.$implicit); _ck(_v, 7, 0, currVal_2); }, null); }
function View_MegaMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.showMenuItem(_v.context.$implicit) && _co.isRouterLinkItem(_v.context.$implicit)) || (_co.showMenuItem(_v.context.$implicit) && _co.isDropDownMenu(_v.context.$implicit))) || (_co.showMenuItem(_v.context.$implicit) && _co.isActionLinkItem(_v.context.$implicit))); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MegaMenuComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "d-flex flex-column justify-content-end impersonation-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "d-flex justify-content-end impersonation-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "d-flex justify-content-end impersonation-partner"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.l("ImpersonatingMenu"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.impersonatorName; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.impersonatorAccountNumber; _ck(_v, 6, 0, currVal_2); }); }
function View_MegaMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "nav-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "nav", [["class", "navbar navbar-expand-md bg-dark navbar-dark "]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "collapse navbar-collapse d-flex justify-content-center"], ["id", "navbarCollapse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_2)), i1.ɵdid(5, 802816, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_9)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu.items; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.impersonation; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_MegaMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MegaMenuComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MegaMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mega-menu", [], null, null, null, View_MegaMenuComponent_0, RenderType_MegaMenuComponent)), i1.ɵdid(1, 114688, null, 0, i11.MegaMenuComponent, [i8.AppNavigationService, i9.AppSessionService, i12.ProductsServiceProxy, i12.ViewServiceProxy, i1.Injector, i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MegaMenuComponentNgFactory = i1.ɵccf("app-mega-menu", i11.MegaMenuComponent, View_MegaMenuComponent_Host_0, {}, {}, []);
export { MegaMenuComponentNgFactory as MegaMenuComponentNgFactory };
