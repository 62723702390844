var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CheckoutDataDto, PaymentsServiceProxy, TenantAccountDto, TenantServiceProxy, } from './../../../../shared/service-proxies/service-proxies';
import { OnInit, Injector, EventEmitter } from '@angular/core';
import { CartServiceProxy, AvaCartDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppMarkets, CartCheckoutDisable, PaygateMode, PaymentTypes, ProductItemType } from '@shared/AppEnums';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { CartDataService } from '@app/shared/common/services/cart.data.service';
import { ShoppingCartFormService } from '@app/cart/services/shopping-cart-form.service';
import { AppConsts } from '@shared/AppConsts';
import { result } from 'lodash';
import { finalize } from 'rxjs/operators';
var ShoppingCartBaseComponentComponent = /** @class */ (function (_super) {
    __extends(ShoppingCartBaseComponentComponent, _super);
    function ShoppingCartBaseComponentComponent(injector, cartService, router, cartDataService, shoppingCartFormService, tenantsAppService, paymentsService) {
        var _this = _super.call(this, injector) || this;
        _this.cartService = cartService;
        _this.router = router;
        _this.cartDataService = cartDataService;
        _this.shoppingCartFormService = shoppingCartFormService;
        _this.tenantsAppService = tenantsAppService;
        _this.paymentsService = paymentsService;
        _this.cartLoaded = new EventEmitter();
        _this.onCartItemsUpdatedEvent = new EventEmitter();
        _this.avaReferenceGenerated = new EventEmitter();
        _this.processing = true;
        _this.loading = false;
        _this.groupedCartItems = [];
        _this.tenantAccount = new TenantAccountDto();
        return _this;
    }
    Object.defineProperty(ShoppingCartBaseComponentComponent.prototype, "IBM", {
        // The scope of binding expressions in a components template is the components class instance.
        // You can't refer to globals or statics directly.
        //As a workaround you can add a getter to your components class
        get: function () {
            return AppMarkets.IBM;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartBaseComponentComponent.prototype, "Microsoft", {
        get: function () {
            return AppMarkets.Microsoft;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartBaseComponentComponent.prototype, "Cisco", {
        get: function () {
            return AppMarkets.Cisco;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartBaseComponentComponent.prototype, "Redhat", {
        get: function () {
            return AppMarkets.Redhat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartBaseComponentComponent.prototype, "GeneralMarket", {
        get: function () {
            return AppMarkets.GeneralMarket;
        },
        enumerable: true,
        configurable: true
    });
    ShoppingCartBaseComponentComponent.prototype.getCartApplicableMarkets = function () {
        var marketProviderIds = [];
        if (this.cart && this.cart.items) {
            this.cart.items
                .filter(function (i) { return i != null; })
                .forEach(function (item) {
                marketProviderIds.push(item.purchaseDecision.product.market);
            });
        }
        return marketProviderIds;
    };
    ShoppingCartBaseComponentComponent.prototype.ngOnInit = function () {
        this.groupCartByProduct();
    };
    ShoppingCartBaseComponentComponent.prototype.getItems = function () {
        if (this.cart && this.cart.items)
            return this.cart.items;
        return [];
    };
    ShoppingCartBaseComponentComponent.prototype.cartHasIbmProducts = function () {
        return this.getCartApplicableMarkets().filter(function (market) { return market === AppMarkets.IBM; }).length > 0;
    };
    ShoppingCartBaseComponentComponent.prototype.cartHasIbmMetaData = function () {
        return (_(this.checkoutData.marketCheckoutData)
            .map(function (m) { return m.market; })
            .indexOf(AppMarkets.IBM) !== -1);
    };
    ShoppingCartBaseComponentComponent.prototype.cartHasMicrosoftItems = function (cartItems) {
        return this.cartHasMarketItems(AppMarkets.Microsoft, cartItems);
    };
    ShoppingCartBaseComponentComponent.prototype.cartHasAdobeItems = function (cartItems) {
        return this.cartHasMarketItems(AppMarkets.Adobe, cartItems);
    };
    ShoppingCartBaseComponentComponent.prototype.cartHasCiscoItems = function (cartItems) {
        return this.cartHasMarketItems(AppMarkets.Cisco, cartItems);
    };
    ShoppingCartBaseComponentComponent.prototype.cartHasMarketItems = function (market, cartItems) {
        var _this = this;
        if (!cartItems) {
            cartItems = this.cart.items;
        }
        return this.cart
            ? _(cartItems)
                .map(function (m) { return _this.getCartItemMarket(m); })
                .indexOf(market) !== -1
            : false;
    };
    ShoppingCartBaseComponentComponent.prototype.cartHasGeneralMarketItems = function (cartItems) {
        return this.cartHasMarketItems(AppMarkets.GeneralMarket, cartItems);
    };
    ShoppingCartBaseComponentComponent.prototype.getPaymentMethod = function () {
        if (this.checkoutData) {
            if (this.checkoutData.paymentMethod == PaymentTypes[PaymentTypes.AxizCredit]) {
                return PaymentTypes.AxizCredit;
            }
            else if (this.checkoutData.paymentMethod == PaymentTypes[PaymentTypes.AxizCDC]) {
                return PaymentTypes.AxizCDC;
            }
            else if (this.checkoutData.paymentMethod == PaymentTypes[PaymentTypes.CapitecCredit]) {
                return PaymentTypes.CapitecCredit;
            }
        }
        return PaymentTypes.NoneSelected;
    };
    ShoppingCartBaseComponentComponent.prototype.isAxizCDC = function () {
        return this.getPaymentMethod() === PaymentTypes.AxizCDC;
    };
    ShoppingCartBaseComponentComponent.prototype.notifyBypassPaymentGateway = function () {
        if (AppConsts.paygateIntegrationMode === PaygateMode.BypassPayGate) {
            this.notify.warn(AppConsts.bypassPaymentGatewayMessage);
        }
        if (AppConsts.BlockCDCCloseCardForTesting == true) {
            this.notify.warn("Paygate in debug mode, order will not be placed");
        }
    };
    ShoppingCartBaseComponentComponent.prototype.notifyRunPaygateLocal = function () {
        if (AppConsts.paygateIntegrationMode === PaygateMode.Local) {
            this.notify.warn(AppConsts.runPaygateLocalMessage);
        }
    };
    ShoppingCartBaseComponentComponent.prototype.hasAnyVendorSpecificRequirements = function () {
        return this.cartHasIbmProducts() && this.cartHasIbmMetaData();
    };
    ShoppingCartBaseComponentComponent.prototype.getCartItemMarket = function (cartItem) {
        if (!cartItem)
            return AppMarkets.Default;
        return cartItem.purchaseDecision.product.market;
    };
    ShoppingCartBaseComponentComponent.prototype.groupCartByProduct = function () {
        var _this = this;
        if (!this.cart) {
            this.cart = new AvaCartDto();
            this.cart.init({
                items: [],
            });
        }
        else {
            this.groupedCartItems = _(this.cart.items)
                .groupBy(function (g) { return _this.getCartItemMarket(g); })
                .map(function (group) {
                return {
                    marketProvider: _this.getCartItemMarket(group[0]),
                    items: group,
                };
            })
                .value();
        }
        this.cartLoaded.emit({
            rootCart: this.cart,
            cartItems: this.groupedCartItems,
        });
    };
    ShoppingCartBaseComponentComponent.prototype.isProcessing = function () {
        return this.processing;
    };
    ShoppingCartBaseComponentComponent.prototype.clearProcessing = function () {
        this.processing = false;
        this.loading = false;
        abp.ui.clearBusy();
    };
    ShoppingCartBaseComponentComponent.prototype.setLoading = function (element) {
        abp.ui.setBusy(element);
        this.loading = true;
    };
    ShoppingCartBaseComponentComponent.prototype.setProcessing = function () {
        this.processing = true;
    };
    // Gets markets based on cart items
    ShoppingCartBaseComponentComponent.prototype.getCartMarkets = function () {
        var markets = [];
        if (this.cart.items && this.cart.items.length > 0) {
            this.cart.items.forEach(function (item) {
                if (markets.indexOf(item.purchaseDecision.product.market) == -1) {
                    markets.push(item.purchaseDecision.product.market);
                }
            });
        }
        return markets;
    };
    ShoppingCartBaseComponentComponent.prototype.tenantAcountLoaded = function (tenantAccount) {
        var _this = this;
        this.tenantAccount = tenantAccount;
        if (result && tenantAccount.accountNumber && tenantAccount.accountNumber.length > 0) {
            var msg = this.l('SelectedTenantAccountMsg') + " " + tenantAccount.accountNumber;
            this.notify.info(msg);
            // Gets and sets the PaymentReference, the account is used as a prefix
            this.paymentsService
                .generatePaymentReference(tenantAccount.accountNumber, "", "", false, true)
                .pipe(finalize(function () {
                _this.shoppingCartFormService.setPaymentReference(_this.paymentReference);
                _this.shoppingCartFormService.setResellerAccountNumber(tenantAccount.accountNumber);
                _this.clearProcessing();
                _this.avaReferenceGenerated.emit(_this.paymentReference.avaReferenceNumber);
            }))
                .subscribe(function (paymentReference) {
                _this.paymentReference = paymentReference;
            });
        }
        else {
            this.notify.error(this.l('CannotFindTenantAccountMsg'));
            this.shoppingCartFormService.UpdateDisableCheckout(true, CartCheckoutDisable.TENANT_NOT_FOUND);
        }
    };
    // Gets the correct account based on the items in the cart
    ShoppingCartBaseComponentComponent.prototype.getAccountForCheckout = function () {
        var _this = this;
        if (this.shoppingCartFormService.getFormData().checkoutData.paymentMethod == PaymentTypes[PaymentTypes.AxizCDC]) {
            this.tenantsAppService.fetchTenantAccountByType(this.abpSession.tenantId, 1, this.cart.items)
                .subscribe(function (tenantAccount) { _this.tenantAcountLoaded(tenantAccount); });
        }
        else {
            this.tenantsAppService.getTenantAccountForCheckout(this.abpSession.tenantId, this.getCartMarkets())
                .subscribe(function (tenantAccount) { _this.tenantAcountLoaded(tenantAccount); });
        }
    };
    ShoppingCartBaseComponentComponent.prototype.getDealTotal = function () {
        var items = _.filter(this.cart.items, {
            purchaseDecision: {
                product: {
                    itemType: ProductItemType.Deal.valueOf(),
                }
            }
        });
        return _.sumBy(items, function (x) {
            return x.pricingSummary.sellPriceInc;
        });
    };
    ShoppingCartBaseComponentComponent.prototype.getTotal = function () {
        return this.cart.totalInc;
    };
    ShoppingCartBaseComponentComponent.prototype.haveDealInBasket = function () {
        var item = this.getItems().find(function (x) { return x.purchaseDecision.product.itemType == ProductItemType.Deal.valueOf(); });
        return item != null && item != undefined;
    };
    ShoppingCartBaseComponentComponent.prototype.onCartItemsUpdated = function (cartItem) {
        this.onCartItemsUpdatedEvent.emit(cartItem);
    };
    ShoppingCartBaseComponentComponent.prototype.skipDelivery = function () {
        return this.cartHasMicrosoftItems() || this.cartHasAdobeItems();
    };
    ShoppingCartBaseComponentComponent.initiated = false;
    return ShoppingCartBaseComponentComponent;
}(AppComponentBase));
export { ShoppingCartBaseComponentComponent };
