import { OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
var SessionExpiryDialogComponent = /** @class */ (function () {
    function SessionExpiryDialogComponent(activeModal, changeRef) {
        this.activeModal = activeModal;
        this.changeRef = changeRef;
        this.showCancel = false;
        this.confirmText = "Ok";
        this.cancelText = "Cancel";
        this.showRefreshHint = true;
    }
    SessionExpiryDialogComponent.prototype.ngOnInit = function () {
    };
    return SessionExpiryDialogComponent;
}());
export { SessionExpiryDialogComponent };
