/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-complete.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./Payment-complete.component";
import * as i3 from "../../../../shared/service-proxies/service-proxies";
import * as i4 from "@angular/router";
import * as i5 from "../../services/shopping-cart-form.service";
import * as i6 from "../../../shared/common/services/cart.data.service";
var styles_PaymentCompletedComponent = [i0.styles];
var RenderType_PaymentCompletedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentCompletedComponent, data: {} });
export { RenderType_PaymentCompletedComponent as RenderType_PaymentCompletedComponent };
export function View_PaymentCompletedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-12 m--margin-bottom-20"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Payment Complete!! "]))], null, null); }
export function View_PaymentCompletedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "payment-complete", [], null, null, null, View_PaymentCompletedComponent_0, RenderType_PaymentCompletedComponent)), i1.ɵdid(1, 114688, null, 0, i2.PaymentCompletedComponent, [i1.Injector, i3.CartServiceProxy, i4.Router, i5.ShoppingCartFormService, i6.CartDataService, i3.PaymentsServiceProxy, i3.TenantServiceProxy], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentCompletedComponentNgFactory = i1.ɵccf("payment-complete", i2.PaymentCompletedComponent, View_PaymentCompletedComponent_Host_0, { cart: "cart", checkoutData: "checkoutData" }, { cartLoaded: "cartLoaded", onCartItemsUpdatedEvent: "onCartItemsUpdatedEvent", avaReferenceGenerated: "avaReferenceGenerated" }, []);
export { PaymentCompletedComponentNgFactory as PaymentCompletedComponentNgFactory };
