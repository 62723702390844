import { AbstractControl, Validator } from '@angular/forms';
var PasswordComplexityValidator = /** @class */ (function () {
    function PasswordComplexityValidator() {
    }
    PasswordComplexityValidator.prototype.validate = function (control) {
        var givenPassword = control.value;
        var validationResult = null;
        var requireDigit = this.requireDigit;
        if (requireDigit && givenPassword && !/[0-9]/.test(givenPassword)) {
            validationResult = validationResult || {};
            validationResult.requireDigit = true;
        }
        var requireUppercase = this.requireUppercase;
        if (requireUppercase && givenPassword && !/[A-Z]/.test(givenPassword)) {
            validationResult = validationResult || {};
            validationResult.requireUppercase = true;
        }
        var requireLowercase = this.requireLowercase;
        if (requireLowercase && givenPassword && !/[a-z]/.test(givenPassword)) {
            validationResult = validationResult || {};
            validationResult.requireLowercase = true;
        }
        var requiredLength = this.requiredLength;
        if (requiredLength && givenPassword && givenPassword.length < requiredLength) {
            validationResult = validationResult || {};
            validationResult.requiredLength = true;
        }
        // use upperCaseLetters
        var requireNonAlphanumeric = this.requireNonAlphanumeric;
        if (requireNonAlphanumeric && givenPassword && /^[0-9a-zA-Z]+$/.test(givenPassword)) {
            validationResult = validationResult || {};
            validationResult.requireNonAlphanumeric = true;
        }
        return validationResult;
    };
    return PasswordComplexityValidator;
}());
export { PasswordComplexityValidator };
