import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponentBase } from '../generic-dialog-component-base';

@Component({
  selector: 'app-summary-dialog',
  templateUrl: './summary-dialog.component.html',
  styleUrls: ['./summary-dialog.component.less']
})
export class SummaryDialogComponent extends DialogComponentBase implements OnInit {

  constructor(public activeModal: NgbActiveModal) {
    super(activeModal);
   }


  ngOnInit() {
    this.init();
  }

}
