var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentsServiceProxy, CartServiceProxy, TenantServiceProxy } from '@shared/service-proxies/service-proxies';
import { CartDataService } from '../../../shared/common/services/cart.data.service';
import { ShoppingCartBaseComponentComponent } from '../../base/shopping-cart-base-component/shopping-cart-base.component';
import { ShoppingCartFormService } from '../../services/shopping-cart-form.service';
var PaymentCompletedComponent = /** @class */ (function (_super) {
    __extends(PaymentCompletedComponent, _super);
    function PaymentCompletedComponent(injector, cartService, router, shoppingCartFormService, cartDataService, paymentsService, tenantsAppService) {
        var _this = _super.call(this, injector, cartService, router, cartDataService, shoppingCartFormService, tenantsAppService, paymentsService) || this;
        _this.shoppingCartFormService = shoppingCartFormService;
        _this.cartDataService = cartDataService;
        _this.paymentsService = paymentsService;
        _this.tenantsAppService = tenantsAppService;
        return _this;
    }
    return PaymentCompletedComponent;
}(ShoppingCartBaseComponentComponent));
export { PaymentCompletedComponent };
