import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionExpiryDialogComponent } from '../components/session-expiry-dialog/session-expiry-dialog.component';
var DialogService = /** @class */ (function () {
    function DialogService(modalService) {
        this.modalService = modalService;
    }
    DialogService.prototype.confirm = function () {
        var modalRef = this.modalService.open(SessionExpiryDialogComponent);
        var instance = modalRef._windowCmptRef.instance;
        instance.windowClass = '';
        setTimeout(function () {
            instance.windowClass = 'custom-show';
        }, 0);
        var fx = modalRef._removeModalElements.bind(modalRef);
        modalRef._removeModalElements = function () {
            instance.windowClass = '';
            setTimeout(fx, 250);
        };
        modalRef.componentInstance.changeRef.markForCheck();
        return modalRef.result;
    };
    DialogService.prototype.open = function (title, message, showCancel, confirmText, cancelText, options, showRefreshHint) {
        if (showCancel === void 0) { showCancel = false; }
        if (confirmText === void 0) { confirmText = "Ok"; }
        if (cancelText === void 0) { cancelText = "Cancel"; }
        if (options === void 0) { options = { size: 'sm' }; }
        if (showRefreshHint === void 0) { showRefreshHint = true; }
        var modalRef = this.modalService.open(SessionExpiryDialogComponent, options);
        var instance = modalRef._windowCmptRef.instance;
        setTimeout(function () {
            instance.windowClass = 'custom-show';
        }, 0);
        var fx = modalRef._removeModalElements.bind(modalRef);
        modalRef._removeModalElements = function () {
            instance.windowClass = "";
            setTimeout(fx, 250);
        };
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.showCancel = showCancel;
        modalRef.componentInstance.confirmText = confirmText;
        modalRef.componentInstance.cancelText = cancelText;
        modalRef.componentInstance.showRefreshHint = showRefreshHint;
        modalRef.componentInstance.changeRef.markForCheck();
        return modalRef.result;
    };
    return DialogService;
}());
export { DialogService };
