import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var HeaderDataService = /** @class */ (function () {
    function HeaderDataService() {
        this.roeSource = new BehaviorSubject(null);
        this.currentRoe = this.roeSource.asObservable();
    }
    HeaderDataService.prototype.updateCurrentRoe = function (roeRate) {
        this.roeSource.next(roeRate);
    };
    HeaderDataService.ngInjectableDef = i0.defineInjectable({ factory: function HeaderDataService_Factory() { return new HeaderDataService(); }, token: HeaderDataService, providedIn: "root" });
    return HeaderDataService;
}());
export { HeaderDataService };
