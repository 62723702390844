import { Injectable, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription, timer, from } from 'rxjs';
import { IdleTimeoutService } from './idle-timeout.service';
import { DialogService } from './session-dialog.service';
import { TokenManagementService } from './token-management.service';

@Injectable({
  providedIn: 'root'
})
export class SessionManagementService {

  public _counter: number = 0;
  private _timer: Observable<number>;
  private _timerSubscription: Subscription;
  private _idleTimerSubscription: Subscription;
  private _notify_idleTimerSubscription: Subscription;
  private changeRef: ChangeDetectorRef;

  constructor(
    private idleTimeoutSvc: IdleTimeoutService,
    private dialogSvc: DialogService,
    private tokenSvc: TokenManagementService) {
  }

  public startCounter() {
    if (this.tokenSvc.isCurrentTokenValid() == false)
      return;
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
    }

    this._counter = 0;
    this._timer = timer(1000, 1000);
    this._timerSubscription = this._timer.subscribe(n => {
      this._counter++;
      this.changeRef.markForCheck();
    });
  }

  public reset() {
    this.startCounter();
    this.idleTimeoutSvc.resetTimer();
  }

  initialize(changeRef: ChangeDetectorRef) {
    if (this.tokenSvc.isCurrentTokenValid() == false)
      return;
    this.changeRef = changeRef;

    this.startCounter();
    this._notify_idleTimerSubscription = this.idleTimeoutSvc.notify_timeoutExpired.subscribe(res => {
      var modalPromise = this.dialogSvc.open("Session Expiring!", `Your session will expire in ${this.idleTimeoutSvc.minutesToNotifyFrom} minute(s). Do you need more time?`, true, "Yes", "No");
      var newObservable = from(modalPromise);
      newObservable.subscribe(
        (res) => {
          if (res === true) {
            console.log("Extending session...");
            this.tokenSvc.refreshToken().then(res => {
              this.idleTimeoutSvc.resetTimer();
              this.startCounter();
              this.changeRef.markForCheck();
            });
          } else {
            console.log("Not extending session...");
          }
        },
        (reason) => {
          console.log("Dismissed " + reason);
        }
      );
    });

    this._idleTimerSubscription = this.idleTimeoutSvc.timeoutExpired.subscribe(s => location.reload());
  }

  stop() {
    this._idleTimerSubscription.unsubscribe();
    this._notify_idleTimerSubscription.unsubscribe();
  }

  public onTokenExpired() {
    if (this.tokenSvc.isCurrentTokenValid() == false) {
      location.reload();
    }
  }
}
