/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-methods-banner.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./payment-methods-banner.component";
var styles_PaymentMethodsBannerComponent = [i0.styles];
var RenderType_PaymentMethodsBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentMethodsBannerComponent, data: {} });
export { RenderType_PaymentMethodsBannerComponent as RenderType_PaymentMethodsBannerComponent };
function View_PaymentMethodsBannerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "payment-img"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.link, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_PaymentMethodsBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "main-con d-flex flex-fill justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodsBannerComponent_2)), i1.ɵdid(2, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentMethods; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PaymentMethodsBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentMethodsBannerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.paymentMethods; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PaymentMethodsBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-methods-banner", [], null, null, null, View_PaymentMethodsBannerComponent_0, RenderType_PaymentMethodsBannerComponent)), i1.ɵdid(1, 114688, null, 0, i3.PaymentMethodsBannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentMethodsBannerComponentNgFactory = i1.ɵccf("app-payment-methods-banner", i3.PaymentMethodsBannerComponent, View_PaymentMethodsBannerComponent_Host_0, {}, {}, []);
export { PaymentMethodsBannerComponentNgFactory as PaymentMethodsBannerComponentNgFactory };
