import * as i0 from "@angular/core";
var UploadUtilsService = /** @class */ (function () {
    function UploadUtilsService() {
    }
    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    UploadUtilsService.prototype.formatBytes = function (bytes, decimals) {
        if (decimals === void 0) { decimals = 2; }
        if (bytes === 0) {
            return "0 Bytes";
        }
        var k = 1024;
        var dm = decimals <= 0 ? 0 : decimals;
        var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };
    UploadUtilsService.prototype.dataURItoBlob = function (dataURI) {
        // convert base64 to raw binary data held in a string
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to an ArrayBuffer
        var arrayBuffer = new ArrayBuffer(byteString.length);
        var _ia = new Uint8Array(arrayBuffer);
        for (var i = 0; i < byteString.length; i++) {
            _ia[i] = byteString.charCodeAt(i);
        }
        var dataView = new DataView(arrayBuffer);
        return new Blob([dataView], { type: mimeString });
    };
    UploadUtilsService.prototype.cleanImageName = function (name) {
        return name
            .replace(/\s/g, '')
            .replace(/[()]/g, "-");
    };
    UploadUtilsService.ngInjectableDef = i0.defineInjectable({ factory: function UploadUtilsService_Factory() { return new UploadUtilsService(); }, token: UploadUtilsService, providedIn: "root" });
    return UploadUtilsService;
}());
export { UploadUtilsService };
