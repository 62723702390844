var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbpHttpInterceptor, AbpHttpConfiguration } from '@abp/abpHttpInterceptor';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
var AxizHttpInterceptor = /** @class */ (function (_super) {
    __extends(AxizHttpInterceptor, _super);
    function AxizHttpInterceptor(configuration, route) {
        var _this = _super.call(this, configuration) || this;
        _this.route = route;
        return _this;
    }
    AxizHttpInterceptor.prototype.intercept = function (request, next) {
        //Check if a tenant is being managed , add the viewing tenant id to the header
        if (this.route.url.indexOf(AppConsts.manageTenantUrl) > -1) {
            //get id of tenant from url
            var index = this.route.url.lastIndexOf("/");
            var tenantId = this.route.url.substr(index + 1);
            //Add the viewing tenant id to the header
            var httpRequest = request.clone({
                headers: request.headers.set(AppConsts.customHeaders.viewingTenantID, tenantId)
            });
            return _super.prototype.intercept.call(this, httpRequest, next);
        }
        else {
            return _super.prototype.intercept.call(this, request, next);
        }
    };
    AxizHttpInterceptor.prototype.handleSuccessResponse = function (event, interceptObservable) {
        if (!event || !event.type) {
            _super.prototype.handleSuccessResponse.call(this, event, interceptObservable);
            return;
        }
        // When upload progress is relayed. Follow default angular behaviour
        if (event.type === HttpEventType.UploadProgress) {
            interceptObservable.next(event);
            return;
        }
        _super.prototype.handleSuccessResponse.call(this, event, interceptObservable);
    };
    return AxizHttpInterceptor;
}(AbpHttpInterceptor));
export { AxizHttpInterceptor };
