import { Component, OnInit, Injector, ViewChild, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { ServiceApplicationType, AppMarkets, EntityType, AppMarketsDesc, ServicesPricingMethodType, ServicePropertyMappingType } from '@shared/AppEnums';
import { ServiceDto, CreateOrUpdateServiceInput, DigitalServicesServiceProxy, ServiceMappingDto, ServicePricingVariableDto, ServicePropertyMappingDto, ServicePricingMethodDto, CustomerDto, CustomersServiceProxy, EntityTypeForServiceDto, ServiceEntityTypeLinkDto, PagedAndFilteredInputDto, NameValueDto, FindCustomersLookupInput } from '@shared/service-proxies/service-proxies';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forEach } from '@angular/router/src/utils/collection';
import { forkJoin } from 'rxjs';
@Component({
    selector: 'createOrEditServices',
    templateUrl: './create-edit-services.component.html',
    styleUrls: ['./create-edit-services.component.less']
})

export class CreateOrEditServiceComponent extends AppComponentBase implements OnInit {
    applicationMethod = null;
    selectedPricingMethod: any;
    servicePricingVariableDto = new ServicePricingVariableDto();
    service: ServiceDto;
    appMethodList: any;
    priceMethods: any;
    servicePropertyMappingList: any;
    filteredPriceMethods: any;
    warningMessage: string;
    marketEntityTypes: EntityTypeForServiceDto[];
    availableEntityTypes: EntityTypeForServiceDto[];
    selectedEntityTypes: EntityTypeForServiceDto[];
    multipleMappingSelected: boolean;
    allowMultiMapping: boolean;
    active: boolean = false;

    @Input()
    market: number;

    defaultEntityType: number = null;

    @Input()
    defaultPropertyMappingEntity: number;

    serviceItemId = 0;

    servicesPricingMethodTypes = ServicesPricingMethodType;
    serviceApplicationType = ServiceApplicationType;
    servicePropertyMappingType = ServicePropertyMappingType;
    originalService: ServiceDto;
    filteredCustomers: NameValueDto[];
    customerDetail: NameValueDto;
    sessionTenant: number = undefined;
    hasServicesLinked: boolean;

    constructor(injector: Injector,
        private route: ActivatedRoute,
        private _digitalProxy: DigitalServicesServiceProxy,
        private _customersServiceProxy: CustomersServiceProxy,
        private _router: Router) {
        super(injector);
    }

    ngOnInit() {
        this.market = this.route.snapshot.queryParams['market'];
        this.serviceItemId = this.route.snapshot.queryParams['id'];
        this.defaultEntityType = this.route.snapshot.queryParams['defaultEntityType'];
        this.appMethodList = AppConsts.ServiceApplicationTypeList;
        this.servicePropertyMappingList = AppConsts.ServicePropertyMappingTypeList;
        this.multipleMappingSelected = false;

        this.sessionTenant = abp.session.tenantId;

        abp.ui.setBusy()

        forkJoin([
            this._digitalProxy.getServiceEntityTypes(this.market, this.serviceItemId)
            , this._digitalProxy.getServicePricingMethod()])
            .pipe(finalize(() => abp.ui.clearBusy()))
            .subscribe(results => {
                this.marketEntityTypes = results[0];

                this.priceMethods = results[1];
                
                if (this.serviceItemId) {
                    this.loadService(this.serviceItemId);
                } else {
                    this.service = new ServiceDto();
                    this.service.serviceMappings = new Array<ServiceMappingDto>();
                    this.addServiceMapping();
                    this.active = true;
                }
            }, error => {
                abp.notify.error(error, 'Loading Failed');
            });
    }

    addServiceMapping() {
        this.service.serviceMappings.splice(0, 0, new ServiceMappingDto({
            id: 0,
            matchValue: null,
            criteria: null,
            serviceId: this.service.id,
            serviceEntityTypeId: null,
            servicePricingVariable: null,
            propertyId: null,
            property: null,
            creationTime: null,
            creatorUserId: null,
            deleterUserId: null,
            deletionTime: null,
            isDeleted: false,
            lastModificationTime: null,
            lastModifierUserId: null
        }));

        if (this.selectedPricingMethod) {
            this.service.serviceMappings[0].servicePricingVariable = this.createPricingVariable(this.selectedPricingMethod);
        }
        else {
            this.service.serviceMappings[0].servicePricingVariable = new Array<ServicePricingVariableDto>();
        }
    }

    updateSelection(event, serviceEntityType: EntityTypeForServiceDto) {

        if (event.target.checked) {
            //Check if mapping will be removed by removing this entity type link
            if (!serviceEntityType.allowPropertyMapping
                && (this.service.serviceMappings.some(mapping => mapping.isDeleted == false && mapping.serviceEntityTypeId && mapping.serviceEntityTypeId !== serviceEntityType.id)
                    || this.service.serviceMappings.filter(mapping => mapping.isDeleted == false).length > 1)) {

                abp.message.confirm("By adding " + serviceEntityType.name + "'s to this service you won't be able to setup multiple mapping values. This is because " + serviceEntityType.name
                    + "'s don't yet have predefined properties to map to. Currently there are existing multiple mapping values that will be deleted if you wish to continue. Do you want to continue?",
                    result => {
                        if (result) {

                            //delete existing mappings 
                            this.service.serviceMappings.forEach(obj => {
                                obj.isDeleted = true
                            })

                            //Add single value
                            this.addServiceMapping();

                            this.addEntityTypeFromService(serviceEntityType);

                            if (!serviceEntityType.allowPropertyMapping) this.toggleMappingValue(this.servicePropertyMappingType.SingleMapping);

                        }
                        else {
                            event.target.checked = !event.target.checked;
                        }
                    })
            }
            else {
                this.addEntityTypeFromService(serviceEntityType);

                if (!serviceEntityType.allowPropertyMapping) this.toggleMappingValue(this.servicePropertyMappingType.SingleMapping);
            }
        }
        else {

            //Check if mapping will be removed by removing this entity type link
            var hasExistingMapping = this.service.serviceMappings.some(mapping => serviceEntityType.id == mapping.serviceEntityTypeId && mapping.isDeleted == false);

            if (hasExistingMapping) {
                abp.message.confirm("There are currently mapping values setup for " + serviceEntityType.name + " remove this entity link will delete those mappings. Do you want to continue?",
                    result => {
                        if (result) {
                            this.removeEntityTypeFromService(serviceEntityType);
                        }
                        else {
                            event.target.checked = !event.target.checked;
                        }
                    })
            }
            else {
                this.removeEntityTypeFromService(serviceEntityType);
            }
        }
    }

    addEntityTypeFromService(serviceEntityType: EntityTypeForServiceDto) {

        if (!this.selectedEntityTypes) {
            this.selectedEntityTypes = new Array<EntityTypeForServiceDto>();
        }

        this.selectedEntityTypes.push(serviceEntityType);

        if (!this.service.serviceEntityTypeLinks) {
            this.service.serviceEntityTypeLinks = new Array<ServiceEntityTypeLinkDto>();
        }

        let existingItem = this.service.serviceEntityTypeLinks
            .find(obj => obj.serviceEntityTypeId == serviceEntityType.id);

        if (existingItem) {
            existingItem.isDeleted = false;
        }
        else {

            let selectedEntitytype = new ServiceEntityTypeLinkDto();

            selectedEntitytype.serviceId = this.serviceItemId;
            selectedEntitytype.serviceEntityTypeId = serviceEntityType.id;
            selectedEntitytype.market = this.market;

            this.service.serviceEntityTypeLinks.push(selectedEntitytype);
        }

        //if any of the service entity types doesn't allow property mapping then don't allow multiple mapping to be setup
        this.allowMultiMapping = !this.selectedEntityTypes.some(obj => !obj.allowPropertyMapping);
    }

    removeEntityTypeFromService(serviceEntityType: EntityTypeForServiceDto) {

        this.selectedEntityTypes = this.selectedEntityTypes.filter(obj => obj.id != serviceEntityType.id);

        let deleteItem = this.service.serviceEntityTypeLinks
            .find(obj => obj.isDeleted == false && obj.serviceEntityTypeId == serviceEntityType.id);

        if (deleteItem) {
            deleteItem.isDeleted = true;

            //remove mappings
            this.service.serviceMappings
                .filter(obj => deleteItem.serviceEntityTypeId == obj.serviceEntityTypeId)
                .forEach(obj => obj.isDeleted = true);
        }

        //if any of the service entity types doesn't allow property mapping then don't allow multiple mapping to be setup
        this.allowMultiMapping = !this.selectedEntityTypes.some(obj => !obj.allowPropertyMapping);
    }


    isChecked(serviceEntityType: EntityTypeForServiceDto) {
        if (!this.service.serviceEntityTypeLinks) return false;

        return this.service.serviceEntityTypeLinks.some(obj => obj.isDeleted == false && obj.serviceEntityTypeId === serviceEntityType.id)
    }

    cancel() {
        this._router.navigate(['app/admin/vendor/' + AppMarketsDesc[this.market].toLowerCase(), 'configure-digital-services']);
    }

    changeApplicationMethod(item) {
        if (!this.hasServicesLinked
            && (!this.selectedPricingMethod || this.selectedPricingMethod.id !== this.servicesPricingMethodTypes.CustomPrice)) {
            this.applicationMethod = item;
            this.service.applicationType = item.value;

            this.checkMultipleMappingSelected();
            this.updateServicePropertyMappings();
            this.adjustPricingMethodForAdhoc(item.value);
        }

        if (this.applicationMethod.value == this.serviceApplicationType.Custom) {
            this.availableEntityTypes = this.marketEntityTypes.filter(obj => obj.allowPropertyMapping);

            if (this.selectedEntityTypes) {
                //loop through the selected entity types and remove them if they are not in the list of available entity types
                this.selectedEntityTypes.forEach(obj => {
                    if (!this.availableEntityTypes.some(check => check.id == obj.id)) {
                        this.removeEntityTypeFromService(obj);
                    }
                });
            }

        }
        else {
            this.availableEntityTypes = this.marketEntityTypes;
        }
    }

    pricingMethodChanged(servicePricingMethod) {
        this.warningMessage = this.selectedPricingMethod.warning;

        this.service.serviceMappings.forEach(mapping => {

            //Update previous variables as deleted because they cant be used with another pricing method
            mapping.servicePricingVariable.forEach(variable => { variable.isDeleted = true });

            var deletedVariables = mapping.servicePricingVariable.filter(x => x.id > 0);
            var newVariables = this.createPricingVariable(servicePricingMethod, mapping.id);

            //New variables have to be first for forms to work correct , deleted variables have to get passed to api to get removed
            mapping.servicePricingVariable = newVariables.concat(deletedVariables);
        });
    }

    createPricingVariable(servicePricingMethod: ServicePricingMethodDto, serviceMappingId: number = 0): ServicePricingVariableDto[] {
        let array: any[] = [];
        servicePricingMethod.servicePricingMethodVariableTypeLinks.forEach(element => {
            let servicePricingVar = new ServicePricingVariableDto();
            servicePricingVar.servicePriceVariableType = element.servicePriceVariableType;
            servicePricingVar.servicePriceVariableTypeId = element.servicePriceVariableTypeId;
            servicePricingVar.serviceMappingId = serviceMappingId;
            array.push(servicePricingVar);
        });
        return array;
    }

    save() {

        if (this.service.serviceMappings.length === 0 && this.applicationMethod.value == this.serviceApplicationType.Custom) {
            abp.message.error('Please add mappings before saving');
            return;
        }

        if (this.service.serviceEntityTypeLinks.length === 0) {
            abp.message.error('Please selected the types linked to this service before saving');
            return;
        }

        //remove mapping that have been deleted on the front end and dont need to be deleted in the database
        this.service.serviceMappings = this.service.serviceMappings.filter(obj => !obj.isDeleted || obj.id > 0)

        let saveService = new CreateOrUpdateServiceInput({ service: this.service });
        saveService.service.market = this.market;

        if (abp.session.tenantId && this.customerDetail) {
            saveService.service.customerId = parseInt(this.customerDetail.value);
        }

        saveService.service.servicePricingMethodId = this.selectedPricingMethod.id;

        this._digitalProxy.createOrUpdateService(saveService)
            .subscribe(success => {
                abp.notify.success(this.serviceItemId ? 'The service was updated' : 'New service added successfully', 'Service');
                this._router.navigate(['app/admin/vendor/' + AppMarketsDesc[this.market].toLowerCase(), 'configure-digital-services']);
            }, error => {
                abp.notify.error('There was an error while saving this service : ' + error, 'Saving Error');
            });
    }

    checkMultipleMappingSelected() {
        this.multipleMappingSelected = this.service.serviceMappings.some(obj => !obj.isDeleted && (obj.criteria != null || obj.serviceEntityTypeId != null || obj.propertyId != null));
    }

    loadService(serviceItemId) {
        abp.ui.setBusy();
        this._digitalProxy.getServiceById(serviceItemId)
            .pipe(finalize(() => { abp.ui.clearBusy(); }))
            .subscribe(item => {
                this.service = item.services;
                this.originalService = item.services;
                this.applicationMethod = this.appMethodList.find(x => x.value === this.service.applicationType);

                this.changeApplicationMethod(this.applicationMethod);

                
                this.updateServicePropertyMappings();
                
                this.selectedEntityTypes = new Array<EntityTypeForServiceDto>();

                this.availableEntityTypes.forEach(entityType => {

                    if (this.service.serviceEntityTypeLinks.findIndex(obj => obj.serviceEntityTypeId == entityType.id) > -1) {
                        this.selectedEntityTypes.push(entityType);
                    }
                });

                //if any of the service entity types doesn't allow property mapping then don't allow multiple mapping to be setup
                this.allowMultiMapping = !this.selectedEntityTypes.some(obj => !obj.allowPropertyMapping);

                this.adjustPricingMethodForAdhoc(this.applicationMethod.value);
                this.selectedPricingMethod = this.priceMethods.find(x => x.id === this.service.servicePricingMethodId);
                this.warningMessage = this.selectedPricingMethod.warning;
                this.hasServicesLinked = this.originalService.hasServicesLinked;

                if (item.services.customerId) {
                    this.getCustomer(item.services.customerId);
                }    

                this.active = true;
            }, error => {
                abp.notify.error('Error collecting data for service id :' + serviceItemId, 'Error Collecting Data');
            });
    }

    adjustPricingMethodForAdhoc(applicationMethod) {
        if (applicationMethod != null) {
            switch (applicationMethod) {
                case ServiceApplicationType.AdHoc:
                    this.filteredPriceMethods = this.priceMethods;            
                break;
            default:
                    this.filteredPriceMethods = this.priceMethods.filter(x => x.id !== this.servicesPricingMethodTypes.CustomPrice);
                break;
            }
        }
    }

    compareFn(optionOne, optionTwo): boolean {
        return optionOne === optionTwo;
    }

    getCustomer(customerId) {
        this._customersServiceProxy
            .getCustomerById(customerId)
            .pipe(finalize(() => { abp.ui.clearBusy(); }))
            .subscribe(customerResult => {
                this.customerDetail = new NameValueDto({
                    name: customerResult.customer.name,
                    value: customerResult.customer.id.toString()
                });
            });
    }

    filterCustomerSingle(event): void {
        this._customersServiceProxy.findCustomersLookup(new FindCustomersLookupInput({
            filter: event.query,
            skipCount: 0,
            maxResultCount: 30,
            tenantId: this.sessionTenant
        }))
        .subscribe(customers => {
            this.filteredCustomers = customers.items;
        });
    }

    updateServiceMappings(data) {
        this.service.serviceMappings = data;
    }

    getButtonGroupDataToggle() {
        if (this.allowMultiMapping) {
            return 'buttons';
        }
        else {
            return '';
        }
    } 

    propertyMappingChange(event: any, type: string) {  

        if (!this.allowMultiMapping) return;

        this.checkMultipleMappingSelected();

        if (type === AppConsts.SingleMapping && this.multipleMappingSelected) {

            abp.message.confirm("You currently have multiple values mapped, by selecting single mapping the mappings currently setup will be deleted do you want to continue?",
                result => {
                    if (result) {

                        //remove mappings
                        this.service.serviceMappings
                            .forEach(obj => obj.isDeleted = true);

                        //Add single value
                        this.addServiceMapping();

                        this.toggleMappingValue(type);
                    }
                    else {
                        event.target.classList.remove("active");
                        event.target.nextSibling.classList.add("active");
                    }
                });
        }
        else {
            this.toggleMappingValue(type);
        }
    }

    updateServicePropertyMappings() {

        var selectedType = ServicePropertyMappingType.SingleMapping;

        if (this.multipleMappingSelected == true) {
            selectedType = ServicePropertyMappingType.MultipleMapping
        }

        this.servicePropertyMappingList.forEach(element => {
            if (element.value === selectedType) {
                element.isActive = true
            }
            else {
                element.isActive = false;
            }
        });

    }

    toggleMappingValue(type: string) {

        if (type === AppConsts.MultipleMapping) {
            this.multipleMappingSelected = true;
        }
        else {
            this.multipleMappingSelected = false;
        }

        this.updateServicePropertyMappings();

        this.adjustPricingMethodForAdhoc(this.applicationMethod.value);  
    }

}

