export enum DialogType {
    Detail = 1,
    Summary = 2
  }
  
  export enum DialogSeverity {
    Info = 0,
    Question = 1,
    Warn = 2,
    Danger = 3
  }
  
  export class DialogOptions {
  
    constructor(title: string,
      message: string,
      showCancel = true,
      confirmText = "Ok",
      cancelText = "Cancel",
      dialogType: DialogType = DialogType.Summary,
      iconClass = '',
      severity: DialogSeverity = DialogSeverity.Info) {
  
      this.title = title;
      this.message = message;
      this.showCancel = showCancel;
      this.confirmText = confirmText;
      this.cancelText = cancelText;
      this.dialogType = dialogType;
      this.iconClass = iconClass;
      this.dialogSeverity = severity;
    }
  
    title: string;
    message: string;
    showCancel: boolean;
    confirmText: string;
    cancelText: string;
    dialogType: DialogType;
    iconClass: string;
    dialogSeverity: DialogSeverity;
  }