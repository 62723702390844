import { AppConsts } from '@shared/AppConsts';
import * as i0 from "@angular/core";
//custom attributes
var has = Object.prototype.hasOwnProperty;
var GAServiceService = /** @class */ (function () {
    function GAServiceService() {
    }
    GAServiceService.prototype.loadGoogleAnalytics = function (trackingID) {
        // register google tag manager
        var gTagManagerScript = document.createElement('script');
        gTagManagerScript.async = true;
        gTagManagerScript.src = AppConsts.GoogleAnalyticsSource + trackingID;
        document.head.appendChild(gTagManagerScript);
        // register google analytics
        var gaScript = document.createElement('script');
        gaScript.innerHTML = "\n        window.dataLayer = window.dataLayer || [];\n        function gtag() { dataLayer.push(arguments); }\n        gtag('js', new Date());\n        gtag('config', '" + trackingID + "');\n        ";
        document.head.appendChild(gaScript);
    };
    //For custom elements
    GAServiceService.prototype.loadCustomEvent = function (eventCategory, eventAction, eventLabel) {
        var gaScript = document.createElement('script');
        gaScript.innerHTML = "\n        window.dataLayer = window.dataLayer || [];\n        function gtag() { dataLayer.push(arguments); }\n        gtag('eventCategory', '" + eventCategory + "');\n        gtag('eventAction', '" + eventAction + "');\n        gtag('eventLabel', '" + eventLabel + "');\n        ";
        document.head.appendChild(gaScript);
    };
    GAServiceService.prototype.logPageView = function (url) {
        if (has.call(window, "dataLayer")) {
            gtag('event', 'page_view', {
                page: url
            });
        }
    };
    GAServiceService.prototype.sendEvent = function (eventName, eventParams) {
        gtag('event', eventName, eventParams);
    };
    GAServiceService.ngInjectableDef = i0.defineInjectable({ factory: function GAServiceService_Factory() { return new GAServiceService(); }, token: GAServiceService, providedIn: "root" });
    return GAServiceService;
}());
export { GAServiceService };
