import { AppCartSteps } from '@app/cart/models/shopping-cart-steps';
import * as i0 from "@angular/core";
var ShoppingCartWorkflowService = /** @class */ (function () {
    function ShoppingCartWorkflowService() {
        this.workflow = [
            { step: AppCartSteps.items, valid: false },
            { step: AppCartSteps.checkout, valid: false },
            { step: AppCartSteps.payment, valid: false },
            { step: AppCartSteps.finish, valid: false }
        ];
    }
    /**
     * Run the the steps and get the first item that matches the past step
     * @param step
     */
    ShoppingCartWorkflowService.prototype.getWorkflowItemByStep = function (step) {
        for (var i = 0; i < this.workflow.length; i++) {
            if (this.workflow[i].step === step) {
                return this.workflow[i];
            }
        }
    };
    /**
     * If the state is found, set the valid field to true.
     * @param step target Step
     */
    ShoppingCartWorkflowService.prototype.validateStep = function (step) {
        var workflow = this.getWorkflowItemByStep(step);
        if (workflow) {
            workflow.valid = true;
        }
    };
    /**
     * If the state is found, set the valid field to false.
     * @param step target Step
     */
    ShoppingCartWorkflowService.prototype.invalidateStep = function (step) {
        var workflow = this.getWorkflowItemByStep(step);
        if (workflow) {
            workflow.valid = false;
        }
    };
    /**
     * If the state is found, set the valid field to true.
     * @param step target Step
     */
    ShoppingCartWorkflowService.prototype.isStepValid = function (step) {
        var workflow = this.getWorkflowItemByStep(step);
        return (workflow && workflow.valid) || false;
    };
    /**
     *  Reset all the steps in the Workflow to be invalid
     */
    ShoppingCartWorkflowService.prototype.resetSteps = function () {
        this.workflow.forEach(function (element) {
            element.valid = false;
        });
    };
    /**
     * If all the previous steps are validated, return blank
     * Otherwise, return the first invalid step
     * @param step target Step
     */
    ShoppingCartWorkflowService.prototype.getFirstInvalidStep = function (step) {
        var found = false;
        var valid = true;
        var redirectToStep = '';
        for (var i = 0; i < this.workflow.length && !found && valid; i++) {
            var item = this.workflow[i];
            if (item.step === step) {
                found = true;
                redirectToStep = '';
            }
            else {
                valid = item.valid;
                redirectToStep = item.step;
            }
        }
        return redirectToStep;
    };
    ShoppingCartWorkflowService.ngInjectableDef = i0.defineInjectable({ factory: function ShoppingCartWorkflowService_Factory() { return new ShoppingCartWorkflowService(); }, token: ShoppingCartWorkflowService, providedIn: "root" });
    return ShoppingCartWorkflowService;
}());
export { ShoppingCartWorkflowService };
