import {Injectable} from '@angular/core';
import {AppConsts} from '@shared/AppConsts';
//custom attributes
const has = Object.prototype.hasOwnProperty;

@Injectable({
    providedIn: "root"
})
export class GAServiceService {

    loadGoogleAnalytics(trackingID: string) {
        // register google tag manager
        const gTagManagerScript = document.createElement('script');
        gTagManagerScript.async = true;
        gTagManagerScript.src = AppConsts.GoogleAnalyticsSource + trackingID;
        document.head.appendChild(gTagManagerScript);

        // register google analytics
        let gaScript = document.createElement('script');
        gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${trackingID}');
        `;
        document.head.appendChild(gaScript);
    }

    //For custom elements
    loadCustomEvent(
        eventCategory: string,
        eventAction: string,
        eventLabel?: string) {
        let gaScript = document.createElement('script');
        gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('eventCategory', '${eventCategory}');
        gtag('eventAction', '${eventAction}');
        gtag('eventLabel', '${eventLabel}');
        `;
        document.head.appendChild(gaScript);
    }

    logPageView(url: string) {
        if (has.call(window, "dataLayer")) {
            gtag('event', 'page_view', {
                page: url
            })
        }
    }

    sendEvent(eventName: string, eventParams: {}) {
        gtag('event', eventName, eventParams)
    }
}