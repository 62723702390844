import { Injectable } from '@angular/core';
import {
  ShoppingCartFormDataDto,
  CheckoutDataDto
} from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { cloneShoppingCartFormDataDto } from '@shared/service-proxies/service-proxies-extension';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartFormLocalStorageService {
  private stateKey = 'CartForm_V3';

  constructor() {}

  getFormData(): ShoppingCartFormDataDto {
    let storedFormData = localStorage.getItem(this.stateKey);

    if (storedFormData === null) {
      // This use to be handled in the constructor, but since we moved to the black magic that is ASPNetZero we will have to do things differently
      let newFormData = new ShoppingCartFormDataDto();
      newFormData.checkoutData = new CheckoutDataDto();
      //newFormData.checkoutData.ibmCheckoutData = new IbmCheckoutData();

      return newFormData;
    }

    const formData = <ShoppingCartFormDataDto>JSON.parse(storedFormData);

    // Handle complex objects
    if (formData &&
        formData.checkoutData
        //&& formData.checkoutData.ibmCheckoutData &&
        //formData.checkoutData.ibmCheckoutData.licencing &&
        //typeof formData.checkoutData.ibmCheckoutData.licencing.captured_dateTime === 'string'
        ) {

        //formData.checkoutData.ibmCheckoutData.licencing.captured_dateTime = moment(formData.checkoutData.ibmCheckoutData.licencing.captured_dateTime);
    }

    return cloneShoppingCartFormDataDto(formData); // We clone the object, but this is here for fixing the typings actually
  }

    saveFormData(formData: ShoppingCartFormDataDto) { 
    localStorage.setItem(this.stateKey, JSON.stringify(formData));
  }

  resetFormData() {
    localStorage.removeItem(this.stateKey);
  }
}
