import { AppSessionService } from "@shared/common/session/app-session.service";
import { AppMenuItem, MenuItemUsers } from "../app-menu-item";
import { AppNavigationService } from "../app-navigation.service";
import { AppComponentBase } from "@shared/common/app-component-base";
import { Injector } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";

export abstract class TopMenuBase extends AppComponentBase {
    constructor(private appSessionService: AppSessionService,
        private appNavigationService: AppNavigationService, injector: Injector) {
        super(injector)

    }

    userId = abp.session.userId;
    tenantId = abp.session.tenantId;

    isLoggedIn = this.userId != null && this.userId > 0;
    isPartner = this.isLoggedIn && this.tenantId > 0;
    isHost = this.isLoggedIn && !this.isPartner;
    customDomain = AppConsts.customDomain;
    
    canViewCloud = this.feature.isEnabled('App.WhiteLabeling.Cloud') && !this.customDomain;
    hasAxizContentFeature = this.feature.isEnabled("App.WhiteLabeling.AxizContent") && !this.customDomain;
    whitelabeling = this.feature.isEnabled("App.WhiteLabeling") && this.customDomain;
    showAxizTopMenuLinks =  this.feature.isEnabled("App.WhiteLabeling.ShowAxizTopMenuLinks") && !this.customDomain;
    
    showMenuItem(menuItem: AppMenuItem): boolean {
        if (!menuItem.users || menuItem.users.length == 0)
            return this.hasPermission(menuItem);

        if(this.isHost && menuItem.users.indexOf(MenuItemUsers.Host) > -1)
            return true;

       if (!this.isLoggedIn && menuItem.users.indexOf(MenuItemUsers.Guest) > -1 && !this.canViewCloud && menuItem.name == "Cloud")
            return false; 


        if ((menuItem.name == "More About Us" || menuItem.name == "What We Do" || menuItem.name == "Our Vendor Partners"  || menuItem.name == "Get In Touch") && this.showAxizTopMenuLinks)
        return true;

        if (!this.isLoggedIn && menuItem.users.indexOf(MenuItemUsers.Guest) > -1)
            return true;

        var currentUserType = this.getUserType();

        if (menuItem.users.indexOf(currentUserType) > -1)
            return this.hasPermission(menuItem);

        return false;
    }

    getUserType(): MenuItemUsers {
        if (!this.isLoggedIn)
            return MenuItemUsers.Guest;

        if (this.isHost)
            return MenuItemUsers.Host;

        if (this.isPartner)
            return MenuItemUsers.Tenant;

        return null;
    }

    hasPermission(menuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this.appSessionService.tenant && !this.appSessionService.tenant.edition) {
            return false;
        }

        if (menuItem.orPermissionNames && menuItem.orPermissionNames.length > 0){
            for(let key in menuItem.orPermissionNames){
                if(this.permission.isGranted(menuItem.orPermissionNames[key])){
                    return true;
                }
            }
            return false;
        }
        
        if (menuItem.permissionName) {
            return this.permission.isGranted(menuItem.permissionName);
        }

        if (this.hasChildren(menuItem)) {
            return this.appNavigationService.checkChildMenuItemPermission(menuItem);
        }

        return true;
    }

    getDisplayableChildren(menuItem: AppMenuItem): AppMenuItem[] {
        return menuItem.items.filter(m => this.showMenuItem(m));
    }

    hasChildren(menuItem: AppMenuItem): boolean {
        return menuItem.items && menuItem.items.length > 0 && this.getDisplayableChildren(menuItem).length > 0;
    }

    getFullActionLink(actionLink: string) { 
        if (actionLink.startsWith('#')) {
            let url = window.location.href;

            if (url.indexOf('#') > -1) {
                url = url.substr(0, url.indexOf('#'));
            }
            actionLink = `${url}${actionLink}`
        }
        return actionLink;
    }

    isImageLink(menuItem: AppMenuItem): boolean {
        if (menuItem.imageLink && menuItem.imageLink.defaultImageSrc)
            return true;

        return false;
    }

    isActionLink(menuItem: AppMenuItem): boolean {
        if (!this.isImageLink(menuItem) && (menuItem.route || menuItem.actionLink))
            return true;

        return false;
    }

    isParentLink(menuItem: AppMenuItem): boolean {
        return menuItem && menuItem.items && this.getDisplayableChildren(menuItem).length > 0;
    }
}