import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.CustomerAddressesServiceProxy,
        ApiServiceProxies.CustomersServiceProxy,
        ApiServiceProxies.ContactPersonServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.DigitalServicesServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoicesServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.CartServiceProxy,
        ApiServiceProxies.JourneyServiceProxy,
        ApiServiceProxies.VendorServiceProxy,
        ApiServiceProxies.VendorDocumentServiceProxy,
        ApiServiceProxies.EmailQueueServiceProxy,
        ApiServiceProxies.TenantCreditServiceProxy,
        ApiServiceProxies.NotificationCenterServiceProxy,
        ApiServiceProxies.ResellerJourneyServiceProxy,
        ApiServiceProxies.DocumentsServiceProxy,
        ApiServiceProxies.PriceListServiceProxy,
        ApiServiceProxies.ParcelTrackingServiceProxy, 
        ApiServiceProxies.OrderServiceProxy,
        ApiServiceProxies.ProductsServiceProxy,
        ApiServiceProxies.RateOfExchangeServiceProxy,
        ApiServiceProxies.MarketAccountServiceProxy,
        ApiServiceProxies.MarketUserServiceProxy,
        ApiServiceProxies.SubscriptionsServiceProxy,
        ApiServiceProxies.CurrencyServiceProxy,
        ApiServiceProxies.TenantAddressesServiceProxy,
        ApiServiceProxies.ReportingServiceProxy,
        ApiServiceProxies.MarketAuthServiceProxy,
        ApiServiceProxies.TaggingServiceProxy,
        ApiServiceProxies.PriceListDocumentServiceProxy,
        ApiServiceProxies.SalesOrderServiceProxy,
        ApiServiceProxies.SalesRequestServiceProxy,
        ApiServiceProxies.CourierServiceProxy,
        ApiServiceProxies.DistributionServiceProxy,
        ApiServiceProxies.IdentityServiceProxy,
        ApiServiceProxies.PricingRuleServiceProxy,
        ApiServiceProxies.DealsServiceProxy,
        ApiServiceProxies.AccountInfoServiceProxy,
        ApiServiceProxies.TermsAndConditionsServiceProxy,
        ApiServiceProxies.ViewServiceProxy,
        ApiServiceProxies.PaymentsServiceProxy,
        ApiServiceProxies.OrderDocumentsServiceProxy,
        ApiServiceProxies.LandingSettingsServiceProxy,
        ApiServiceProxies.BannerImagesServiceProxy,
        ApiServiceProxies.TrackingServiceProxy,
        ApiServiceProxies.TagPromoServiceProxy,
        ApiServiceProxies.OnboardingServiceProxy,
        ApiServiceProxies.TocServiceProxy,
        ApiServiceProxies.CustomerMigrateServiceProxy,
        ApiServiceProxies.BulkImportServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
