export class MenuItemImageLink {
    defaultImageSrc: string = '';
    alternateImageSrc?: string = ''
    alternateImages: boolean = this.alternativeImageExist();

    constructor(defaultImgSrc: string, alternateImgSrc?: string, alternate: boolean = true) {
        this.defaultImageSrc = defaultImgSrc;
        this.alternateImageSrc = alternateImgSrc;
        this.alternateImages = this.alternativeImageExist() && alternate;
    }

    alternativeImageExist() : boolean { return this.alternateImageSrc != null && this.alternateImageSrc != undefined && this.alternateImageSrc != ''; }
}

export enum MenuItemUsers {
    Guest = 0,
    Host = 1,
    Tenant = 2,
    CustomGuest = 3
}

export interface IAppMenuItem{
    name: string;
    permissionName?: string;
    orPermissionNames?: string[];
    featureName?: string;
    icon?: string;
    route?: string;
    items?: AppMenuItem[];
    users?: MenuItemUsers[];
    impersonatorPermission?: string;
    imageLink?: MenuItemImageLink;
    actionLink?: string;
    subSection?: string;
    marketingImage?: string;
    paramName?: string;
    paramValue?: string;    
}

export class AppMenuItem implements IAppMenuItem {
    name = '';
    permissionName = '';
    orPermissionNames: string[] = [];
    featureName = '';
    icon = '';
    route = '';
    items: AppMenuItem[] = [];
    users: MenuItemUsers[] = [];
    impersonatorPermission = '';
    imageLink?: MenuItemImageLink = new MenuItemImageLink('');
    actionLink?: string = null;
    subSection?: string = null;
    marketingImage?: string = null;
    paramName?: string = null;
    paramValue?: string = null;

    constructor(data: IAppMenuItem){
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}

