import { timer } from "rxjs";
import { AppConsts } from "@shared/AppConsts";
import { AppAuthService } from "@app/shared/common/auth/app-auth.service";
import { AbpSessionService } from "@abp/session/abp-session.service";
import * as i0 from "@angular/core";
import * as i1 from "../../app/shared/common/auth/app-auth.service";
import * as i2 from "abp-ng2-module/dist/src/session/abp-session.service";
var IdleCheckerService = /** @class */ (function () {
    function IdleCheckerService(_authService, _abpSessionService) {
        this._authService = _authService;
        this._abpSessionService = _abpSessionService;
        this.interactionKey = "LIT";
    }
    IdleCheckerService.prototype.startChecking = function () {
        var _this = this;
        //user is not login
        if (this._abpSessionService.userId == null || this._abpSessionService.userId <= 0) {
            return;
        }
        this.idleTimeMilliSeconds = AppConsts.idleTimeMinutes * 60 * 1000;
        console.log(this.idleTimeMilliSeconds);
        //check if site is opened again after idle time has expired
        if (localStorage.getItem(this.interactionKey)) {
            this.CheckStatus();
        }
        else {
            this.interaction();
        }
        timer(0, 60000)
            .subscribe(function (_) {
            _this.CheckStatus();
        });
    };
    IdleCheckerService.prototype.interaction = function () {
        //use local storage, to communicate across tabs, as one tab can time out while the other is in use.
        localStorage.setItem(this.interactionKey, Date.now().valueOf() + '');
    };
    IdleCheckerService.prototype.CheckStatus = function () {
        if (!this.idleTimeMilliSeconds) {
            return;
        }
        var interactionDate = localStorage.getItem(this.interactionKey);
        if (!interactionDate) {
            return;
        }
        if ((Date.now().valueOf() - this.idleTimeMilliSeconds) >
            parseInt(interactionDate)) {
            localStorage.removeItem(this.interactionKey);
            this._authService.logout();
        }
    };
    IdleCheckerService.ngInjectableDef = i0.defineInjectable({ factory: function IdleCheckerService_Factory() { return new IdleCheckerService(i0.inject(i1.AppAuthService), i0.inject(i2.AbpSessionService)); }, token: IdleCheckerService, providedIn: "root" });
    return IdleCheckerService;
}());
export { IdleCheckerService };
