var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector, EventEmitter } from '@angular/core';
import { ServiceMappingDto, DigitalServicesServiceProxy, ServiceDto, ServicePricingVariableDto, ServicePricingMethodDto, EntityTypeForServiceDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { AppMarkets } from '@shared/AppEnums';
var AddCustomPropertiesComponent = /** @class */ (function (_super) {
    __extends(AddCustomPropertiesComponent, _super);
    function AddCustomPropertiesComponent(injector, _digitalProxy) {
        var _this = _super.call(this, injector) || this;
        _this._digitalProxy = _digitalProxy;
        _this.onMappingRemoved = new EventEmitter();
        _this.currentMapping = [];
        _this.serviceCriteriaTypes = AppConsts.ServiceCriteriaTypeList;
        _this.createService = false;
        return _this;
    }
    AddCustomPropertiesComponent.prototype.ngOnInit = function () {
        this.serviceCriteria = this.serviceCriteriaTypes.find(function (serviceCriteria) { return serviceCriteria.value === 1; });
    };
    AddCustomPropertiesComponent.prototype.getMappings = function () {
        return this.serviceMapping.filter(function (x) { return x.isDeleted != true; });
    };
    AddCustomPropertiesComponent.prototype.canDelete = function () {
        return this.serviceMapping.length > 1;
    };
    AddCustomPropertiesComponent.prototype.copyPropertyMapping = function (mapping) {
        var newMappingObj = new ServiceMappingDto({
            id: 0,
            matchValue: mapping.matchValue,
            criteria: mapping.criteria,
            serviceEntityTypeId: mapping.serviceEntityTypeId,
            serviceId: this.service.id,
            servicePricingVariable: this.copyPricingVariable(mapping),
            propertyId: mapping.propertyId,
            property: null,
            creationTime: null,
            creatorUserId: null,
            deleterUserId: null,
            deletionTime: null,
            isDeleted: false,
            lastModificationTime: null,
            lastModifierUserId: null,
        });
        this.serviceMapping.push(newMappingObj);
    };
    AddCustomPropertiesComponent.prototype.copyPricingVariable = function (mapping) {
        var copyServicePricingVariable = [];
        mapping.servicePricingVariable.forEach(function (map) {
            var newVariableObj = new ServicePricingVariableDto({
                id: 0,
                serviceMappingId: 0,
                servicePriceVariableType: map.servicePriceVariableType,
                servicePriceVariableTypeId: map.servicePriceVariableTypeId,
                value: map.value,
                creationTime: null,
                creatorUserId: null,
                deleterUserId: null,
                deletionTime: null,
                isDeleted: false,
                lastModificationTime: null,
                lastModifierUserId: null,
            });
            copyServicePricingVariable.push(newVariableObj);
        });
        return copyServicePricingVariable;
    };
    AddCustomPropertiesComponent.prototype.removeMapping = function (serviceMaping) {
        this.message.confirm('Are you sure you want to delete this', function (isConfirmed) {
            if (isConfirmed) {
                serviceMaping.isDeleted = true;
            }
        });
    };
    AddCustomPropertiesComponent.prototype.updateServiceAndMapping = function (serviceId) {
        var _this = this;
        this._digitalProxy.getServiceById(serviceId)
            .subscribe(function (element) {
            _this.serviceMapping = element.services.serviceMappings;
            _this.service = element.services;
            _this.serviceMapping = element.services.serviceMappings;
            //update parent control mappings
            _this.onMappingRemoved.emit(_this.serviceMapping);
        }, function (error) {
            abp.notify.error('Error:' + error, 'Error Removing Item');
        });
    };
    AddCustomPropertiesComponent.prototype.getProperties = function (mapping) {
        if (!mapping
            || !this.entityTypes
            || (this.entityTypes.length > 1 && !mapping.serviceEntityTypeId))
            return new Array();
        if (!mapping.serviceEntityTypeId && this.entityTypes.length == 1) {
            mapping.serviceEntityTypeId = this.entityTypes[0].id;
        }
        return this.entityTypes.find(function (obj) { return obj.id == mapping.serviceEntityTypeId; }).propertyMappings;
    };
    AddCustomPropertiesComponent.prototype.pushMapping = function (serviceMappings) {
        this.serviceMapping.push(new ServiceMappingDto({
            id: 0,
            matchValue: null,
            criteria: 1,
            serviceId: this.service.id,
            serviceEntityTypeId: this.entityTypes.length == 1 ? this.entityTypes[0].id : null,
            servicePricingVariable: this.createPricingVariable(this.selectedPricingMethod),
            propertyId: 1,
            property: null,
            creationTime: null,
            creatorUserId: null,
            deleterUserId: null,
            deletionTime: null,
            isDeleted: false,
            lastModificationTime: null,
            lastModifierUserId: null,
        }));
    };
    AddCustomPropertiesComponent.prototype.createPricingVariable = function (servicePricingMethod) {
        var array = [];
        servicePricingMethod.servicePricingMethodVariableTypeLinks.forEach(function (element) {
            var servicePricingVar = new ServicePricingVariableDto();
            servicePricingVar.servicePriceVariableType = element.servicePriceVariableType;
            servicePricingVar.servicePriceVariableTypeId = element.servicePriceVariableTypeId;
            array.push(servicePricingVar);
        });
        return array;
    };
    AddCustomPropertiesComponent.prototype.compareFn = function (optionOne, optionTwo) {
        return optionOne === optionTwo;
    };
    AddCustomPropertiesComponent.prototype.checkShowButton = function (mapping) {
        if (!mapping.id || mapping.id === 0) {
            return false;
        }
        else {
            return true;
        }
    };
    return AddCustomPropertiesComponent;
}(AppComponentBase));
export { AddCustomPropertiesComponent };
