﻿import { Injectable } from '@angular/core';
import { AvaCartDto } from '@shared/service-proxies/service-proxies';
import { BehaviorSubject } from 'rxjs';

@Injectable(({
    providedIn: 'root'
}))
export class CartDataService {
    private cartSource = new BehaviorSubject<AvaCartDto>(null);
    currentCart = this.cartSource.asObservable();

    updateCurrentCart(cart: AvaCartDto) { 
        this.cartSource.next(cart);
    }

    private cartRefresh = new BehaviorSubject<AvaCartDto>(null);
    currentCartRefresh = this.cartRefresh.asObservable();

    refreshCurrentCart(cart: AvaCartDto) {
        this.cartRefresh.next(cart);
    }
}