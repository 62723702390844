import {
  ShoppingCartFormDataDto,
  CheckoutDataDto } from '@shared/service-proxies/service-proxies';

export interface IInlineDtoEdit {
  editing: boolean;
  isNew: boolean;
}

interface NoParamConstructor<T> {
  new (): T;
}

export class InlineDtoEdit implements IInlineDtoEdit {
  editing: boolean;
  isNew: boolean;
  isEdited: boolean;
  isMarkedForDelete: boolean;
}

export class InlineCreateOrEditDto<T> implements IInlineDtoEdit {
  editing: boolean;
  isNew: boolean;
  isEdited: boolean;
  isMarkedForDelete: boolean;
  editCopy: T;
  physicalCopy: T;

  constructor(ctor: NoParamConstructor<T>) {
    this.physicalCopy = new ctor();
    this.editCopy = new ctor();
  }
}

export function cloneShoppingCartFormDataDto(src: ShoppingCartFormDataDto): ShoppingCartFormDataDto {
  let result = new ShoppingCartFormDataDto();
  result.checkoutData = new CheckoutDataDto();
  result.checkoutData.selectedCustomerDisplayName = src.checkoutData.selectedCustomerDisplayName;
  result.checkoutData.selectedCustomerId = src.checkoutData.selectedCustomerId;

  return result;
}

export class DynamicMarketInput implements IDynamicMarketInput {
  market!: number | undefined;
  input!: any | undefined;

  constructor(data?: IDynamicMarketInput) {
    if (data) {
      for (let property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  static fromJS(data: any): DynamicMarketInput {
    data = typeof data === 'object' ? data : {};
    let result = new DynamicMarketInput();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      this.market = data['market'];
      if (data['input']) {
        this.input = {};
        for (let key in data['input']) {
          if (data['input'].hasOwnProperty(key)) {
            this.input[key] = data['input'][key];
          }
        }
      }
    }
  }
  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['market'] = this.market;
    if (this.input) {
      data['input'] = {};
      for (let key in this.input) {
        if (this.input.hasOwnProperty(key)) {
          data['input'][key] = this.input[key];
        }
      }
    }
    return data;
  }
}

export interface IDynamicMarketInput {
  market: number | undefined;
  input: any | undefined;
}
