import {Injectable} from '@angular/core';
import {timer} from "rxjs";
import {AppConsts} from "@shared/AppConsts";
import {AppAuthService} from "@app/shared/common/auth/app-auth.service";
import {AbpSessionService} from "@abp/session/abp-session.service";
import {Local} from "@node_modules/protractor/built/driverProviders";

@Injectable({
    providedIn: 'root'
})
export class IdleCheckerService {
    
    private idleTimeMilliSeconds;
    private interactionKey = "LIT"

    constructor(
        private _authService: AppAuthService,
        private _abpSessionService: AbpSessionService) {
    }

    public startChecking() {
        //user is not login
        if (this._abpSessionService.userId == null || this._abpSessionService.userId <= 0) {
            return;
        }

        this.idleTimeMilliSeconds = AppConsts.idleTimeMinutes * 60 * 1000;
        console.log(this.idleTimeMilliSeconds);
        //check if site is opened again after idle time has expired
        if(localStorage.getItem(this.interactionKey)){
            this.CheckStatus();    
        }else{
            this.interaction();    
        }
        
        timer(0, 60000)
            .subscribe(_ => {
                this.CheckStatus();       
            })
    }

    public interaction() {
        //use local storage, to communicate across tabs, as one tab can time out while the other is in use.
        localStorage.setItem(this.interactionKey, Date.now().valueOf() + '');
    }
    
    private CheckStatus(){
        if(!this.idleTimeMilliSeconds){
            return
        }
        const interactionDate = localStorage.getItem(this.interactionKey)
        if(!interactionDate){
            return;
        }
        if ((Date.now().valueOf() - this.idleTimeMilliSeconds) >
            parseInt(interactionDate)) {
            localStorage.removeItem(this.interactionKey);
            this._authService.logout();
        }
    }


}
