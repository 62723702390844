<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal"
     (onShown)="shown()"
     aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{options.title}}
                </h4>
                <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form novalidate (ngSubmit)="refreshTable()" autocomplete="off">
                    <div *ngIf="options.isFilterEnabled" class="input-group margin-bottom-10">
                        <input autoFocus [(ngModel)]="filterText" name="filterText" class="form-control" placeholder="{{l('SearchWithThreeDot')}}" type="text">
                        <span class="input-group-btn">
                            <button class="btn btn btn-primary" type="submit"><i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i></button>
                        </span>
                    </div>
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                 (onLazyLoad)="getRecordsIfNeeds($event)"
                                 [value]="primengTableHelper.records"
                                 rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                 [paginator]="false"
                                 [lazy]="true"
                                 [responsive]="primengTableHelper.isResponsive">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:15%">
                                        {{l('Select')}}
                                    </th>
                                    <th>
                                        {{l('Name')}}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td>
                                        <button class="btn btn-metal m-btn m-btn--icon m-btn--icon-only"
                                                (click)="selectItem(record)"
                                                title="{{l('Select')}}">
                                            <i class="la la-chevron-circle-right" [attr.aria-label]="l('Select')"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <span class="ui-column-title">{{l('Name')}}</span>
                                        {{record.name}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{l('NoData')}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                         #paginator
                                         (onPageChange)="getRecordsIfNeeds($event)"
                                         [totalRecords]="primengTableHelper.totalRecordsCount"
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                            </p-paginator>
                            <span class="total-records-count">
                                {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
            </div>
        </div>
    </div>
</div>
