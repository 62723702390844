import {Component, EventEmitter, Injector, Output, ViewChild} from '@angular/core';
import {AppComponentBase} from "@shared/common/app-component-base";
import {ModalDirective} from "@node_modules/ngx-bootstrap";

@Component({
  selector: 'twoFactorAlertModal',
  templateUrl: './user-two-factor-alert.component.html',
  styleUrls: ['./user-two-factor-alert.component.less']
})
export class UserTwoFactorAlertComponent extends AppComponentBase {
  @ViewChild('twoFaAlertModal') public myModal: ModalDirective;
  
  @Output() onConfigureClick = new EventEmitter<boolean>();

  active: boolean;
  constructor(injector: Injector) {
    super(injector);
  }

  show() {
    this.active = true;
    this.myModal.config = {
      backdrop: 'static',
      keyboard: false,
    }
    this.myModal.show();
  }

  onConfigureClickEvent() {
    this.onConfigureClick.emit(true);
    this.close();    
  }
  
  close(){
    this.myModal.hide();
  }
}
