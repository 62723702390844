import { PlatformLocation, registerLocaleData } from '@angular/common';
import { Injector } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import * as localForage from 'localforage';
import * as _ from 'lodash';
import { AppPreBootstrap } from './AppPreBootstrap';
import { AppUiHelper } from '@shared/common/ui/ui-controller-helper';
export function appInitializerFactory(injector, platformLocation) {
    return function () {
        abp.ui.setBusy();
        handleLogoutRequest(injector.get(AppAuthService));
        return new Promise(function (resolve, reject) {
            AppConsts.appBaseHref = getBaseHref(platformLocation);
            var appBaseUrl = getDocumentOrigin() + AppConsts.appBaseHref;
            AppPreBootstrap.run(appBaseUrl, function () {
                // Initialize local Forage
                localForage.config({
                    driver: localForage.LOCALSTORAGE,
                    name: 'Digital',
                    version: 1.0,
                    storeName: 'abpzerotemplate_local_storage',
                    description: 'Cached data for Digital'
                });
                var appSessionService = injector.get(AppSessionService);
                var ui = injector.get(AppUiCustomizationService);
                appSessionService.init().then(function (result) {
                    //Css classes based on the layout
                    var appUiCustomizationService = injector.get(AppUiCustomizationService);
                    if (abp.session.userId) {
                        $('body').attr('class', appUiCustomizationService.getAppModuleBodyClass());
                    }
                    else {
                        $('body').attr('class', appUiCustomizationService.getAccountModuleBodyClass());
                    }
                    AppUiHelper.hideSideBar();
                    //tenant specific custom css
                    if (appSessionService.tenant && appSessionService.tenant.customCssId) {
                        $('head').append('<link id="TenantCustomCss" href="' + AppConsts.remoteServiceBaseUrl + '/TenantCustomization/GetCustomCss?id=' + appSessionService.tenant.customCssId + '" rel="stylesheet"/>');
                    }
                    //set og share image meta tag
                    if (!appSessionService.tenant || !appSessionService.tenant.logoId) {
                        $('meta[property=og\\:image]').attr('content', window.location.origin + '/assets/common/images/app-logo-on-' + ui.getAsideSkin() + '.png');
                    }
                    else {
                        $('meta[property=og\\:image]').attr('content', AppConsts.remoteServiceBaseUrl + '/TenantCustomization/GetLogo?id=' + appSessionService.tenant.logoId);
                    }
                    abp.ui.clearBusy();
                    if (shouldLoadLocale()) {
                        var angularLocale = convertAbpLocaleToAngularLocale(abp.localization.currentLanguage.name);
                        import("@angular/common/locales/" + angularLocale + ".js")
                            .then(function (module) {
                            registerLocaleData(module.default);
                            resolve(result);
                        }, reject);
                    }
                    else {
                        resolve(result);
                    }
                }, function (err) {
                    abp.ui.clearBusy();
                    reject(err);
                });
            }, resolve, reject);
        });
    };
}
function getDocumentOrigin() {
    if (!document.location.origin) {
        return document.location.protocol + "//" + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
    }
    return document.location.origin;
}
export function shouldLoadLocale() {
    return abp.localization.currentLanguage.name && abp.localization.currentLanguage.name !== 'en-US';
}
export function convertAbpLocaleToAngularLocale(locale) {
    if (!AppConsts.localeMappings) {
        return locale;
    }
    var localeMapings = _.filter(AppConsts.localeMappings, { from: locale });
    if (localeMapings && localeMapings.length) {
        return localeMapings[0]['to'];
    }
    return locale;
}
export function getRemoteServiceBaseUrl() {
    return AppConsts.remoteServiceBaseUrl;
}
export function getCurrentLanguage() {
    return abp.localization.currentLanguage.name;
}
export function getBaseHref(platformLocation) {
    var baseUrl = platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
        return baseUrl;
    }
    return '/';
}
function handleLogoutRequest(authService) {
    var currentUrl = UrlHelper.initialUrl;
    var returnUrl = UrlHelper.getReturnUrl();
    if (currentUrl.indexOf(('account/logout')) >= 0 && returnUrl) {
        authService.logout(true, returnUrl);
    }
}
var RootModule = /** @class */ (function () {
    function RootModule() {
    }
    return RootModule;
}());
export { RootModule };
