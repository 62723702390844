<div class="nav-bar slider">
    <div class="container" id="nav-container">
        <nav class="navbar navbar-expand-md bg-dark navbar-dark">
            <div *ngIf="menu" class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                <div class="navbar-nav">
                    <ng-template ngFor let-menuItem [ngForOf]="menu.items" let-i="index">

                        <a *ngIf="isRouterLinkItem(menuItem)" id="link_{{i}}" (click)="resetActivePath()"
                            routerLink="{{menuItem.route}}"
                            class="nav-item nav-link {{getMenuItemStateClass(menuItem)}}">{{l(menuItem.name)}}</a>

                        <a *ngIf="isActionLinkItem(menuItem)" id="link_{{i}}" (click)="resetActivePath()"
                            target="{{getActionLinkTarget(menuItem)}}" href="{{getFullActionLink(menuItem.actionLink)}}"
                            class="nav-item nav-link {{getMenuItemStateClass(menuItem)}}">{{l(menuItem.name)}}</a>

                        <div ngbDropdown #dd *ngIf="isDropDownMenu(menuItem)" id="dropdown_{{i}}"
                            class="nav-item dropdown" (openChange)="toggled($event, link.id)">
                            <a ngbDropdownToggle href="#" #link
                                class="nav-link dropdown-toggle {{getParentActiveState(menuItem)}}" id="link_{{i}}"
                                role="menuitem" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                [attr.tabindex]="i == 0 ? 0 : -1">
                                <span class="m-menu__link-text">
                                    <span class="title">{{l(menuItem.name)}} </span>
                                </span>
                                <i class="m-menu__hor-arrow la la-angle-down"></i>
                            </a>
                            <div class="dropdown-menu" role="menu" #dropDownMenu>
                                <app-drop-down-menu [MenuItems]="getDisplayableChildren(menuItem)"></app-drop-down-menu>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </nav>
    </div>
</div>