<header class="m-grid__item m-header" data-minimize="false" [attr.data-minimize]="ui.getIsMenuMinimizable()"
    [attr.data-minimize-mobile]="ui.getIsMenuMinimizableMobile()" data-minimize-offset="200"
    data-minimize-mobile-offset="200">
    <div class="m-header__top">
        <div class="m-container {{ui.getContainerClass()}} m-container--xxl m-container--full-height top-bar" [ngClass]="{'top-bar-shadow': basic}">
            <div class="m-stack m-stack--ver m-stack--desktop">
                <!-- BEGIN: Brand -->
                <div class="v-mobile-header m-stack__item m-brand m-brand--skin-{{ui.getAsideSkin()}} d-print-none">
                    <div class="m-stack m-stack--ver m-stack--general">
                        <div class="m-stack__item m-stack__item--middle m-brand__logo">
                            <a routerLink="/" target="_blank" class="m-brand__logo-wrapper">
                                <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" [src]="defaultLogo" alt="logo" class="m-brand__logo-img" style="image-rendering: auto;"/>
                                <img *ngIf="appSession.tenant && appSession.tenant.logoId" [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + ui.getAsideSkin() + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId" alt="logo" class="m-brand__logo-img" style="image-rendering: auto;"/>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- END: Brand -->

                <!-- BEGIN: Nav bar -->
                <div class="m-stack__item m-stack__item--fluid m-brand--skin-{{ui.getHeaderSkin()}} d-print-none" style="width: 400px;">
                    <div *ngIf="hasAxizContentFeature" class="m-stack m-stack--ver m-stack--general">
                        <app-top-nav-bar></app-top-nav-bar>
                    </div>
                </div>
                
                <!-- END: Nav bar -->

                <div *ngIf="true" class="m-stack__item m-stack__item--fluid m-header-head m-brand--skin-{{ui.getHeaderSkin()}} d-print-none action-bar"
                     id="m_header_nav">
                    <!-- BEGIN: Topbar -->
                    <div id="m_header_topbar" class="m-topbar  m-stack m-stack--ver m-stack--general">
                        <nav class="m-stack__item m-topbar__nav-wrapper" [attr.aria-label]="l('TopMenu')">
                            <ul class="m-topbar__nav m-nav m-nav--inline" aria-label="TopMenu" role="menubar" style="margin: auto;">
                                
                                <li *ngIf="showROE" id="roe_li" class="bar-icon m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width m--margin-left-10">
                                    <div class="input-group">
                                        <div class="input-group-prepend currency-btn">
                                            <span id="btnROE"><i class="fa-light fa-dollar-sign"></i> </span>
                                        </div>
                                        <label type="text" class="roe-value" readonly="readonly" aria-describedby="btnROE" >{{rateOfExchange}}</label> <!-- [value]="rateOfExchange" -->
                                    </div>
                                </li>

                                <!--APPLY TO BE A PARTNER MENU ITEM-->
                                <li *ngIf="!isLoggedIn && hasAxizContentFeature && isTenantSelfRegistrationAllowed" class="m-nav__item m-topbar__notifications navbar-nav nav-link-apply m--margin-left-10">
                                    <div  class="nav-item  nav-link ">
                                        <span class="m--margin-right-5">
                                            <a  id="link_1"
                                                target="/account/register-tenant" href="/account/register-tenant" class="">{{l("ApplyNow")}}
                                            </a> 
                                        </span>
                                    </div>
                                </li>

                                <li *ngIf="platformAdminButtonList.length > 0" class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width  m--margin-left-10">
                                    <a class="m-nav__link m-dropdown__toggle"
                                       data-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false"
                                    ><span class="m-nav__link-icon hint--right"
                                           [attr.aria-label]="l('PlatformAdministration')">
                                            <i class="fa-regular fa-gear administration-btn-cog"></i>
                                        </span>
                                    </a>
                                    <ul class="dropdown-menu actions-con">
                                        <ng-container *ngFor="let group of platformAdminButtonList">
                                            <li>
                                                <label class="text-bold" style="padding-left: 20px">{{group.title}}</label>
                                            </li>
                                            <ng-container *ngFor="let action of group.actions">
                                                <li>
                                                    <a style="padding-left: 30px"
                                                       (click)="navigateToAction(action)">{{action.name}}</a>
                                                </li>
                                            </ng-container>
                                        </ng-container>

                                    </ul>
                                </li>
                                
                                <li *ngIf="isLoggedIn" headerNotifications
                                    class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width   m--margin-left-10"
                                    data-dropdown-toggle="click" data-dropdown-persistent="true"
                                    id="header_notification_bar">
                                </li>

                                <!--CART MENU ITEM-->
                                <li class="m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center 	m-dropdown--mobile-full-width  m--margin-left-10"
                                    *ngIf="isLoggedIn && tenant != null && isGrantedForImpersonator('Pages.Tenants.Impersonation.ViewCartItems')">
                                    <a [routerLink]="['/app/cart/items']" class="m-nav__link m-dropdown__toggle"
                                       id="m_topbar_notification_icon_not">                                        
                                        <span class="m-nav__link-icon">
                                            <i class="fal fa-shopping-cart" [attr.aria-label]="l('Cart')"></i>
                                        </span>
                                        <span *ngIf="cartItemCount > 0" class="m-badge m-badge--info" [ngClass]="{'d-none': !cartItemCount}"
                                              id="CartItemCount">{{cartItemCount}}</span>
                                    </a>
                                </li>                                
                                <li *ngIf="isLoggedIn"
                                    class="m-nav__item m-topbar__user-profile m-topbar__user-profile--img  m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light m--margin-left-10"
                                    data-dropdown-toggle="click">
                                    <a href="javascript:;" class="m-nav__link m-dropdown__toggle" role="menuitem"
                                       aria-haspopup="true" aria-expanded="false" tabindex="0">
                                        <span class="m-topbar__username m--font">
                                            <i *ngIf="isImpersonatedLogin" class="fa fa-reply m--font-danger"></i>
                                            <span *ngIf="isMultiTenancyEnabled" class="tenancy-name"> {{name | truncate: 30: '...'}}</span>
                                        </span>
                                        <span class="m-topbar__userpic">
                                            <img *ngIf="profilePicture" alt=""
                                                 class="header-profile-picture m--img-rounded m--marginless m--img-centered"
                                                 [src]="profilePicture" />
                                        </span>
                                        <span class='avatar-sts avatar-sts__s'></span>
                                    </a>
                                    <div class="m-dropdown__wrapper">
                                        <div class="m-dropdown__inner m-inner-border">
                                            <div class="m-dropdown__header m--align-center header-nav">
                                                <div class="m-card-user m-card-user--skin-dark">
                                                    <div class="m-card-user__pic">
                                                        <img alt=""
                                                             class="header-profile-picture m--img-rounded m--marginless m--img-centered"
                                                             [src]="profilePicture" />
                                                             <span class='avatar-sts avatar-sts__m'></span>
                                                    </div>
                                                    <div class="m-card-user__details">
                                                        <span class="m-card-user__name m--font-weight-500">
                                                            {{name | truncate: 30: '...'}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="m-dropdown__body">
                                                <nav class="m-dropdown__content" [attr.aria-label]="l('UserAccount')">
                                                    <ul class="m-nav m-nav--skin-light"
                                                        [attr.aria-label]="l('UserAccount')" role="menu">
                                                        <span *ngIf="isLoggedIn">
                                                            <li *ngIf="isImpersonatedLogin" class="m-nav__item"
                                                                role="none">
                                                                <a href="javascript:;" class="m-nav__link"
                                                                   (click)="backToMyAccount()" role="menuitem"
                                                                   aria-haspopup="true" aria-expanded="false"
                                                                   tabindex="-1">
                                                                    <span class="m-nav__link-title">
                                                                        <span class="m-nav__link-wrap">
                                                                            <span id="UserProfileBackToMyAccountButton"
                                                                                  class="m-nav__link-text">
                                                                                {{l("BackToMyAccount")}}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li class="m-nav__item" role="none">
                                                                <a href="javascript:;" class="m-nav__link"
                                                                   (click)="showLinkedAccounts()" role="menuitem"
                                                                   aria-haspopup="true" aria-expanded="false"
                                                                   tabindex="-1">
                                                                    <span class="m-nav__link-title">
                                                                        <span class="m-nav__link-wrap">
                                                                            <span id="ManageLinkedAccountsLink"
                                                                                  class="m-nav__link-text">
                                                                                {{l("ManageLinkedAccounts")}}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                                <ul id="RecentlyUsedLinkedUsers"
                                                                    class="m-nav linked-accounts"
                                                                    *ngIf="recentlyLinkedUsers"
                                                                    [attr.aria-label]="l('LinkedAccounts')" role="menu">
                                                                    <li *ngFor="let linkedUser of recentlyLinkedUsers"
                                                                        class="m-nav__item" role="none">
                                                                        <a href="javascript:;"
                                                                           (click)="switchToLinkedUser(linkedUser)"
                                                                           class="m-nav__link" role="menuitem"
                                                                           aria-haspopup="true" aria-expanded="false"
                                                                           tabindex="-1">
                                                                            <span class="m-nav__link-bullet m-nav__link-bullet--dot">
                                                                                <span></span>
                                                                            </span>
                                                                            <span class="m-nav__link-text">
                                                                                {{getShownUserName(linkedUser)}}
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                            <li class="m-nav__item" role="none">
                                                                <a href="javascript:;" class="m-nav__link"
                                                                   (click)="changePassword()" role="menuitem"
                                                                   aria-haspopup="true" aria-expanded="false"
                                                                   tabindex="-1">
                                                                    <span class="m-nav__link-title">
                                                                        <span class="m-nav__link-wrap">
                                                                            <span id="UserProfileChangePasswordLink"
                                                                                  class="m-nav__link-text">
                                                                                {{l("ChangePassword")}}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li class="m-nav__item" role="none">
                                                                <a href="javascript:;" class="m-nav__link"
                                                                   (click)="showLoginAttempts()" role="menuitem"
                                                                   aria-haspopup="true" aria-expanded="false"
                                                                   tabindex="-1">
                                                                    <span class="m-nav__link-title">
                                                                        <span class="m-nav__link-wrap">
                                                                            <span id="ShowLoginAttemptsLink"
                                                                                  class="m-nav__link-text">
                                                                                {{l("LoginAttempts")}}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li class="m-nav__item" role="none">
                                                                <a href="javascript:;" class="m-nav__link"
                                                                   (click)="changeProfilePicture()" role="menuitem"
                                                                   aria-haspopup="true" aria-expanded="false"
                                                                   tabindex="-1">
                                                                    <span class="m-nav__link-title">
                                                                        <span class="m-nav__link-wrap">
                                                                            <span id="UserProfileChangePictureLink"
                                                                                  class="m-nav__link-text">
                                                                                {{l("ChangeProfilePicture")}}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li class="m-nav__item" role="none">
                                                                <a href="javascript:;" class="m-nav__link"
                                                                   (click)="changeMySettings()" role="menuitem"
                                                                   aria-haspopup="true" aria-expanded="false"
                                                                   tabindex="-1">
                                                                    <span class="m-nav__link-title">
                                                                        <span class="m-nav__link-wrap">
                                                                            <span id="UserProfileMySettingsLink"
                                                                                  class="m-nav__link-text">
                                                                                {{l("MySettings")}}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li class="m-nav__item"
                                                                *ngIf="!isGranted('Pages.Administration.UiCustomization')"
                                                                role="none">
                                                                <a routerLink="admin/ui-customization"
                                                                   class="m-nav__link" role="menuitem"
                                                                   aria-haspopup="true" aria-expanded="false"
                                                                   tabindex="-1">
                                                                    <span class="m-nav__link-title">
                                                                        <span class="m-nav__link-wrap">
                                                                            <span class="m-nav__link-text">
                                                                                {{l("VisualSettings")}}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li class="m-nav__separator m-nav__separator--fit"
                                                                role="none"></li>
                                                            <li class="m-nav__item" role="none">
                                                                <a href="javascript:;" (click)="logout()"
                                                                   class="btn btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                                                   role="menuitem" aria-haspopup="true"
                                                                   aria-expanded="false" tabindex="-1">
                                                                    {{l("Logout")}}
                                                                </a>
                                                            </li>
                                                        </span>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="!isLoggedIn" style="padding-top: 0px;"
                                    class="bar-icon m-nav__item m-topbar__notifications m-topbar__notifications--img m-dropdown m-dropdown--large m-dropdown--header-bg-fill m-dropdown--arrow m-dropdown--align-center m-dropdown--mobile-full-width m--margin-left-10" role="none">
                                    <a href="javascript:;" (click)="login()"
                                       class="btn m-btn--pill header-nav btn-secondary m-btn m-btn--signin m-btn--label-brand m-btn--bolder m--font-inverse-primary"
                                       role="menuitem" aria-haspopup="true" aria-expanded="false" tabindex="-1">
                                        {{l("SignIn")}}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <!-- END: Topbar -->
                </div>
            </div>
            
            <div *ngIf="!basic" class="m-stack m-stack--ver m-stack--desktop" >
                <!-- BEGIN: Nav bar -->
                <div class="v-mobile m-stack__item m-stack__item--fluid m-brand--skin-{{ui.getHeaderSkin()}} d-print-none">
                    <div class="m-stack m-stack--ver m-stack--general navbar-dark navbar-nav nav-link:hover">
                        <app-mega-menu></app-mega-menu>
                    </div>
                </div>
                <!-- END: Nav bar -->
            </div>
        </div>
    </div>
</header>

<span *ngIf="isLoggedIn">
    <loginAttemptsModal #loginAttemptsModal></loginAttemptsModal>
    <linkedAccountsModal #linkedAccountsModal (modalClose)="getRecentlyLinkedUsers()"></linkedAccountsModal>
    <changePasswordModal #changePasswordModal></changePasswordModal>
    <changeProfilePictureModal #changeProfilePictureModal></changeProfilePictureModal>
    <mySettingsModal #mySettingsModal (modalSave)="onMySettingsModalSaved()"></mySettingsModal>
    <notificationSettingsModal #notificationSettingsModal></notificationSettingsModal>
    <twoFactorAlertModal #twoFactorAlertModal (onConfigureClick)="view2FaSettings()"></twoFactorAlertModal>
</span>
