var PrimengTableHelper = /** @class */ (function () {
    function PrimengTableHelper() {
        this.predefinedRecordsCountPerPage = [5, 10, 25, 50, 100, 250, 500, 1000];
        this.defaultRecordsCountPerPage = 10;
        this.isResponsive = true;
        this.totalRecordsCount = 0;
        this.isLoading = false;
    }
    PrimengTableHelper.prototype.showLoadingIndicator = function () {
        var _this = this;
        setTimeout(function () {
            _this.isLoading = true;
        }, 0);
    };
    PrimengTableHelper.prototype.hideLoadingIndicator = function () {
        var _this = this;
        setTimeout(function () {
            _this.isLoading = false;
        }, 0);
    };
    PrimengTableHelper.prototype.getSorting = function (table) {
        var sorting;
        if (table.sortField) {
            sorting = table.sortField;
            if (table.sortOrder === 1) {
                sorting += ' ASC';
            }
            else if (table.sortOrder === -1) {
                sorting += ' DESC';
            }
        }
        return sorting;
    };
    PrimengTableHelper.prototype.getMaxResultCount = function (paginator, event) {
        if (paginator.rows) {
            return paginator.rows;
        }
        if (!event) {
            return 0;
        }
        return event.rows;
    };
    PrimengTableHelper.prototype.getSkipCount = function (paginator, event) {
        if (paginator.first) {
            return paginator.first;
        }
        if (!event) {
            return 0;
        }
        return event.first;
    };
    PrimengTableHelper.prototype.shouldResetPaging = function (event) {
        if (!event /*|| event.sortField*/) {
            return true;
        }
        return false;
    };
    return PrimengTableHelper;
}());
export { PrimengTableHelper };
