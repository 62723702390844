<div class="nav-bar" *ngIf="menu">
      <nav class="navbar navbar-expand-md bg-dark navbar-dark ">
          <div class="collapse navbar-collapse d-flex justify-content-center" id="navbarCollapse">
              <div class="navbar-nav">
                 <ng-container *ngFor="let menuItem of menu.items; let i = index">
                  <ng-container *ngIf="(showMenuItem(menuItem) && isRouterLinkItem(menuItem) || (showMenuItem(menuItem) && isDropDownMenu(menuItem)) || (showMenuItem(menuItem) && isActionLinkItem(menuItem)))" >
                     <div style="width: 100px!important"> 
                        <a *ngIf="isRouterLinkItem(menuItem)" id="link_{{i}}" (click)="resetActivePath()" [queryParams]="getRouterParams(menuItem)" routerLink="{{menuItem.route}}" class="top-nav nav-item nav-link {{getMenuItemStateClass(menuItem)}}">
                           <i class="{{ menuItem.icon }}">
                           </i>
                           <span class="m--margin-top-5"> {{l(menuItem.name)}}</span>
                        </a>
   
                        <a *ngIf="isActionLinkItem(menuItem)" id="link_{{i}}" (click)="resetActivePath()"
                           target="{{getActionLinkTarget(menuItem)}}" href="{{getFullActionLink(menuItem.actionLink)}}" class="nav-item nav-link {{getMenuItemStateClass(menuItem)}}">
                           <span class="m--margin-top-5"> {{l(menuItem.name)}}</span> 
                        </a>
   
                           <div ngbDropdown  #dd *ngIf="isDropDownMenu(menuItem)" id="dropdown_{{i}}"
                              class="nav-item dropdown"                               
                              (openChange)="toggled($event, link.id)">
                              <a ngbDropdownToggle href="#" #link class="top-nav nav-link dropdown-toggle {{getParentActiveState(menuItem)}}"
                                 id="link_{{i}}"
                                 role="menuitem"
                                 data-toggle="dropdown"
                                 aria-haspopup="true"
                                 aria-expanded="false"
                                 (click)="closeMegaDropDown()"
                                 [attr.tabindex]="i == 0 ? 0 : -1">
                                 <i class="{{ menuItem.icon }}">
                                 </i> 
                                 <span *ngIf="menuItem.name !== 'Digital Commerce'" class="m--margin-top-5"> {{l(menuItem.name)}}</span>
                                 <span *ngIf="menuItem.name === 'Digital Commerce'" class="m--margin-top-5">{{ defaultHome }}</span>
                              </a>
                              <div class="dropdown-menu" role="menu" #dropDownMenu id="dropDownMenu_{{i}}" appendTo="body">
                                  
                                 <app-drop-down-menu [MenuItems]="getDisplayableChildren(menuItem)" [menuItemName]="menuItem.name" [marketingImage]="menuItem.marketingImage" (onCloseClick)="closeMegaDropDown()" ></app-drop-down-menu>
                              </div>
                           </div>
                     </div>   
                  </ng-container>
                 </ng-container>
                  <div *ngIf="impersonation" class="d-flex flex-column justify-content-end impersonation-details">
                      <div class="d-flex justify-content-end impersonation-text">{{l("ImpersonatingMenu")}}</div>
                      <div class="d-flex justify-content-end impersonation-partner">{{impersonatorName}}</div>
                      <div class="d-flex justify-content-end">{{impersonatorAccountNumber}}</div>
                  </div>
              </div>
          </div>
      </nav>
</div> 