import { SignalRHelper } from "./SignalRHelper";
import { ChatSignalrService } from "../../app/shared/layout/chat/chat-signalr.service";
import * as i0 from "@angular/core";
import * as i1 from "../../app/shared/layout/chat/chat-signalr.service";
var SignalRConnectionService = /** @class */ (function () {
    function SignalRConnectionService(_chatSignalrService) {
        this._chatSignalrService = _chatSignalrService;
    }
    SignalRConnectionService.prototype.connect = function () {
        var _this = this;
        if (this.connected) {
            return;
        }
        SignalRHelper.initSignalR(function () {
            _this._chatSignalrService.init();
        });
        this.connected = true;
    };
    SignalRConnectionService.ngInjectableDef = i0.defineInjectable({ factory: function SignalRConnectionService_Factory() { return new SignalRConnectionService(i0.inject(i1.ChatSignalrService)); }, token: SignalRConnectionService, providedIn: "root" });
    return SignalRConnectionService;
}());
export { SignalRConnectionService };
