var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { EventEmitter, Injector } from '@angular/core';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UnlinkUserInput, UserLinkServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { Paginator } from 'primeng/components/paginator/paginator';
import { Table } from 'primeng/components/table/table';
import { LinkAccountModalComponent } from './link-account-modal.component';
var LinkedAccountsModalComponent = /** @class */ (function (_super) {
    __extends(LinkedAccountsModalComponent, _super);
    function LinkedAccountsModalComponent(injector, abpMultiTenancyService, _userLinkService, _linkedAccountService) {
        var _this = _super.call(this, injector) || this;
        _this.abpMultiTenancyService = abpMultiTenancyService;
        _this._userLinkService = _userLinkService;
        _this._linkedAccountService = _linkedAccountService;
        _this.modalClose = new EventEmitter();
        return _this;
    }
    LinkedAccountsModalComponent.prototype.getLinkedUsers = function (event) {
        var _this = this;
        this.primengTableHelper.showLoadingIndicator();
        this._userLinkService.getLinkedUsers(this.primengTableHelper.getMaxResultCount(this.paginator, event), this.primengTableHelper.getSkipCount(this.paginator, event), this.primengTableHelper.getSorting(this.dataTable))
            .subscribe(function (result) {
            _this.primengTableHelper.totalRecordsCount = result.totalCount;
            _this.primengTableHelper.records = result.items;
            _this.primengTableHelper.hideLoadingIndicator();
        });
    };
    LinkedAccountsModalComponent.prototype.getShownLinkedUserName = function (linkedUser) {
        if (!this.abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }
        return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
    };
    LinkedAccountsModalComponent.prototype.deleteLinkedUser = function (linkedUser) {
        var _this = this;
        this.message.confirm(this.l('LinkedUserDeleteWarningMessage', linkedUser.username), this.l('AreYouSure'), function (isConfirmed) {
            if (isConfirmed) {
                var unlinkUserInput = new UnlinkUserInput();
                unlinkUserInput.userId = linkedUser.id;
                unlinkUserInput.tenantId = linkedUser.tenantId;
                _this._userLinkService.unlinkUser(unlinkUserInput).subscribe(function () {
                    _this.reloadPage();
                    _this.notify.success(_this.l('SuccessfullyUnlinked'));
                });
            }
        });
    };
    LinkedAccountsModalComponent.prototype.reloadPage = function () {
        this.paginator.changePage(this.paginator.getPage());
    };
    LinkedAccountsModalComponent.prototype.manageLinkedAccounts = function () {
        this.linkAccountModal.show();
    };
    LinkedAccountsModalComponent.prototype.switchToUser = function (linkedUser) {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    };
    LinkedAccountsModalComponent.prototype.show = function () {
        this.modal.show();
    };
    LinkedAccountsModalComponent.prototype.close = function () {
        this.modal.hide();
        this.modalClose.emit(null);
    };
    return LinkedAccountsModalComponent;
}(AppComponentBase));
export { LinkedAccountsModalComponent };
