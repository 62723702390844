var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TokenService } from '@abp/auth/token.service';
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy, UpdateProfilePictureInput } from '@shared/service-proxies/service-proxies';
import { FileUploader } from 'ng2-file-upload';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
var ChangeProfilePictureModalComponent = /** @class */ (function (_super) {
    __extends(ChangeProfilePictureModalComponent, _super);
    function ChangeProfilePictureModalComponent(injector, _profileService, _tokenService) {
        var _this = _super.call(this, injector) || this;
        _this._profileService = _profileService;
        _this._tokenService = _tokenService;
        _this.active = false;
        _this.saving = false;
        _this.maxProfilPictureBytesUserFriendlyValue = 5;
        _this._uploaderOptions = {};
        return _this;
    }
    ChangeProfilePictureModalComponent.prototype.initializeModal = function () {
        this.active = true;
        this.temporaryPictureUrl = '';
        this.temporaryPictureFileName = '';
        this._$profilePictureResize = null;
        this._$jcropApi = null;
        this.initFileUploader();
    };
    ChangeProfilePictureModalComponent.prototype.initFileUploader = function () {
        var _this = this;
        var self = this;
        self.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + '/Profile/UploadProfilePicture' });
        self._uploaderOptions.autoUpload = true;
        self._uploaderOptions.authToken = 'Bearer ' + self._tokenService.getToken();
        self._uploaderOptions.removeAfterUpload = true;
        self.uploader.onAfterAddingFile = function (file) {
            file.withCredentials = false;
        };
        self.uploader.onSuccessItem = function (item, response, status) {
            var resp = JSON.parse(response);
            if (resp.success) {
                self.temporaryPictureFileName = resp.result.fileName;
                self.temporaryPictureUrl = AppConsts.remoteServiceBaseUrl + '/Temp/Downloads/' + resp.result.fileName + '?v=' + new Date().valueOf();
                var newCanvasHeight = resp.result.height * self._$profilePictureResize.width() / resp.result.width;
                self._$profilePictureResize.height(newCanvasHeight + 'px');
                if (self._$jcropApi) {
                    self._$jcropApi.destroy();
                }
                self._$profilePictureResize.attr('src', self.temporaryPictureUrl);
                self._$profilePictureResize.attr('originalWidth', resp.result.width);
                self._$profilePictureResize.attr('originalHeight', resp.result.height);
                self._$profilePictureResize.Jcrop({
                    setSelect: [0, 0, 100, 100],
                    aspectRatio: 1,
                    boxWidth: 400,
                    boxHeight: 400
                }, function () {
                    self._$jcropApi = this;
                });
            }
            else {
                _this.message.error(resp.error.message);
            }
        };
        self.uploader.setOptions(self._uploaderOptions);
    };
    ChangeProfilePictureModalComponent.prototype.onModalShown = function () {
        this._$profilePictureResize = $('#ProfilePictureResize');
    };
    ChangeProfilePictureModalComponent.prototype.show = function () {
        this.initializeModal();
        this.modal.show();
    };
    ChangeProfilePictureModalComponent.prototype.close = function () {
        this.active = false;
        this.modal.hide();
    };
    ChangeProfilePictureModalComponent.prototype.save = function () {
        var _this = this;
        var self = this;
        if (!self.temporaryPictureFileName) {
            return;
        }
        var resizeParams = { x: 0, y: 0, w: 0, h: 0 };
        if (self._$jcropApi) {
            resizeParams = self._$jcropApi.getSelection();
        }
        var containerWidth = Math.ceil(self._$jcropApi.getContainerSize()[0]);
        var containerHeight = Math.ceil(self._$jcropApi.getContainerSize()[1]);
        var originalWidth = containerWidth;
        var originalHeight = containerHeight;
        if (self._$profilePictureResize) {
            originalWidth = parseInt(self._$profilePictureResize.attr('originalWidth'));
            originalHeight = parseInt(self._$profilePictureResize.attr('originalHeight'));
        }
        var widthRatio = originalWidth / containerWidth;
        var heightRatio = originalHeight / containerHeight;
        var input = new UpdateProfilePictureInput();
        input.fileName = self.temporaryPictureFileName;
        input.x = Math.floor(resizeParams.x * widthRatio);
        input.y = Math.floor(resizeParams.y * heightRatio);
        input.width = Math.floor(resizeParams.w * widthRatio);
        input.height = Math.floor(resizeParams.h * heightRatio);
        this.saving = true;
        self._profileService.updateProfilePicture(input)
            .pipe(finalize(function () { _this.saving = false; }))
            .subscribe(function () {
            var self = _this;
            self._$jcropApi.destroy();
            self._$jcropApi = null;
            abp.event.trigger('profilePictureChanged');
            self.close();
        });
    };
    return ChangeProfilePictureModalComponent;
}(AppComponentBase));
export { ChangeProfilePictureModalComponent };
