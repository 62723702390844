<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHTML]="message"></p>
  <ng-content></ng-content>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close(true)">{{ confirmText }}</button>
  <button *ngIf="showCancel" type="button" class="btn btn-secondary" (click)="activeModal.close(false)">{{ cancelText }}</button>
</div>