import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { DetailDialogComponent } from './detail-dialog/detail-dialog.component';
import { DialogType } from './generic-dialog-models';
import { SummaryDialogComponent } from './summary-dialog/summary-dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap/modal/modal";
var GenericDialogService = /** @class */ (function () {
    function GenericDialogService(modalService) {
        this.modalService = modalService;
    }
    GenericDialogService.prototype.open = function () {
        var options = { centered: true };
        if (this.options.dialogType == DialogType.Detail) {
            options.size = 'lg';
            var modalRef = this.modalService.open(DetailDialogComponent, options);
        }
        else {
            options.size = 'sm';
            var modalRef = this.modalService.open(SummaryDialogComponent, options);
        }
        var instance = modalRef._windowCmptRef.instance;
        setTimeout(function () {
            instance.windowClass = 'custom-show';
        }, 0);
        var fx = modalRef._removeModalElements.bind(modalRef);
        modalRef._removeModalElements = function () {
            instance.windowClass = "";
            setTimeout(fx, 250);
        };
        modalRef.componentInstance.iconClass = this.options.iconClass;
        modalRef.componentInstance.title = this.options.title;
        modalRef.componentInstance.message = this.options.message;
        modalRef.componentInstance.showCancel = this.options.showCancel;
        modalRef.componentInstance.confirmText = this.options.confirmText;
        modalRef.componentInstance.cancelText = this.options.cancelText;
        modalRef.componentInstance.severity = this.options.dialogSeverity;
        return modalRef.result;
    };
    // Just to stick to our observable pattern (instead of promises)
    GenericDialogService.prototype.openDialog = function (options) {
        this.options = options;
        return from(this.open());
    };
    GenericDialogService.ngInjectableDef = i0.defineInjectable({ factory: function GenericDialogService_Factory() { return new GenericDialogService(i0.inject(i1.NgbModal)); }, token: GenericDialogService, providedIn: "root" });
    return GenericDialogService;
}());
export { GenericDialogService };
