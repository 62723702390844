import { Component, OnInit, Input, Injector, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppMenuItem } from '../../app-menu-item';
import { TopMenuBase } from '../top-menu-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppNavigationService } from '../../app-navigation.service';
import { HttpClient } from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Component({
   selector: 'app-drop-down-menu',
   templateUrl: './drop-down-menu.component.html',
   styleUrls: ['./drop-down-menu.component.less'],
})
export class DropDownMenuComponent extends TopMenuBase implements OnInit {
   @Input() MenuItems: AppMenuItem[];
   @Input() menuItemName: string;
   @Input() marketingImage: string;

   @Output() onCloseClick = new EventEmitter<boolean>();

   private basePath = "../../assets/common/images/dropdown-menu-banners/";
   private imageLayouts = `${this.basePath}layout.json`;
   imageLayoutData: any[];

   linksExist: boolean;
   linkWidth: number;

   marketingTag = 'Just What Spring Ordered'; //this is a temporary marketing tag that will be removed

   constructor(
      private router: Router,
      navigationSvc: AppNavigationService,
      appSessionService: AppSessionService,
      injector: Injector,
      private httpClient: HttpClient
   ) {
      super(appSessionService, navigationSvc, injector);
   }

   menuSubSections = ['Categories', 'Tools', 'User Management', 'Profile', 'Brands', 'Stores', 'Pricelists', 'Vendors', 'Pricing System'];

   ngOnInit() {
      const links = this.imageLinks().length + this.actionLinks().length;
      this.linksExist = links > 0;
      this.linkWidth = 100 / (links + 1);

      this.httpClient.get(this.imageLayouts)
         .pipe(finalize(() => {
            this.imageLayoutData.sort(function(a, b) {
               return a.order - b.order
            });
         }))
         .subscribe((x: any[]) => {
            this.imageLayoutData = x;
         });
   }

   hover(ev: MouseEvent, navItem: AppMenuItem) {
      if (navItem.imageLink && navItem.imageLink.alternativeImageExist() && navItem.imageLink.alternateImages)
         $(ev.currentTarget).attr('src', navItem.imageLink.alternateImageSrc);
   }

   unhover(ev: MouseEvent, navItem: AppMenuItem) {
      if (navItem.imageLink && navItem.imageLink.alternateImages) $(ev.currentTarget).attr('src', navItem.imageLink.defaultImageSrc);
   }

   navigateWithParams(path: string, tag: string) {
      this.router.navigate([path], { queryParams: { tag: tag } }).then(() => {
         window.location.reload();
      });
   }

   navigate(path: string) {
      this.router.navigate([path]);
   }

   imageLinks() {
      const any = this.MenuItems && this.MenuItems.length > 0;
      if (any) {
         return this.MenuItems.filter((i) => this.isImageLink(i));
      }

      return [];
   }

   actionLinks() {
      const any = this.MenuItems && this.MenuItems.length > 0;
      if (any) {
         return this.MenuItems.filter((i) => this.isActionLink(i));
      }

      return [];
   }

   showMenuSection(sectionName: string): boolean {
      var found = this.MenuItems.find((d) => d.subSection == sectionName);
      if (found) {
         return true;
      }
      return false;
   }

   navigateTo(menuItem: AppMenuItem) {
      let name = menuItem.name;
      let paramName = menuItem.paramName;
      let paramValue = menuItem.paramValue;
      switch (paramName) {
         case 'Brand': {
            this.router.navigate([menuItem.route], { queryParams: { Brand: name, BrandId: paramValue } });
            break;
         }
         case 'Category': {
            this.router.navigate([menuItem.route], { queryParams: { Category: name } });
            break;
         }
         case 'View': {
            this.router.navigate([menuItem.route], { queryParams: { viewId: paramValue } });
            break;
         }
         default: {
            this.router.navigate([menuItem.route]);
            break;
         }
      }
   }

   getRouterParams(routerLink){
      if(routerLink.paramName && routerLink.paramValue){
         return { [routerLink.paramName]: routerLink.paramValue};
      }
      return null;
   }
   onClosedDropDownClick() {
      this.onCloseClick.emit(true);
   }

   getUrl(url, isRel) {
      if (isRel) {
         return `${this.basePath}${url}`;
      }

      return url;
   }
}
