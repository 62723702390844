import { AfterViewInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
var FriendProfilePictureComponent = /** @class */ (function () {
    function FriendProfilePictureComponent(_profileService) {
        this._profileService = _profileService;
        this.cssClass = 'media-object';
        this.profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    }
    FriendProfilePictureComponent.prototype.ngAfterViewInit = function () {
        this.setProfileImage();
    };
    FriendProfilePictureComponent.prototype.setProfileImage = function () {
        var _this = this;
        if (!this.profilePictureId) {
            this.profilePictureId = undefined;
        }
        if (!this.tenantId) {
            this.tenantId = undefined;
        }
        if (!this.profilePictureId) {
            return;
        }
        this._profileService.getFriendProfilePictureById(this.profilePictureId, this.userId, this.tenantId).subscribe(function (result) {
            if (result && result.profilePicture) {
                _this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    };
    return FriendProfilePictureComponent;
}());
export { FriendProfilePictureComponent };
