import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponentBase } from '../generic-dialog-component-base';

@Component({
  selector: 'app-detail-dialog',
  templateUrl: './detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.less']
})
export class DetailDialogComponent extends DialogComponentBase implements OnInit {



  constructor(public activeModal: NgbActiveModal) {
    super(activeModal);
   }

  ngOnInit() {
  }

}
