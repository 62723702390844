import { ElementRef } from '@angular/core';
var BusyIfDirective = /** @class */ (function () {
    function BusyIfDirective(_element) {
        this._element = _element;
    }
    Object.defineProperty(BusyIfDirective.prototype, "busyIf", {
        set: function (isBusy) {
            this.refreshState(isBusy);
        },
        enumerable: true,
        configurable: true
    });
    BusyIfDirective.prototype.refreshState = function (isBusy) {
        if (isBusy === undefined) {
            return;
        }
        if (isBusy) {
            abp.ui.setBusy($(this._element.nativeElement));
        }
        else {
            abp.ui.clearBusy($(this._element.nativeElement));
        }
    };
    return BusyIfDirective;
}());
export { BusyIfDirective };
