import {
    AfterViewInit,
    Component,
    Injector,
    OnInit,
    ViewContainerRef,
    OnDestroy,
    ChangeDetectorRef,
    HostListener
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';
import { SubscriptionStartType } from '@shared/AppEnums';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import * as moment from 'moment';
import { AppComponentBase } from 'shared/common/app-component-base';
import { UiCustomizationSettingsServiceProxy } from '@shared/service-proxies/service-proxies';
import { ImpersonatorPermissionsService } from './shared/common/services/impersonator-permissions.service';
import { SessionManagementService } from './shared/common/token-management/services';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { GAServiceService } from '@shared/utils/google-analytics-service';
import {SignalRConnectionService} from "@shared/helpers/signal-r-connection.service";
import {IdleCheckerService} from "@shared/helpers/idle-checker.service";

@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {

    private viewContainerRef: ViewContainerRef;
    private router: Router;

    subscriptionStartType = SubscriptionStartType;
    installationMode = true;

    public _counter: number = 0;
    public _status: string = "Initialized.";
    private _timer: Observable<number>;
    private _timerSubscription: Subscription;
    private _idleTimerSubscription: Subscription;
    private _enableTracking: Boolean;

    //Used to set custom favicon
    favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    public constructor(
        injector: Injector,
        viewContainerRef: ViewContainerRef,
        private _router: Router,
        private _uiCustomizationService: UiCustomizationSettingsServiceProxy,
        private _appSessionService: AppSessionService,
        private _impersonatorPermissionsService: ImpersonatorPermissionsService,
        private changeRef: ChangeDetectorRef,
        private sessionSvs: SessionManagementService,
        private gaService: GAServiceService,
        private titleService: Title,
        private signalRConnectionService :SignalRConnectionService,
        private idleChecker: IdleCheckerService
        ) {
        super(injector);
        this.viewContainerRef = viewContainerRef; // You need this small hack in order to catch application root view container ref (required by ng2 bootstrap modal)
        this.router = _router;

        this._enableTracking = AppConsts.enableTracking;
        if(this._enableTracking) {
            let trackingId = AppConsts.googleAnalyticsTrackingId;
            this.gaService.loadGoogleAnalytics(trackingId);
        }
    }

    ngOnInit(): void {
        if (this.appSession.application) {
            this.signalRConnectionService.connect();
        }
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.updateTheme();

        this._impersonatorPermissionsService.loadPermissions();
        this.sessionSvs.initialize(this.changeRef);

        if(AppConsts.customTitle){
            this.setDocTitle(AppConsts.customTitle);            
        }  

        if(AppConsts.customFaviconUrl){
            this.changeIcon(AppConsts.customFaviconUrl);
        }   
        if(this._enableTracking) {
            this.setUpAnalytics();
        }

        this.idleChecker.startChecking();
    }
    
    setUpAnalytics() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.gaService.logPageView(event.url);
            });
    }

    setDocTitle(title: string) {        
        this.titleService.setTitle(title);
     }

     changeIcon(iconUrl: string) {
        this.favIcon.href = iconUrl;
      }

    updateTheme() {
        this._uiCustomizationService.getUiManagementSettings().subscribe((settingsResult) => {
            let siteColorSetting = settingsResult.siteColor;
            Object.keys(siteColorSetting).forEach(k =>
                document.documentElement.style.setProperty(`--${k}`, siteColorSetting[k])
            );
        });
    }

    subscriptionStatusBarVisible(): boolean {
        return this._appSessionService.tenantId > 0 &&
            (this._appSessionService.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this._appSessionService.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNotifyDayCount, 'days') >= moment(this._appSessionService.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    ngAfterViewInit(): void {
        if (mApp.initialized) {
            return;
        }

        mApp.init();
        mLayout.init();
        mApp.initialized = true;
    }

    ngOnDestroy() {
        if (this._idleTimerSubscription)
            this._idleTimerSubscription.unsubscribe();
    }

    @HostListener('document:click', ['$event'])
    public documentClick(event: Event): void {
        this.idleChecker.interaction();
    }
    
}
