var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DefaultTimezoneScope, TimingServiceProxy } from '@shared/service-proxies/service-proxies';
var TimeZoneComboComponent = /** @class */ (function (_super) {
    __extends(TimeZoneComboComponent, _super);
    function TimeZoneComboComponent(_timingService, injector) {
        var _this = _super.call(this, injector) || this;
        _this._timingService = _timingService;
        _this.selectedTimeZoneChange = new EventEmitter();
        _this.timeZones = [];
        _this.selectedTimeZone = undefined;
        return _this;
    }
    TimeZoneComboComponent.prototype.ngOnInit = function () {
        var self = this;
        self._timingService.getTimezones(self.defaultTimezoneScope).subscribe(function (result) {
            self.timeZones = result.items;
        });
    };
    return TimeZoneComboComponent;
}(AppComponentBase));
export { TimeZoneComboComponent };
