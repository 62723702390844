var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnDestroy, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NotificationServiceProxy } from '@shared/service-proxies/service-proxies';
import { UserNotificationHelper } from './UserNotificationHelper';
var HeaderNotificationsComponent = /** @class */ (function (_super) {
    __extends(HeaderNotificationsComponent, _super);
    function HeaderNotificationsComponent(injector, _notificationService, _userNotificationHelper) {
        var _this = _super.call(this, injector) || this;
        _this._notificationService = _notificationService;
        _this._userNotificationHelper = _userNotificationHelper;
        _this.notifications = [];
        _this.unreadNotificationCount = 0;
        _this.subscriptions = [];
        return _this;
    }
    HeaderNotificationsComponent.prototype.ngOnInit = function () {
        this.loadNotifications();
        this.registerToEvents();
    };
    HeaderNotificationsComponent.prototype.ngOnDestroy = function () {
        this.unregisterEvents();
    };
    HeaderNotificationsComponent.prototype.loadNotifications = function () {
        var _this = this;
        this._notificationService.getUserNotifications(undefined, 3, 0).subscribe(function (result) {
            _this.unreadNotificationCount = result.unreadCount;
            _this.notifications = [];
            $.each(result.items, function (index, item) {
                _this.notifications.push(_this._userNotificationHelper.format(item));
            });
        });
    };
    HeaderNotificationsComponent.prototype.registerToEvents = function () {
        var _this = this;
        var self = this;
        var cbReceived = function (userNotification) {
            self._userNotificationHelper.show(userNotification);
            self.loadNotifications();
        };
        this.registerEvent('abp.notifications.received', cbReceived);
        var cbRefresh = function () {
            self.loadNotifications();
        };
        this.registerEvent('app.notifications.refresh', cbRefresh);
        var cbRead = function (userNotificationId) {
            for (var i = 0; i < _this.notifications.length; i++) {
                if (_this.notifications[i].userNotificationId === userNotificationId) {
                    _this.notifications[i].state = 'READ';
                }
            }
            _this.unreadNotificationCount -= 1;
        };
        this.registerEvent('app.notifications.read', cbRead);
    };
    HeaderNotificationsComponent.prototype.registerEvent = function (name, cb) {
        this.subscriptions.push({
            name: name,
            cb: cb
        });
        abp.event.on(name, cb);
    };
    HeaderNotificationsComponent.prototype.unregisterEvents = function () {
        for (var _i = 0, _a = this.subscriptions; _i < _a.length; _i++) {
            var subscription = _a[_i];
            abp.event.off(subscription.name, subscription.cb);
        }
        this.subscriptions = [];
    };
    HeaderNotificationsComponent.prototype.setAllNotificationsAsRead = function () {
        this._userNotificationHelper.setAllAsRead();
    };
    HeaderNotificationsComponent.prototype.openNotificationSettingsModal = function () {
        this._userNotificationHelper.openSettingsModal();
    };
    HeaderNotificationsComponent.prototype.setNotificationAsRead = function (userNotification) {
        this._userNotificationHelper.setAsRead(userNotification.userNotificationId);
    };
    HeaderNotificationsComponent.prototype.gotoUrl = function (url) {
        if (url) {
            location.href = url;
        }
    };
    return HeaderNotificationsComponent;
}(AppComponentBase));
export { HeaderNotificationsComponent };
