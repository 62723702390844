var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { ServiceApplicationType, AppMarketsDesc, ServicesPricingMethodType, ServicePropertyMappingType } from '@shared/AppEnums';
import { ServiceDto, CreateOrUpdateServiceInput, DigitalServicesServiceProxy, ServiceMappingDto, ServicePricingVariableDto, ServicePricingMethodDto, CustomersServiceProxy, EntityTypeForServiceDto, ServiceEntityTypeLinkDto, NameValueDto, FindCustomersLookupInput } from '@shared/service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
var CreateOrEditServiceComponent = /** @class */ (function (_super) {
    __extends(CreateOrEditServiceComponent, _super);
    function CreateOrEditServiceComponent(injector, route, _digitalProxy, _customersServiceProxy, _router) {
        var _this = _super.call(this, injector) || this;
        _this.route = route;
        _this._digitalProxy = _digitalProxy;
        _this._customersServiceProxy = _customersServiceProxy;
        _this._router = _router;
        _this.applicationMethod = null;
        _this.servicePricingVariableDto = new ServicePricingVariableDto();
        _this.active = false;
        _this.defaultEntityType = null;
        _this.serviceItemId = 0;
        _this.servicesPricingMethodTypes = ServicesPricingMethodType;
        _this.serviceApplicationType = ServiceApplicationType;
        _this.servicePropertyMappingType = ServicePropertyMappingType;
        _this.sessionTenant = undefined;
        return _this;
    }
    CreateOrEditServiceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.market = this.route.snapshot.queryParams['market'];
        this.serviceItemId = this.route.snapshot.queryParams['id'];
        this.defaultEntityType = this.route.snapshot.queryParams['defaultEntityType'];
        this.appMethodList = AppConsts.ServiceApplicationTypeList;
        this.servicePropertyMappingList = AppConsts.ServicePropertyMappingTypeList;
        this.multipleMappingSelected = false;
        this.sessionTenant = abp.session.tenantId;
        abp.ui.setBusy();
        forkJoin([
            this._digitalProxy.getServiceEntityTypes(this.market, this.serviceItemId),
            this._digitalProxy.getServicePricingMethod()
        ])
            .pipe(finalize(function () { return abp.ui.clearBusy(); }))
            .subscribe(function (results) {
            _this.marketEntityTypes = results[0];
            _this.priceMethods = results[1];
            if (_this.serviceItemId) {
                _this.loadService(_this.serviceItemId);
            }
            else {
                _this.service = new ServiceDto();
                _this.service.serviceMappings = new Array();
                _this.addServiceMapping();
                _this.active = true;
            }
        }, function (error) {
            abp.notify.error(error, 'Loading Failed');
        });
    };
    CreateOrEditServiceComponent.prototype.addServiceMapping = function () {
        this.service.serviceMappings.splice(0, 0, new ServiceMappingDto({
            id: 0,
            matchValue: null,
            criteria: null,
            serviceId: this.service.id,
            serviceEntityTypeId: null,
            servicePricingVariable: null,
            propertyId: null,
            property: null,
            creationTime: null,
            creatorUserId: null,
            deleterUserId: null,
            deletionTime: null,
            isDeleted: false,
            lastModificationTime: null,
            lastModifierUserId: null
        }));
        if (this.selectedPricingMethod) {
            this.service.serviceMappings[0].servicePricingVariable = this.createPricingVariable(this.selectedPricingMethod);
        }
        else {
            this.service.serviceMappings[0].servicePricingVariable = new Array();
        }
    };
    CreateOrEditServiceComponent.prototype.updateSelection = function (event, serviceEntityType) {
        var _this = this;
        if (event.target.checked) {
            //Check if mapping will be removed by removing this entity type link
            if (!serviceEntityType.allowPropertyMapping
                && (this.service.serviceMappings.some(function (mapping) { return mapping.isDeleted == false && mapping.serviceEntityTypeId && mapping.serviceEntityTypeId !== serviceEntityType.id; })
                    || this.service.serviceMappings.filter(function (mapping) { return mapping.isDeleted == false; }).length > 1)) {
                abp.message.confirm("By adding " + serviceEntityType.name + "'s to this service you won't be able to setup multiple mapping values. This is because " + serviceEntityType.name
                    + "'s don't yet have predefined properties to map to. Currently there are existing multiple mapping values that will be deleted if you wish to continue. Do you want to continue?", function (result) {
                    if (result) {
                        //delete existing mappings 
                        _this.service.serviceMappings.forEach(function (obj) {
                            obj.isDeleted = true;
                        });
                        //Add single value
                        _this.addServiceMapping();
                        _this.addEntityTypeFromService(serviceEntityType);
                        if (!serviceEntityType.allowPropertyMapping)
                            _this.toggleMappingValue(_this.servicePropertyMappingType.SingleMapping);
                    }
                    else {
                        event.target.checked = !event.target.checked;
                    }
                });
            }
            else {
                this.addEntityTypeFromService(serviceEntityType);
                if (!serviceEntityType.allowPropertyMapping)
                    this.toggleMappingValue(this.servicePropertyMappingType.SingleMapping);
            }
        }
        else {
            //Check if mapping will be removed by removing this entity type link
            var hasExistingMapping = this.service.serviceMappings.some(function (mapping) { return serviceEntityType.id == mapping.serviceEntityTypeId && mapping.isDeleted == false; });
            if (hasExistingMapping) {
                abp.message.confirm("There are currently mapping values setup for " + serviceEntityType.name + " remove this entity link will delete those mappings. Do you want to continue?", function (result) {
                    if (result) {
                        _this.removeEntityTypeFromService(serviceEntityType);
                    }
                    else {
                        event.target.checked = !event.target.checked;
                    }
                });
            }
            else {
                this.removeEntityTypeFromService(serviceEntityType);
            }
        }
    };
    CreateOrEditServiceComponent.prototype.addEntityTypeFromService = function (serviceEntityType) {
        if (!this.selectedEntityTypes) {
            this.selectedEntityTypes = new Array();
        }
        this.selectedEntityTypes.push(serviceEntityType);
        if (!this.service.serviceEntityTypeLinks) {
            this.service.serviceEntityTypeLinks = new Array();
        }
        var existingItem = this.service.serviceEntityTypeLinks
            .find(function (obj) { return obj.serviceEntityTypeId == serviceEntityType.id; });
        if (existingItem) {
            existingItem.isDeleted = false;
        }
        else {
            var selectedEntitytype = new ServiceEntityTypeLinkDto();
            selectedEntitytype.serviceId = this.serviceItemId;
            selectedEntitytype.serviceEntityTypeId = serviceEntityType.id;
            selectedEntitytype.market = this.market;
            this.service.serviceEntityTypeLinks.push(selectedEntitytype);
        }
        //if any of the service entity types doesn't allow property mapping then don't allow multiple mapping to be setup
        this.allowMultiMapping = !this.selectedEntityTypes.some(function (obj) { return !obj.allowPropertyMapping; });
    };
    CreateOrEditServiceComponent.prototype.removeEntityTypeFromService = function (serviceEntityType) {
        this.selectedEntityTypes = this.selectedEntityTypes.filter(function (obj) { return obj.id != serviceEntityType.id; });
        var deleteItem = this.service.serviceEntityTypeLinks
            .find(function (obj) { return obj.isDeleted == false && obj.serviceEntityTypeId == serviceEntityType.id; });
        if (deleteItem) {
            deleteItem.isDeleted = true;
            //remove mappings
            this.service.serviceMappings
                .filter(function (obj) { return deleteItem.serviceEntityTypeId == obj.serviceEntityTypeId; })
                .forEach(function (obj) { return obj.isDeleted = true; });
        }
        //if any of the service entity types doesn't allow property mapping then don't allow multiple mapping to be setup
        this.allowMultiMapping = !this.selectedEntityTypes.some(function (obj) { return !obj.allowPropertyMapping; });
    };
    CreateOrEditServiceComponent.prototype.isChecked = function (serviceEntityType) {
        if (!this.service.serviceEntityTypeLinks)
            return false;
        return this.service.serviceEntityTypeLinks.some(function (obj) { return obj.isDeleted == false && obj.serviceEntityTypeId === serviceEntityType.id; });
    };
    CreateOrEditServiceComponent.prototype.cancel = function () {
        this._router.navigate(['app/admin/vendor/' + AppMarketsDesc[this.market].toLowerCase(), 'configure-digital-services']);
    };
    CreateOrEditServiceComponent.prototype.changeApplicationMethod = function (item) {
        var _this = this;
        if (!this.hasServicesLinked
            && (!this.selectedPricingMethod || this.selectedPricingMethod.id !== this.servicesPricingMethodTypes.CustomPrice)) {
            this.applicationMethod = item;
            this.service.applicationType = item.value;
            this.checkMultipleMappingSelected();
            this.updateServicePropertyMappings();
            this.adjustPricingMethodForAdhoc(item.value);
        }
        if (this.applicationMethod.value == this.serviceApplicationType.Custom) {
            this.availableEntityTypes = this.marketEntityTypes.filter(function (obj) { return obj.allowPropertyMapping; });
            if (this.selectedEntityTypes) {
                //loop through the selected entity types and remove them if they are not in the list of available entity types
                this.selectedEntityTypes.forEach(function (obj) {
                    if (!_this.availableEntityTypes.some(function (check) { return check.id == obj.id; })) {
                        _this.removeEntityTypeFromService(obj);
                    }
                });
            }
        }
        else {
            this.availableEntityTypes = this.marketEntityTypes;
        }
    };
    CreateOrEditServiceComponent.prototype.pricingMethodChanged = function (servicePricingMethod) {
        var _this = this;
        this.warningMessage = this.selectedPricingMethod.warning;
        this.service.serviceMappings.forEach(function (mapping) {
            //Update previous variables as deleted because they cant be used with another pricing method
            mapping.servicePricingVariable.forEach(function (variable) { variable.isDeleted = true; });
            var deletedVariables = mapping.servicePricingVariable.filter(function (x) { return x.id > 0; });
            var newVariables = _this.createPricingVariable(servicePricingMethod, mapping.id);
            //New variables have to be first for forms to work correct , deleted variables have to get passed to api to get removed
            mapping.servicePricingVariable = newVariables.concat(deletedVariables);
        });
    };
    CreateOrEditServiceComponent.prototype.createPricingVariable = function (servicePricingMethod, serviceMappingId) {
        if (serviceMappingId === void 0) { serviceMappingId = 0; }
        var array = [];
        servicePricingMethod.servicePricingMethodVariableTypeLinks.forEach(function (element) {
            var servicePricingVar = new ServicePricingVariableDto();
            servicePricingVar.servicePriceVariableType = element.servicePriceVariableType;
            servicePricingVar.servicePriceVariableTypeId = element.servicePriceVariableTypeId;
            servicePricingVar.serviceMappingId = serviceMappingId;
            array.push(servicePricingVar);
        });
        return array;
    };
    CreateOrEditServiceComponent.prototype.save = function () {
        var _this = this;
        if (this.service.serviceMappings.length === 0 && this.applicationMethod.value == this.serviceApplicationType.Custom) {
            abp.message.error('Please add mappings before saving');
            return;
        }
        if (this.service.serviceEntityTypeLinks.length === 0) {
            abp.message.error('Please selected the types linked to this service before saving');
            return;
        }
        //remove mapping that have been deleted on the front end and dont need to be deleted in the database
        this.service.serviceMappings = this.service.serviceMappings.filter(function (obj) { return !obj.isDeleted || obj.id > 0; });
        var saveService = new CreateOrUpdateServiceInput({ service: this.service });
        saveService.service.market = this.market;
        if (abp.session.tenantId && this.customerDetail) {
            saveService.service.customerId = parseInt(this.customerDetail.value);
        }
        saveService.service.servicePricingMethodId = this.selectedPricingMethod.id;
        this._digitalProxy.createOrUpdateService(saveService)
            .subscribe(function (success) {
            abp.notify.success(_this.serviceItemId ? 'The service was updated' : 'New service added successfully', 'Service');
            _this._router.navigate(['app/admin/vendor/' + AppMarketsDesc[_this.market].toLowerCase(), 'configure-digital-services']);
        }, function (error) {
            abp.notify.error('There was an error while saving this service : ' + error, 'Saving Error');
        });
    };
    CreateOrEditServiceComponent.prototype.checkMultipleMappingSelected = function () {
        this.multipleMappingSelected = this.service.serviceMappings.some(function (obj) { return !obj.isDeleted && (obj.criteria != null || obj.serviceEntityTypeId != null || obj.propertyId != null); });
    };
    CreateOrEditServiceComponent.prototype.loadService = function (serviceItemId) {
        var _this = this;
        abp.ui.setBusy();
        this._digitalProxy.getServiceById(serviceItemId)
            .pipe(finalize(function () { abp.ui.clearBusy(); }))
            .subscribe(function (item) {
            _this.service = item.services;
            _this.originalService = item.services;
            _this.applicationMethod = _this.appMethodList.find(function (x) { return x.value === _this.service.applicationType; });
            _this.changeApplicationMethod(_this.applicationMethod);
            _this.updateServicePropertyMappings();
            _this.selectedEntityTypes = new Array();
            _this.availableEntityTypes.forEach(function (entityType) {
                if (_this.service.serviceEntityTypeLinks.findIndex(function (obj) { return obj.serviceEntityTypeId == entityType.id; }) > -1) {
                    _this.selectedEntityTypes.push(entityType);
                }
            });
            //if any of the service entity types doesn't allow property mapping then don't allow multiple mapping to be setup
            _this.allowMultiMapping = !_this.selectedEntityTypes.some(function (obj) { return !obj.allowPropertyMapping; });
            _this.adjustPricingMethodForAdhoc(_this.applicationMethod.value);
            _this.selectedPricingMethod = _this.priceMethods.find(function (x) { return x.id === _this.service.servicePricingMethodId; });
            _this.warningMessage = _this.selectedPricingMethod.warning;
            _this.hasServicesLinked = _this.originalService.hasServicesLinked;
            if (item.services.customerId) {
                _this.getCustomer(item.services.customerId);
            }
            _this.active = true;
        }, function (error) {
            abp.notify.error('Error collecting data for service id :' + serviceItemId, 'Error Collecting Data');
        });
    };
    CreateOrEditServiceComponent.prototype.adjustPricingMethodForAdhoc = function (applicationMethod) {
        var _this = this;
        if (applicationMethod != null) {
            switch (applicationMethod) {
                case ServiceApplicationType.AdHoc:
                    this.filteredPriceMethods = this.priceMethods;
                    break;
                default:
                    this.filteredPriceMethods = this.priceMethods.filter(function (x) { return x.id !== _this.servicesPricingMethodTypes.CustomPrice; });
                    break;
            }
        }
    };
    CreateOrEditServiceComponent.prototype.compareFn = function (optionOne, optionTwo) {
        return optionOne === optionTwo;
    };
    CreateOrEditServiceComponent.prototype.getCustomer = function (customerId) {
        var _this = this;
        this._customersServiceProxy
            .getCustomerById(customerId)
            .pipe(finalize(function () { abp.ui.clearBusy(); }))
            .subscribe(function (customerResult) {
            _this.customerDetail = new NameValueDto({
                name: customerResult.customer.name,
                value: customerResult.customer.id.toString()
            });
        });
    };
    CreateOrEditServiceComponent.prototype.filterCustomerSingle = function (event) {
        var _this = this;
        this._customersServiceProxy.findCustomersLookup(new FindCustomersLookupInput({
            filter: event.query,
            skipCount: 0,
            maxResultCount: 30,
            tenantId: this.sessionTenant
        }))
            .subscribe(function (customers) {
            _this.filteredCustomers = customers.items;
        });
    };
    CreateOrEditServiceComponent.prototype.updateServiceMappings = function (data) {
        this.service.serviceMappings = data;
    };
    CreateOrEditServiceComponent.prototype.getButtonGroupDataToggle = function () {
        if (this.allowMultiMapping) {
            return 'buttons';
        }
        else {
            return '';
        }
    };
    CreateOrEditServiceComponent.prototype.propertyMappingChange = function (event, type) {
        var _this = this;
        if (!this.allowMultiMapping)
            return;
        this.checkMultipleMappingSelected();
        if (type === AppConsts.SingleMapping && this.multipleMappingSelected) {
            abp.message.confirm("You currently have multiple values mapped, by selecting single mapping the mappings currently setup will be deleted do you want to continue?", function (result) {
                if (result) {
                    //remove mappings
                    _this.service.serviceMappings
                        .forEach(function (obj) { return obj.isDeleted = true; });
                    //Add single value
                    _this.addServiceMapping();
                    _this.toggleMappingValue(type);
                }
                else {
                    event.target.classList.remove("active");
                    event.target.nextSibling.classList.add("active");
                }
            });
        }
        else {
            this.toggleMappingValue(type);
        }
    };
    CreateOrEditServiceComponent.prototype.updateServicePropertyMappings = function () {
        var selectedType = ServicePropertyMappingType.SingleMapping;
        if (this.multipleMappingSelected == true) {
            selectedType = ServicePropertyMappingType.MultipleMapping;
        }
        this.servicePropertyMappingList.forEach(function (element) {
            if (element.value === selectedType) {
                element.isActive = true;
            }
            else {
                element.isActive = false;
            }
        });
    };
    CreateOrEditServiceComponent.prototype.toggleMappingValue = function (type) {
        if (type === AppConsts.MultipleMapping) {
            this.multipleMappingSelected = true;
        }
        else {
            this.multipleMappingSelected = false;
        }
        this.updateServicePropertyMappings();
        this.adjustPricingMethodForAdhoc(this.applicationMethod.value);
    };
    return CreateOrEditServiceComponent;
}(AppComponentBase));
export { CreateOrEditServiceComponent };
