import { OnInit } from '@angular/core';
var PaymentMethodsBannerComponent = /** @class */ (function () {
    function PaymentMethodsBannerComponent() {
        this.assetsImagesFolder = './assets/payment/methods';
        this.americanExpress = this.assetsImagesFolder + "/american-express-blue.png";
        this.dinersClub = this.assetsImagesFolder + "/diners-club-blue.png";
        this.visa = this.assetsImagesFolder + "/visa-blue.png";
        this.verifiedByVisa = this.assetsImagesFolder + "/visa-verified-blue.png";
        this.masterpass = this.assetsImagesFolder + "/masterpass.png";
        this.paygateDpoCompany = this.assetsImagesFolder + "/paygate-dpo-company.png";
        this.paymentMethods = [{
                name: 'americanExpress',
                link: "" + this.americanExpress,
                order: 3
            },
            {
                name: 'paygateDpoCompany',
                link: "" + this.paygateDpoCompany,
                order: -1
            },
            {
                name: 'dinersClub',
                link: "" + this.dinersClub,
                order: 4
            },
            {
                name: 'visa',
                link: "" + this.visa,
                order: 1
            },
            {
                name: 'verifiedByVisa',
                link: "" + this.verifiedByVisa,
                order: 2
            },
            {
                name: 'masterpass',
                link: "" + this.masterpass,
                order: 5
            }];
        this.paymentMethods = this.paymentMethods.sort(function (a, b) { return (a.order < b.order ? -1 : 1); });
    }
    PaymentMethodsBannerComponent.prototype.ngOnInit = function () {
    };
    return PaymentMethodsBannerComponent;
}());
export { PaymentMethodsBannerComponent };
