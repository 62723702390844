var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterContentChecked, AfterViewChecked, ElementRef, EventEmitter, Injector } from '@angular/core';
import { AppTimezoneScope } from '@shared/AppEnums';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { ProfileServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { SmsVerificationModalComponent } from './sms-verification-modal.component';
import { finalize } from 'rxjs/operators';
import { TabsetComponent } from "@node_modules/ngx-bootstrap";
var MySettingsModalComponent = /** @class */ (function (_super) {
    __extends(MySettingsModalComponent, _super);
    function MySettingsModalComponent(injector, _profileService, _appSessionService) {
        var _this = _super.call(this, injector) || this;
        _this._profileService = _profileService;
        _this._appSessionService = _appSessionService;
        _this.modalSave = new EventEmitter();
        _this.active = false;
        _this.saving = false;
        _this.isGoogleAuthenticatorEnabled = false;
        _this.isSmsAuthenticatorEnabled = false;
        _this.isEmailAuthenticatorEnabled = false;
        _this.isEmailConfirmed = false;
        _this.showTimezoneSelection = abp.clock.provider.supportsMultipleTimezone;
        _this.canChangeUserName = false;
        _this.defaultTimezoneScope = AppTimezoneScope.User;
        _this._initialTimezone = undefined;
        _this.focusTab = undefined;
        return _this;
    }
    MySettingsModalComponent.prototype.ngAfterViewChecked = function () {
        //Temporary fix for: https://github.com/valor-software/ngx-bootstrap/issues/1508
        $('tabset ul.nav').addClass('m-tabs-line');
        $('tabset ul.nav li a.nav-link').addClass('m-tabs__link');
    };
    MySettingsModalComponent.prototype.ngAfterContentChecked = function () {
        var _this = this;
        if (this.settingsTabSet && this.focusTab) {
            var selectedTab = this.settingsTabSet.tabs.find(function (tab) { return tab.id === _this.focusTab; });
            if (selectedTab) {
                selectedTab.active = true;
                this.focusTab = undefined;
            }
        }
    };
    MySettingsModalComponent.prototype.show = function () {
        this.getUserDetails();
    };
    MySettingsModalComponent.prototype.getUserDetails = function (tabName) {
        var _this = this;
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe(function (result) {
            _this.smsEnabled = _this.setting.getBoolean('App.UserManagement.SmsVerificationEnabled');
            _this.user = result;
            _this._initialTimezone = result.timezone;
            _this.isGoogleAuthenticatorEnabled = result.isGoogleAuthenticatorEnabled;
            _this.isEmailAuthenticatorEnabled = result.isEmailAuthEnabled;
            _this.isSmsAuthenticatorEnabled = result.isSmsAuthEnabled;
            _this.isPhoneNumberConfirmed = result.isPhoneNumberConfirmed;
            if (_this.isPhoneNumberConfirmed) {
                _this.wasPhoneNumberConfirmed = true;
            }
            if (tabName) {
                _this.settingsTabSet.tabs[tabName].active = true;
            }
            if (!_this.wasPhoneNumberConfirmed) {
                _this.modal.config = {
                    backdrop: 'static',
                    keyboard: false
                };
            }
            _this.modal.show();
        });
    };
    MySettingsModalComponent.prototype.showTwoFactor = function () {
        this.focusTab = 'twoFactorSettings';
        this.getUserDetails();
    };
    MySettingsModalComponent.prototype.updateQrCodeSetupImageUrl = function (isEnabled) {
        var _this = this;
        this._profileService.updateGoogleAuthenticatorKey(isEnabled).subscribe(function (result) {
            _this.user.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
            _this.isGoogleAuthenticatorEnabled = result.isEnabled;
            _this.user.isGoogleAuthenticatorEnabled = result.isEnabled;
        });
    };
    MySettingsModalComponent.prototype.updateSmsAuthenticationStatus = function () {
        this.isSmsAuthenticatorEnabled = !this.isSmsAuthenticatorEnabled;
        this.user.isSmsAuthEnabled = this.isSmsAuthenticatorEnabled;
    };
    MySettingsModalComponent.prototype.updateEmailAuthenticationStatus = function () {
        this.isEmailAuthenticatorEnabled = !this.isEmailAuthenticatorEnabled;
        this.user.isEmailAuthEnabled = this.isEmailAuthenticatorEnabled;
    };
    MySettingsModalComponent.prototype.smsVerify = function () {
        var _this = this;
        this._profileService.sendVerificationSms(this.user.phoneNumber)
            .subscribe(function () {
            _this.smsVerificationModal.show();
        });
    };
    MySettingsModalComponent.prototype.changePhoneNumberToVerified = function () {
        this.isPhoneNumberConfirmed = true;
    };
    MySettingsModalComponent.prototype.onShown = function () {
        $(this.nameInput.nativeElement).focus();
    };
    MySettingsModalComponent.prototype.onPhoneNumberChanged = function () {
        this.isPhoneNumberConfirmed = false;
    };
    MySettingsModalComponent.prototype.close = function () {
        this.active = false;
        this.modal.hide();
    };
    MySettingsModalComponent.prototype.save = function () {
        var _this = this;
        if (this.isSmsAuthenticatorEnabled && !this.isPhoneNumberConfirmed) {
            this.notify.warn(this.l('YourPhoneNumberIsNotVerified'));
            return;
        }
        this.saving = true;
        this._profileService.updateCurrentUserProfile(this.user)
            .pipe(finalize(function () { _this.saving = false; }))
            .subscribe(function () {
            _this._appSessionService.user.name = _this.user.name;
            _this._appSessionService.user.surname = _this.user.surname;
            _this._appSessionService.user.userName = _this.user.userName;
            _this._appSessionService.user.emailAddress = _this.user.emailAddress;
            _this.notify.info(_this.l('SavedSuccessfully'));
            _this.close();
            _this.modalSave.emit(null);
            if (abp.clock.provider.supportsMultipleTimezone && _this._initialTimezone !== _this.user.timezone) {
                _this.message.info(_this.l('TimeZoneSettingChangedRefreshPageNotification')).done(function () {
                    window.location.reload();
                });
            }
        });
    };
    return MySettingsModalComponent;
}(AppComponentBase));
export { MySettingsModalComponent };
