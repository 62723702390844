import {Injectable, OnInit} from '@angular/core';
import {FlatPermissionDto, PermissionServiceProxy} from '../../../../shared/service-proxies/service-proxies';
import {ReplaySubject} from "@node_modules/rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImpersonatorPermissionsService implements OnInit {

    protected permissions: FlatPermissionDto[];
    loadingPermissions: boolean = false;
    public permissionsLoaded: ReplaySubject<boolean> = new ReplaySubject(1);

    constructor(
        protected permissionsService: PermissionServiceProxy
    ) {
    }

    ngOnInit() {
        this.loadPermissions();
    }

    //Load and store impersonator permissions for checking
    loadPermissions() {

        this.loadingPermissions = true;

        this.permissionsService.getAllImpersonatorPermissions().subscribe(results => {
            this.permissions = results.items;
            this.loadingPermissions = false;
            this.permissionsLoaded.next(true);
        });
    }

    //is permission granted
    isGranted(permissionName: string) {

        if (!this.permissions) {

            if (!this.loadingPermissions) {
                this.loadPermissions();
            }

            return false;
        }

        return this.permissions.some(item => item.name == permissionName);
    }
}
