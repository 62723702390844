import * as localForage from 'localforage';
var LocalStorageService = /** @class */ (function () {
    function LocalStorageService() {
    }
    LocalStorageService.prototype.getItem = function (key, callback) {
        if (!localForage) {
            return;
        }
        localForage.getItem(key, callback);
    };
    LocalStorageService.prototype.setItem = function (key, value) {
        if (!localForage) {
            return;
        }
        if (value === null) {
            value = undefined;
        }
        localForage.setItem(key, value);
    };
    return LocalStorageService;
}());
export { LocalStorageService };
