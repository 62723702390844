import { OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DialogSeverity } from "./generic-dialog-models";

export class DialogComponentBase {

    iconClass = ''
    title = '';
    message = '';
    showCancel = false;
    confirmText: string = "Ok";
    cancelText: string = "Cancel";
    severity = DialogSeverity.Info;

    //modalClass = '';

    constructor(public activeModal: NgbActiveModal) {
    }

    init() {
        if (!this.iconClass)
            this.getDefaultSeverityIcon();
    }

    getDefaultSeverityIcon() {
        var s = this.severity;
        if (s == DialogSeverity.Info)
            this.iconClass = 'fa fa-info-circle';
        else if (s == DialogSeverity.Question)
            this.iconClass = 'fa fa-question-circle';
        else if (s == DialogSeverity.Warn)
            this.iconClass = 'fa fa-exclamation-circle';
        else if (s == DialogSeverity.Danger)
            this.iconClass = 'fa fa-exclamation-triangle';
    }
}