var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MenuItemUsers } from "../app-menu-item";
import { AppComponentBase } from "@shared/common/app-component-base";
import { AppConsts } from "@shared/AppConsts";
var TopMenuBase = /** @class */ (function (_super) {
    __extends(TopMenuBase, _super);
    function TopMenuBase(appSessionService, appNavigationService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.appSessionService = appSessionService;
        _this.appNavigationService = appNavigationService;
        _this.userId = abp.session.userId;
        _this.tenantId = abp.session.tenantId;
        _this.isLoggedIn = _this.userId != null && _this.userId > 0;
        _this.isPartner = _this.isLoggedIn && _this.tenantId > 0;
        _this.isHost = _this.isLoggedIn && !_this.isPartner;
        _this.customDomain = AppConsts.customDomain;
        _this.canViewCloud = _this.feature.isEnabled('App.WhiteLabeling.Cloud') && !_this.customDomain;
        _this.hasAxizContentFeature = _this.feature.isEnabled("App.WhiteLabeling.AxizContent") && !_this.customDomain;
        _this.whitelabeling = _this.feature.isEnabled("App.WhiteLabeling") && _this.customDomain;
        _this.showAxizTopMenuLinks = _this.feature.isEnabled("App.WhiteLabeling.ShowAxizTopMenuLinks") && !_this.customDomain;
        return _this;
    }
    TopMenuBase.prototype.showMenuItem = function (menuItem) {
        if (!menuItem.users || menuItem.users.length == 0)
            return this.hasPermission(menuItem);
        if (this.isHost && menuItem.users.indexOf(MenuItemUsers.Host) > -1)
            return true;
        if (!this.isLoggedIn && menuItem.users.indexOf(MenuItemUsers.Guest) > -1 && !this.canViewCloud && menuItem.name == "Cloud")
            return false;
        if ((menuItem.name == "More About Us" || menuItem.name == "What We Do" || menuItem.name == "Our Vendor Partners" || menuItem.name == "Get In Touch") && this.showAxizTopMenuLinks)
            return true;
        if (!this.isLoggedIn && menuItem.users.indexOf(MenuItemUsers.Guest) > -1)
            return true;
        var currentUserType = this.getUserType();
        if (menuItem.users.indexOf(currentUserType) > -1)
            return this.hasPermission(menuItem);
        return false;
    };
    TopMenuBase.prototype.getUserType = function () {
        if (!this.isLoggedIn)
            return MenuItemUsers.Guest;
        if (this.isHost)
            return MenuItemUsers.Host;
        if (this.isPartner)
            return MenuItemUsers.Tenant;
        return null;
    };
    TopMenuBase.prototype.hasPermission = function (menuItem) {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this.appSessionService.tenant && !this.appSessionService.tenant.edition) {
            return false;
        }
        if (menuItem.orPermissionNames && menuItem.orPermissionNames.length > 0) {
            for (var key in menuItem.orPermissionNames) {
                if (this.permission.isGranted(menuItem.orPermissionNames[key])) {
                    return true;
                }
            }
            return false;
        }
        if (menuItem.permissionName) {
            return this.permission.isGranted(menuItem.permissionName);
        }
        if (this.hasChildren(menuItem)) {
            return this.appNavigationService.checkChildMenuItemPermission(menuItem);
        }
        return true;
    };
    TopMenuBase.prototype.getDisplayableChildren = function (menuItem) {
        var _this = this;
        return menuItem.items.filter(function (m) { return _this.showMenuItem(m); });
    };
    TopMenuBase.prototype.hasChildren = function (menuItem) {
        return menuItem.items && menuItem.items.length > 0 && this.getDisplayableChildren(menuItem).length > 0;
    };
    TopMenuBase.prototype.getFullActionLink = function (actionLink) {
        if (actionLink.startsWith('#')) {
            var url = window.location.href;
            if (url.indexOf('#') > -1) {
                url = url.substr(0, url.indexOf('#'));
            }
            actionLink = "" + url + actionLink;
        }
        return actionLink;
    };
    TopMenuBase.prototype.isImageLink = function (menuItem) {
        if (menuItem.imageLink && menuItem.imageLink.defaultImageSrc)
            return true;
        return false;
    };
    TopMenuBase.prototype.isActionLink = function (menuItem) {
        if (!this.isImageLink(menuItem) && (menuItem.route || menuItem.actionLink))
            return true;
        return false;
    };
    TopMenuBase.prototype.isParentLink = function (menuItem) {
        return menuItem && menuItem.items && this.getDisplayableChildren(menuItem).length > 0;
    };
    return TopMenuBase;
}(AppComponentBase));
export { TopMenuBase };
