import { JwtHelperService } from "@auth0/angular-jwt";
import { AppPreBootstrap } from "AppPreBootstrap";
import * as i0 from "@angular/core";
var TokenManagementService = /** @class */ (function () {
    function TokenManagementService() {
    }
    TokenManagementService.prototype.getTokenExpiryDateTime = function () {
        var myRawToken = abp.auth.getToken();
        var jwtHelper = new JwtHelperService();
        return jwtHelper.getTokenExpirationDate(myRawToken);
    };
    TokenManagementService.prototype.isCurrentTokenValid = function () {
        return this.getTokenExpiryDateTime() > new Date();
    };
    TokenManagementService.prototype.refreshToken = function (callbackFunction) {
        if (callbackFunction === void 0) { callbackFunction = null; }
        return AppPreBootstrap.RefreshToken(abp.auth.getToken(), callbackFunction);
    };
    TokenManagementService.ngInjectableDef = i0.defineInjectable({ factory: function TokenManagementService_Factory() { return new TokenManagementService(); }, token: TokenManagementService, providedIn: "root" });
    return TokenManagementService;
}());
export { TokenManagementService };
