import { Injectable, NgModuleRef } from '@angular/core';
import { NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { SessionExpiryDialogComponent } from '../components/session-expiry-dialog/session-expiry-dialog.component';

@Injectable()
export class DialogService {

    constructor(private modalService: NgbModal) { }

    public confirm() {
        const modalRef = this.modalService.open(SessionExpiryDialogComponent);

        let instance = (modalRef as any)._windowCmptRef.instance
        instance.windowClass = '';

        setTimeout(() => {
            instance.windowClass = 'custom-show';
        }, 0)

        let fx = (modalRef as any)._removeModalElements.bind(modalRef);
        (modalRef as any)._removeModalElements = () => {
            instance.windowClass = '';
            setTimeout(fx, 250);
        }

        modalRef.componentInstance.changeRef.markForCheck();
        return modalRef.result;
    }

    public open(title: string, message: string, showCancel: boolean = false, confirmText: string = "Ok", cancelText: string = "Cancel",
        options: NgbModalOptions = { size: 'sm' }, showRefreshHint: boolean = true) {
        const modalRef = this.modalService.open(SessionExpiryDialogComponent, options);

        let instance = (modalRef as any)._windowCmptRef.instance
      
        setTimeout(() => {
            instance.windowClass = 'custom-show';
        }, 0)

        let fx = (modalRef as any)._removeModalElements.bind(modalRef);
        (modalRef as any)._removeModalElements = () => {
            instance.windowClass = "";
            setTimeout(fx, 250);
        }

        modalRef.componentInstance.title = title
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.showCancel = showCancel;
        modalRef.componentInstance.confirmText = confirmText;
        modalRef.componentInstance.cancelText = cancelText;
        modalRef.componentInstance.showRefreshHint = showRefreshHint;
        modalRef.componentInstance.changeRef.markForCheck();
        return modalRef.result;
    }
}
