import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { DetailDialogComponent } from './detail-dialog/detail-dialog.component';
import { DialogOptions, DialogType } from './generic-dialog-models';
import { SummaryDialogComponent } from './summary-dialog/summary-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class GenericDialogService {

  options: DialogOptions;
  constructor(private modalService: NgbModal) { }

  private open(): Promise<any> {

    var options: NgbModalOptions = { centered: true};
    if (this.options.dialogType == DialogType.Detail) {
      options.size = 'lg';
      var modalRef = this.modalService.open(DetailDialogComponent, options);
    } else {
      options.size = 'sm';
      var modalRef = this.modalService.open(SummaryDialogComponent, options);
    }

    let instance = (modalRef as any)._windowCmptRef.instance

    setTimeout(() => {
      instance.windowClass = 'custom-show';
    }, 0)

    let fx = (modalRef as any)._removeModalElements.bind(modalRef);
    (modalRef as any)._removeModalElements = () => {
      instance.windowClass = "";
      setTimeout(fx, 250);
    }

    modalRef.componentInstance.iconClass = this.options.iconClass;
    modalRef.componentInstance.title = this.options.title
    modalRef.componentInstance.message = this.options.message;
    modalRef.componentInstance.showCancel = this.options.showCancel;
    modalRef.componentInstance.confirmText = this.options.confirmText;
    modalRef.componentInstance.cancelText = this.options.cancelText;
    modalRef.componentInstance.severity = this.options.dialogSeverity;

    return modalRef.result;
  }

  // Just to stick to our observable pattern (instead of promises)
  public openDialog(options: DialogOptions) {
    this.options = options;
    return from(this.open());
  }
}


