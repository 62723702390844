<div id="dialog-containter">
  <div [ngClass]="{'primary': severity <= 1, 'warn' : severity == 2, 'danger': severity == 3 }">
    <div *ngIf="iconClass" id="icon-section">
      <i [class]="iconClass + ' severity-color'"></i>
    </div>
    <div id="header-section">
      <span>{{ title }}</span>
    </div>
    <div id="msg-section">
      <p>
        {{message}}
      </p>
    </div>
    <div id="footer-section">
      <button type="button" class="btn btn-primary" (click)="activeModal.close(true)">{{ confirmText }}</button>
      <button *ngIf="showCancel" type="button" class="btn btn-secondary" (click)="activeModal.close(false)">
        {{ cancelText}}</button>
    </div>
  </div>
</div>