var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from "@shared/common/app-component-base";
import { ModalDirective } from "@node_modules/ngx-bootstrap";
var UserTwoFactorAlertComponent = /** @class */ (function (_super) {
    __extends(UserTwoFactorAlertComponent, _super);
    function UserTwoFactorAlertComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.onConfigureClick = new EventEmitter();
        return _this;
    }
    UserTwoFactorAlertComponent.prototype.show = function () {
        this.active = true;
        this.myModal.config = {
            backdrop: 'static',
            keyboard: false,
        };
        this.myModal.show();
    };
    UserTwoFactorAlertComponent.prototype.onConfigureClickEvent = function () {
        this.onConfigureClick.emit(true);
        this.close();
    };
    UserTwoFactorAlertComponent.prototype.close = function () {
        this.myModal.hide();
    };
    return UserTwoFactorAlertComponent;
}(AppComponentBase));
export { UserTwoFactorAlertComponent };
