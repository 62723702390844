/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./friend-profile-picture.component";
import * as i2 from "../service-proxies/service-proxies";
var styles_FriendProfilePictureComponent = [];
var RenderType_FriendProfilePictureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FriendProfilePictureComponent, data: {} });
export { RenderType_FriendProfilePictureComponent as RenderType_FriendProfilePictureComponent };
export function View_FriendProfilePictureComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "..."]], [[8, "src", 4], [8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profilePicture; var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.cssClass, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_FriendProfilePictureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "friend-profile-picture", [], null, null, null, View_FriendProfilePictureComponent_0, RenderType_FriendProfilePictureComponent)), i0.ɵdid(1, 4243456, null, 0, i1.FriendProfilePictureComponent, [i2.ProfileServiceProxy], null, null)], null, null); }
var FriendProfilePictureComponentNgFactory = i0.ɵccf("friend-profile-picture", i1.FriendProfilePictureComponent, View_FriendProfilePictureComponent_Host_0, { profilePictureId: "profilePictureId", userId: "userId", tenantId: "tenantId", cssClass: "cssClass" }, {}, []);
export { FriendProfilePictureComponentNgFactory as FriendProfilePictureComponentNgFactory };
