import {
    AfterContentChecked,
    AfterContentInit,
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Output,
    ViewChild
} from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppTimezoneScope } from '@shared/AppEnums';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { CurrentUserProfileEditDto, DefaultTimezoneScope, ProfileServiceProxy, UpdateGoogleAuthenticatorKeyOutput } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { SmsVerificationModalComponent } from './sms-verification-modal.component';
import { finalize } from 'rxjs/operators';
import {TabsetComponent} from "@node_modules/ngx-bootstrap";

@Component({
    selector: 'mySettingsModal',
    templateUrl: './my-settings-modal.component.html'
})
export class MySettingsModalComponent extends AppComponentBase implements AfterViewChecked, AfterContentChecked {

    @ViewChild('settingsTabSet') public settingsTabSet: TabsetComponent;

    @ViewChild('nameInput') nameInput: ElementRef;
    @ViewChild('mySettingsModal') modal: ModalDirective;
    @ViewChild('smsVerificationModal') smsVerificationModal: SmsVerificationModalComponent;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public active = false;
    public saving = false;
    public isGoogleAuthenticatorEnabled = false;
    public isSmsAuthenticatorEnabled = false;
    public isEmailAuthenticatorEnabled = false;
    public isEmailConfirmed = false;
    public isPhoneNumberConfirmed: boolean;
    public wasPhoneNumberConfirmed: boolean;
    public smsEnabled: boolean;
    public user: CurrentUserProfileEditDto;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean = false;
    public defaultTimezoneScope: DefaultTimezoneScope = AppTimezoneScope.User;
    private _initialTimezone: string = undefined;
    private focusTab: string = undefined;

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _appSessionService: AppSessionService
    ) {
        super(injector);
    }

    ngAfterViewChecked(): void {
        //Temporary fix for: https://github.com/valor-software/ngx-bootstrap/issues/1508
        $('tabset ul.nav').addClass('m-tabs-line');
        $('tabset ul.nav li a.nav-link').addClass('m-tabs__link');
    }
    
    ngAfterContentChecked() {
        if (this.settingsTabSet && this.focusTab){
            let selectedTab = this.settingsTabSet.tabs.find(tab => tab.id === this.focusTab);
            if (selectedTab) {
                selectedTab.active = true;
                this.focusTab = undefined;
            }
        }
    }

    show(): void {
        this.getUserDetails();
    }

    private getUserDetails(tabName?: string): void {
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe((result) => {
            this.smsEnabled = this.setting.getBoolean('App.UserManagement.SmsVerificationEnabled');
            this.user = result;
            this._initialTimezone = result.timezone;
            this.isGoogleAuthenticatorEnabled = result.isGoogleAuthenticatorEnabled;
            this.isEmailAuthenticatorEnabled = result.isEmailAuthEnabled;
            this.isSmsAuthenticatorEnabled = result.isSmsAuthEnabled;
            this.isPhoneNumberConfirmed = result.isPhoneNumberConfirmed;
            if(this.isPhoneNumberConfirmed){
                this.wasPhoneNumberConfirmed = true;
            }
            if (tabName) {
                this.settingsTabSet.tabs[tabName].active = true;
            }
            
            if(!this.wasPhoneNumberConfirmed){
                this.modal.config= {
                    backdrop: 'static',
                    keyboard: false
                }    
            }
            this.modal.show();
        });
    }

    showTwoFactor(): void{
        this.focusTab = 'twoFactorSettings';
        this.getUserDetails();
    }

    updateQrCodeSetupImageUrl(isEnabled:boolean): void {
        this._profileService.updateGoogleAuthenticatorKey(isEnabled).subscribe((result: UpdateGoogleAuthenticatorKeyOutput) => {
            this.user.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
            this.isGoogleAuthenticatorEnabled = result.isEnabled;
            this.user.isGoogleAuthenticatorEnabled = result.isEnabled;
        });
    }

    updateSmsAuthenticationStatus(): void {
        this.isSmsAuthenticatorEnabled = !this.isSmsAuthenticatorEnabled;
        this.user.isSmsAuthEnabled = this.isSmsAuthenticatorEnabled
    }

    updateEmailAuthenticationStatus():void {
        this.isEmailAuthenticatorEnabled = !this.isEmailAuthenticatorEnabled;
        this.user.isEmailAuthEnabled = this.isEmailAuthenticatorEnabled;
    }

    smsVerify(): void {
        this._profileService.sendVerificationSms(this.user.phoneNumber)
            .subscribe(() => {
                this.smsVerificationModal.show();
            });
    }

    changePhoneNumberToVerified(): void {
        this.isPhoneNumberConfirmed = true;
    }

    onShown(): void {
        $(this.nameInput.nativeElement).focus();
    }

    onPhoneNumberChanged(){
        this.isPhoneNumberConfirmed = false;
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        if(this.isSmsAuthenticatorEnabled && !this.isPhoneNumberConfirmed){
            this.notify.warn(this.l('YourPhoneNumberIsNotVerified'));
            return
        }

        this.saving = true;
        this._profileService.updateCurrentUserProfile(this.user)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                this._appSessionService.user.name = this.user.name;
                this._appSessionService.user.surname = this.user.surname;
                this._appSessionService.user.userName = this.user.userName;
                this._appSessionService.user.emailAddress = this.user.emailAddress;

                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);

                if (abp.clock.provider.supportsMultipleTimezone && this._initialTimezone !== this.user.timezone) {
                    this.message.info(this.l('TimeZoneSettingChangedRefreshPageNotification')).done(() => {
                        window.location.reload();
                    });
                }
            });
    }
}
