var AppMenu = /** @class */ (function () {
    function AppMenu(name, displayName, items) {
        this.name = '';
        this.displayName = '';
        this.name = name;
        this.displayName = displayName;
        this.items = items;
    }
    return AppMenu;
}());
export { AppMenu };
