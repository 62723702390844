import { Component, OnInit, Injector } from '@angular/core';
import { AppMarkets } from '@shared/AppEnums';
import { ProductsServiceProxy, FindInput, DynamicOutput } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
	selector: 'app-brand-loading',
	template: `
    <p>
    	brand-loading works!
    </p>
	`,
	styles: []
})
export class BrandLoadingComponent extends AppComponentBase implements OnInit {

	loading = false;
	processing: boolean;

	get GeneralMarket() {
		return AppMarkets.GeneralMarket;
	}

	constructor(
		injector: Injector,
		protected _productService: ProductsServiceProxy,
	) {
		super(injector);
	}

	ngOnInit() {
	}

	async fetchProductConfiguration(brandId: number, productId: string, market: any): Promise<any> {

		let findInput = new FindInput({
			market: this.GeneralMarket,
			identifier: productId,
			additionalInfo: {
				brand: brandId
			},
		});
		let productConfig: any;

		await this._productService
			.findProductConfiguration(findInput).toPromise().then
			(productConfigs => {
				productConfig = productConfigs;
			});

		return productConfig;
	}
}
