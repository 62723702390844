var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap';
import { Paginator } from 'primeng/components/paginator/paginator';
import { Table } from 'primeng/components/table/table';
//For more modal options http://valor-software.com/ngx-bootstrap/#/modals#modal-directive
var AppLookupComponentBase = /** @class */ (function (_super) {
    __extends(AppLookupComponentBase, _super);
    function AppLookupComponentBase(injector) {
        var _this = _super.call(this, injector) || this;
        _this.itemSelected = new EventEmitter();
        _this.isShown = false;
        _this.isInitialized = false;
        _this.filterText = '';
        _this.tenantId = null;
        return _this;
    }
    AppLookupComponentBase.prototype.configure = function (options) {
        this.options = $.extend(true, {
            title: this.l('SelectAnItem')
        }, AppLookupComponentBase.defaultOptions, options);
    };
    AppLookupComponentBase.prototype.show = function () {
        if (!this.options) {
            throw Error('Should call CommonLookupModalComponent.configure once before CommonLookupModalComponent.show!');
        }
        this.modal.show();
    };
    AppLookupComponentBase.prototype.refreshTable = function () {
        this.paginator.changePage(this.paginator.getPage());
    };
    AppLookupComponentBase.prototype.close = function () {
        this.modal.hide();
    };
    AppLookupComponentBase.prototype.shown = function () {
        this.isShown = true;
        this.getRecordsIfNeeds(null);
    };
    AppLookupComponentBase.prototype.getRecordsIfNeeds = function (event) {
        if (!this.isShown) {
            return;
        }
        if (!this.options.loadOnStartup && !this.isInitialized) {
            return;
        }
        this.getRecords(event);
        this.isInitialized = true;
    };
    AppLookupComponentBase.prototype.getRecords = function (event) {
        var _this = this;
        var maxResultCount = this.primengTableHelper.getMaxResultCount(this.paginator, event);
        var skipCount = this.primengTableHelper.getSkipCount(this.paginator, event);
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }
        this.primengTableHelper.showLoadingIndicator();
        this.options
            .dataSource(skipCount, maxResultCount, this.filterText, this.tenantId)
            .subscribe(function (result) {
            _this.primengTableHelper.totalRecordsCount = result.totalCount;
            _this.primengTableHelper.records = result.items;
            _this.primengTableHelper.hideLoadingIndicator();
        });
    };
    AppLookupComponentBase.prototype.selectItem = function (item) {
        var _this = this;
        this.selectedItem = item;
        var boolOrPromise = this.options.canSelect(item);
        if (!boolOrPromise) {
            return;
        }
        if (boolOrPromise === true) {
            this.itemSelected.emit(item);
            this.close();
            return;
        }
        //assume as observable
        boolOrPromise
            .subscribe(function (result) {
            if (result) {
                _this.itemSelected.emit(item);
                _this.close();
            }
        });
    };
    AppLookupComponentBase.defaultOptions = {
        dataSource: null,
        canSelect: function () { return true; },
        loadOnStartup: true,
        isFilterEnabled: true,
        pageSize: AppConsts.grid.defaultPageSize
    };
    return AppLookupComponentBase;
}(AppComponentBase));
export { AppLookupComponentBase };
