import {Injectable} from '@angular/core';
import {Observable, Subject} from "@node_modules/rxjs";
import {TokenAuthServiceProxy, UserTwoFactorAuthDetailsModel} from "@shared/service-proxies/service-proxies";

@Injectable({
    providedIn: 'root'
})
export class UserAccountService {

    constructor(private _tokenAuthService: TokenAuthServiceProxy,) {
    }

    getTwoFactorStatus(): Observable<UserTwoFactorAuthDetailsModel> {

        let userTwoFactorAuthDetailsSubject = new Subject<UserTwoFactorAuthDetailsModel>();
        if (!abp.session.userId || abp.session.impersonatorUserId)  {
            userTwoFactorAuthDetailsSubject.next(new UserTwoFactorAuthDetailsModel());
        } else {
            this._tokenAuthService.getUserTwoFactorAuthStatus(
                abp.session.tenantId != null ? abp.session.tenantId : undefined,
                abp.session.userId)
                .subscribe((result) => {
                    userTwoFactorAuthDetailsSubject.next(result);
                });
        }

        return userTwoFactorAuthDetailsSubject.asObservable();
    }
}
