import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import { AppMarkets } from '@shared/AppEnums';
import { AppSessionService } from '@shared/common/session/app-session.service';
import {
    ProductsServiceProxy,
    SearchInput,
    ViewListRequestInputDto, ViewOption,
    ViewServiceProxy
} from '@shared/service-proxies/service-proxies';
import { AppMenu } from '../../app-menu';
import { AppMenuItem, MenuItemUsers } from '../../app-menu-item';
import { AppNavigationService } from '../../app-navigation.service';
import { TopMenuBase } from '../top-menu-base';
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-mega-menu',
    templateUrl: './mega-menu.component.html',
    styleUrls: ['./mega-menu.component.less'],
})
export class MegaMenuComponent extends TopMenuBase implements OnInit {    
    userId = abp.session.userId;
    tenantId = abp.session.tenantId;
    menu: AppMenu;
    defaultHome = 'Digital Commerce';

    isHost = this.isLoggedIn && !this.isPartner;
    isLoggedIn = this.userId != null && this.userId > 0;
    canSeePublicViews: boolean = this.feature.isEnabled('App.Views.SeeGenericViewsInSearchBar') || !this.isLoggedIn || this.isHost;
    canSeeMarketPlace: boolean = this.feature.isEnabled('App.Markets.GeneralMarket') || !this.isLoggedIn || this.isHost;
    isPartner = this.isLoggedIn && this.tenantId > 0;

    assetsImagesFolder = './assets/common/images';

    activeArea = '';

    categoryName1: string;
    categoryName2: string;
    availableCategory1items: any = [];
    availableCategory2items: any[];
    selectedCategory1items: any = [];
    selectedCategory2items: any[] = [];
    isLoading: boolean = false;
    categories: any;
    stockOnHandFilterDropDown: any;
    selectedViewId: number = null;
    impersonatorName: string;
    impersonatorAccountNumber: string;
    impersonation: boolean = false;

    constructor(
        private navigationSvc: AppNavigationService,
        appSessionService: AppSessionService,
        private _productServiceProxy: ProductsServiceProxy,
        protected _viewService: ViewServiceProxy,
        injector: Injector,
        private activeRoute: ActivatedRoute
    ) {
        super(appSessionService, navigationSvc, injector);
    }

    ngOnInit() {
        this.loadFilterOtions();
        this.menu = this.navigationSvc.getMegaMenu();
        if(abp.session.impersonatorUserId > 0){
            this.impersonation = true;
            this.impersonatorName = this.appSession.tenant.name;
            this.impersonatorAccountNumber = this.appSession.tenant.accountNumber;
        }
    }

    isCurrentMarket(menuItem: AppMenuItem) : boolean {
        if (window.location.href.indexOf('?') == -1) return true;
        
        let queryString = window.location.href.split('?')[1];
        let marketQueryString = queryString ? queryString.split('&')[0].split('=') : null;
        let market = marketQueryString && marketQueryString.length > 1 && marketQueryString[0] === 'market' ? marketQueryString[1] : null;
        
        // return true if we do not have a market query string
        if (!market) return true;
        
        return market == menuItem.paramValue;
    }
    
    getMenuItemStateClass(menuItem: AppMenuItem) {
        let location = this.activeArea ? this.activeArea : window.location.pathname;
        let isMarketMatched = this.isCurrentMarket(menuItem);
        
        return location.toLowerCase() == menuItem.route.toLowerCase() && this.activeRoute.snapshot.queryParams[menuItem.paramName] == menuItem.paramValue ? 'active' : '';
    }

    getParentActiveState(menuItem: AppMenuItem) {
        let currentLocation = window.location.pathname;
        const routeParams = this.activeRoute.snapshot.queryParams
        
        if (this.hasChildren(menuItem) && this.getDisplayableChildren(menuItem).filter((c) => c.route == currentLocation && routeParams[c.paramName] == c.paramValue).length > 0) {
            return 'active';
        } else {
            return '';
        }
    }

    getActionLinkTarget(menuItem: AppMenuItem) {
        return menuItem.actionLink.indexOf('#') > -1 ? '' : '_blank';
    }

    isLastMenuItem(menuItem: AppMenuItem) {
        let displayedList = this.menu.items.filter((m) => this.isDisplayed(m));
        return displayedList[displayedList.length - 1].name === menuItem.name;
    }

    resetActivePath() {
        this.activeArea = '';
    }

    toggled(isOpen: boolean, id: string) {
        if (isOpen) {
            this.activeArea = id;
        } else {
            if (this.activeArea == id) this.activeArea = window.location.pathname;
        }
    }
    
    getRouterParams(routerLink){ 
        if(routerLink.paramName && routerLink.paramValue){ 
            return { [routerLink.paramName]: routerLink.paramValue};
        }
        return ;
    }

    isDropDownMenu(menuItem) {
        return this.hasChildren(menuItem) && this.showMenuItem(menuItem);
    }

    isActionLinkItem(menuItem) {
        return !this.hasChildren(menuItem) && !menuItem.route && menuItem.actionLink && this.showMenuItem(menuItem);
    }

    isRouterLinkItem(menuItem) {
        return !this.hasChildren(menuItem) && menuItem.route && this.showMenuItem(menuItem);
    }

    isDisplayed(menuItem) {
        return this.isActionLinkItem(menuItem) || this.isRouterLinkItem(menuItem) || this.isDropDownMenu(menuItem);
    }

    loadFilterOtions() {
        if (this.canSeePublicViews) {
        }
    }

    rebuildProductCategoryList(selectedItems: string[], originalList: any[]) {
        if (!selectedItems || selectedItems.length == 0 || !originalList || originalList.length == 0) {
            return [];
        }

        return originalList
            .filter((l) => selectedItems.indexOf(l.value) >= 0)
            .map((l) => {
                let name = l.label;
                if (name && name.indexOf(':') >= 0) name = name.split(':')[0];
                return {
                    id: l.value,
                    name: name,
                };
            });
    }

    buildCategoryDropdownOptions(categories: any[]) {
        const options = [];

        if (!categories || categories.length == 0) return options;

        categories = categories.filter(this.distinct);

        categories.forEach((cat) => {
            const label = !cat.name ? 'Not specified' : cat.name;
            options.push({
                label: label,
                value: cat.id,
            });
        });

        return options;
    }

    distinct(value, index, self) {
        return self.indexOf(value) === index;
    }

    closeMegaDropDown() {
        // loop through all the menu items and check if one is open and then close it
        for (let i = 0; i < this.menu.items.length; i++) {
            let menuItem = this.menu.items[i];
            if (this.isDropDownMenu(menuItem)) {
                let t = document.getElementById('dropDownMenu_'+i);
                if (t.classList.contains('show')) {
                    t.classList.remove('show');
                }
            }
        }
    }
}
