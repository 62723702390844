var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { ProductsServiceProxy, ViewServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppMenuItem } from '../../app-menu-item';
import { AppNavigationService } from '../../app-navigation.service';
import { TopMenuBase } from '../top-menu-base';
import { ActivatedRoute } from "@angular/router";
var MegaMenuComponent = /** @class */ (function (_super) {
    __extends(MegaMenuComponent, _super);
    function MegaMenuComponent(navigationSvc, appSessionService, _productServiceProxy, _viewService, injector, activeRoute) {
        var _this = _super.call(this, appSessionService, navigationSvc, injector) || this;
        _this.navigationSvc = navigationSvc;
        _this._productServiceProxy = _productServiceProxy;
        _this._viewService = _viewService;
        _this.activeRoute = activeRoute;
        _this.userId = abp.session.userId;
        _this.tenantId = abp.session.tenantId;
        _this.defaultHome = 'Digital Commerce';
        _this.isHost = _this.isLoggedIn && !_this.isPartner;
        _this.isLoggedIn = _this.userId != null && _this.userId > 0;
        _this.canSeePublicViews = _this.feature.isEnabled('App.Views.SeeGenericViewsInSearchBar') || !_this.isLoggedIn || _this.isHost;
        _this.canSeeMarketPlace = _this.feature.isEnabled('App.Markets.GeneralMarket') || !_this.isLoggedIn || _this.isHost;
        _this.isPartner = _this.isLoggedIn && _this.tenantId > 0;
        _this.assetsImagesFolder = './assets/common/images';
        _this.activeArea = '';
        _this.availableCategory1items = [];
        _this.selectedCategory1items = [];
        _this.selectedCategory2items = [];
        _this.isLoading = false;
        _this.selectedViewId = null;
        _this.impersonation = false;
        return _this;
    }
    MegaMenuComponent.prototype.ngOnInit = function () {
        this.loadFilterOtions();
        this.menu = this.navigationSvc.getMegaMenu();
        if (abp.session.impersonatorUserId > 0) {
            this.impersonation = true;
            this.impersonatorName = this.appSession.tenant.name;
            this.impersonatorAccountNumber = this.appSession.tenant.accountNumber;
        }
    };
    MegaMenuComponent.prototype.isCurrentMarket = function (menuItem) {
        if (window.location.href.indexOf('?') == -1)
            return true;
        var queryString = window.location.href.split('?')[1];
        var marketQueryString = queryString ? queryString.split('&')[0].split('=') : null;
        var market = marketQueryString && marketQueryString.length > 1 && marketQueryString[0] === 'market' ? marketQueryString[1] : null;
        // return true if we do not have a market query string
        if (!market)
            return true;
        return market == menuItem.paramValue;
    };
    MegaMenuComponent.prototype.getMenuItemStateClass = function (menuItem) {
        var location = this.activeArea ? this.activeArea : window.location.pathname;
        var isMarketMatched = this.isCurrentMarket(menuItem);
        return location.toLowerCase() == menuItem.route.toLowerCase() && this.activeRoute.snapshot.queryParams[menuItem.paramName] == menuItem.paramValue ? 'active' : '';
    };
    MegaMenuComponent.prototype.getParentActiveState = function (menuItem) {
        var currentLocation = window.location.pathname;
        var routeParams = this.activeRoute.snapshot.queryParams;
        if (this.hasChildren(menuItem) && this.getDisplayableChildren(menuItem).filter(function (c) { return c.route == currentLocation && routeParams[c.paramName] == c.paramValue; }).length > 0) {
            return 'active';
        }
        else {
            return '';
        }
    };
    MegaMenuComponent.prototype.getActionLinkTarget = function (menuItem) {
        return menuItem.actionLink.indexOf('#') > -1 ? '' : '_blank';
    };
    MegaMenuComponent.prototype.isLastMenuItem = function (menuItem) {
        var _this = this;
        var displayedList = this.menu.items.filter(function (m) { return _this.isDisplayed(m); });
        return displayedList[displayedList.length - 1].name === menuItem.name;
    };
    MegaMenuComponent.prototype.resetActivePath = function () {
        this.activeArea = '';
    };
    MegaMenuComponent.prototype.toggled = function (isOpen, id) {
        if (isOpen) {
            this.activeArea = id;
        }
        else {
            if (this.activeArea == id)
                this.activeArea = window.location.pathname;
        }
    };
    MegaMenuComponent.prototype.getRouterParams = function (routerLink) {
        if (routerLink.paramName && routerLink.paramValue) {
            return _a = {}, _a[routerLink.paramName] = routerLink.paramValue, _a;
        }
        return;
        var _a;
    };
    MegaMenuComponent.prototype.isDropDownMenu = function (menuItem) {
        return this.hasChildren(menuItem) && this.showMenuItem(menuItem);
    };
    MegaMenuComponent.prototype.isActionLinkItem = function (menuItem) {
        return !this.hasChildren(menuItem) && !menuItem.route && menuItem.actionLink && this.showMenuItem(menuItem);
    };
    MegaMenuComponent.prototype.isRouterLinkItem = function (menuItem) {
        return !this.hasChildren(menuItem) && menuItem.route && this.showMenuItem(menuItem);
    };
    MegaMenuComponent.prototype.isDisplayed = function (menuItem) {
        return this.isActionLinkItem(menuItem) || this.isRouterLinkItem(menuItem) || this.isDropDownMenu(menuItem);
    };
    MegaMenuComponent.prototype.loadFilterOtions = function () {
        if (this.canSeePublicViews) {
        }
    };
    MegaMenuComponent.prototype.rebuildProductCategoryList = function (selectedItems, originalList) {
        if (!selectedItems || selectedItems.length == 0 || !originalList || originalList.length == 0) {
            return [];
        }
        return originalList
            .filter(function (l) { return selectedItems.indexOf(l.value) >= 0; })
            .map(function (l) {
            var name = l.label;
            if (name && name.indexOf(':') >= 0)
                name = name.split(':')[0];
            return {
                id: l.value,
                name: name,
            };
        });
    };
    MegaMenuComponent.prototype.buildCategoryDropdownOptions = function (categories) {
        var options = [];
        if (!categories || categories.length == 0)
            return options;
        categories = categories.filter(this.distinct);
        categories.forEach(function (cat) {
            var label = !cat.name ? 'Not specified' : cat.name;
            options.push({
                label: label,
                value: cat.id,
            });
        });
        return options;
    };
    MegaMenuComponent.prototype.distinct = function (value, index, self) {
        return self.indexOf(value) === index;
    };
    MegaMenuComponent.prototype.closeMegaDropDown = function () {
        // loop through all the menu items and check if one is open and then close it
        for (var i = 0; i < this.menu.items.length; i++) {
            var menuItem = this.menu.items[i];
            if (this.isDropDownMenu(menuItem)) {
                var t = document.getElementById('dropDownMenu_' + i);
                if (t.classList.contains('show')) {
                    t.classList.remove('show');
                }
            }
        }
    };
    return MegaMenuComponent;
}(TopMenuBase));
export { MegaMenuComponent };
