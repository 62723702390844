var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, Injector, OnInit, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppConsts } from '@shared/AppConsts';
import { SubscriptionStartType } from '@shared/AppEnums';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import * as moment from 'moment';
import { AppComponentBase } from 'shared/common/app-component-base';
import { UiCustomizationSettingsServiceProxy } from '@shared/service-proxies/service-proxies';
import { ImpersonatorPermissionsService } from './shared/common/services/impersonator-permissions.service';
import { SessionManagementService } from './shared/common/token-management/services';
import { Observable, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { GAServiceService } from '@shared/utils/google-analytics-service';
import { SignalRConnectionService } from "@shared/helpers/signal-r-connection.service";
import { IdleCheckerService } from "@shared/helpers/idle-checker.service";
var AppComponent = /** @class */ (function (_super) {
    __extends(AppComponent, _super);
    function AppComponent(injector, viewContainerRef, _router, _uiCustomizationService, _appSessionService, _impersonatorPermissionsService, changeRef, sessionSvs, gaService, titleService, signalRConnectionService, idleChecker) {
        var _this = _super.call(this, injector) || this;
        _this._router = _router;
        _this._uiCustomizationService = _uiCustomizationService;
        _this._appSessionService = _appSessionService;
        _this._impersonatorPermissionsService = _impersonatorPermissionsService;
        _this.changeRef = changeRef;
        _this.sessionSvs = sessionSvs;
        _this.gaService = gaService;
        _this.titleService = titleService;
        _this.signalRConnectionService = signalRConnectionService;
        _this.idleChecker = idleChecker;
        _this.subscriptionStartType = SubscriptionStartType;
        _this.installationMode = true;
        _this._counter = 0;
        _this._status = "Initialized.";
        //Used to set custom favicon
        _this.favIcon = document.querySelector('#appIcon');
        _this.viewContainerRef = viewContainerRef; // You need this small hack in order to catch application root view container ref (required by ng2 bootstrap modal)
        _this.router = _router;
        _this._enableTracking = AppConsts.enableTracking;
        if (_this._enableTracking) {
            var trackingId = AppConsts.googleAnalyticsTrackingId;
            _this.gaService.loadGoogleAnalytics(trackingId);
        }
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        if (this.appSession.application) {
            this.signalRConnectionService.connect();
        }
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.updateTheme();
        this._impersonatorPermissionsService.loadPermissions();
        this.sessionSvs.initialize(this.changeRef);
        if (AppConsts.customTitle) {
            this.setDocTitle(AppConsts.customTitle);
        }
        if (AppConsts.customFaviconUrl) {
            this.changeIcon(AppConsts.customFaviconUrl);
        }
        if (this._enableTracking) {
            this.setUpAnalytics();
        }
        this.idleChecker.startChecking();
    };
    AppComponent.prototype.setUpAnalytics = function () {
        var _this = this;
        this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.gaService.logPageView(event.url);
        });
    };
    AppComponent.prototype.setDocTitle = function (title) {
        this.titleService.setTitle(title);
    };
    AppComponent.prototype.changeIcon = function (iconUrl) {
        this.favIcon.href = iconUrl;
    };
    AppComponent.prototype.updateTheme = function () {
        this._uiCustomizationService.getUiManagementSettings().subscribe(function (settingsResult) {
            var siteColorSetting = settingsResult.siteColor;
            Object.keys(siteColorSetting).forEach(function (k) {
                return document.documentElement.style.setProperty("--" + k, siteColorSetting[k]);
            });
        });
    };
    AppComponent.prototype.subscriptionStatusBarVisible = function () {
        return this._appSessionService.tenantId > 0 &&
            (this._appSessionService.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    };
    AppComponent.prototype.subscriptionIsExpiringSoon = function () {
        if (this._appSessionService.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNotifyDayCount, 'days') >= moment(this._appSessionService.tenant.subscriptionEndDateUtc);
        }
        return false;
    };
    AppComponent.prototype.setTitle = function (newTitle) {
        this.titleService.setTitle(newTitle);
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        if (mApp.initialized) {
            return;
        }
        mApp.init();
        mLayout.init();
        mApp.initialized = true;
    };
    AppComponent.prototype.ngOnDestroy = function () {
        if (this._idleTimerSubscription)
            this._idleTimerSubscription.unsubscribe();
    };
    AppComponent.prototype.documentClick = function (event) {
        this.idleChecker.interaction();
    };
    return AppComponent;
}(AppComponentBase));
export { AppComponent };
