import { Component, OnInit, ViewChild, Injector, Input, EventEmitter, Output } from '@angular/core';
import { ServiceMappingDto, DigitalServicesServiceProxy, ServicePropertyMappingDto, ServiceDto, ServicePricingVariableDto, ServicePricingMethodDto, ServiceEntityType, EntityTypeForServiceDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';
import { finalize } from 'rxjs/operators';
import { AppMarkets, EntityType } from '@shared/AppEnums';
import { ControlContainer, NgForm } from '@angular/forms';


@Component({
    selector: 'app-add-custom-properties',
    templateUrl: './add-custom-properties.component.html',
    styleUrls: ['./add-custom-properties.component.less'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class AddCustomPropertiesComponent extends AppComponentBase implements OnInit {
    @Input() serviceMapping: ServiceMappingDto[];
    @Input() market: AppMarkets;
    @Input() selectedPricingMethod: any;
    @Input() serviceApplicationType: any;
    @Input() service: ServiceDto;
    @Input() entityTypes: EntityTypeForServiceDto[];
    @Output() onMappingRemoved: EventEmitter<ServiceMappingDto[]> = new EventEmitter<ServiceMappingDto[]>();

    currentMapping: ServiceMappingDto[] = [];
    serviceCriteriaTypes = AppConsts.ServiceCriteriaTypeList;
    serviceCriteria: any;
    createService = false;


    constructor(injector: Injector, private _digitalProxy: DigitalServicesServiceProxy) {
        super(injector);
    }

    ngOnInit() {
        this.serviceCriteria = this.serviceCriteriaTypes.find(serviceCriteria => serviceCriteria.value === 1);
    }

    getMappings() {
        return this.serviceMapping.filter(x => x.isDeleted != true);
    }

    canDelete() {
        return this.serviceMapping.length > 1;
    }

    copyPropertyMapping(mapping: ServiceMappingDto) {

        let newMappingObj = new ServiceMappingDto({
            id: 0,
            matchValue: mapping.matchValue,
            criteria: mapping.criteria,
            serviceEntityTypeId: mapping.serviceEntityTypeId,
            serviceId: this.service.id,
            servicePricingVariable: this.copyPricingVariable(mapping),
            propertyId: mapping.propertyId,
            property: null,
            creationTime: null,
            creatorUserId: null,
            deleterUserId: null,
            deletionTime: null,
            isDeleted: false,
            lastModificationTime: null,
            lastModifierUserId: null,
        });

        this.serviceMapping.push(newMappingObj);
    }

    copyPricingVariable(mapping: ServiceMappingDto){
        let copyServicePricingVariable: ServicePricingVariableDto[] = [];

        mapping.servicePricingVariable.forEach(map => {
            let newVariableObj = new ServicePricingVariableDto({
                id: 0,
                serviceMappingId: 0,
                servicePriceVariableType: map.servicePriceVariableType,
                servicePriceVariableTypeId: map.servicePriceVariableTypeId,
                value: map.value,
                creationTime: null,
                creatorUserId: null,
                deleterUserId: null,
                deletionTime: null,
                isDeleted: false,
                lastModificationTime: null,
                lastModifierUserId: null,
            });
            copyServicePricingVariable.push(newVariableObj);
        });

        return copyServicePricingVariable;
    }

    removeMapping(serviceMaping : ServiceMappingDto) {

        this.message.confirm('Are you sure you want to delete this', isConfirmed => {
            if (isConfirmed) {
                serviceMaping.isDeleted = true;
            }
        });
    }

    updateServiceAndMapping(serviceId) {
        this._digitalProxy.getServiceById(serviceId)
            .subscribe(element => {
                this.serviceMapping = element.services.serviceMappings;
                this.service = element.services;
                this.serviceMapping = element.services.serviceMappings;

                //update parent control mappings
                this.onMappingRemoved.emit(this.serviceMapping)

            }, error => {
                abp.notify.error('Error:' + error, 'Error Removing Item');
            });
    }

    getProperties(mapping : ServiceMappingDto) {

        if (!mapping
            || !this.entityTypes
            || (this.entityTypes.length > 1 && !mapping.serviceEntityTypeId )
        ) return new Array();

        if (!mapping.serviceEntityTypeId && this.entityTypes.length == 1) {
            mapping.serviceEntityTypeId = this.entityTypes[0].id;
        }

        return this.entityTypes.find(obj => obj.id == mapping.serviceEntityTypeId).propertyMappings;
    }

    pushMapping(serviceMappings) {
        this.serviceMapping.push(new ServiceMappingDto({
            id: 0,
            matchValue: null,
            criteria: 1,
            serviceId: this.service.id,
            serviceEntityTypeId: this.entityTypes.length == 1? this.entityTypes[0].id : null,
            servicePricingVariable: this.createPricingVariable(this.selectedPricingMethod),
            propertyId: 1,
            property: null,
            creationTime: null,
            creatorUserId: null,
            deleterUserId: null,
            deletionTime: null,
            isDeleted: false,
            lastModificationTime: null,
            lastModifierUserId: null,
        }));

    }

    createPricingVariable(servicePricingMethod: ServicePricingMethodDto): ServicePricingVariableDto[] {
        let array: any[] = [];
        servicePricingMethod.servicePricingMethodVariableTypeLinks.forEach(element => {
            let servicePricingVar = new ServicePricingVariableDto();
            servicePricingVar.servicePriceVariableType = element.servicePriceVariableType;
            servicePricingVar.servicePriceVariableTypeId = element.servicePriceVariableTypeId;
            array.push(servicePricingVar);
        });
        return array;
    }

    compareFn(optionOne, optionTwo): boolean {
        return optionOne === optionTwo;
    }

    checkShowButton(mapping) {
        if (!mapping.id || mapping.id === 0) {
            return false;
        } else {
            return true;
        }
    }

}
