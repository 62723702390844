import { PipeTransform } from '@angular/core';
import * as moment from 'moment';
var MomentFormatPipe = /** @class */ (function () {
    function MomentFormatPipe() {
    }
    MomentFormatPipe.prototype.transform = function (value, format) {
        if (!value) {
            return '';
        }
        return moment(value).format(format);
    };
    return MomentFormatPipe;
}());
export { MomentFormatPipe };
