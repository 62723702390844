import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AccountServiceProxy, SwitchToLinkedAccountInput } from '@shared/service-proxies/service-proxies';
var LinkedAccountService = /** @class */ (function () {
    function LinkedAccountService(_accountService, _appUrlService, _authService) {
        this._accountService = _accountService;
        this._appUrlService = _appUrlService;
        this._authService = _authService;
    }
    LinkedAccountService.prototype.switchToAccount = function (userId, tenantId) {
        var _this = this;
        var input = new SwitchToLinkedAccountInput();
        input.targetUserId = userId;
        input.targetTenantId = tenantId;
        this._accountService.switchToLinkedAccount(input)
            .subscribe(function (result) {
            _this._authService.logout(false);
            var targetUrl = _this._appUrlService.getAppRootUrlOfTenant(result.tenancyName) + '?switchAccountToken=' + result.switchAccountToken;
            if (input.targetTenantId) {
                targetUrl = targetUrl + '&tenantId=' + input.targetTenantId;
            }
            location.href = targetUrl;
        });
    };
    return LinkedAccountService;
}());
export { LinkedAccountService };
