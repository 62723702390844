<div id="dropdownContainer" *ngIf="linksExist">
    <div class="m-subheader">
        <div class="d-flex align-items-center">
            <div class="mr-auto col-xs-6">
                <span *ngIf="menuItemName !== 'Digital Commerce'" class="text-heading">{{ l(menuItemName) }}</span>
                <!-- Temp disable stores for whitelabelled sites until feature is developed -->
                <a *ngIf="menuItemName === 'Digital Commerce' && !customDomain" routerLink="/app/main/store"
                    role="menuitem" class="action-link bolder" aria-haspopup="true" aria-expanded="false" tabindex="-1">
                    <span class="m-menu__link-text-heading">{{ menuItemName }}</span>
                </a>

            </div>
            <div class="col-xs-6 text-right">
                <a class="btn btn-sm btn-outline-secondary" (click)="onClosedDropDownClick()">
                    <i class="fas fa-window-close"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="row m-0 p-0">
        <ng-container *ngFor="let subSection of menuSubSections">
            <div *ngIf="showMenuSection(subSection)" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-0 m-0">
                <label class="section_title ml-3 mt-3">
                    <span>{{ subSection }}</span>
                </label>
                <hr size="1" />
                <div class="m-menu__nav menu-list" role="menu">
                    <ng-template ngFor let-menuItem [ngForOf]="MenuItems" let-i="index" class="img-nav-container">
                        <div *ngIf="menuItem.subSection === subSection" routerLinkActive="m-menu__item--active"
                            class="m-menu__item m-menu__item--submenu list-item"
                            [ngClass]="{ 'm-menu__item--expanded m-menu__item--open': menuItem.items.length > 0 }"
                            aria-haspopup="true" role="none" data-menu-submenu-toggle="hover">
                            <!--Images-->
                            <a *ngIf="!isParentLink(menuItem) && isImageLink(menuItem) && menuItem.route"  [queryParams]="getRouterParams(menuItem)"
                                routerLink="{{ menuItem.route }}" role="menuitem" aria-haspopup="true"
                                aria-expanded="false" tabindex="-1">
                                <!-- <img id="{{'nav-img' + i}}" src="{{menuItem.imageLink.defaultImageSrc}}?version=1" (mouseover)="hover($event, menuItem);"
                                        (mouseleave)="unhover($event, menuItem);" class="img-action-link" /> -->
                                <span class="m-menu__link-text">{{ l(menuItem.name) }}</span>
                            </a>

                            <a *ngIf="!isParentLink(menuItem) && isImageLink(menuItem) && !menuItem.route && menuItem.actionLink"
                                href="{{ getFullActionLink(menuItem.actionLink) }}" role="menuitem" aria-haspopup="true"
                                aria-expanded="false" tabindex="-1">
                                <img id="{{ 'nav-img' + i }}" src="{{ menuItem.imageLink.defaultImageSrc }}?version=1"
                                    (mouseover)="hover($event, menuItem)" (mouseleave)="unhover($event, menuItem)"
                                    class="img-action-link" />
                                <span class="m-menu__link-text">{{ l(menuItem.name) }}</span>
                            </a>

                            <!--Links-->
                            <a *ngIf="!isParentLink(menuItem) && isActionLink(menuItem) && menuItem.route"
                                (click)="navigateTo(menuItem)" role="menuitem" class="action-link bolder"
                                aria-haspopup="true" aria-expanded="false" tabindex="-1">
                                <span class="m-menu__link-text">{{ l(menuItem.name) }}</span>
                            </a>

                            <a *ngIf="!isParentLink(menuItem) && isActionLink(menuItem) && !menuItem.route && menuItem.actionLink"
                                href="{{ getFullActionLink(menuItem.actionLink) }}" class="action-link bolder"
                                role="menuitem" aria-haspopup="true" aria-expanded="false" tabindex="-1">
                                <span class="m-menu__link-text">{{ l(menuItem.name) }}</span>
                            </a>

                            <!--Parent-->
                            <a href="javascript:;" *ngIf="isParentLink(menuItem)"
                                class="m-menu__link m-menu__toggle action-link bolder inActiveLink" role="menuitem"
                                aria-haspopup="true" aria-expanded="false"
                                [attr.tabindex]="mainMenuItemIndex == 0 ? 0 : -1">
                                <span class="m-menu__link-text">
                                    <span class="title">{{ l(menuItem.name) }}</span>
                                </span>
                            </a>
                            <nav id="m_ver_menu" *ngIf="menuItem.items.length" data-menu-vertical="true"
                                data-menu-dropdown-timeout="500" [attr.aria-label]="l('TopMenu')">
                                <ul class="m-menu__nav menu-list" role="menu">
                                    <ng-template ngFor let-childMenuItem [ngForOf]="menuItem.items" let-i="index"
                                        class="img-nav-container child-nav">
                                        <li routerLinkActive="m-menu__item--active"
                                            class="m-menu__item m-menu__item--submenu list-item"
                                            [ngClass]="{ 'm-menu__item--expanded m-menu__item--open': childMenuItem.items.length > 0 }"
                                            aria-haspopup="true" role="none" data-menu-submenu-toggle="hover">
                                            <!--Images-->
                                            <a *ngIf="!isParentLink(childMenuItem) && isImageLink(childMenuItem) && childMenuItem.route"
                                                routerLink="{{ menuItem.route }}" role="menuitem" aria-haspopup="true"
                                                aria-expanded="false" tabindex="-1">
                                                <!--  <img
                                                            id="{{ 'nav-img' + i }}"
                                                            src="{{ childMenuItem.imageLink.defaultImageSrc }}?version=1"
                                                            (mouseover)="hover($event, childMenuItem)"
                                                            (mouseleave)="unhover($event, childMenuItem)"
                                                        /> -->
                                                <span class="m-menu__link-text">{{ l(menuItem.name) }}</span>
                                            </a>

                                            <a *ngIf="
                                                    !isParentLink(childMenuItem) &&
                                                    isImageLink(childMenuItem) &&
                                                    !childMenuItem.route &&
                                                    childMenuItem.actionLink
                                                " href="{{ getFullActionLink(childMenuItem.actionLink) }}"
                                                role="menuitem" aria-haspopup="true" aria-expanded="false"
                                                tabindex="-1">
                                                <!--  <img
                                                            id="{{ 'nav-img' + i }}"
                                                            src="{{ childMenuItem.imageLink.defaultImageSrc }}?version=1"
                                                            (mouseover)="hover($event, childMenuItem)"
                                                            (mouseleave)="unhover($event, childMenuItem)"
                                                        /> -->
                                                <span class="m-menu__link-text">{{ l(menuItem.name) }}</span>
                                            </a>

                                            <!--Links-->
                                            <a *ngIf="!isParentLink(childMenuItem) && isActionLink(childMenuItem) && childMenuItem.route"
                                                routerLink="{{ childMenuItem.route }}" role="menuitem"
                                                class="action-link" aria-haspopup="true" aria-expanded="false"
                                                tabindex="-1">
                                                <span class="m-menu__link-text">{{ l(menuItem.name) }}</span>
                                            </a>

                                            <a *ngIf="
                                                    !isParentLink(childMenuItem) &&
                                                    isActionLink(childMenuItem) &&
                                                    !childMenuItem.route &&
                                                    childMenuItem.actionLink
                                                " href="{{ getFullActionLink(childMenuItem.actionLink) }}"
                                                class="action-link" role="menuitem" aria-haspopup="true"
                                                aria-expanded="false" tabindex="-1">
                                                <span class="m-menu__link-text">{{ l(menuItem.name) }}</span>
                                            </a>

                                            <!--Parent-->
                                            <a href="javascript:;" *ngIf="isParentLink(childMenuItem)"
                                                class="m-menu__link m-menu__toggle action-link bolder inActiveLink"
                                                role="menuitem" aria-haspopup="true" aria-expanded="false"
                                                [attr.tabindex]="mainMenuItemIndex == 0 ? 0 : -1">
                                                <span class="m-menu__link-text">
                                                    <span class="title">{{ l(childMenuItem.name) }}</span>
                                                </span>
                                            </a>
                                        </li>
                                    </ng-template>
                                </ul>
                            </nav>
                        </div>
                    </ng-template>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="imageLayoutData">
            <div *ngFor="let image of imageLayoutData" class="col-m-6 m--padding-left-10">
                <a href="{{ getUrl(image.hyperlink, image.isHyperlinkRelative) }}" target="_blank">
                    <img src="{{ getUrl(image.url, image.isUrlRelative) }}" alt="No Results Found" />
                </a>
            </div>
        </ng-container>
    </div>
</div>