var MenuItemImageLink = /** @class */ (function () {
    function MenuItemImageLink(defaultImgSrc, alternateImgSrc, alternate) {
        if (alternate === void 0) { alternate = true; }
        this.defaultImageSrc = '';
        this.alternateImageSrc = '';
        this.alternateImages = this.alternativeImageExist();
        this.defaultImageSrc = defaultImgSrc;
        this.alternateImageSrc = alternateImgSrc;
        this.alternateImages = this.alternativeImageExist() && alternate;
    }
    MenuItemImageLink.prototype.alternativeImageExist = function () { return this.alternateImageSrc != null && this.alternateImageSrc != undefined && this.alternateImageSrc != ''; };
    return MenuItemImageLink;
}());
export { MenuItemImageLink };
export var MenuItemUsers;
(function (MenuItemUsers) {
    MenuItemUsers[MenuItemUsers["Guest"] = 0] = "Guest";
    MenuItemUsers[MenuItemUsers["Host"] = 1] = "Host";
    MenuItemUsers[MenuItemUsers["Tenant"] = 2] = "Tenant";
    MenuItemUsers[MenuItemUsers["CustomGuest"] = 3] = "CustomGuest";
})(MenuItemUsers || (MenuItemUsers = {}));
var AppMenuItem = /** @class */ (function () {
    function AppMenuItem(data) {
        this.name = '';
        this.permissionName = '';
        this.orPermissionNames = [];
        this.featureName = '';
        this.icon = '';
        this.route = '';
        this.items = [];
        this.users = [];
        this.impersonatorPermission = '';
        this.imageLink = new MenuItemImageLink('');
        this.actionLink = null;
        this.subSection = null;
        this.marketingImage = null;
        this.paramName = null;
        this.paramValue = null;
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    this[property] = data[property];
            }
        }
    }
    return AppMenuItem;
}());
export { AppMenuItem };
