import { UtilsService } from '@abp/utils/utils.service';
import { AppConsts } from '@shared/AppConsts';
var SignalRHelper = /** @class */ (function () {
    function SignalRHelper() {
    }
    SignalRHelper.initSignalR = function (callback) {
        var encryptedAuthToken = new UtilsService().getCookieValue(AppConsts.authorization.encrptedAuthTokenName);
        abp.signalr = {
            autoConnect: true,
            connect: undefined,
            hubs: undefined,
            qs: AppConsts.authorization.encrptedAuthTokenName + "=" + encodeURIComponent(encryptedAuthToken),
            remoteServiceBaseUrl: AppConsts.remoteServiceBaseUrl,
            startConnection: undefined,
            url: '/signalr'
        };
        jQuery.getScript(AppConsts.appBaseUrl + '/assets/abp/abp.signalr-client.js', function () {
            callback();
        });
    };
    return SignalRHelper;
}());
export { SignalRHelper };
