import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable(({
    providedIn: 'root'
}))
export class HeaderDataService {
    private roeSource = new BehaviorSubject<number>(null);
    currentRoe = this.roeSource.asObservable();

    updateCurrentRoe(roeRate: number) {
        this.roeSource.next(roeRate);
    }
}