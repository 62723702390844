import { AbpModule } from '@abp/abp.module';
import * as ngCommon from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { CommonModule } from '@shared/common/common.module';
import { CommonRoutingModule } from '@app/shared/common/app-common-routing.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { ModalModule } from 'ngx-bootstrap';
import { PaginatorModule, ChipsModule, SliderModule, DropdownModule, MultiSelectModule, AutoCompleteModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { JqPluginDirective } from './libs/jq-plugin.directive'; 
import { DatePickerDirective } from './timing/date-picker.component';
import { DateRangePickerComponent } from './timing/date-range-picker.component';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { AxizCardFooterComponent } from './axiz-card-footer/axiz-card-footer.component';

import { AddressTypeLookupComponent } from '@app/shared/common/lookup/addressType-lookup.component';
import { CustomerLookupComponent } from '@app/shared/common/lookup/customer-lookup.component';
import { CountryLookupComponent } from '@app/shared/common/lookup/country-lookup.component';
import { TenantLookupComponent } from '@app/shared/common/lookup/tenant-lookup.component';
import { UserLookupComponent } from '@app/shared/common/lookup/user-lookup.component';

import { IbmCompanyNameValidator } from '@app/shared/common/directives/ibm-companyname-validator.directive';
import { NumericValidator } from '@app/shared/common/directives/numeric-validator.directive';
import { RequiredTrueValidatorDirective } from './directives/required-true-validator.directive';
import { BlockuiDirective } from './directives/blockui.directive';
import { GroupDetailDisplayModalComponent } from './group-detail-display-modal/group-detail-display-modal.component';
import { WizardComponent } from './wizard/wizard.component';
import { WizardStepComponent } from './wizard-step/wizard-step.component';
import { OAuth2RedirectNotificationModalComponent } from './oauth2-redirect-notification-modal/oauth2-redirect-notification-modal.component';
import { PriceBreakdownModalComponent } from './price-breakdown-modal/price-breakdown-modal.component';
import { MicrosoftMPNIdValidatorDirective } from './directives/microsoft-npm-validator.directive';
import { MetronicWizardStepComponent } from './metronic/wizard/metronic-wizard-step/metronic-wizard-step.component';
import { MetronicWizardComponent } from './metronic/wizard/metronic-wizard/metronic-wizard.component';
import { AddressFormComponent } from './address/address-form/address-form.component';
import { AddressViewerComponent } from './address/address-viewer/address-viewer.component';
import { SearchComponent } from './search/search.component';
import { StoreCardItemComponent } from './store-card-item/store-card-item.component';
import { AuthModalComponent } from './auth-modal/auth-modal.component';
import { TagModalComponent } from './tag-modal/tag-modal.component';
import { ClearTagModalComponent } from './clear-tag-modal/clear-tag-modal.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AdvanceSearchComponent } from './search/advance-search/advance-search.component';
import { ImagePreloadDirective } from './directives/image-preload.directive';

import { DialogService, SessionManagementService, IdleTimeoutService, TokenManagementService } from './token-management/services/index';
import { SessionExpiryDialogComponent } from './token-management/components/session-expiry-dialog/session-expiry-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselItemComponent } from './carousel-item/carousel-item/carousel-item.component';
import { CarouselItemProductComponent } from './carousel-item/carousel-item-product-detail/carousel-item-product-detail.component';
import { SectionTitleComponent } from './section-title/section-title.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrandLoadingComponent } from '../../main/markets/general-market/brands/brand-loading/brand-loading.component';
import { ConfigureHpeComponent } from '../../main/markets/general-market/brands/hpe/configure-hpe/configure-hpe.component';
import { MarketNavBarComponent } from '@app/main/markets/shared/market-nav-bar/market-nav-bar.component';
import { RouterModule } from '@angular/router';
import { GroupedKeyValueComponent } from './grouped-key-value/grouped-key-value.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { ProductLookupModalComponent } from './product-lookup-modal/product-lookup-modal.component';
import { MsProductLookupModalComponent } from './markets/Microsoft/ms-product-lookup-modal/ms-product-lookup-modal.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HeadedParagraphComponent } from './headed-paragraph/headed-paragraph.component';
import { CiscoTransactionComponent } from '../../main/markets/cisco-market/cisco-transaction-component/cisco-transaction-component';
import { CiscoTransactionTreeComponent } from '../../main/markets/cisco-market/cisco-transaction-component/cisco-transaction-component-tree/cisco-transaction-component-tree.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { InvoiceComponent } from './invoice/invoice.component';
import { GroupedMarketLineItemsComponent } from './markets/grid-items/grouped-market-line-items.component';
import { MicrosoftGridItemsComponent } from './markets/grid-items/microsoft-grid-items/microsoft-grid-items.component';
import { MarketPlaceGridItemsComponent } from './markets/grid-items/market-place-grid-items/market-place-grid-items.component';
import { BrandDealGridItemsComponent } from './markets/grid-items/brand-deal-grid-items/brand-deal-grid-items.component';
import { IbmGridItemsComponent } from './markets/grid-items/ibm-grid-items/ibm-grid-items.component';
import { CiscoGridItemsComponent } from './markets/grid-items/cisco-grid-items/cisco-grid-items.component';
import { MicrosoftOfferAddonGroupingComponent } from './markets/grid-items/microsoft-grid-items/microsoft-offer-addon-grouping/microsoft-offer-addon-grouping.component';
import { AddDigitalServiceModalComponent } from 'app/shared/common/digital-services/add-digital-service-modal/add-digital-service-modal.component';
import { ConfigureServicesComponent } from './digital-services/configure-services/configure-services.component';
import { CreateOrEditServiceComponent } from '@app/shared/common/digital-services/create-edit-services/create-edit-services.component';
import { AddCustomPropertiesComponent } from '@app/shared/common/digital-services/create-edit-services/add-custom-properties/add-custom-properties.component';
import { ServiceSummaryComponent } from '@app/shared/common/digital-services/service-summary/service-summary.component';
import { ServiceSummaryModalComponent } from '@app/shared/common/digital-services/service-summary-modal/service-summary-modal.component';
import { TermsModalComponent } from './modals/terms-modal/terms-modal.component';
import { ProductDetailBannerComponent } from '@app/shared/common/product-detail-loggedout/product-detail-banner.component';
import { CiscoRenewalTransactionTreeComponent } from "@app/main/markets/cisco-market/cisco-transaction-component/cisco-renewal-transaction-component-tree/cisco-renewal-transaction-tree.component";
import { MultiLineItemComponent } from './markets/grid-items/multi-line-item/multi-line-item.component';
import { AutocompleteContentDirective } from './directives/autocomplete-content.directive';
import { OverlayModule } from "@angular/cdk/overlay";
import { OptionComponent } from './autocomplete/option/option.component';
import { AutocompleteDirective } from './directives/autocomplete.directive';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { CartNoticeComponent } from '../layout/cart-notice/cart-notice.component';
import { UserTwoFactorAlertComponent } from './user-two-factor-alert/user-two-factor-alert.component';
import { EditMicrosoftProductComponent } from './markets/grid-items/microsoft-grid-items/edit-microsoft-product/edit-microsoft-product.component';
import { CreditWidgetItemComponent } from './credit-widget-item/credit-widget-item.component'; 
import { CreditWidgetComponent } from "@app/shared/common/credit-widget/credit-widget.component";
import { AvaDealCartItemsComponent } from './markets/grid-items/ava-deal-cart-items/ava-deal-cart-items.component';
import { AvaDealCartItemComponent } from './markets/grid-items/ava-deal-cart-item/ava-deal-cart-item.component';
import { LastUpdatedWidgetComponent } from './widgets/last-updated-widget/last-updated-widget.component';
import {UploadFileComponent} from "@app/shared/common/upload-file/upload-file.component";
import {ProgressComponent} from "@app/shared/common/progress/progress.component";
import {ProductSearchComponent} from "@app/shared/common/product-search/product-search.component";
import { BasePurchaseDetailComponent } from './markets/product-details/purchase-detail/base-purchase-detail/base-purchase-detail.component';
import { InlinePurchaseDetailComponent } from './markets/product-details/purchase-detail/inline-purchase-detail/inline-purchase-detail.component';
import { InlineAddonPurchaseDetailComponent } from './markets/product-details/purchase-detail/inline-addon-purchase-detail/inline-addon-purchase-detail.component';
import {MarketStoreCardItemComponent} from "@app/shared/common/market-store-card/market-store-card-item.component";
import { ConfigLinkedImageComponent } from './config-linked-image/config-linked-image.component';
import { ConfigBannersComponent } from './config-banners/config-banners.component';
import { BannerListItemComponent } from './config-banners/banner-list-item/banner-list-item.component'; 
import {HorizontalScrollBarComponent} from "@app/main/Store/horizontal-scroll-bar/horizontal-scroll-bar.component";
import {TocModalComponent} from "@app/shared/common/TocModal/toc-modal.component";
import { TagPromoModalComponent } from './tag-promo-modal/tag-promo-modal.component';
import { TagPromoColorSelectorComponent } from './tag-promo-color-selector/tag-promo-color-selector.component';
import { TagPromoContentComponent } from './tag-promo-content/tag-promo-content.component';
import { OnboardModalComponent } from './onboard-modal/onboard-modal.component';
import { AdobeGridItemsComponent } from './markets/grid-items/adobe-grid-items/adobe-grid-items.component';
import {GenericConfirmDialogModalComponent} from '@app/shared/common/modals/generic-confirmation-dialog-modal/generic-confirm-dialog-modal.component';
import { EditProductButtonComponent } from './edit-product-button/edit-product-button.component';
import { TagModalNoticeComponent } from './tag-modal-notice/tag-modal-notice/tag-modal-notice.component';
import { AlertComponent } from '../common/alert/alert.component';
import {IsNullOrDefaultDatePipe} from "@app/shared/common/Dates/is-null-or-default-date.pipe"; 

import { PrimaryButtonComponent } from './ui-elements/buttons/primary-button/primary-button.component';
import { SecondaryButtonComponent } from './ui-elements/buttons/secondary-button/secondary-button.component';
import { PrimaryDropdownButtonComponent } from './ui-elements/buttons/primary-dropdown-button/primary-dropdown-button.component';
import { SecondaryDropdownButtonComponent } from './ui-elements/buttons/secondary-dropdown-button/secondary-dropdown-button.component';
 

 
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

@NgModule({
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        AbpModule,
        CommonModule,
        TableModule,
        PaginatorModule,
        ChipsModule,
        CarouselModule,
        NgxDocViewerModule,
        SliderModule, 
        DropdownModule,
        MultiSelectModule,
        NgbModule,
        RouterModule,
        AutoCompleteModule,
        NgScrollbarModule,
        PerfectScrollbarModule,
        CommonRoutingModule,
        OverlayModule
    ],
    declarations: [
        TimeZoneComboComponent,
        JqPluginDirective,
        CustomerLookupComponent,
        AddressTypeLookupComponent,
        CountryLookupComponent,
        TenantLookupComponent,
        UserLookupComponent,
        DateRangePickerComponent,
        DatePickerDirective,
        AxizCardFooterComponent,
        IbmCompanyNameValidator,
        NumericValidator,
        RequiredTrueValidatorDirective,
        BlockuiDirective,
        GroupDetailDisplayModalComponent,
        WizardComponent,
        WizardStepComponent,
        OAuth2RedirectNotificationModalComponent,
        PriceBreakdownModalComponent,
        MicrosoftMPNIdValidatorDirective,
        MetronicWizardStepComponent,
        MetronicWizardComponent,
        AddressFormComponent,
        AddressViewerComponent,
        StoreCardItemComponent,
        SearchComponent,
        AuthModalComponent,
        TagModalComponent,
        ClearTagModalComponent,
        DocumentViewerComponent,
        AdvanceSearchComponent,
        ImagePreloadDirective,
        SessionExpiryDialogComponent,
        CarouselItemComponent,
        CarouselItemProductComponent,
        SectionTitleComponent,
        BrandLoadingComponent,
        ConfigureHpeComponent,
        MarketNavBarComponent,
        GroupedKeyValueComponent,
        ProductLookupModalComponent,
        MsProductLookupModalComponent,
        TermsModalComponent,
        ImageGalleryComponent,
        HeadedParagraphComponent,
        InvoiceComponent,
        GroupedMarketLineItemsComponent,
        IbmGridItemsComponent,
        CiscoGridItemsComponent,
        MicrosoftGridItemsComponent,
        MarketPlaceGridItemsComponent,
        BrandDealGridItemsComponent,
        MicrosoftOfferAddonGroupingComponent,
        CiscoTransactionComponent,
        CiscoTransactionTreeComponent,
        CiscoRenewalTransactionTreeComponent,
        AddDigitalServiceModalComponent,
        ConfigureServicesComponent,
        CreateOrEditServiceComponent,
        AddCustomPropertiesComponent,
        ServiceSummaryComponent,
        ServiceSummaryModalComponent,
        ProductDetailBannerComponent,
        AutocompleteContentDirective,
        AutocompleteDirective,
        AutocompleteComponent,
        OptionComponent,
        MultiLineItemComponent,
        CartNoticeComponent,
        UserTwoFactorAlertComponent,
        EditMicrosoftProductComponent,
        CreditWidgetComponent,
        CreditWidgetItemComponent,
        LastUpdatedWidgetComponent,
        UploadFileComponent,
        ProgressComponent,
        LastUpdatedWidgetComponent,
        ProductSearchComponent,
        AvaDealCartItemComponent,
        AvaDealCartItemsComponent,
        BasePurchaseDetailComponent,
        InlinePurchaseDetailComponent,
        InlineAddonPurchaseDetailComponent,
        MarketStoreCardItemComponent,
        ConfigLinkedImageComponent,
        ConfigBannersComponent,
        BannerListItemComponent,
        TocModalComponent,
        HorizontalScrollBarComponent,
        TagPromoModalComponent,
        TagPromoColorSelectorComponent,
        TagPromoContentComponent,
        TagPromoContentComponent,
        OnboardModalComponent,
        AdobeGridItemsComponent,
        GenericConfirmDialogModalComponent,
        EditProductButtonComponent,
        TagModalNoticeComponent,
        AlertComponent,
        IsNullOrDefaultDatePipe,
        PrimaryButtonComponent,
        SecondaryButtonComponent,
        PrimaryDropdownButtonComponent,
        SecondaryDropdownButtonComponent
    ],
    exports: [
        TimeZoneComboComponent,
        JqPluginDirective,
        CustomerLookupComponent,
        AddressTypeLookupComponent,
        CountryLookupComponent,
        TenantLookupComponent,
        UserLookupComponent,
        DateRangePickerComponent,
        DatePickerDirective,
        AxizCardFooterComponent,
        CustomerLookupComponent,
        IbmCompanyNameValidator,
        NumericValidator,
        RequiredTrueValidatorDirective,
        BlockuiDirective,
        GroupDetailDisplayModalComponent,
        WizardComponent,
        WizardStepComponent,
        OAuth2RedirectNotificationModalComponent,
        PriceBreakdownModalComponent,
        MicrosoftMPNIdValidatorDirective,
        MetronicWizardStepComponent,
        MetronicWizardComponent,
        AddressFormComponent,
        AddressViewerComponent,
        StoreCardItemComponent,
        SearchComponent,
        AuthModalComponent,
        TagModalComponent,
        ClearTagModalComponent,
        DocumentViewerComponent,
        AdvanceSearchComponent,
        ImagePreloadDirective,
        CarouselItemComponent,
        CarouselItemProductComponent,
        SectionTitleComponent,
        ConfigureHpeComponent,
        MarketNavBarComponent,
        GroupedKeyValueComponent,
        ProductLookupModalComponent,
        MsProductLookupModalComponent,
        TermsModalComponent,
        ImageGalleryComponent,
        HeadedParagraphComponent,
        InvoiceComponent,
        GroupedMarketLineItemsComponent,
        IbmGridItemsComponent,
        CiscoGridItemsComponent,
        MicrosoftGridItemsComponent,
        MarketPlaceGridItemsComponent,
        MicrosoftOfferAddonGroupingComponent,
        CiscoTransactionComponent,
        CiscoTransactionTreeComponent,
        CiscoRenewalTransactionTreeComponent,
        AddDigitalServiceModalComponent,
        ConfigureServicesComponent,
        CreateOrEditServiceComponent,
        AddCustomPropertiesComponent,
        ServiceSummaryComponent,
        ServiceSummaryModalComponent,
        ProductDetailBannerComponent,
        AutocompleteContentDirective,
        AutocompleteDirective,
        AutocompleteComponent,
        OptionComponent,
        UserTwoFactorAlertComponent,
        CartNoticeComponent,
        CreditWidgetComponent,
        UploadFileComponent,
        ProgressComponent,
        ProductSearchComponent,
        AvaDealCartItemComponent,
        AvaDealCartItemsComponent,
        BasePurchaseDetailComponent,
        InlinePurchaseDetailComponent,
        InlineAddonPurchaseDetailComponent,
        MarketStoreCardItemComponent,
        ConfigLinkedImageComponent,
        ConfigBannersComponent,
        TocModalComponent,
        TagPromoModalComponent,
        OnboardModalComponent,
        GenericConfirmDialogModalComponent,
        EditProductButtonComponent,
        AlertComponent,
        IsNullOrDefaultDatePipe,
        SecondaryButtonComponent,
        PrimaryDropdownButtonComponent,
        SecondaryDropdownButtonComponent,
        PrimaryButtonComponent
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        DialogService,
        SessionManagementService,
        IdleTimeoutService,
        TokenManagementService,
        BrandLoadingComponent,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
    ],
    entryComponents: [SessionExpiryDialogComponent],
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppCommonModule,
            providers: [AppAuthService, AppRouteGuard],
        };
    }
}
