import { UtilsService } from '@abp/utils/utils.service';
import { CompilerOptions, Injector, NgModuleRef, Type } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import { SubdomainTenancyNameFinder } from '@shared/helpers/SubdomainTenancyNameFinder';
import * as moment from 'moment';
import { LocalizedResourcesHelper } from './shared/helpers/LocalizedResourcesHelper';
import { UrlHelper } from './shared/helpers/UrlHelper';
import { environment } from './environments/environment';
import { PaygateMode } from '@shared/AppEnums';

export class AppPreBootstrap {
    constructor(injector: Injector) {}

    static run(appRootUrl: string, callback: () => void, resolve: any, reject: any): void {
        AppPreBootstrap.getApplicationConfig(appRootUrl, () => {
            if (UrlHelper.isInstallUrl(location.href)) {
                LocalizedResourcesHelper.loadLocalizedStylesForTheme('default', false);
                callback();
                return;
            }

            const queryStringObj = UrlHelper.getQueryParameters();

            if (queryStringObj.redirect && queryStringObj.redirect === 'TenantRegistration') {
                if (queryStringObj.forceNewRegistration) {
                    // new AppAuthService(null).logout();
                }

                location.href = AppConsts.appBaseUrl + '/account/select-edition';
            } else if (queryStringObj.impersonationToken) {
                AppPreBootstrap.impersonatedAuthenticate(queryStringObj.impersonationToken, queryStringObj.tenantId, () => {
                    AppPreBootstrap.getUserConfiguration(callback);
                });
            } else if (queryStringObj.switchAccountToken) {
                AppPreBootstrap.linkedAccountAuthenticate(queryStringObj.switchAccountToken, queryStringObj.tenantId, () => {
                    AppPreBootstrap.getUserConfiguration(callback);
                });
            } else {
                AppPreBootstrap.getUserConfiguration(callback);
            }
        });
    }

    static bootstrap<TM>(moduleType: Type<TM>, compilerOptions?: CompilerOptions | CompilerOptions[]): Promise<NgModuleRef<TM>> {
        return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions);
    }

    private static getApplicationConfig(appRootUrl: string, callback: () => void) {
        return abp
            .ajax({
                url: appRootUrl + 'assets/' + environment.appConfig,
                method: 'GET',
                headers: {
                    'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
                },
            })
            .done((result) => {
                const subdomainTenancyNameFinder = new SubdomainTenancyNameFinder();
                if (appRootUrl.includes('bcx-shopportal') || appRootUrl.includes('http://localhost:4300/')) {
                    //use port 4300 for BCX local custom domain testing
                    //bcx custom config values
                    AppConsts.appBaseUrlFormat = result.bxcCustomConfig.appBaseUrl;
                    AppConsts.customDomain = result.bxcCustomConfig.appBaseUrl;
                    AppConsts.remoteServiceBaseUrlFormat = result.bxcCustomConfig.remoteServiceBaseUrl;
                    AppConsts.helpDeskEmail = result.bxcCustomConfig.helpDeskEmail;
                    AppConsts.helpDeskEmailAuthSupport = result.bxcCustomConfig.helpDeskEmailAuthSupport;
                    AppConsts.supportEmailAddress = result.bxcCustomConfig.supportEmailAddress;
                    AppConsts.customTitle = result.bxcCustomConfig.title;
                    AppConsts.customFaviconUrl = result.bxcCustomConfig.faviconUrl;

                    const tenancyName = subdomainTenancyNameFinder.getCurrentTenancyNameOrNull(result.bxcCustomConfig.appBaseUrl);

                    if (tenancyName == null) {
                        AppConsts.appBaseUrl = result.bxcCustomConfig.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                        AppConsts.remoteServiceBaseUrl = result.bxcCustomConfig.remoteServiceBaseUrl.replace(
                            AppConsts.tenancyNamePlaceHolderInUrl + '.',
                            ''
                        );
                    } else {
                        AppConsts.appBaseUrl = result.bxcCustomConfig.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
                        AppConsts.remoteServiceBaseUrl = result.bxcCustomConfig.remoteServiceBaseUrl.replace(
                            AppConsts.tenancyNamePlaceHolderInUrl,
                            tenancyName
                        );
                    }
                } else if (appRootUrl.includes('procure.netsurit')) {
                    // //use port 4300 for local Netsurit custom domain testing
                    //NetSurit custom config values
                    AppConsts.appBaseUrlFormat = result.netSuritCustomConfig.appBaseUrl;
                    AppConsts.customDomain = result.netSuritCustomConfig.appBaseUrl;
                    AppConsts.remoteServiceBaseUrlFormat = result.netSuritCustomConfig.remoteServiceBaseUrl;
                    AppConsts.customTitle = result.netSuritCustomConfig.title;
                    AppConsts.customFaviconUrl = result.netSuritCustomConfig.faviconUrl;

                    const tenancyName = subdomainTenancyNameFinder.getCurrentTenancyNameOrNull(result.netSuritCustomConfig.appBaseUrl);

                    if (tenancyName == null) {
                        AppConsts.appBaseUrl = result.netSuritCustomConfig.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                        AppConsts.remoteServiceBaseUrl = result.netSuritCustomConfig.remoteServiceBaseUrl.replace(
                            AppConsts.tenancyNamePlaceHolderInUrl + '.',
                            ''
                        );
                    } else {
                        AppConsts.appBaseUrl = result.netSuritCustomConfig.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
                        AppConsts.remoteServiceBaseUrl = result.netSuritCustomConfig.remoteServiceBaseUrl.replace(
                            AppConsts.tenancyNamePlaceHolderInUrl,
                            tenancyName
                        );
                    }
                }
                else if (appRootUrl.includes('axiz.u-t-s')) {
                    // //use port 4300 for local Netsurit custom domain testing
                    //NetSurit custom config values                    
                    AppConsts.appBaseUrlFormat = result.utsWhiteLabelCustomConfig.appBaseUrl;
                    AppConsts.customDomain = result.utsWhiteLabelCustomConfig.appBaseUrl;
                    AppConsts.remoteServiceBaseUrlFormat = result.utsWhiteLabelCustomConfig.remoteServiceBaseUrl;
                    AppConsts.customTitle = result.utsWhiteLabelCustomConfig.title;
                    AppConsts.customFaviconUrl = result.utsWhiteLabelCustomConfig.faviconUrl;

                    const tenancyName = subdomainTenancyNameFinder.getCurrentTenancyNameOrNull(result.utsWhiteLabelCustomConfig.appBaseUrl);

                    if (tenancyName == null) {
                        AppConsts.appBaseUrl = result.utsWhiteLabelCustomConfig.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                        AppConsts.remoteServiceBaseUrl = result.utsWhiteLabelCustomConfig.remoteServiceBaseUrl.replace(
                            AppConsts.tenancyNamePlaceHolderInUrl + '.',
                            ''
                        );
                    } else {
                        AppConsts.appBaseUrl = result.utsWhiteLabelCustomConfig.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
                        AppConsts.remoteServiceBaseUrl = result.utsWhiteLabelCustomConfig.remoteServiceBaseUrl.replace(
                            AppConsts.tenancyNamePlaceHolderInUrl,
                            tenancyName
                        );
                    }
                }
                else if(appRootUrl.includes('malitres')) {
                    // //use port 4300 for local Netsurit custom domain testing
                    //NetSurit custom config values
                    AppConsts.appBaseUrlFormat = result.malitresCustomConfig.appBaseUrl;
                    AppConsts.customDomain = result.malitresCustomConfig.appBaseUrl;
                    AppConsts.remoteServiceBaseUrlFormat = result.malitresCustomConfig.remoteServiceBaseUrl;
                    AppConsts.customTitle = result.malitresCustomConfig.title;
                    AppConsts.customFaviconUrl = result.malitresCustomConfig.faviconUrl;

                    const tenancyName = subdomainTenancyNameFinder.getCurrentTenancyNameOrNull(result.malitresCustomConfig.appBaseUrl);

                    if (tenancyName == null) {
                        AppConsts.appBaseUrl = result.malitresCustomConfig.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                        AppConsts.remoteServiceBaseUrl = result.malitresCustomConfig.remoteServiceBaseUrl.replace(
                            AppConsts.tenancyNamePlaceHolderInUrl + '.',
                            ''
                        );
                    } else {
                        AppConsts.appBaseUrl = result.malitresCustomConfig.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
                        AppConsts.remoteServiceBaseUrl = result.malitresCustomConfig.remoteServiceBaseUrl.replace(
                            AppConsts.tenancyNamePlaceHolderInUrl,
                            tenancyName
                        );
                    }
                } else {
                    //Default configs value
                    const tenancyName = subdomainTenancyNameFinder.getCurrentTenancyNameOrNull(result.appBaseUrl);

                    AppConsts.appBaseUrlFormat = result.appBaseUrl;
                    AppConsts.remoteServiceBaseUrlFormat = result.remoteServiceBaseUrl;
                    AppConsts.helpDeskEmail = result.helpDeskEmail;
                    AppConsts.helpDeskEmailAuthSupport = result.helpDeskEmailAuthSupport;
                    AppConsts.supportEmailAddress = result.supportEmailAddress;
                    AppConsts.customDomain = undefined;
                    AppConsts.customTitle = undefined;

                    if (tenancyName == null) {
                        AppConsts.appBaseUrl = result.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                        AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                    } else {
                        AppConsts.appBaseUrl = result.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
                        AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
                    }
                }

                //#region PaymentGatewayConfig 
                if (result.paymentGatewayConfig) {
                    AppConsts.paygateIntegrationMode =
                        result.paymentGatewayConfig.paygateIntegrationMode == undefined ? PaygateMode.Production : result.paymentGatewayConfig.paygateIntegrationMode;
                    if (result.paymentGatewayConfig.defaultTransactionStatus) {
                        AppConsts.defaultTransactionStatus = Number(result.paymentGatewayConfig.defaultTransactionStatus);
                    }
                    if (result.paymentGatewayConfig.localPaygateTestUrl) {
                        AppConsts.localPaygateTestUrl = result.paymentGatewayConfig.localPaygateTestUrl;
                    }
                    AppConsts.BlockCDCCloseCardForTesting = result.paymentGatewayConfig.BlockCDCCloseCardForTesting;
                }
                //#endregion

                AppConsts.localeMappings = result.localeMappings;
                AppConsts.partnerNetworkUrl = result.partnerNetworkUrl;
                AppConsts.googleAnalyticsTrackingId = result.googleAnaltics.googleAnalyticsTrackingId;
                AppConsts.enableTracking = result.googleAnaltics.enableTracking;
                AppConsts.idleTimeMinutes = result.idleTimeMinutes;

                callback();
            });
    }

    private static getCurrentClockProvider(currentProviderName: string): abp.timing.IClockProvider {
        if (currentProviderName === 'unspecifiedClockProvider') {
            return abp.timing.unspecifiedClockProvider;
        }

        if (currentProviderName === 'utcClockProvider') {
            return abp.timing.utcClockProvider;
        }

        return abp.timing.localClockProvider;
    }

    private static impersonatedAuthenticate(impersonationToken: string, tenantId: number, callback: () => void): JQueryPromise<any> {
        abp.multiTenancy.setTenantIdCookie(tenantId);
        const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        return abp
            .ajax({
                url: AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/ImpersonatedAuthenticate?impersonationToken=' + impersonationToken,
                method: 'POST',
                headers: {
                    '.AspNetCore.Culture': 'c=' + cookieLangValue + '|uic=' + cookieLangValue,
                    'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
                },
            })
            .done((result) => {
                abp.auth.setToken(result.accessToken);
                AppPreBootstrap.setEncryptedTokenCookie(result.encryptedAccessToken);
                location.search = '';
                callback();
            });
    }

    public static RefreshToken(accessToken: string, callback: () => void): JQueryPromise<any> {
        const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        return abp
            .ajax({
                url: AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/RefreshToken?token=' + accessToken,
                body: accessToken,
                method: 'POST',
                headers: {
                    '.AspNetCore.Culture': 'c=' + cookieLangValue + '|uic=' + cookieLangValue,
                    'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
                },
            })
            .done((result) => {
                abp.auth.setToken(result.accessToken);
                AppPreBootstrap.setEncryptedTokenCookie(result.encryptedAccessToken);
                location.search = '';
                if (callback) callback();
            });
    }

    private static linkedAccountAuthenticate(switchAccountToken: string, tenantId: number, callback: () => void): JQueryPromise<any> {
        abp.multiTenancy.setTenantIdCookie(tenantId);
        const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        return abp
            .ajax({
                url: AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/LinkedAccountAuthenticate?switchAccountToken=' + switchAccountToken,
                method: 'POST',
                headers: {
                    '.AspNetCore.Culture': 'c=' + cookieLangValue + '|uic=' + cookieLangValue,
                    'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
                },
            })
            .done((result) => {
                abp.auth.setToken(result.accessToken);
                AppPreBootstrap.setEncryptedTokenCookie(result.encryptedAccessToken);
                location.search = '';
                callback();
            });
    }

    private static getUserConfiguration(callback: () => void): JQueryPromise<any> {
        const cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        const token = abp.auth.getToken();

        let requestHeaders = {
            '.AspNetCore.Culture': 'c=' + cookieLangValue + '|uic=' + cookieLangValue,
            'Abp.TenantId': abp.multiTenancy.getTenantIdCookie(),
        };

        if (token) {
            requestHeaders['Authorization'] = 'Bearer ' + token;
        }

        return abp
            .ajax({
                url: AppConsts.remoteServiceBaseUrl + '/AbpUserConfiguration/GetAll',
                method: 'GET',
                headers: requestHeaders,
            })
            .done((result) => {
                $.extend(true, abp, result);

                abp.clock.provider = this.getCurrentClockProvider(result.clock.provider);

                moment.locale(abp.localization.currentLanguage.name);
                (window as any).moment.locale(abp.localization.currentLanguage.name);

                if (abp.clock.provider.supportsMultipleTimezone) {
                    moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
                    (window as any).moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
                }

                abp.event.trigger('abp.dynamicScriptsInitialized');

                AppConsts.recaptchaSiteKey = abp.setting.get('Recaptcha.SiteKey');
                AppConsts.subscriptionExpireNotifyDayCount = parseInt(abp.setting.get('App.TenantManagement.SubscriptionExpireNotifyDayCount'));

                LocalizedResourcesHelper.loadResources(callback);
            });
    }

    private static setEncryptedTokenCookie(encryptedToken: string) {
        new UtilsService().setCookieValue(
            AppConsts.authorization.encrptedAuthTokenName,
            encryptedToken,
            new Date(new Date().getTime() + 365 * 86400000), //1 year
            abp.appPath
        );
    }
}
