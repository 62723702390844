import { AbpModule } from '@abp/abp.module';
import * as ngCommon from '@angular/common';
import {NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule, JsonpModule } from '@angular/http';
import { QuickSideBarChat } from '@app/shared/layout/chat/QuickSideBarChat';
import { ChatSignalrService } from '@app/shared/layout/chat/chat-signalr.service';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FileUploadModule } from 'ng2-file-upload'; 
import {
    ModalModule,
    TabsModule,
    TooltipModule,
    CarouselModule
} from 'ngx-bootstrap';

import {
    FileUploadModule as PrimeNgFileUploadModule,
    PaginatorModule,
    ProgressBarModule,
    MultiSelectModule,
    EditorModule,
    SliderModule,
    ChipsModule,
    ColorPickerModule
} from 'primeng/primeng';
import { NgxEditorModule } from 'ngx-editor';
import { TableModule } from 'primeng/table';
import { ImpersonationService } from './admin/users/impersonation.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppCommonModule } from './shared/common/app-common.module';
import { ChatBarComponent } from './shared/layout/chat/chat-bar.component';
import { ChatFriendListItemComponent } from './shared/layout/chat/chat-friend-list-item.component';
import { ChatMessageComponent } from './shared/layout/chat/chat-message.component';
import { LinkedAccountService } from './shared/layout/linked-account.service';
import { SideBarMenuComponent } from './shared/layout/nav/side-bar-menu.component';
import { TopBarMenuComponent } from './shared/layout/nav/top-bar-menu.component';
import { UserNotificationHelper } from './shared/layout/notifications/UserNotificationHelper'; 
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ArchwizardModule } from 'angular-archwizard';
import { CustomFormsModule } from 'ng2-validation'
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AccordionModule } from 'primeng/accordion';
import { DetailDialogComponent } from './shared/common/generic-dialog/detail-dialog/detail-dialog.component';
import { SummaryDialogComponent } from './shared/common/generic-dialog/summary-dialog/summary-dialog.component'
import { GenericDialogService } from './shared/common/generic-dialog/generic-dialog.service';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { PaymentCompletedComponent } from './cart/payment-methods/PaymentCompleted/Payment-complete.component';
import { CustomReuseStrategy } from './reuse-strategy';
import { RouteReuseStrategy } from '@angular/router';
import {AppLayoutModule} from "@app/shared/layout/app-layout.module";
import {HorizontalScrollBarComponent} from "@app/main/Store/horizontal-scroll-bar/horizontal-scroll-bar.component";


@NgModule({
    declarations: [
        AppComponent,
        SideBarMenuComponent,
        TopBarMenuComponent,
        NotificationsComponent,
        PaymentCompletedComponent,
        ChatBarComponent,
        ChatFriendListItemComponent,
        ChatMessageComponent,
        DetailDialogComponent,
        SummaryDialogComponent
    ],
    entryComponents: [
        DetailDialogComponent,
        SummaryDialogComponent
    ],
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        HttpModule,
        JsonpModule,
        HttpClientModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        FileUploadModule,
        AbpModule,
        AppRoutingModule,
        UtilsModule,
        AppCommonModule.forRoot(),
        ServiceProxyModule,
        TableModule,
        PaginatorModule,
        PrimeNgFileUploadModule,
        ChipsModule,
        ProgressBarModule,
        MultiSelectModule,
        NgxEditorModule,
        EditorModule,
        NgScrollbarModule,
        SliderModule,
        ColorPickerModule,
        CarouselModule.forRoot(),
        ArchwizardModule,
        CustomFormsModule,
        NgxJsonViewerModule,
        NgxDocViewerModule,
        BsDatepickerModule.forRoot(),
        ProgressbarModule.forRoot(),
        NgbModule.forRoot(),
        AccordionModule,
        BrowserModule,
        AppLayoutModule
    ],
    providers: [
        ImpersonationService,
        LinkedAccountService,
        UserNotificationHelper,
        ChatSignalrService,
        QuickSideBarChat,
        GenericDialogService,
        Title,
        {provide: RouteReuseStrategy, useClass: CustomReuseStrategy}
    ] 
})
export class AppModule { }
